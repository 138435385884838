import React from 'react';
import PieChartComponent from './PieChartComponent';
import { Box, Typography } from '@mui/material';

const ProductFeedIssues = ({ data }) => {
  const pieChartData = [
    { name: 'Approved', value: data.approved_count, borderColor: "#038F80", bgcolor: "#e3fcf9" },
    { name: 'Limited', value: data.limited_count, borderColor: "#BA970B", bgcolor: "#fff0c4" },
    { name: 'Not approved', value: data.disapproved_count, borderColor: "#CF0505", bgcolor: "#fee8e8" },
    { name: 'Under review', value: data.under_review_count, borderColor: "#14B8A6", bgcolor: "#edfff5" },
  ];

  // Define a color map for each category
  const colorMap = {
    Approved: '#219653', // Green or another color
    Limited: '#F58120', // Amber color
    'Not approved': '#CF0505', // Red or another color
    'Under review': '#1EBC61', // Light blue or whatever color you choose
  };

  // Map pieChartData to their respective colors
  const colors = pieChartData.map(item => colorMap[item.name]);

  return (
    <Box bgcolor="#F9F9FA" p={2} borderRadius="8px">
      <Typography variant="body1" color="#777777" fontWeight={"600"} pb={1}>
        Total Approved Count: {data.approved_count}
      </Typography>
      <Typography color="#9C9C9C" fontWeight={"500"} lineHeight={"14px"}>
        {data.limited_count + data.disapproved_count} products require your attention
      </Typography>
      <PieChartComponent
        data={pieChartData}
        colors={colors}
        innerRadius={70}
        outerRadius={100}
      />
    </Box>
  );
};

export default ProductFeedIssues;
