import { Tab, Tabs } from "@mui/material";
import icons from "../../components/icons";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
function HeaderTabs(props) {
  const { pathname } = props;

  console.log("pathname==", pathname);

  const isHeaderRenderIn =
    (
      window.lxrOptions.defaultRoute == "dashboard" ||
      window.lxrOptions.defaultRoute == "ACCOUNT_SETTINGS" ||
      window.lxrOptions.defaultRoute == "PROFILE_SETTINGS" ||
      window.lxrOptions.defaultRoute == "PAYMENT_HISTORY" ||
      window.lxrOptions.defaultRoute == "CLIENT_SETTINGS" ||
      window.lxrOptions.defaultRoute == "PROPERTIES_AND_VIEWS") &&
    (
      pathname == "/dashboard" ||
      pathname == "/tasks/" ||
      pathname == "/reports" ||
      pathname == "/activity-report"
      );

      console.log("value in header tabs ===", props.value);
  
  return (
    <Fragment>
      {(props.width === "md" || props.width === "lg" || props.width === "xl") &&
        ((isHeaderRenderIn &&
          props.pricingPlanId != null &&
          props.pricingPlanId != 7) ||
          (props.pathname != "/account-settings" &&
            !(
              window.lxrOptions.defaultRoute == "ACCOUNT_SETTINGS" ||
              window.lxrOptions.defaultRoute == "PROFILE_SETTINGS" ||
              window.lxrOptions.defaultRoute == "CLIENT_SETTINGS" ||
              window.lxrOptions.defaultRoute == "PAYMENT_HISTORY" ||
              window.lxrOptions.defaultRoute == "PROPERTIES_AND_VIEWS"
            ) &&
            props.pathname != "/client-settings" &&
            props.pathname != "/payment-history" &&
            props.pathname != "/profile-settings" &&
            props.pathname != "/on-demand-service" &&
            props.pathname != "/auto-posting" &&
            props.pathname != "/connections-page" &&
            props.pathname != "/google-analytics-dashboard" &&
            props.pathname != "/google-analytics-dashboard-menu" &&
            props.pathname != "/properties-views" &&          
            props.pathname != "/holiday-package" &&
            props.pathname != "/subscriptions" &&
            props.pathname != "/site-grader-report/1" &&
            props.pathname != "/website-health-scorecard" &&
            props.pathname != "/whs-payment" &&
            props.pathname != "/intelli-Dashboard" &&
            props.pathname != "/tickets" &&
            !props.pathname.includes("/ticket/") &&
            props.pricingPlanId != null &&
            props.pricingPlanId != 7)) && (
          <Tabs
            value={props.value}
            onChange={props.onChange}
            TabIndicatorProps={{ style: { background: "#F58120" } }}
            textColor="primary"
            variant="scrollable"
            scrollButtons={props.open ? false : true}
            // scrollButtons={props.open ? "off" : "auto"}
            style={{ marginLeft: props.open ? "0px" : "24px" }}
            className={props.classes.tabsIndicator}
            classes={{
              root: props.classes.tabsRoot,
              indicator: props.classes.tabsIndicator,
            }}
          >
            <Tab
              id={"dashboard-tab"}
              label={
                <div>
                  <span className={props.classes.imageAlignment}>
                    {icons.dashboard(props.color)}
                  </span>{" "}
                  &nbsp;Dashboard
                </div>
              }
              component={Link}
              to={`/dashboard/`}
              /*icon={icons.dashboard("#000")}*/ className={props.classes.tab}
              classes={{
                root: props.classes.tabRoot,
                selected: props.classes.tabSelected,
              }}
            />
            <Tab
              style={{ paddingLeft: "48px" }}
              id={"task-tab"}
              label={
                <div>
                  <span className={props.classes.imageAlignment}>
                    {icons.tasks1(props.color1)}
                  </span>{" "}
                  {props.taskscount > 0 ? (
                    <Badge
                      badgeContent={props.taskscount}
                      classes={{ badge: props.classes.marginMain }}
                    >
                      <span>&nbsp;Tasks</span>
                    </Badge>
                  ) : (
                    <span className={props.classes.imageAlignment}>
                      &nbsp;Tasks
                    </span>
                  )}
                </div>
              }
              component={Link}
              to={`/tasks/`}
              /*icon={icons.tasks1("#000")}*/ className={props.classes.tab}
              classes={{
                root: props.classes.tabRoot,
                selected: props.classes.tabSelected,
              }}
            />
            <Tab
              style={{ paddingLeft: "48px" }}
              label={
                <div>
                  <span className={props.classes.imageAlignment}>
                    {icons.reports(props.color2)}
                  </span>{" "}
                  &nbsp;Reports
                </div>
              }
              component={Link}
              id={"reports-tab"}
              to={`/reports`}
              /*icon={icons.reports("#000")}*/ className={props.classes.tab}
              classes={{
                root: props.classes.tabRoot,
                selected: props.classes.tabSelected,
              }}
            />
            <Tab
              style={{ paddingLeft: "48px" }}
              label={
                <div>
                  <span className={props.classes.imageAlignment}>
                    {icons.activityReports(props.color3)}
                  </span>{" "}
                  &nbsp;Activity Report
                </div>
              }
              component={Link}
              to={`/activity-report`}
              id={"activity-reports-tab"}
              /*icon={icons.activityReports("#000")}*/ className={
                props.classes.tab
              }
              classes={{
                root: props.classes.tabRoot,
                selected: props.classes.tabSelected,
              }}
            />
          </Tabs>
        )}
    </Fragment>
  );
}

HeaderTabs.propTypes = {
  width: PropTypes.any,
  pathname: PropTypes.any,
  pricingPlanId: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  open: PropTypes.any,
  classes: PropTypes.any,
  color: PropTypes.any,
  color1: PropTypes.any,
  taskscount: PropTypes.number,
  color2: PropTypes.any,
  color3: PropTypes.any,
};

export default HeaderTabs;
