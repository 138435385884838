import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../jumpstart.css";
import { JsSectionTypography } from "../../../components/typography";
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Select,
  Slider,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { JsAddUserLabelTypography } from "../../../components/typography";
import {
  JsBlackContainedBtn,
  JsOrangeContainedBtn,
  NewOrangeContainedBtn,
} from "../../../components/buttons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import icons from "../../../components/icons";
import { ReplayOutlined } from "@mui/icons-material";
import { KeywordFilterOperators } from "../commonComponents/jumpstartEnum";

const thumbStyle = {
  width: "7px",
  height: "21px",
  borderRadius: "0px",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 2px 0px #00000033",
};

function CustomSlider(label, value, handleChange, isDisabled, ranges) {
  return (
    <Slider
      disabled={isDisabled == true ? false : true}
      getAriaLabel={() => label}
      value={value}
      onChange={handleChange}
      min={ranges && ranges[0] != null ? ranges[0] : 0}
      max={ranges && ranges[1] != null ? ranges[1] : 100}
      valueLabelDisplay="auto"
      slotProps={{
        thumb: {
          style: thumbStyle,
        },
      }}
      sx={{
        ".MuiSlider-rail": {
          color: "#F2F2F4",
        },
      }}
    />
  );
}

function SliderGrid(
  name,
  label,
  value,
  handleChange,
  isDisabled,
  handleSelectedFilters,
  ranges,
  handleError,
  currencySymbol
) {
  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Checkbox
          size="small"
          color="secondary"
          name={label}
          checked={isDisabled == true ? true : false}
          onChange={handleSelectedFilters}
        />
      </Grid>
      <Grid item>
        <JsAddUserLabelTypography>
          {name}{" "}
          {name === "90 Days Trend" || name === "Y-O-Y Trend"
            ? "(%)"
            : name === "Bid"
            ?  "(" + currencySymbol+ ")"
            : ""}
        </JsAddUserLabelTypography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        columnSpacing={2}
        xs={12}
        sx={{ padding: "0 7%", paddingBottom: "2%" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={5} container alignItems="center">
          <Typography fontSize="0.875rem" fontWeight={500} color="#9A9A9A">
            Min &nbsp;
          </Typography>
          <TextField
            id={`min-${label}`}
            name={`min-${label}`}
            variant="outlined"
            size="small"
            type="number"
            hiddenLabel
            onChange={handleChange}
            value={value[0]}
            sx={{ width: "70%" }}
            disabled={!isDisabled}
            error={value[0] === "" || Number(value[0]) > Number(value[1])}
            onKeyDown={(event) => {
              if ((name == "Monthly Search Volume" ) && (event?.key === '-' || event?.key === '+' || event?.key === '.') ) {
                event.preventDefault();
              }else if((name == "Bid" ) && (event?.key === '-' || event?.key === '+' )){
                event.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography align="center" color={"#CCCCCC"}>
            —
          </Typography>
        </Grid>
        <Grid item xs={5} container alignItems="center">
          <Typography fontSize="0.875rem" fontWeight={500} color="#9A9A9A">
            Max &nbsp;
          </Typography>
          <TextField
            id={`max-${label}`}
            name={`max-${label}`}
            variant="outlined"
            size="small"
            hiddenLabel
            type="number"
            value={value[1]}
            onChange={handleChange}
            sx={{ width: "70%" }}
            disabled={!isDisabled}
            error={value[1] === "" || Number(value[0]) > Number(value[1])}
            onKeyDown={(event) => {
              if ((name == "Monthly Search Volume" ) && (event?.key === '-' || event?.key === '+' || event?.key === '.') ) {
                event.preventDefault();
              }else if((name == "Bid" ) && (event?.key === '-' || event?.key === '+' )){
                event.preventDefault();
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ padding: "0 7%" }}>
        {CustomSlider(label, value, handleChange, isDisabled, ranges)}
      </Grid>
      {Number(value[0]) > Number(value[1]) ? (
        <Grid item xs={12} sx={{ padding: "0 7%" }}>
          <Typography fontSize={"0.8125rem"} color={"error"}>
            Min. value cannot be more than max. value
          </Typography>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}

function KeywordFilterDrawer(props) {
  const {
    handleCloseIcon,
    handleApplyFilters,
    filterCriterion,
    handleFilterCriterion,
    maxRanges,
    handleResetAll,
    currencySymbol,
  } = props;

  const [monthlySearchValue, setMonthlySearchValue] = useState([0, 100]);
  const [ninetyDaysTrendValue, setNinetyDaysTrendValue] = useState([0, 100]);
  const [yoyTrendValue, setYoyTrendValue] = useState([0, 100]);
  const [bid, setBid] = useState([0, 100]);
  const [competition, setCompetition] = useState({
    HIGH: false,
    MEDIUM: false,
    LOW: false,
    NA: false,
  });

  const [filtersSelected, setFiltersSelected] = useState({
    monthlySearchValue: false,
    ninetyDaysTrendValue: false,
    yoyTrendValue: false,
    bid: false,
    competition: false,
  });

  const [searchVolumeRange, setSearchVolumeRange] = useState([0, 100]);
  const [ninetyDaysTrendRange, setNinetyDaysTrendRange] = useState([0, 100]);
  const [yoyTrendRange, setYoyTrendRange] = useState([0, 100]);
  const [bidRange, setBidRange] = useState([0, 100]);
  const [error, setError] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  useEffect(() => {
    setMonthlySearchValue([maxRanges.minSearchVolumeRange, maxRanges.maxSearchVolumeRange]);
    setSearchVolumeRange([maxRanges.minSearchVolumeRange, maxRanges.maxSearchVolumeRange]);

    setNinetyDaysTrendValue([
      maxRanges.minNinetyDaysSearchVolumeTrend,
      maxRanges.maxNinetyDaysSearchVolumeTrend,
    ]);
    setNinetyDaysTrendRange([
      maxRanges.minNinetyDaysSearchVolumeTrend,
      maxRanges.maxNinetyDaysSearchVolumeTrend,
    ]);

    setYoyTrendValue([
      maxRanges.minYoYSearchVolumeTrend,
      maxRanges.maxYoYSearchVolumeTrend,
    ]);
    setYoyTrendRange([
      maxRanges.minYoYSearchVolumeTrend,
      maxRanges.maxYoYSearchVolumeTrend,
    ]);

    setBidRange([maxRanges.lowTopOfPageBid, maxRanges.maxHighTopOfPageBid]);
    setBid([maxRanges.lowTopOfPageBid, maxRanges.maxHighTopOfPageBid]);
  }, [maxRanges]);

  useEffect(() => {
    if (filterCriterion.length > 0) {
      updateStateBasedOnFieldName(filterCriterion);
    }
  }, [filterCriterion]);

  const updateStateBasedOnFieldName = (array) => {
   
    array.forEach((obj) => {
      if (obj.field === "searchVolume") {
        setMonthlySearchValue(obj.value);
        setFiltersSelected((prevState) => ({
          ...prevState,
          monthlySearchValue: true,
        }));
      } else if (obj.field === "ninetyDaysSearchVolumeTrend") {
        setNinetyDaysTrendValue(obj.value);
        setFiltersSelected((prevState) => ({
          ...prevState,
          ninetyDaysTrendValue: true,
        }));
      } else if (obj.field === "yearOverYearSearchVolumeTrend") {
        setYoyTrendValue(obj.value);
        setFiltersSelected((prevState) => ({
          ...prevState,
          yoyTrendValue: true,
        }));
      } else if (obj.field === "bid") {
        setBid(obj.value);
        setFiltersSelected((prevState) => ({
          ...prevState,
          bid: true,
        }));
      }else if (obj.field === "competition") {
        const competitionValue = obj.value.reduce(
          (acc, val) => {
            if(val) {
              acc[val] = true;
            } else {
              acc['NA'] = true;
            }
            return acc;
          },
          { HIGH: false, MEDIUM: false, LOW: false, NA: false }
        );
        setCompetition(competitionValue);
        setFiltersSelected((prevState) => ({
          ...prevState,
          competition: true,
        }));
      }
    });
  };

  useEffect(() => {
    if (competition.HIGH || competition.LOW || competition.MEDIUM || competition.NA) {
      setFiltersSelected((prevState) => ({
        ...prevState,
        competition: true,
      }));
    } else {
      setFiltersSelected((prevState) => ({
        ...prevState,
        competition: false,
      }));
    }
  }, [competition]);

  const handleSelectedFilters = (event) => {
    setFiltersSelected({
      ...filtersSelected,
      [event.target.name]: event.target.checked,
    });
  };

  const handleMonthlySearchValue = (event, newValue) => {
    if (newValue == undefined) {
      if (event.target.name == "min-monthlySearchValue") {
        setMonthlySearchValue([event.target.value, monthlySearchValue[1]]);
      } else if (event.target.name == "max-monthlySearchValue") {
        setMonthlySearchValue([monthlySearchValue[0], event.target.value]);
      }
    } else {
      setMonthlySearchValue(newValue);
    }
  };
  const handleNinetyDaysTrendSliderChange = (event, newValue) => {
    if (newValue == undefined) {
      if (event.target.name == "min-ninetyDaysTrendValue") {
        setNinetyDaysTrendValue([event.target.value, ninetyDaysTrendValue[1]]);
      } else if (event.target.name == "max-ninetyDaysTrendValue") {
        setNinetyDaysTrendValue([ninetyDaysTrendValue[0], event.target.value]);
      }
    } else {
      setNinetyDaysTrendValue(newValue);
    }
  };
  const handleYoyTrendSliderChange = (event, newValue) => {
    if (newValue == undefined) {
      if (event.target.name == "min-yoyTrendValue") {
        setYoyTrendValue([event.target.value, yoyTrendValue[1]]);
      } else if (event.target.name == "max-yoyTrendValue") {
        setYoyTrendValue([yoyTrendValue[0], event.target.value]);
      }
    } else {
      setYoyTrendValue(newValue);
    }
  };

  const handleBidSliderChange = (event, newValue) => {
    if (newValue == undefined) {
      if (event.target.name == "min-bid") {
        setBid([event.target.value, bid[1]]);
      } else if (event.target.name == "max-bid") {
        setBid([bid[0], event.target.value]);
      }
    } else {
      setBid(newValue);
    }
  };

  const handleCompetitionChange = (event) => {
    setCompetition({
      ...competition,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleApplySelectedFilters = () => {
    let filterList = [];
    if (
      filtersSelected.competition &&
      (competition.HIGH || competition.LOW || competition.MEDIUM || competition.NA)
    ) {
      let selectedCompetition = Object.entries(competition)
        .filter(([key, value]) => value && key !== "NA")
        .map(([key]) => key);
    
      if (competition.NA) {
        selectedCompetition.push(null);
      }
    
      const filter = {
        field: "competition",
        value: selectedCompetition,
        operation: KeywordFilterOperators.EQUALS,
      };
      filterList.push(filter);
    }

    if (filtersSelected.ninetyDaysTrendValue) {
      const filter = {
        field: "ninetyDaysSearchVolumeTrend",
        value: ninetyDaysTrendValue,
        operation: KeywordFilterOperators.IN_BETWEEN,
      };
      filterList.push(filter);
    }

    if (filtersSelected.yoyTrendValue) {
      const filter = {
        field: "yearOverYearSearchVolumeTrend",
        value: yoyTrendValue,
        operation: KeywordFilterOperators.IN_BETWEEN,
      };
      filterList.push(filter);
    }

    if (filtersSelected.monthlySearchValue) {
      const filter = {
        field: "searchVolume",
        value: monthlySearchValue,
        operation: KeywordFilterOperators.IN_BETWEEN,
      };
      filterList.push(filter);
    }

    if (filtersSelected.bid) {
      const filter = {
        field: "bid",
        value: bid,
        operation: KeywordFilterOperators.IN_BETWEEN,
      };
      filterList.push(filter);
    }
    handleApplyFilters(filterList);
  };

  // const handleResetAll = async () => {
  //   setBid([0, 10]);
  //   setMonthlySearchValue([0, 10]);
  //   setNinetyDaysTrendValue([0, 10]);
  //   setYoyTrendValue([0, 10]);
  //   setCompetition({
  //     HIGH: false,
  //     MEDIUM: false,
  //     LOW: false,
  //   });
  //   setFiltersSelected({
  //     monthlySearchValue: false,
  //     ninetyDaysTrendValue: false,
  //     yoyTrendValue: false,
  //     bid: false,
  //     competition: false,
  //   });
  //   await handleApplyFilters([]);
  //   await handleFilterCriterion([]);
  //   handleCloseIcon();
  // };

  const checkIsMinMoreThanMax = (min, max) => {
    if (Number(min) > Number(max)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (
      (filtersSelected.bid == true &&
        ((bid[0] == "" && bid[0] !== 0) ||
          bid[1] == "" ||
          checkIsMinMoreThanMax(bid[0], bid[1]))) ||
      (filtersSelected.monthlySearchValue == true &&
        ((monthlySearchValue[0] == "" && monthlySearchValue[0] !== 0) ||
          monthlySearchValue[1] == "")) ||
        checkIsMinMoreThanMax(monthlySearchValue[0], monthlySearchValue[1]) ||
      (filtersSelected.ninetyDaysTrendValue == true &&
        (ninetyDaysTrendValue[0] == "" ||
          ninetyDaysTrendValue[1] == "" ||
          checkIsMinMoreThanMax(
            ninetyDaysTrendValue[0],
            ninetyDaysTrendValue[1]
          ))) ||
      (filtersSelected.yoyTrendValue == true &&
        (yoyTrendValue[0] == "" ||
          yoyTrendValue[1] == "" ||
          checkIsMinMoreThanMax(yoyTrendValue[0], yoyTrendValue[1])))
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [
    filtersSelected,
    monthlySearchValue,
    ninetyDaysTrendValue,
    bid,
    yoyTrendValue,
  ]);

  return (
    <>
      <div
        style={{
          zIndex: 2000,
          backgroundColor: "#fff",
          position: "sticky",
          top: 0,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ padding: "2%", borderBottom: "1px solid #E6E6E6" }}
        >
          <Grid item xs={11}>
            <JsSectionTypography style={{ fontSize: "1rem" }}>
              Select Filters to Apply
            </JsSectionTypography>
          </Grid>
          <Grid item xs={1}>
            <IconButton aria-label="close" onClick={handleCloseIcon}>
              <CloseIcon color="secondary" />
            </IconButton>
          </Grid>
        </Grid>
      </div>

      <div className="content-container">
        <Grid
          container
          direction="row"
          columnSpacing={2}
          rowSpacing={2}
          style={{ padding: "2%" }}
        >
          {SliderGrid(
            "Monthly Search Volume",
            "monthlySearchValue",
            monthlySearchValue,
            handleMonthlySearchValue,
            filtersSelected.monthlySearchValue,
            handleSelectedFilters,
            searchVolumeRange,
            setError,
            currencySymbol
          )}
          {SliderGrid(
            "90 Days Trend",
            "ninetyDaysTrendValue",
            ninetyDaysTrendValue,
            handleNinetyDaysTrendSliderChange,
            filtersSelected.ninetyDaysTrendValue,
            handleSelectedFilters,
            ninetyDaysTrendRange,
            setError,
            currencySymbol
          )}
          {SliderGrid(
            "Y-O-Y Trend",
            "yoyTrendValue",
            yoyTrendValue,
            handleYoyTrendSliderChange,
            filtersSelected.yoyTrendValue,
            handleSelectedFilters,
            yoyTrendRange,
            setError,
            currencySymbol
          )}
          {SliderGrid(
            "Bid",
            "bid",
            bid,
            handleBidSliderChange,
            filtersSelected.bid,
            handleSelectedFilters,
            bidRange,
            setError,
            currencySymbol
          )}
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item style={{ padding: "0 3%" }}>
              <JsAddUserLabelTypography>Competition: </JsAddUserLabelTypography>
            </Grid>
            <Grid item style={{ padding: "0 3%" }}>
              <FormControl
                component="fieldset"
                variant="standard"
                style={{ paddingBottom: "2%" }}
              >
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    name="HIGH"
                    control={
                      <Checkbox
                        color="secondary"
                        size="small"
                        checked={competition.HIGH}
                        onChange={(e) => handleCompetitionChange(e)}
                      />
                    }
                    label={
                      <Typography variant="subtitle2" color="#747474">
                        High
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    name="MEDIUM"
                    control={
                      <Checkbox
                        color="secondary"
                        size="small"
                        checked={competition.MEDIUM}
                        onChange={(e) => handleCompetitionChange(e)}
                      />
                    }
                    label={
                      <Typography variant="subtitle2" color="#747474">
                        Medium
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    name="LOW"
                    control={
                      <Checkbox
                        color="secondary"
                        size="small"
                        checked={competition.LOW}
                        onChange={(e) => handleCompetitionChange(e)}
                      />
                    }
                    label={
                      <Typography variant="subtitle2" color="#747474">
                        Low
                      </Typography>
                    }
                  />
                   <FormControlLabel
                    name="NA"
                    control={
                      <Checkbox
                        color="secondary"
                        size="small"
                        checked={competition.NA}
                        onChange={(e) => handleCompetitionChange(e)}
                      />
                    }
                    label={
                      <Typography variant="subtitle2" color="#747474">
                        NA
                      </Typography>
                    }
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="footer-pin">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{ backgroundColor: "#F8F9F9", padding: "2%" }}
        >
          <Grid item xs={9}>
            <Button
              onClick={handleResetAll}
              disabled={filterCriterion && filterCriterion.length == 0}
              variant="text"
              sx={{
                textTransform: "none",
                color: "#747474",
                fontWeight: "500",
              }}
            >
              <ReplayOutlined /> Reset All
            </Button>
          </Grid>
          <Grid item xs={3}>
            <JsOrangeContainedBtn
              disabled={
                error ||
                (filterCriterion &&
                  filterCriterion.length == 0 &&
                  filtersSelected.bid == false &&
                  filtersSelected.competition == false &&
                  filtersSelected.monthlySearchValue == false &&
                  filtersSelected.ninetyDaysTrendValue == false &&
                  filtersSelected.yoyTrendValue == false)
              }
              onClick={() => handleApplySelectedFilters()}
              sx={{ float: "right", borderRadius: 0 }}
            >
              Apply
            </JsOrangeContainedBtn>
          </Grid>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={error ? "error" : "success"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMsg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeywordFilterDrawer);
