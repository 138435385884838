import * as types from "./actionTypes";

export const domainUrl = "http://localhost:8080";
import Cookies from "js-cookie";
export function fetchGoogleBingPerformanceData(clientId, startDate, endDate) {
  return async function (dispatch) {
 
    const url = `/performance-data.json?clientId=${clientId}`;
    dispatch({
      type: types.FETCH_GOOGLE_BING_PERFORMANCE_DATA_REQUESTED,
      
    });

    try {
      let response = await fetch(url, {
        method: "POST", // Use POST request as expected by the server
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure JSON content type
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        body: JSON.stringify([startDate, endDate ]), // Send the request body
      });

      let data = await response.json(); // Expect a JSON response

      dispatch({
        type: types.FETCH_GOOGLE_BING_PERFORMANCE_DATA_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_BING_PERFORMANCE_DATA_FAILED,
      });
      console.error("Error fetching GoogleBing performance scores: ", e);
    }
  };
}
