import Cookies from "js-cookie";
export var testAPIGateWayUrl;
export var domainUrl;
export var testAPIGateWayUrlChatbot;
// Get the hostname from the window location
const hostname = window.location.hostname;
export var cookieDomain;
// Determine the environment based on the hostname
if (hostname.includes("guide.netelixir.com")) {
    // Test environment
    testAPIGateWayUrl = "https://lxrguideapi.netelixir.com";
    cookieDomain = 'guide.netelixir.com';
    // console.log("Using test environment:", testAPIGateWayUrl);
} else if (hostname.includes("lxrguide.com")) {
    // Production environment
    testAPIGateWayUrl = "https://lxrguideprodapi.netelixir.com";
    cookieDomain = '.lxrguide.com';

    // console.log("Using production environment:", testAPIGateWayUrl);
} else if (hostname.includes("localhost")) {
    // Local development environment
    testAPIGateWayUrl = "http://localhost:8765";
    cookieDomain = 'localhost';

    // console.log("Using local environment:", testAPIGateWayUrl);
} else {
    // Fallback or unknown environment
    testAPIGateWayUrl = "http://localhost:8765"; // Local as fallback
   cookieDomain = 'localhost';

    // console.warn("Unknown environment, defaulting to local");
}

// Set the additional URLs based on the determined environment
testAPIGateWayUrlChatbot = testAPIGateWayUrl + "/chatbot/webhooks/rest/webhook";
domainUrl = testAPIGateWayUrl;

export const storeTokensInCookies = (name, token, sameSite) => {
    const options = {
        secure: true,
        sameSite: sameSite,
      };
    options.domain = cookieDomain;
    Cookies.set(name, token, options);
  };