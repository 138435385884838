import { Typography } from '@mui/material';
export const BigTitle = (props) => (
    <Typography variant="h4" sx={{ 
      fontWeight: 'bold', 
      color: '#333', 
      fontSize: { xs: '1.5rem', sm: '2rem' } // Responsive font size
    }} {...props} />
  );
  
  export const GraphTitle = (props) => {
    const { sx = {}, ...otherProps } = props;
    return (
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 'normal',
          color: '#333',
          fontSize: { xs: '1.5rem', sm: '2rem' }, // Default responsive font size
          ...sx, // Merge other sx styles passed in props
        }}
        {...otherProps}
      />
    );
  };
  export const CardTitle = (props) => {
    const { sx = {}, ...otherProps } = props;
    return (
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'normal',
          color: '#333',
          fontSize: { xs: '1.5rem', sm: '2rem' }, // Default responsive font size
          ...sx, // Merge other sx styles passed in props
        }}
        {...otherProps}
      />
    );
  };
