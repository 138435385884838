import axios from "axios";
import * as types from "./actionTypes"; // Ensure you have proper action types defined
import { testAPIGateWayUrlChatbot } from "../actions/jumpstartDomain";
import Cookies from "js-cookie";
export function fetchIntelliChatBotResponse(
  userId,
  message,
  ppc_id,
  clientId,
  current_date_range,
  previous_date_range,section
) {
  return async function (dispatch) {
    const url = testAPIGateWayUrlChatbot;
    // Dispatch an action indicating the chatbot response process has started
    dispatch({ type: types.INTELLI_CHATBOT_RESPONSE_REQUESTED });

    // Set up the request data payload
    const customData = {
      sender: userId,
      message: message,
      metadata: {
        user_id: userId,
        ppc_id: ppc_id,
        client_id: clientId,
        current_date_range: current_date_range,
        previous_date_range: previous_date_range,
        section: section,
      },
    };

    try {
      // Debugging point to ensure the data structure is correct


      // Make the API request using axios for better handling
      const response = await axios.post(url, customData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get('jwt_token')}`,
        },
        withCredentials: true, // Include cookies if required for authentication
      });

      

      // Dispatch an action with the chatbot response data
      dispatch({
        type: types.INTELLI_CHATBOT_RESPONSE_RECEIVED,
        data: response.data,
      });

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);

      // Dispatch a failure action
      dispatch({ type: types.INTELLI_CHATBOT_RESPONSE_FAILED });

      // Log server-side errors for debugging if available
      if (error.response) {
        console.error("Server error response:", error.response.data);
      }

      // Re-throw the error for further handling if needed
      throw error;
    }
  };
}

export function fetchIntelliChatBotNormalResponse(
  userId,
  message,
  ppc_id,
  clientId
) {
  return async function (dispatch) {
    const url = testAPIGateWayUrlChatbot;
    // Dispatch an action indicating the chatbot response process has started
    dispatch({ type: types.INTELLI_CHATBOT_NORMAL_RESPONSE_REQUESTED });

    // Set up the request data payload
    const customData = {
      sender: userId,
      message: message,
      metadata: {
        user_id: userId,
        ppc_id: ppc_id,
        client_id: clientId,
      },
    };

    try {
      // Debugging point to ensure the data structure is correct
      
      // Make the API request using axios for better handling
      const response = await axios.post(url, customData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get('jwt_token')}`,
        },
        withCredentials: true, // Include cookies if required for authentication
      });

      console.log("Response received normal chatbot:", response.data);

      // Dispatch an action with the chatbot response data
      dispatch({
        type: types.INTELLI_CHATBOT_NORMAL_RESPONSE_RECEIVED,
        data: response.data,
      });

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);

      // Dispatch a failure action
      dispatch({ type: types.INTELLI_CHATBOT_NORMAL_RESPONSE_FAILED });

      // Log server-side errors for debugging if available
      if (error.response) {
        console.error("Server error response:", error.response.data);
      }

      // Re-throw the error for further handling if needed
      throw error;
    }
  };
}
