import * as types from "./actionTypes";
import Cookies from "js-cookie";

export function getAuthUrls() {
    return async function (dispatch) {
        try {
            await dispatch({
                type: types.AUTH_URLS_REQUESTED
            });
            let url = "/auth-urls.json";
            let response = await fetch(url, {
                method: "Get",
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include',
            });
            let data = await response.json();
            console.log("updated Selected Received", data);
            await dispatch({
                type: types.AUTH_URLS_RECEIVED,
                data
            });
            return data;
        } catch (e) {
            dispatch({type: types.AUTH_URLS_FAILED});
        }
    }
}

export function accessStatus(searchEngineId) {
    return async function (dispatch) {

        let url = "/account-refresh-status.json";
        if (searchEngineId == 2) {
            url = "/bing-account-refresh-status.json";
        }
        dispatch({
            type: types.Account_Import_STATUS_REQUESTED
        });
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
                },
                'credentials': 'include',
            });
            let data = await response.json();
            console.log("data = ", data);
            dispatch({
                type: types.Account_Import_STATUS_RECEIVED,
                data
            });
        } catch (e) {
            dispatch({
                type: types.Account_Import_STATUS_FAILED,
            });
            console.log("Error in getting account access Status=", e);
        }
    }
}

export function mccAccountSubmit(accountId, searchEngineId) {
    return async function (dispatch) {
        let url = "/google-mcc-account-refresh.json";
        if (searchEngineId == 2) {
            url = "/bing-mcc-account-import-refresh.json";
        }
        let response = await fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
            },
            'credentials': 'include',
            body: new URLSearchParams({
                selectedId: accountId,
            })
        });
        let data = await response.json();
        // console.log("MccAccountStatusResponse= ", data);
        dispatch({
            type: types.Account_Import_STATUS_RECEIVED,
            data
        });

    }

}

export function updateRefreshAccessSuccess() {
    return {
        type: types.UPDATE_REFRESH_ACCESS_SUCCESS,
    };
}
export function resetData() {
    return async function (dispatch) {
        const url = "/reset-data.json";
        try {
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
                },
                'credentials': 'include',
            });
            let data = await response.json();
            console.log("data = ", data);
            return data;
        } catch (e) {
            dispatch({
                type: types.ADD_CARD_DETAILS_FAILED,
            })
        }
    }
}