import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import icons from "../../components/icons";

function TicketTypeContainer(props) {
  const { type, count, loading } = props;
  const [icon, setIcon] = useState(icons.TotalTickets);
  useEffect(() => {
    switch (type) {
      case "Total Tickets":
        setIcon(icons.TotalTickets);
        break;
      case "Open Tickets":
        setIcon(icons.OpenTickets);
        break;
      case "Closed Tickets":
        setIcon(icons.ClosedTickets);
        break;
      default:
        setIcon(icons.TotalTickets);
        break;
    }
  });
  return (
    <Paper
      elevation={1}
      style={{
        padding: "1% 2%",
        alignContent: "center",
      }}
    >
      <Grid container direction={"row"} alignItems={"center"} columnSpacing={4}>
        <Grid item xs={4}>
          <Typography>{icon}</Typography>
        </Grid>
        <Grid container item xs={8} direction={"column"}>
          <Grid item>
            {loading ? (
              <Skeleton />
            ) : (
              <Typography fontWeight={"bold"} fontSize={"1.375rem"}>
                {count}
              </Typography>
            )}
          </Grid>
          <Grid item>
            {loading ? (
              <Skeleton />
            ) : (
              <Typography fontWeight={"regular"} fontSize={"1rem"}>
                {type}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTypeContainer);
