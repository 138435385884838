import React from "react";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography, Chip } from '@mui/material';
import "./NewDashBoard.css";
import GoogleAds from "../../assets/img/connection_page/Google_Ads.svg";
import BingAds from "../../assets/img/connection_page/Bing_Ads.svg";


const TaskList = ({ data }) => {

  const tasks = [
    ...data.googleAds.map((task) => ({ ...task, platform: "googleAds" })),
    ...data.bingAds.map((task) => ({ ...task, platform: "bingAds" })),
  ];
  
  const getStatusChip = (status) => {
    const statusMap = {
      0: { label: "Pending", color: "#F58120" },
      2: { label: "Completed", color: "#3EBC61" },
      default: { label: "Failed", color: "#CF0505" },
    };
    const { label, color } = statusMap[status] || statusMap.default;
    return <Chip label={label} 
    sx={{ background: color,
       color: "#fff",
        width: "100%", 
     }} />;
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper} className="custom-table-container">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="styled-header-cell" colSpan={2} align="left" width={350}>
                  Tasks
                </TableCell>
                <TableCell className="styled-header-cell" align="left" width={20}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.length > 0 ? (
                tasks.map((task, index) => (
                  <TableRow key={index}>
                    <TableCell className="styled-body-cell" width={40} align="center"><img src={task.platform === "googleAds" ? GoogleAds : BingAds} alt="Google Ads" style={{ width: "100%" }} /></TableCell>
                    <TableCell className="styled-body-cell" width={300}>
                      <Typography variant="body2" fontWeight={500} color="#414141" sx={{ pb: "3px" }}>{task.taskName}</Typography>
                      {task.timeEstimate && (
                        <Typography variant="body2" color="#F58120" display={"flex"} alignItems={"center"}>
                          <TimerOutlinedIcon color="secondary" fontSize="small" /> {task.timeEstimate}  mins average time saving per week
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell className="styled-body-cell" >
                      {getStatusChip(task.actionStatus)}
                    </TableCell>
                  </TableRow>
                ))
              ): (
                <TableRow>
                  <TableCell className="styled-body-cell" colSpan={3} align="center">
                    No tasks available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TaskList;