
import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import "../NewHomeDashboard/NewDashBoard.css";

// Custom Tooltip component
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tool-tip" >
        <p className='custom-tool-tip-value' >{`${payload[0].name}`}</p>
        <p  >{`Count: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};
const renderCustomizedLegend = (props) => {
  const { payload } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px", 
    margin: "10px 10px 10px 10px",
    // margin:
    width:"100%" }}
    >
      {payload.map((entry, index) => {
        return (
          <div
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.875rem",
              color: "#333",
            }}
          >{
            console.log("entry.payload.bgcolor==>",entry.payload.payload.fill)
          }
            {/* Color box */}
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: entry.payload.payload.fill,
                marginRight: "8px",
              }}
            ></div>
            {/* Label and percentage */}
            <span style={{ flex: 3}}>{entry.value}</span>
            <span>{entry.payload.value}</span>
          </div>
        );
      })}
    </div>
  );
};
const PieChartComponent = ({ data, colors, innerRadius, outerRadius }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect if screen is mobile
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#8884d8"
       
        >
          {data.map((entry, index) => (
            <Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend
          content={renderCustomizedLegend}
          layout={isMobile ? 'horizontal' : 'vertical'} // Adjust layout based on screen size
          align={isMobile ? 'center' : 'right'}
          verticalAlign={isMobile ? 'bottom' : 'middle'}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
