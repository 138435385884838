// typography.js
import styled from 'styled-components';
import { ChatBotTheme } from './ChatbotTheme';

export const Heading1 = styled.h1`
  font-size: clamp(2rem, 2.5vw + 1rem, 2.5rem);
  font-weight: ${({ Chatbottheme }) => Chatbottheme.fontWeights.bold};
  line-height: ${({ Chatbottheme }) => Chatbottheme.lineHeights.heading1};
  color: ${({ Chatbottheme }) => Chatbottheme.colors.primary};
`;

export const Heading2 = styled.h2`
  font-size: clamp(1.75rem, 2vw + 0.5rem, 2rem);
  font-weight: ${({ Chatbottheme }) => Chatbottheme.fontWeights.bold};
  line-height: ${({ Chatbottheme }) => Chatbottheme.lineHeights.heading2};
  color: ${({ Chatbottheme }) => Chatbottheme.colors.primary};
`;

export const BodyText = styled.p`
  font-size: clamp(0.875rem, 1.5vw + 0.25rem, 1rem);
  font-weight: ${({ Chatbottheme }) => Chatbottheme.fontWeights.normal};
  line-height: ${({ Chatbottheme }) => Chatbottheme.lineHeights.bodyText};
  color: ${({ Chatbottheme }) => Chatbottheme.colors.secondary};
`;

export const OrangeTextCB = styled.p`
  font-size: clamp(0.875rem, 1.5vw + 0.25rem, 1.2rem);
  font-weight: ${({ theme }) => ChatBotTheme.fontWeights.welcomeFont};
  line-height: ${({ theme }) => ChatBotTheme.lineHeights.bodyText};
  color: ${({ theme }) => ChatBotTheme.colors.secondary};
  font-family:${({theme})=> ChatBotTheme.fontFamily.primary};
`;