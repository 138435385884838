import * as types from "./actionTypes";
import Cookies from "js-cookie";
export function fetchSubscriptions() {
    return async function (dispatch) {
        console.log("fetching User info SUBSCRIPTIONS");
        try {
            dispatch({
                type: types.SUBSCRIPTIONS_REQUESTED,
            });
            let url = "/user-subscriptions.json";
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
                },
                'credentials': 'include'
            });
            let data = await response.json();
            console.log("ACTIVITY REPORT-123", data);
            await dispatch({
                type: types.SUBSCRIPTIONS_RECEIVED,
                data,
            });
            return data;
        } catch (e) {
            dispatch({type: types.SUBSCRIPTIONS_FAILED});
        }
    }
}

export function unSubscribePack(id) {
    return async function (dispatch) {
        console.log("Deleting SUBSCRIPTION");
        try {
            dispatch({
                type: types.UN_SUBSCRIPTIONS_REQUESTED,
            });
            let url = "/user-unsubscribe.json";
            let response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
                },
                'credentials': 'include',
                body: new URLSearchParams({subscriptionId: id})
            });
            let data = await response.json();
            await dispatch({
                type: types.UN_SUBSCRIPTIONS_RECEIVED,
                data: data, id: id
            });
            return data;
        } catch (e) {
            dispatch({type: types.UN_SUBSCRIPTIONS_FAILED});
        }
    }
}

export function unSubscribeService(subscribeId) {
    return async function (dispatch) {
        console.log("fetching User info SUBSCRIPTIONS");
        try {
            dispatch({
                type: types.UN_SUBSCRIPTIONS_REQUESTED,
            });
            let url = "/un-subscription.json";
            let response = await fetch(url, {
                headers: {
                    'Accept': 'application/json',
                },
                'credentials': 'include'
            });
            let data = await response.json();
            console.log("ACTIVITY REPORT-123", data);
            await dispatch({
                type: types.UN_SUBSCRIPTIONS_RECEIVED,
                data,
            });
            return data;
        } catch (e) {
            dispatch({type: types.UN_SUBSCRIPTIONS_FAILED});
        }
    }
}