import * as types from "../actions/actionTypes";

export function fetchWHSScoresByDateRangesReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_WHS_SCORES_BY_DATE_RANGE_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_WHS_SCORES_BY_DATE_RANGE_REQUESTED",
        });
      case types.FETCH_WHS_SCORES_BY_DATE_RANGE_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_WHS_SCORES_BY_DATE_RANGE_RECEIVED",
        });
      case types.FETCH_WHS_SCORES_BY_DATE_RANGE_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_WHS_SCORES_BY_DATE_RANGE_FAILED",
        });
      default:
        return state;
    }
  }
