import * as types from "./actionTypes";
import Cookies from "js-cookie";
export function fetchAllTickets(clientId, filter) {
    return async function (dispatch) {
      const url = "/tickets.json?clientId=" + clientId + "&filter=" + filter;
      dispatch({
        type: types.FETCH_ALL_TICKETS_REQUESTED,
        clientId,
        filter
      });
      try {
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        let data = await response.json();
  
        dispatch({
          type: types.FETCH_ALL_TICKETS_RECEIVED,
          clientId,
          filter,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.FETCH_ALL_TICKETS_FAILED,
          clientId,
          filter
        });
      }
    };
  }


  export function fetchTicketSummary(clientId, ticketId) {
    console.log("fetchTicketSummary", clientId, ticketId);
    return async function (dispatch) {
      const url = "/fetch-ticket.json?clientId=" + clientId + "&ticketId=" + ticketId;
      dispatch({
        type: types.FETCH_TICKET_DETAILS_REQUESTED,
        clientId,
        ticketId
      });
      try {
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        let data = await response.json();
        dispatch({
          type: types.FETCH_TICKET_DETAILS_RECEIVED,
          clientId,
          ticketId,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.FETCH_TICKET_DETAILS_FAILED,
          clientId,
          ticketId
        });
      }
    };
  }


  export function updateTicketSummary(ticketSummary, ticketId) {
    return async function (dispatch) {
      const newTicketSummary = { ...ticketSummary };
      delete newTicketSummary.ticketId;
      const url = "/update-ticket.json?ticketId=" + ticketId;
      dispatch({
        type: types.UPDATE_TICKET_DETAILS_REQUESTED,
        ticketSummary,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
          },
          credentials: "include",
          body: JSON.stringify(newTicketSummary),
        });
  
        let data;
        if (response.ok) {
          data = await response.json();
          data = { ...data, responseStatus: response.status };
        } else {
          data = { error: true, status: response.status };
        }
  
        dispatch({
          type: types.UPDATE_TICKET_DETAILS_RECEIVED,
          ticketSummary,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.UPDATE_TICKET_DETAILS_FAILED,
          ticketSummary,
          error: e.message,
        });
        return { error: true, message: e.message };
      }
    };
  }
  