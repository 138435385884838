import React, { Component, Fragment, useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { store } from "../../reducers/common";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  Radio,
  TableBody,
  FormControl,
  DialogContentText,
  DialogContent,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import accountSettingsStyles from "../../assets/jss/accountSettings/accountSettingStyle";
import {
  CircularProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
  DialogTitle,
} from "@mui/material";
import { connect } from "react-redux";
import {
  handleAccountToggles,
  updateAccountDetails,
  updateSelectedAccount,
} from "../../actions/fetchingUserData";
import AccountSettingsForm from "./accountSettingsForm";
import MySnackbarWrapper from "../snackbars";
import { fetchDashboardData } from "../header";
import {
  getAuthUrls,
  accessStatus,
  mccAccountSubmit,
  updateRefreshAccessSuccess,
} from "../../actions/fetchAccountSettingsData";
import * as _ from "lodash";
import {
  NewOrangeLargeBtn,
  NewTextRectOrangeTextBtn2,
} from "../../components/buttons";
import {
  AGENCY_ELITE,
  AGENCY_PREMIER,
  AGENCY_PROFESSIONAL,
  AGENCY_PROFESSIONAL_PLUS,
} from "../../actions/actionTypes";
import { ErrorTypography } from "../../components/typography";
import Checkbox from "@mui/material/Checkbox";
import icons from "../../components/icons";
import Warning from "@mui/icons-material/Warning";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { fetchPerfData } from "../../actions";
import { resetData } from "../../actions/fetchAccountSettingsData";

export const CustomTableCellHead = withStyles((theme) => ({
  head: {
    background: "rgba(242, 243, 249, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    borderRadius: "10px 10px 0px 0px",
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: "Lato",
  },
  // label
}))(TableCell);
const DialogHead = withStyles((theme) => ({
  root: {
    fontWeight: "900",
    color: theme.palette.text.primary,
    fontFamily: "Lato",
  },
}))(DialogTitle);
const PopUpOrangeBtn = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    color: theme.palette.orangeColor,
  },
}))(Button);
export const PopUpCommonBtn = withStyles((theme) => ({
  root: {
    fontWeight: "400",
    color: theme.palette.text.primary,
  },
}))(Button);
const DialogText = withStyles((theme) => ({
  root: {
    fontWeight: "lighter",
    fontSize: 16,
    color: theme.palette.text.primary,
    fontFamily: "Lato",
  },
}))(DialogContentText);
export const CustomTableCell = withStyles((theme) => ({
  body: {
    background: "rgba(255, 255, 255, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    fontFamily: "Lato",
  },
  // label
}))(TableCell);
export const SelectedTableCell = withStyles((theme) => ({
  body: {
    background: "rgba(255, 255, 255, 0.6)",
    border: "1px solid #E3E6F4",
    boxSizing: "border-box",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(16),
    fontFamily: "Lato",
  },
  // label
}))(TableCell);
export const KpiType = {
  1: "R/C",
  2: "C/R",
  3: "CPO",
  4: "CPL",
};
export const pricingDetails = {
  AGENCY_PROFESSIONAL:
    "You have reached maximum account limit as per your pricing plan. Please contact support@lxrguide.com to add new account.",
  AGENCY_ELITE:
    "You have reached maximum account limit as per your pricing plan. Please contact support@lxrguide.com to add new account.",
  AGENCY_PROFESSIONAL_PLUS:
    "You have reached maximum account limit as per your pricing plan. Please contact support@lxrguide.com to add new account.",
  AGENCY_PREMIER: "",
};

function pricingCalculation(pricingDetailsId, userInfo) {
  const count = userInfo
    ? _.map(userInfo.clientInfo, (client) =>
        _.flatMap(client.accounts).filter(
          (account) => account.searchEngineId == 1
        )
      ).length
    : null;
  console.log("count=", count);
  let accLimit = "";
  if (
    pricingDetailsId == AGENCY_PROFESSIONAL ||
    pricingDetailsId == AGENCY_ELITE ||
    pricingDetailsId == AGENCY_PROFESSIONAL_PLUS
  ) {
    if (count >= userInfo.getPricingPlan().getAccountLimit()) {
      accLimit =
        "You have reached maximum account limit as per your pricing plan. Please contact support@lxrguide.com to add new account.";
    }
  }
  return accLimit;
}

window.refreshAfterSuccess = () => {
  console.log("Google access");
  // window.searchEngineId = 1;
  store.dispatch(accessStatus(1));
};

window.refreshAfterBingSuccess = () => {
  console.log("Bing access");
  // window.searchEngineId = 2;
  store.dispatch(accessStatus(2));
  // store.dispatch(toggleSignUpSteppers(1, true, ""))
};

function AccountSettings(props) {
  const [state, setState] = useState({
    name: "",
    alert: false,
    message: "",
    variantType: "info",
    bingDialog: false,
    bingWarningChek: false,
  });

  const {
    classes,
    userDetails,
    handleAccountToggles,
    updateAccountDetails,
    accountSettingsData,
    isMcc,
    integrationStatus,
    clientHashMap,
  } = props;

  const {
    selectedClientInfo,
    selectedAccountInfo,
    isLoading,
    isAccountDetailsLoading,
    userInfo,
  } = userDetails;

  const { accessSuccess, errorMsg } = accountSettingsData;

  const selectedPccId =
    selectedAccountInfo && selectedAccountInfo.ppcId
      ? selectedAccountInfo.ppcId
      : 0;

  const pricingDetailId =
    userInfo && userInfo.paymentDetails
      ? userInfo.paymentDetails.paymentDetailId
      : 0;

  const isBingAdd =
    !_.isEmpty(selectedClientInfo) &&
    selectedClientInfo.accounts.filter(
      (account) => account.searchEngineId === 1 || account.searchEngineId === 2
    ).length > 0;

  console.log("selectedAccountInfo=", selectedAccountInfo);
  console.log("selectedClientInfo=", selectedClientInfo);
  console.log("accountSettingsData=", accountSettingsData);
  console.log("accessSuccess=", accessSuccess);

  useEffect(() => {
    document.title = "LXRGuide - Account Settings";
    props.getAuthUrls();
    if (window.hopscotch && window.hopscotch != null) {
      const currentStep = window.hopscotch.getCurrStepNum();
      console.log("currentStep=", currentStep);
      if (currentStep) {
        const currentTour = window.hopscotch.getCurrTour();
        console.log("currentTour=", currentTour);
        window.hopscotch.startTour(currentTour, currentStep);
      }
    }
  }, []);

  useEffect(() => {
    const pricingPlanId =
      userInfo && userInfo.pricingPlan
        ? userInfo.pricingPlan.pricingPlanId
        : null;
    const isAccountDetailsUpdated =
      props.userDetails.selectedAccountInfo.enabled !==
      userDetails.selectedAccountInfo.enabled;

    if (
      props.userDetails.selectedAccountInfo &&
      props.userDetails.selectedAccountInfo.ppcId ===
        userDetails.selectedAccountInfo.ppcId &&
      pricingPlanId !== null &&
      pricingPlanId !== 7 &&
      isAccountDetailsUpdated
    ) {
      fetchPerfData();
    }
  }, [userDetails]);

  const handleChange = (name) => (event) => {
    setState({
      [name]: event.target.value,
    });
  };

  const updateSelectedAccount = async (ppcId) => {
    const { userDetails } = props;
    const selectedPpcId = userDetails.selectedAccountInfo.ppcId
      ? userDetails.selectedAccountInfo.ppcId
      : 0;
    if (ppcId == selectedPpcId) {
      return;
    }
    await props.updateSelectedAccount(ppcId);
  };

  const handleUpdate = async ({
    ppcId,
    budget,
    targetValue,
    kPIMetric,
    emailIds,
  }) => {
    let response = await props.updateAccountDetails({
      ppcId,
      budget,
      targetValue,
      kPIMetric,
      emailIds,
    });
    if (response !== null && response.error && response.error != "") {
      setState({
        alert: true,
        message: response.error,
        variantType: "error",
      });
    } else {
      setState({
        alert: true,
        message: "Account Details Updated Successfully",
        variantType: "success",
      });
    }
  };

  const handleCloseAlert = async () => {
    setState((prevState) => ({
      ...prevState,
      alert: false,
      message: "",
    }));
    props.updateRefreshAccessSuccess();
  };

  const onAccountSubmit = () => {
    props.mccAccountImport(state.selectedAccountId, window.searchEngineId);
  };

  const handleRadioChange = (event) => {
    console.log("the radio value is: " + event);
    setState({ selectedAccountId: event.target.value });
  };

  const handleBindAdding = (event) => {
    console.log("handleBindAdding-->",event)
    // setState({ bingDialog: true });
    window.location.href = "/edit-settings.html"
  };

  const handleCloseBingPop = (event) => {
    setState({ bingDialog: false });
  };

  const handleResetData = async () => {
    let response = await props.handleResetData();
    console.log(
      "response != null && response && response.errorMsg && response.errorMsg !=",
      response != null &&
        response &&
        response.errorMsg &&
        response.errorMsg != ""
    );
    console.log("response=", response);
    if (
      response != null &&
      response &&
      response.errorMsg &&
      response.errorMsg != ""
    ) {
      setState({
        alert: true,
        message: response.errorMsg,
        variantType: "error",
      });
    } else {
      setState({
        alert: true,
        message: "Data Reset Successfully Done",
        variantType: "success",
      });
    }
    return response;
  };
  return (
    <Fragment>
      {isLoading ? (
        <div
          className={"flex alignItemsCenter justifyCenter"}
          style={{ height: "70%" }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Card className={classes.card}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item>
                <Grid container justifyContent={"end"}>
                  {userInfo.type != 11 ? (
                    <Grid item>
                      {!_.isEmpty(selectedClientInfo) &&
                      userInfo.type != 9 &&
                      selectedClientInfo.accounts.length > 0 &&
                      (userDetails.userInfo.userType == true ||
                        selectedClientInfo.accounts.filter(
                          (account) =>
                            account.searchEngineId == 1 ||
                            account.searchEngineId == 2
                        ).length > 1) ? (
                        <Fragment>
                          <Fragment>
                            <NewTextRectOrangeTextBtn2
                              type={"button"}
                              disabled={
                                pricingCalculation(
                                  pricingDetailId,
                                  userDetails.userInfo
                                ) != ""
                                  ? true
                                  : false
                              }
                            >
                              {/* Add New Account */}
                            </NewTextRectOrangeTextBtn2>
                            <ErrorTypography>
                              {pricingCalculation(
                                pricingDetailId,
                                userDetails.userInfo
                              )}
                            </ErrorTypography>
                          </Fragment>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {userInfo.userType == true ? (
                            <a
                              href="/edit-settings.html"
                              name="UPDATE"
                              id="update"
                            >
                              <NewTextRectOrangeTextBtn2
                                onClick={() =>
                                  (window.location.href = "/edit-settings.html")
                                }
                                type={"button"}
                              >
                                {/* Add New Account */}
                              </NewTextRectOrangeTextBtn2>
                            </a>
                          ) : (
                            <NewTextRectOrangeTextBtn2
                              type={"button"}
                              onClick={handleBindAdding}
                            >
                              {/* Add New Account */}
                            </NewTextRectOrangeTextBtn2>
                          )}
                        </Fragment>
                      )}
                    </Grid>
                  ) : (
                    <Grid />
                  )}
                </Grid>

                <Paper className={classes.paper}>
                  <Typography
                    color={"textPrimary"}
                    variant={"h5"}
                    component={"h5"}
                    gutterBottom
                  >
                    <b>Accounts</b>
                  </Typography>

                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <CustomTableCellHead>Account Name</CustomTableCellHead>
                        <CustomTableCellHead>Search Engine</CustomTableCellHead>
                        <CustomTableCellHead>Account Id</CustomTableCellHead>
                        <CustomTableCellHead>KPI Metric</CustomTableCellHead>
                        <CustomTableCellHead>Target value</CustomTableCellHead>
                        <CustomTableCellHead>
                          Monthly Budget
                        </CustomTableCellHead>
                        <CustomTableCellHead>Status</CustomTableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedClientInfo.accounts &&
                        selectedClientInfo.accounts.map((account, i) => (
                          <Fragment>
                            {account.ppcId == selectedPccId ? (
                              <TableRow
                                style={{}}
                                onClick={() =>
                                  updateSelectedAccount(account.ppcId)
                                }
                              >
                                <SelectedTableCell>
                                  {account.clientName}
                                </SelectedTableCell>
                                <SelectedTableCell>
                                  {account.searchEngineId == 1
                                    ? "Google"
                                    : "Bing"}
                                </SelectedTableCell>
                                <SelectedTableCell>
                                  {account.seAccountId}
                                </SelectedTableCell>
                                <SelectedTableCell>
                                  {KpiType[account.targetKpi]}
                                </SelectedTableCell>
                                <SelectedTableCell>
                                  {account.targetValue}
                                </SelectedTableCell>
                                <SelectedTableCell>
                                  {account.budget}
                                </SelectedTableCell>
                                <SelectedTableCell>
                                  {account.enabled == 1 ? "Active" : "Disabled"}
                                </SelectedTableCell>
                              </TableRow>
                            ) : (
                              <TableRow
                                onClick={() =>
                                  updateSelectedAccount(account.ppcId)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <CustomTableCell>
                                  {account.clientName}
                                </CustomTableCell>
                                <CustomTableCell>
                                  {account.searchEngineId == 1
                                    ? "Google"
                                    : "Bing"}
                                </CustomTableCell>
                                <CustomTableCell>
                                  {account.seAccountId}
                                </CustomTableCell>
                                <CustomTableCell>
                                  {KpiType[account.targetKpi]}
                                </CustomTableCell>
                                <CustomTableCell>
                                  {account.targetValue}
                                </CustomTableCell>
                                <CustomTableCell>
                                  {account.budget}
                                </CustomTableCell>
                                <CustomTableCell>
                                  {account.enabled == 1 ? "Active" : "Disabled"}
                                </CustomTableCell>
                              </TableRow>
                            )}
                          </Fragment>
                        ))}
                    </TableBody>
                  </Table>
                  <br />
                  <br />
                </Paper>
              </Grid>
              <Grid item md={12}>
                <br />
                {selectedAccountInfo.ppcId ? (
                  <Grid container>
                    <AccountSettingsForm
                      handleResetData={handleResetData}
                      classes={classes}
                      selectedAccountInfo={selectedAccountInfo}
                      updateSelectedAccount={() => updateSelectedAccount()}
                      handleAccountToggles={(type) =>
                        handleAccountToggles(type)
                      }
                      accountSettingsData={accountSettingsData}
                      UpdateAccountDetails={(
                        ppcId,
                        budget,
                        targetValue,
                        kPIMetric,
                        emailIds
                      ) =>
                        handleUpdate(
                          ppcId,
                          budget,
                          targetValue,
                          kPIMetric,
                          emailIds
                        )
                      }
                      isAccountDetailsLoading={isAccountDetailsLoading}
                    />
                  </Grid>
                ) : (
                  <Typography variant={"caption"} gutterBottom color={"error"}>
                    Something Went wrong in loading selected account details
                  </Typography>
                )}
              </Grid>
            </Grid>
            <MySnackbarWrapper
              open={
                state.alert ||
                accessSuccess ||
                (errorMsg != null && errorMsg != "")
              }
              onClose={handleCloseAlert}
              message={
                errorMsg != null && errorMsg != ""
                  ? errorMsg
                  : accessSuccess
                  ? "Refresh tokens updated"
                  : state.message
              }
              verticalAlign={"bottom"}
              horizontalAlign={"right"}
              duration={6000}
              variant={
                errorMsg != null && errorMsg != ""
                  ? "error"
                  : accessSuccess
                  ? "success"
                  : state.variantType
              }
            />
          </CardContent>
        </Card>
      )}
      <Dialog
        open={state.bingDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleCloseBingPop}
      >
        <DialogHead>{"ADD ACCOUNT"}</DialogHead>
        <DialogContent>
          <DialogText id="alert-dialog-description">
            <b>
              LXRGuide will optimize your Bing Ads account for free for the rest
              of the billing cycle, then you'll be charged $950 every month for
              both Google Ads and Bing Ads until you cancel.
            </b>
          </DialogText>
          <CustomFormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={state.bingWarningChek}
                onChange={() =>
                  setState((prevState) => ({
                    ...prevState,
                    bingWarningChek: !state.bingWarningChek,
                  }))
                }
              />
            }
            label={"Accept the Pricing Plan Change"}
          />
        </DialogContent>
        <DialogActions>
          <PopUpCommonBtn onClick={handleCloseBingPop}>Disagree</PopUpCommonBtn>
          <PopUpOrangeBtn
            onClick={() => (window.location.href = "/edit-settings.html")}
            disabled={!state.bingWarningChek}
            autoFocus
          >
            Continue
          </PopUpOrangeBtn>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
const CustomFormControlLabel = withStyles((theme) => ({
  label: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    fontFamily: "Lato",
  },
}))(FormControlLabel);
const mapStateToProps = (state) => ({
  userDetails: state.userInformation,
  accountSettingsData: state.accountSettings,
});
const mapDispatchToProps = (dispatch) => ({
  updateSelectedAccount: (ppcId) => dispatch(updateSelectedAccount(ppcId)),
  handleAccountToggles: (type) => dispatch(handleAccountToggles(type)),
  updateAccountDetails: (AccountDetailsObject) =>
    dispatch(updateAccountDetails(AccountDetailsObject)),
  updateRefreshAccessSuccess: () => dispatch(updateRefreshAccessSuccess()),
  getAuthUrls: () => dispatch(getAuthUrls()),
  mccAccountImport: (accountId, searchEngineId) => {
    return dispatch(mccAccountSubmit(accountId, searchEngineId));
  },
  fetchPerfData: () => dispatch(fetchPerfData()),
  handleResetData: () => dispatch(resetData()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(accountSettingsStyles, { withTheme: true })(AccountSettings));
