import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Switch, Box } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import { getDateRanges, getComparePeriod } from "./commonFunctions";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import moment from "moment";
import "./Datepickerstyles.css";

const CustomDateRangePicker = ({ defaultStartDate, defaultEndDate, handleStartDateChange, 
    handleEndDateChange, handleCompareStartDate, handleCompareEndDate, handleCompareFlagChange }) => {
   
    // startDate, endDate , compareStartDate, compareEndDate are values for date picker
    const [startDate, setStartDate] = useState(
        defaultStartDate ? new Date(defaultStartDate) : new Date()
    );
    const [endDate, setEndDate] = useState(
        defaultEndDate ? new Date(defaultEndDate) : new Date()
    );
    const [compareEnabled, setCompareEnabled] = useState(false);
    const [compareStartDate, setCompareStartDate] = useState(null);
    const [compareEndDate, setCompareEndDate] = useState(null);
    const [isDateChanged, setIsDateChanged] = useState(false); // flag for keeping track if the date is changed from the date picker

    //calculate compare period date ranges
    const setCompareDates = (startDate, endDate) => {
        const diffDays = Math.ceil(
            (endDate - startDate + 1000 * 60 * 60 * 24) / (1000 * 60 * 60 * 24)
        ); 
        const compareStart = moment(startDate).subtract(diffDays, "days");
        const compareEnd = moment(startDate).subtract(1, "days");
        setCompareStartDate(new Date(compareStart));
        setCompareEndDate(new Date(compareEnd));
        return [compareStart, compareEnd];
    }

    // if defaultStartDate and defaultEndDate are provided from dashboard (7 days, 15 days and 30 days) then set the dates for the date picker
    useEffect(() => {
        if (defaultStartDate && defaultEndDate) {
            setStartDate(new Date(defaultStartDate));
            setEndDate(new Date(defaultEndDate));
            setCompareDates(new Date(defaultStartDate), new Date(defaultEndDate));
        }
    }, [defaultStartDate, defaultEndDate]);

    // this function is called when the date is selected from date picker to update the dates in the dashboard
    const handleDateChange = async (selectedDates) => {
        if (selectedDates.length === 2) {
            const [start, end] = selectedDates;
            setStartDate(new Date(start));
            setEndDate(new Date(end));

            const compareDates = await setCompareDates(new Date(start), new Date(end));

            handleStartDateChange(moment(new Date(start)).format("YYYY-MM-DD"));
            handleEndDateChange(moment(new Date(end)).format("YYYY-MM-DD"));

            handleCompareStartDate(moment(compareDates[0]).format("YYYY-MM-DD"));
            handleCompareEndDate(moment(compareDates[1]).format("YYYY-MM-DD"));
        }
    };

    const toggleCompareMode = () => {
        handleCompareFlagChange((prev) => !prev)
        setCompareEnabled((prev) => !prev)
    };

    return (
        <Grid container spacing={2} direction="column">
            <Grid container item md={6} xs={12} alignItems="center" justifyContent="flex-end">
                <Grid item>
                    <Typography color={"#838383"}>Compare</Typography>
                </Grid>
                <Grid item>
                    <Switch
                        color="warning"
                        size="small"
                        checked={compareEnabled}
                        onChange={toggleCompareMode}
                    />
                </Grid>
            </Grid>
            <Grid container direction={"row"} item md={6} xs={12} justifyContent={"flex-end"} columnGap={2}>
                <Grid item>
                    <Typography color="#838383">Selected Period:</Typography>
                    <Box className="custom-box">
                        <DatePicker
                            value={[startDate, endDate]}
                            onChange={handleDateChange}
                            range
                            rangeHover
                            highlightToday={false}
                            weekDays={["S", "M", "T", "W", "T", "F", "S"]}
                            inputClass="custom-date-range-input"
                            maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                        />
                        <DateRangeOutlinedIcon fontSize="small" color="disabled" />
                    </Box>
                </Grid>
                {compareEnabled && (
                    <Grid item>
                        <Typography color="#838383">Compare Period:</Typography>
                        <Box className="custom-box" sx={{ backgroundColor: "#FAFAFA" }}>
                            <DatePicker
                                value={[compareStartDate, compareEndDate]}
                                range
                                highlightToday={false}
                                disabled={true}
                                inputClass="custom-date-range-input"
                            />
                            <DateRangeOutlinedIcon fontSize="small" color="disabled" />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

CustomDateRangePicker.propTypes = {
    defaultStartDate: PropTypes.string,
    defaultEndDate: PropTypes.string,
    handleStartDateChange: PropTypes.func.isRequired,
    handleEndDateChange: PropTypes.func.isRequired,
    handleCompareStartDate: PropTypes.func.isRequired,
    handleCompareEndDate: PropTypes.func.isRequired,
};

export default CustomDateRangePicker;
