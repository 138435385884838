import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@mui/material";
import "./jumpstart.css";
import PageHeader from "./commonComponents/pageHeaderDetails";
import JumpstartMain from "./jumpstartMain";
import { JsSubSectionTypography } from "../../components/typography";
import icons from "../../components/icons";
import { JsOrangeContainedBtn, JsTextAsButton } from "../../components/buttons";
import { Link, useParams, useHistory } from "react-router-dom";
import { AccountStatus, Authority } from "./commonComponents/jumpstartEnum";
import {
  campaignFrameworkDownload,
  fetchJsAccountById,
  fetchJsAccounts,
} from "../../actions/jumpstartRevamped";
import Cookies from "js-cookie";

function JsWaitPage(props) {
  const {
    fetchUserInfoReducer,
    fetchJsAccountById,
    fetchJsAccountByIdReducer,
    campaignFrameworkDownload,
    fetchJsAccounts,
  } = props;
  const { id } = useParams();
  const [authority, setAuthority] = useState();
  const [accountStatus, setAccountStatus] = useState();
  const history = useHistory();
  const token = Cookies.get("js_jwtToken");

  useEffect(() => {
    fetchJsAccountById(token, id);
    fetchJsAccounts(token);
  }, []);

  useEffect(() => {
    if (fetchUserInfoReducer.message == "JS_USER_INFO_RECEIVED") {
      setAuthority(fetchUserInfoReducer.authority);
    }
  }, [fetchUserInfoReducer]);

  useEffect(() => {
    if (fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED") {
      setAccountStatus(fetchJsAccountByIdReducer.status);
    }
  }, [fetchJsAccountByIdReducer]);

  const handleGenerateCampaignStructure = () => {
    history.push(`/generate-campaign-structure/${id}`);
  };
  const handleAccountManagementNav = () => {
    history.push(`/jumpstart-dashboard`);
  };

  const handleDownload = () => {
    if (
      fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED" &&
      fetchJsAccountByIdReducer.id == id
    ) {
      const data = campaignFrameworkDownload(
        token,
        id,
        fetchJsAccountByIdReducer.companyName
      );
    }
  };

  return (
    <JumpstartMain>
      <div className="pageContent">
        <PageHeader
          sectionHeading={"Campaign Framework"}
          hidden="true"
          token={token}
          completedText = {"Framework Setup Completed Successfully"}
        />
      </div>
      <div style={{ padding: "220px 4% 4%" }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          rowSpacing={2}
          className="frameworkCompleted"
          style={{ paddingBottom: "2%" }}
        >
          <Grid item xs={12}>
            <Typography variant="h3">Congratulations!</Typography>
          </Grid>
          <Grid item xs={12}>
            <JsSubSectionTypography>
              Your Framework has been successfully submitted.
            </JsSubSectionTypography>
          </Grid>
          <Grid item xs={12}>
            {icons.FrameworkCompleted}
          </Grid>
          <Grid item xs={12}>
            <JsSubSectionTypography>
              {(authority == Authority.ANALYST || authority == Authority.RWF) &&
              accountStatus !==
                AccountStatus.CAMPAIGN_FRAMEWORK_REVIEWER_APPROVED
                ? "Please wait till the reviewer/manager reviews & approves it."
                : (authority == Authority.ANALYST ||
                    authority == Authority.RWF) &&
                  accountStatus ==
                    AccountStatus.CAMPAIGN_FRAMEWORK_REVIEWER_APPROVED
                ? "The campaign framework has been approved."
                : "Please wait till the client reviews & approves it."}
            </JsSubSectionTypography>
          </Grid>
          <Grid item xs={12}>
            <JsOrangeContainedBtn
              onClick={() => handleGenerateCampaignStructure()}
              disabled={
                (authority == Authority.ANALYST ||
                  authority == Authority.RWF) &&
                accountStatus !==
                  AccountStatus.CAMPAIGN_FRAMEWORK_REVIEWER_APPROVED
              }
              style={{ fontSize: "0.875rem" }}
            >
              Generate Campaign Structure
            </JsOrangeContainedBtn>
          </Grid>
          <Grid item xs={12}>
            <JsSubSectionTypography
              onClick={() => handleAccountManagementNav()}
              style={{ cursor: "pointer" }}
            >
              Go to Account Management
            </JsSubSectionTypography>
          </Grid>
          <Grid item xs={12}>
            <JsTextAsButton onClick={() => handleDownload()}>
              <span>{icons.ExcelDownload}</span>&nbsp;
              <u>Download Campaign Framework</u>
            </JsTextAsButton>
          </Grid>
        </Grid>
      </div>
    </JumpstartMain>
  );
}

const mapStateToProps = (state) => ({
  fetchUserInfoReducer: state.fetchUserInfoReducer,
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
});
const mapDispatchToProps = (dispatch) => ({
  campaignFrameworkDownload: (jwtToken, id, companyName) =>
    dispatch(campaignFrameworkDownload(jwtToken, id, companyName)),
  fetchJsAccountById: (jwtToken, id) =>
    dispatch(fetchJsAccountById(jwtToken, id)),
  fetchJsAccounts: (jwtToken) => dispatch(fetchJsAccounts(jwtToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JsWaitPage);
