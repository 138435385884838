import React, { Fragment } from "react";
import TaskPage from "./task-page";
import Typography from "@mui/material/Typography";
import clsx from "classnames";
import { Grid, Hidden, CircularProgress } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
} from "../selectors/dashboard-selectors";
import Overview from "./overview";
import * as _ from "lodash";
import NotificationAlert from "../components/dashboard/notification-alert";
import JumpstartDashboard from "./jumpstart/jumpstartDashboard";
import icons from "../components/icons";
import ActivityReport from "./activityReport/activityReport";
import UserSessionAlert from "./userSessionSnackBar";
import Reports from "./reports/reports";
import { Redirect, withRouter } from "react-router";
import { createHashHistory } from "history";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductTourDialogs from "./productTour/productTourDialogs";
import ContractSign from "../NewInterfacePages/contractSign/contractSign";
import SystemDowntimeBanner from "./systemMaintenanceBanner/SystemDowntimeBanner";
import DashboardPage from "./NewHomeDashboard/DashboardPage";
// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

let moment = require("moment");

function TabContainer({ children, dir, width, theme }) {
  const isTabletResponsive = useMediaQuery(
    theme.breakpoints.between("sm", "xl")
  );
  return (
    <Fragment>
      <Typography
        component="div"
        dir={dir}
        style={{
          padding:
            width == "sm" || width == "xs" || isTabletResponsive ? 10 : 8 * 3,
        }}
      >
        {children}
      </Typography>
    </Fragment>
  );
}

export function IconAndText({ icon, text, caption }) {
  return (
    <Grid container spacing={1}>
      <Grid item>{icon}</Grid>
      <Grid item style={{ textAlign: "left" }}>
        <Typography
          align={"left"}
          variant={"h3"}
          color={"textPrimary"}
          gutterBottom
        >
          <b>{text}</b>
        </Typography>
        {caption ? (
          <div>
            <Typography
              variant={"body1"}
              style={{ fontWeight: 500, color: "#1E334E", opacity: "0.5" }}
              align={"left"}
            >
              As of {moment().subtract(1, "days").format("Do MMMM")}
            </Typography>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
}

const metrics = [
  "clicks",
  "impressions",
  "kpiValue",
  "orders",
  "revenue",
  "spend",
];
export const metricTitles = {
  clicks: "Clicks",
  impressions: "Impressions",
  orders: "Orders",
  revenue: "Revenue",
  spend: "Spend",
};
const numOptions = {
  commafy: true,
  shortFormatMinValue: 9999,
  shortFormatPrecision: 0,
  precision: 0,
  shortFormat: true,
  justification: "L",
  locales: "en-US",
  // title: true
};
const history = createHashHistory();

function profileSettingRedirectionCheck(userInfo, history) {
  /*      const isPermission = (!_.isEmpty(userInfo) && userInfo.permission) ? userInfo.permission : false;
          const isGraderFreeTrial = (!_.isEmpty(userInfo) && userInfo.type == 9) ? true : false;
          console.log("isPermission===", isPermission);*/

  if (!_.isEmpty(userInfo) && !(!_.isEmpty(userInfo) && userInfo.permission)) {
    return history.push("/profile-settings");
  }
}

class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isRedirectionLoading: false, showProductTour: true };
  }

  /* async componentDidUpdate(prevProps, prevState, snapshot) {
         const {selectedAccountInfo} = this.props;
         if (prevProps.selectedAccountInfo && prevProps.selectedAccountInfo.ppcId !== selectedAccountInfo.ppcId) {
             console.log("componentDidUpdate called condition true");
             await this.props.fetchPerfData();
         }
     }*/

  async componentWillMount() {
    const { userInfo } = this.props;
    profileSettingRedirectionCheck(userInfo, history);
    // this.setState({isRedirectionLoading: false})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { userInfo, history } = this.props;
    if (prevProps.userInfo != userInfo) {
      return profileSettingRedirectionCheck(userInfo, history);
    }
    if (
      this.props.match.params.type == "product-tour" &&
      history.length > 0 &&
      this.state.showProductTour
    ) {
      this.setState({ showProductTour: false });
      this.props.welcomeToggle();
    }
  }

  render() {
    const { viewValue, theme } = this.props;
    const { isRedirectionLoading } = this.state;
    const { classes, width, userInformation } = this.props;
    const {
      openMartech,
      selectedDateRange,
      onSelectDateRange,
      displayDateRange,
      customDateRanges,
      isLoading,
      kpiType,
      kpiHistory,
      isConversionAlert,
      jumpstart,
      isContractSigned,
      martechServices,
    } = this.props;
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const fixedPerfHeightPaper = clsx(classes.paper, classes.fixedPerfHeight);
    const fixedGraphHeightPaper = clsx(
      classes.paper,
      classes.fixedGraphHeightPaper
    );
    const pricingPlanId =
      userInformation.userInfo && userInformation.userInfo.pricingPlan
        ? userInformation.userInfo.pricingPlan.pricingPlanId
        : null;
    const isUserDataLoading = userInformation.isLoading;
    const isAccountLoading = userInformation.isAccountLoading;
    const isClientLoading = userInformation.isClientLoading;
    console.log("isUserDataLoading = ", isUserDataLoading);
    console.log("openMartech---- = ", openMartech);

    const {
      selectedGraphDateRange,
      onSelectGraphDateRange,
      displayGraphDateRange,
      customGraphDateRanges,
      campaignsSnapshot,
      campaignsSnapshotPrevious,
    } = this.props;
    return (
      <div>
        {(width == "md" || width == "lg" || width == "xl") &&
        pricingPlanId != null &&
        pricingPlanId != 7 && location.hash != "#/" ? (
          <Grid item>
            <br />
            <br />
          </Grid>
        ) : null}
        {isUserDataLoading ||
        isClientLoading ||
        isAccountLoading ||
        isRedirectionLoading ? (
          <Grid container style={{ height: "100%" }}>
            <Grid item>
              <CircularProgress color={"secondary"} />
            </Grid>
          </Grid>
        ) : (
          <div>
            {/*
                        {isConversionAlert ?
                            <NotificationAlert isConversionAlert={isConversionAlert} icon={icons.WarningAlert}
                                               message={"Conversion Tracking for your account is not enabled."}/>
                            : null}*/}
            <SystemDowntimeBanner/>                
            {isContractSigned != null && !isContractSigned ? (
              <ContractSign
                isConversionAlert={isConversionAlert}
                icon={icons.WarningAlert}
                message={"Please Accept the Terms of Service"}
              />
            ) : null}

            {pricingPlanId != null && pricingPlanId == 7 ? (
              <JumpstartDashboard jumpstart={jumpstart} />
            ) : (
              <div>
                {viewValue === 0 && (
                  <TabContainer width={width} theme={theme}>
                    <Overview
                      width={width}
                      selectedDateRange={selectedDateRange}
                      onSelectDateRange={onSelectDateRange}
                      displayDateRange={displayDateRange}
                      customDateRanges={customDateRanges}
                      props={this.props}
                      fixedPerfHeightPaper={fixedPerfHeightPaper}
                      fixedGraphHeightPaper={fixedGraphHeightPaper}
                      classes={classes}
                      selectedGraphDateRange={selectedGraphDateRange}
                      onSelectGraphDateRange={onSelectGraphDateRange}
                      displayGraphDateRange={displayGraphDateRange}
                      customGraphDateRanges={customGraphDateRanges}
                      kpiHistory={kpiHistory}
                      kpiType={kpiType}
                      isLoading={isLoading}
                      openMartech={openMartech}
                      martechServices={martechServices}
                      match={this.props.match}
                      {...this.props}
                    />
                  </TabContainer>
                )}
                {viewValue === 1 && (
                  <TabContainer width={width} theme={theme}>
                    <TaskPage />
                  </TabContainer>
                )}
                {viewValue === 2 && (
                  <TabContainer width={width} theme={theme}>
                    <Reports />
                  </TabContainer>
                )}
                {viewValue === 3 && (
                  <TabContainer width={width} theme={theme}>
                    <ActivityReport />
                  </TabContainer>
                )}
                 {viewValue === 4 && (
                  // <TabContainer width={width} theme={theme}>
                    <DashboardPage />
                  // </TabContainer>
                )}
              </div>
            )}
            <ProductTourDialogs />
          </div>
        )}
      </div>
    );
  }
}

Dashboard.propTypes = {};
const style = (theme) => ({
  toolbar: {
    ...theme.mixins.toolbar,
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
  },
  paperGraph: {
    padding: theme.spacing(1),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    border: "1px solid" + theme.palette.common.border,
    borderRadius: 6,
    boxShadow: "4px 4px 18px " + theme.palette.boxShadowC,
  },
  fixedHeight: {
    height: 223,
    [theme.breakpoints.down("md")]: {
      height: 135,
    },
  },
  fixedPerfHeight: {
    [theme.breakpoints.up("sm")]: {
      height: 470,
    },
    [theme.breakpoints.down("xl")]: {
      height: 262,
    },
    [theme.breakpoints.between("sm", "xl")]: {
      height: 470,
    },
  },
  fixedGraphHeightPaper: {
    height: 401,
  },
  avatar: {
    // padding: theme.spacing(0),
    width: 30,
    height: 24,
    fontSize: "1rem",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.whiteGrey,
    borderRadius: 0,
  },
  caption: {
    opacity: 0.5,
    fontWeight: "800",
  },
  taskCaption: {
    opacity: 0.5,
    fontWeight: "600",
  },
  link: {
    textDecoration: "unset",
  },
  dashedLine: {
    border: "1px dashed" + theme.palette.common.darkHash,
    backgroundColor: "unset",
    boxSizing: "border-box",
    borderRadius: 10,
    opacity: 0.4,
    height: 0,
  },
});
// Dashboard = withRouter(Dashboard);
const _Dashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export default withRouter(
  withWidth()(withStyles(style, { withTheme: true })(_Dashboard))
);
