// /service-auth-login.json this is used for the generating the JWT token, for every nbewdasjboard call we need to include JWT token.

// lxrguide main appp -> nneed to hit above url ---> get jwt token ----> this token will be used for every api gateway reqyest 8765 PortableWifiOff.

import * as types from "./actionTypes";

export const domainUrl = "http://localhost:8765";
import Cookies from "js-cookie"

export function fetchJWTTokenURL() {
    return async function (dispatch) {
   
      // const url = domainUrl + `/service-auth-login.json`;
      const url =`/service-auth-login.json`;
      dispatch({
        type: types.APIGATEWAY_AUTH_URL_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method:"POST",
          headers:{
            Accept: "application/json",
            'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
          },
        });
        let data = await response.json();   
        dispatch({
          type: types.APIGATEWAY_AUTH_URL_RECEIVED,
          data,
        });
        return data;
      }
      catch(e){
        dispatch({
          type: types.APIGATEWAY_AUTH_URL_FAILED,
        });
      }
    }
  }
