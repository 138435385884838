// ChatbotTabs.jsx
import React from "react";
import "./chatbottest.css"; // Create this file for styling if you need

const ChatbotTabs = ({ tabs, onTabClick }) => {
  return (
    <div className="chatbot-tabs">
      {tabs.map((tab, index) => (
        <button key={index} className="tab" onClick={() => onTabClick(tab)}>
          {tab}
        </button>
      ))}
    </div>
  );
};

export default ChatbotTabs;
