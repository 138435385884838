// import React from "react";
// import { LinearProgress, Box, Grid } from "@mui/material";
// import { styled } from "@mui/system";

// // Custom styling for the progress bar with dynamic color
// const CustomLinearProgress = styled(LinearProgress)(({ progressColor }) => ({
//   height: 20, // Adjust the height
//   borderRadius: 5, // Make it rounded
//   backgroundColor: '#f5f5f5', // Color for the background
//   '& .MuiLinearProgress-bar': {
//     borderRadius: 5,
//     backgroundColor: progressColor || '#3f51b5', // Use the dynamic color passed as prop or default to a color
//   },
// }));

// export default function ProgressBar({ TextValue, value, ScoreValue, progressColor }) {
//   return (
//     <Box sx={{ width: "100%", mb: 2 }}>
//       <Grid container alignItems="center">
//         <Grid item xs={3}>
//           {TextValue}
//         </Grid>
//         <Grid item xs={6}>
//           {/* Pass the color as a prop to the styled component */}
//           <CustomLinearProgress variant="determinate" value={value > 100 ? 100 : Math.round(value)} progressColor={progressColor} />
//         </Grid>
//         <Grid item xs={3} textAlign="right">
//           {ScoreValue}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }
import React from "react";
import { LinearProgress, Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";

// Custom styling for the progress bar with dynamic color
const CustomLinearProgress = styled(LinearProgress)(({ progressColor }) => ({
  height: 20, // Adjust the height
  borderRadius: 5, // Make it rounded
  backgroundColor: '#f5f5f5', // Color for the background
  position: 'relative', // Make it a relative container
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: progressColor || '#3f51b5', // Use the dynamic color passed as prop or default to a color
  },
}));

export default function ProgressBar({ TextValue, value, ScoreValue, progressColor }) {
  const displayValue = Math.round(value > 100 ? 100 : value);

  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <Grid container alignItems="center">
        <Grid item xs={3}>
          {TextValue}
        </Grid>
        <Grid item xs={6} sx={{ position: 'relative' }}>
          {/* Pass the color as a prop to the styled component */}
          <CustomLinearProgress variant="determinate" value={displayValue} progressColor={progressColor} />
          {/* Overlay the progress percentage in the center */}
          <Typography 
            variant="body2" 
            sx={{ 
              position: 'absolute', 
              top: 0, 
              left: '50%', 
              transform: 'translate(-50%, 50%)',
              color: '#1E344E',
              fontWeight: 'normal',
              lineHeight:'0.7',
            }}
          >
            {`${displayValue}%`}
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign="right">
          {ScoreValue}
        </Grid>
      </Grid>
    </Box>
  );
}

