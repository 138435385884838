import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import { getDateRanges } from './commonFunctions';
import { periodEnums } from './commonFunctions';
// setStartDate={setStartDate} setEndDate={setEndDate} setPeriod={setPeriod}
const TimePeriodSelector = (props) => {
  const{setStartDate, setEndDate,setCompareStartDate,setCompareEndDate} = props;
  const timePeriods = [periodEnums.LAST_30_DAYS, periodEnums.LAST_15_DAYS, periodEnums.LAST_7_DAYS];
  const [selected, setSelected] = useState(periodEnums.LAST_7_DAYS);
  const [compareSelected,setCompareSelected] =useState(periodEnums.COMPARE_7_DAYS);

  useEffect(()=>{
    const data = getDateRanges(selected);

    setStartDate(data[0]);
    setEndDate(data[1]);
    // localStorage.setItem('startDate', data[0]);
    // localStorage.setItem('endDate',data[1])
    const dataCompare = getDateRanges(compareSelected);
    setCompareStartDate(dataCompare[0])
    setCompareEndDate(dataCompare[1])
    // localStorage.setItem('compareStartDate', dataCompare[0]);
    // localStorage.setItem('compareEndDate',dataCompare[1])
  },[selected,compareSelected])

  const handleButtonClick = (period) => {
    setSelected(period);
    if(period === "Last 7 Days"){
     
      setCompareSelected("Compare 7 Days");
    }
    else if(period ==="Last 15 Days"){
      setCompareSelected("Compare 15 Days");
    }
    else {
      setCompareSelected("Compare 30 Days");
    }
  }

  return (
    <ButtonGroup
      variant="contained"
      aria-label="outlined button group"
      sx={buttonGroupStyles}
    >
      {timePeriods.map((period) => (
        <Button
          key={period}
          variant={selected === period ? 'contained' : 'text'}
          onClick={() => handleButtonClick(period)}
          sx={buttonStyles(selected === period)}
        >
          {period}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default TimePeriodSelector;


const buttonGroupStyles = {
  p: 1,
  background: "#fff",
  gap: 1,
  "& .MuiButtonGroup-grouped:not(:last-of-type)": {
    borderRight: "none",
  },
  "& .MuiButtonGroup-grouped": {
    border: "none",
    "&:hover": {
      backgroundColor: "#EFEFEF",
    },
  },
};

const buttonStyles = (isSelected) => ({
  color: "#777777",
  textTransform: "none",
  backgroundColor: isSelected ? "#EFEFEF" : "#fff",
  border: "none",
  "&:hover": {
    backgroundColor: isSelected ? "#EFEFEF" : "#fff",
  },
});