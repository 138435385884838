import React, { useState, useEffect } from "react";
import Companiesimages from "../../assets/img/companiesimages.png";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import {
  OrangeTextSize12,
  GrayText,
  HeadLineTypography,
  BlueTitleTypography,
  CatalinaBlue,
} from "../../components/typography";
import icons from "../../components/icons";
import { Typography } from "@mui/material/index";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  companyStyling: {
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
      backgroundColor: "#ECEFF3",
      paddingTop: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.down("xl")]: {
      paddingTop: 20,
      // paddingBottom: 20,
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      backgroundColor: "#ECEFF3",
      paddingTop: 50,
      paddingBottom: 10,
    },
  },

  confusedbar: {
    [theme.breakpoints.up("xs")]: {
      background: "linear-gradient(90.25deg, #F58120 0.05%, #FF7400 100%)",
      textAlign: "center",
      color: "white",
      fontStyle: "bold",
      // height:100,
    },
    [theme.breakpoints.down("xl")]: {
      height: 200,
      padding: 20,
      // height:100,
    },
    [theme.breakpoints.up("md")]: {
      // background: 'linear-gradient(to right, #F58120 0.05%,#FF7400 100%,#fa461e 100%)',
      background: "linear-gradient(90.25deg, #F58120 0.05%, #FF7400 100%)",
      // background: 'linear-gradient(0deg, #F58120, #F58120)',

      textAlign: "center",
      height: 100,
      lineHeight: 4,
      color: "white",
      fontStyle: "bold",
      fontSize: 18,
    },
  },
  confusionTitle: {
    color: theme.palette.common.white,
    fontWeight: "700",
    fontSize: 24,
    [theme.breakpoints.down("xl")]: {
      fontSize: 20,
    },
  },
  buttonstyle: {
    height: 45,
    fontWeight: "700",
    fontSize: 16,
    fontFamily: "Lato",
    padding: "10px 10px",
    textTransform: "none",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  companyImages: {
    textAlign: "center",
    alignItems: "center",
    backgroundColor: "#ECEFF3",
    paddingBottom: 10,
    paddingLeft: 25,
    paddingRight: 25,
    [theme.breakpoints.down("lg")]: {
      paddingBottom: 20,
    },
  },

  heading: {
    color: "textPrimary",
    fontWeight: "700",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});
function CompaniesSection(props) {
  const classes = props.classes;
  const { width } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          sm={12}
          className={[classes.confusedbar, "flex"]}
        >
          <Grid
            container
            spacing={width == "sm" || width == "xs" ? null : 8}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item>
              <Typography variant={"body1"} className={classes.confusionTitle}>
              Let’s Talk Growth
              </Typography>
            </Grid>
            <Grid item>
              <Button
                varient="primary"
                component={Link}
                to={"/request-demo"}
                className={classes.buttonstyle}
              >
                Book Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <div className={classes.companyStyling}>
            <OrangeTextSize12>SOME OF OUR CLIENTS</OrangeTextSize12>
            <br />
            <Typography className={classes.heading}>
              YOU ARE IN GOOD COMPANY
            </Typography>
            <br />
            <br />
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        className={classes.companyImages}
      >
        <Grid item sm={10} xs={10} md={12}>
          <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.SwanCreekCandleLogo("100%")
                : icons.SwanCreekCandleLogo("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.AmericanDarling("100%")
                : icons.AmericanDarling("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.BagsUnlimited("100%")
                : icons.BagsUnlimited("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.CrabDynasty("100%")
                : icons.CrabDynasty("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.Gyford("100%")
                : icons.Gyford("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.ImpactGel("100%")
                : icons.ImpactGel("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.Jantz("100%")
                : icons.Jantz("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.MagneShade("100%")
                : icons.MagneShade("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.MilksMoms("100%")
                : icons.MilksMoms("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.OkPetroleum("100%")
                : icons.OkPetroleum("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.Rochester100("100%")
                : icons.Rochester100("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.SpudInc("100%")
                : icons.SpudInc("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.TapeGuys("100%")
                : icons.TapeGuys("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.VipPro("100%")
                : icons.VipPro("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.WestCoastSaw("100%")
                : icons.WestCoastSaw("auto")}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
              {screenWidth <= 900
                ? icons.DrinksOnMe("100%")
                : icons.DrinksOnMe("auto")}
            </Grid>
          </Grid>
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
}

export default withWidth()(withStyles(styles)(CompaniesSection));
