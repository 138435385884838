import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Paper,
  Divider,
  Tooltip,
  Chip, MenuItem, Select, Card, Skeleton
} from "@mui/material";
import "./NewDashBoard.css";
import { CardTitle } from "./NewDashBoardTypography";
import { ChannelButton } from "../../components/buttons";
import { ButtonGroup } from "reactstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { fetchChannelPerformanceReportData } from "../../actions/googleAnalyticsNewDashboard";
import { connect } from "react-redux";
import moment from "moment";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatNumberToK } from "../commonFunctions/commonFunctions";
import { fetchConnectionRelatedInfo } from "../../actions";
import { BigTitle } from "./NewDashBoardTypography";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import icons from "../../components/icons";
import PerformanceCard from "./PerformanceCard";
import TaskList from "./TaskTable";
import IntelliChatbot from "./IntelliChatbot";
import * as types from "../../actions/actionTypes";
import { fetchGoogleBingPerformanceData } from "../../actions/googleBingPerformance";
import { format } from 'date-fns';
import {
  fetchIntelliChatBotResponse,
  // fetchIntelliChatBotNormalResponse,
} from "../../actions/intelliChatbot.js";

const dateRanges = (period) => {
  if (period === "Last Week") {
    return {
      startDate: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
    };
  }
  else if (period === "Last Month") {
    return {
      startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    };
  }
  else if (period === "Last QTR") {
    const startOfQuarter = moment().subtract(1, 'quarters').startOf('quarter');
    const endOfQuarter = moment().subtract(1, 'quarters').endOf('quarter');
    return {
      startDate: startOfQuarter.format('YYYY-MM-DD'),
      endDate: endOfQuarter.format('YYYY-MM-DD')
    };
  }
  else if (period === "Last Year") {
    return {
      startDate: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
    };
  }
};


const MetricCard = ({ title, value, previousValue, change }) => {
  const isPositive = change >= 0;
  const formattedValue = title == "Total Revenue" ? `$${formatNumberToK(value)}` : formatNumberToK(value);
  const formattedPrevValue = title == "Total Revenue" ? `$${formatNumberToK(previousValue)}` : formatNumberToK(previousValue);
  return (
    <Box sx={{ p: 2 }}>
      <Typography color="#A3A3A3">
        {title}
      </Typography>
      <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Typography fontSize={"1.25rem"} color="#777777" fontWeight="bold">
          <Tooltip title={Intl.NumberFormat().format(value.toFixed(2))} placement="right-start" sx={{ fontSize: "0.875rem" }}>{formattedValue}{title === "CVR" ? " %" : ""}</Tooltip>
        </Typography>
        <Typography
          fontSize={14}
          color={isPositive ? "success.main" : "error.main"}
          fontWeight={400}
        >
          {isPositive ? `↑` : `↓`} {formatNumberToK(Math.abs(change))}%
        </Typography>
      </span>
      <Typography fontWeight={400} color="#777777">
        <Tooltip title={Intl.NumberFormat().format(previousValue.toFixed(2))} placement="right-start" sx={{ fontSize: "0.875rem" }}>{formattedPrevValue} {title === "CVR" ? " %" : ""}</Tooltip>
      </Typography>
    </Box>
  );
};

const FilterCard = ({ title, optionList, selected, handleChange }) => {
  return (
    <FormControl>
      <FormLabel name={`${title}-select`}><Typography fontSize={18} fontWeight={"bold"} color={"#838383"}>{title}</Typography></FormLabel>
      <RadioGroup
        aria-labelledby={`${title}-select`}
        defaultValue={selected}
        name={`${title}-options`}
        onChange={handleChange}
      >
        {optionList.map((option) => (
          <FormControlLabel key={option} sx={{ color: selected == option ? "#4F4F4F" : "#A3A3A3" }} value={option} control={<Radio size="small" color="secondary" />} label={option} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const AllGAChannels = ["All Channels", "Direct", "Merged Paid Media", "Paid Social", "Email", "Merged Organic", "Referral", "Others"];

const TimePeriod = ["Last Week", "Last Month", "Last QTR", "Last Year"];

const Devices = ["All Devices", "Desktop", "Mobile", "Tablet"];

const ChannelPerformance = (props) => {
  const { fetchChannelPerformanceReportData, fetchGoogleBingPerformanceDataReducer, userInformation,
    fetchChannelPerformanceReportDataReducer, connectionsDataReducer, fetchConnectionRelatedInfo,
    fetchIntelliChatBotNormalResponse, fetchIntelliChatBotNormalResponseReducer, fetchGoogleBingPerformanceData, fetchIntelliChatBotResponse, fetchIntelliChatBotResponseReducer } = props;
  const section = "channels";
  //For request object
  const [accountId, setAccountId] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [endDate, setEndDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));

  const [selectedFilter, setSelectedFilter] = useState("All Channels");
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("Last Week");
  const [selectedDevice, setSelectedDevice] = useState("All Devices");

  const [currentData, setCurrentData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [currentTotals, setCurrentTotals] = useState({ sessions: 0, orders: 0, totalUsers: 0, conversionRate: 0, newUsers: 0, totalRevenue: 0 });
  const [prevTotals, setPrevTotals] = useState({ sessions: 0, orders: 0, totalUsers: 0, conversionRate: 0, newUsers: 0, totalRevenue: 0 });
  const [changePercentage, setChangePercentage] = useState({ sessions: 0, orders: 0, totalUsers: 0, conversionRate: 0, newUsers: 0, totalRevenue: 0 });
  const history = useHistory();
  const [clientId, setClientId] = useState(userInformation.selectedClientInfo
    ? userInformation.selectedClientInfo.clientId
    : null)
  const [clientName, setClientName] = useState(
    userInformation.selectedClientInfo
      ? userInformation.selectedClientInfo.clientName
      : null
  )
  const [googleBingPerformanceData, setGoogleBingPerformanceData] = useState([]);
  const [taskListData, setTaskListData] = useState();

  const [performanceDateRange, setPerformanceDateRange] = useState(
    {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    }
  )

  const [loading, setLoading] = useState(true);

  const [user_id, setUser_id] = useState("");
  // const [message, setMessage] = useState("Intelli chat");
  const [ppc_id, setPpc_id] = useState("");


  useEffect(() => {
    if (userInformation && userInformation.selectedClientInfo) {
      setClientId(userInformation.selectedClientInfo.clientId)
      setClientName(userInformation.selectedClientInfo.clientName)
      setUser_id(userInformation.userInfo.userId);
      setPpc_id(userInformation.selectedAccountInfo.ppcId);
    }
  }, [userInformation.selectedClientInfo])

  useEffect(async () => {
    if (clientId != null) {
      setLoading(true);
      await fetchGoogleBingPerformanceData(clientId, performanceDateRange.startDate, performanceDateRange.endDate);
      setLoading(false);
    }
  }, [clientId])

  useEffect(() => {
    if (fetchGoogleBingPerformanceDataReducer && fetchGoogleBingPerformanceDataReducer.message === "FETCH_GOOGLE_BING_PERFORMANCE_DATA_RECEIVED") {
      const { message, ...restData } = fetchGoogleBingPerformanceDataReducer;
      const performanceData = Object.values(restData).sort(
        (a, b) => a.engineId - b.engineId
      );
      const googleAds = performanceData.find((entry) => entry.engineId === 1);
      const bingAds = performanceData.find((entry) => entry.engineId === 2);
      setGoogleBingPerformanceData(performanceData);
      setTaskListData({
        googleAds: googleAds?.tasks || [],
        bingAds: bingAds?.tasks || [],
      })
    }
  }, [fetchGoogleBingPerformanceDataReducer]);

  useEffect(() => {
    if (connectionsDataReducer["message"] === "FETCH_CONNECTION_DATA_RECEIVED") {
      if (connectionsDataReducer["Google-Analytics4"] && connectionsDataReducer["Google-Analytics4"].length >= 1) {
        setAccountId(connectionsDataReducer["Google-Analytics4"][0]["accountId"]);
        setPropertyId(connectionsDataReducer["Google-Analytics4"][0]["propertyId"]);
      }
    } else if (connectionsDataReducer["message"] === "") {
      fetchConnectionRelatedInfo();
    }
  }, [connectionsDataReducer]);

  useEffect(() => {
    if (propertyId != null && accountId != null) {
      let channelList = selectedFilter == "All Channels" ? [] : [selectedFilter];
      let device = selectedDevice == "All Devices" ? [] : [selectedDevice.toLowerCase()];
      fetchChannelPerformanceReportData(accountId, propertyId, startDate, endDate, "SUMMARY",
        channelList, device, [], [], [], "channel", "ASC");
    }
  }, [propertyId, accountId, selectedDevice, selectedTimePeriod,selectedFilter]);

  useEffect(() => {
    if (fetchChannelPerformanceReportDataReducer.current ) {
      setCurrentData(fetchChannelPerformanceReportDataReducer.current);
    }
    if (fetchChannelPerformanceReportDataReducer.past ) {
      setPrevData(fetchChannelPerformanceReportDataReducer.past);
    }
  }, [fetchChannelPerformanceReportDataReducer["message"] === "FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_RECEIVED"]);


  useEffect(() => {
    
    const updateTotals = (data, totals, channelCondition) => {
      totals.sessions = 0;
      totals.orders = 0;
      totals.totalUsers = 0;
      totals.conversionRate = 0;
      totals.newUsers = 0;
      totals.totalRevenue = 0;

      data.forEach(item => {
        if (channelCondition(item.channel)) {
          totals.sessions += item.sessions ? item.sessions : 0;
          totals.orders += item.transactions ? item.transactions : 0;
          totals.totalUsers += item.totalUsers ? item.totalUsers : 0;
          totals.conversionRate += item.conversionRate ? item.conversionRate : 0;
          totals.newUsers += item.newUsers ? item.newUsers : 0;
          totals.totalRevenue += item.totalRevenue ? item.totalRevenue : 0;
        }
      });
    };

    const channelConditions = {
      "All Channels": channel => !channel.includes('Merged'),
      "Direct": channel => channel === "Direct",
      "Merged Paid Media": channel => channel === "Merged Paid Media",
      "Paid Social": channel => channel === "Paid Social",
      "Email": channel => channel === "Email",
      "Merged Organic": channel => channel === "Merged Organic",
      "Referral": channel => channel.startsWith('Referral'),
      "Others": channel => channel === ('Others'),
    };
    if (currentData ) {
      
      const condition = channelConditions[selectedFilter];
      if (condition) {
        updateTotals(currentData, currentTotals, condition);
      }
    } else {
      setCurrentTotals({ sessions: 0, orders: 0, totalUsers: 0, conversionRate: 0, newUsers: 0, totalRevenue: 0 });
    }

    if (prevData ) {
      const condition = channelConditions[selectedFilter];
      if (condition) {
        updateTotals(prevData, prevTotals, condition);
      }
    } else {
      setPrevTotals({ sessions: 0, orders: 0, totalUsers: 0, conversionRate: 0, newUsers: 0, totalRevenue: 0 });
    }

    calChangePercentages(currentTotals, prevTotals);
  }, [currentData, prevData, selectedFilter,selectedDevice,selectedTimePeriod]);

  const handleOnClick = (channel) => {
    setSelectedFilter(channel);
  };

  const handleDeviceChange = (e) => {
    setSelectedDevice(e.target.value);
  }

  const handlePeriodChange = (e) => {
    const dates = dateRanges(e.target.value);
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
    setSelectedTimePeriod(e.target.value);
  }

  const handleBackBtn = () => {
    history.push("/intelli-Dashboard");
  }

  const calChangePercentages = (currentTotals, prevTotals) => {
    const changePercentage = { ...prevTotals };
    Object.keys(currentTotals).forEach(key => {
      if (currentTotals[key] && prevTotals[key]) {
        const percentage = ((currentTotals[key] - prevTotals[key]) / prevTotals[key]) * 100;
        changePercentage[key] = percentage;
      }
    });
    setChangePercentage(changePercentage);
  }

  const handleChange = async (history) => {
    await props.updateViewValue(1);
    ("/tasks/");
  };

  useEffect(() => {
    if (clientId && user_id && ppc_id) {
      fetchIntelliChatBotResponse(
        user_id,
        "Intelli chat",
        ppc_id,
        clientId,
        [`${startDate}`, `${endDate}`],
        [`${localStorage.getItem("compareStart")}`, `${localStorage.getItem("compareEnd")}`], section
      );
    }
  }, [ppc_id, startDate,
    endDate,])
  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "#fff" }}>
      <Grid container
        // sx={{ padding: "2%" }}
        direction={"row"} spacing={2} >
        <Grid container item xs={12} md={9} rowGap={{ xs: 2 }} >
          <Grid item xs={12} direction={"column"} sx={{ padding: "2%" }}>
            <Grid item display={"flex"} alignItems="center" justifyItems="flex-start">
              <Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBackBtn} sx={{ textTransform: "none", fontSize: "1rem", mb: 2 }}>
                Back to Dashboard
              </Button>
            </Grid>
            <Paper sx={{ width: "100%", padding: "1% 2%" }}>
              <Grid container direction={"column"} rowGap={{ xs: 2, md: 3 }}>
                <Grid item xs={12}>
                  <BigTitle>GA4 Channel Wise Performance</BigTitle>
                </Grid>
                <Divider sx={{ borderWidth: "thin" }} />
                <Grid item xs={12}>
                  <ButtonGroup variant="outlined" aria-label="Channel button group">
                    {AllGAChannels.map((channel, index) => (
                      <ChannelButton
                        key={index}
                        selected={selectedFilter === channel}
                        onClick={() => handleOnClick(channel)}
                      >
                        
                        {channel == "Merged Paid Media" ? "Paid Media" : channel == "Merged Organic" ? "Organic" : channel}
                      </ChannelButton>
                    ))}
                  </ButtonGroup>
                </Grid>
                <Grid item container direction="row" xs={12} columnGap={1} justifyContent={"space-between"} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={4} md={2.5} className="custom-grid">
                    <MetricCard value={currentTotals.sessions ? (currentTotals.sessions) : 0} previousValue={prevTotals.sessions ? (prevTotals.sessions) : 0} change={changePercentage.sessions ? changePercentage.sessions : 0} title="Sessions" />
                    <MetricCard value={currentTotals.orders ? (currentTotals.orders) : 0} previousValue={prevTotals.orders ? (prevTotals.orders) : 0} change={changePercentage.orders ? changePercentage.orders : 0} title="Orders" />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2.5} className="custom-grid">
                    <MetricCard value={currentTotals.totalUsers ? currentTotals.totalUsers : 0} previousValue={prevTotals.totalUsers ? prevTotals.totalUsers : 0}
                      change={changePercentage.totalUsers ? changePercentage.totalUsers : 0} title="Total Users" />

                    <MetricCard value={currentTotals.conversionRate ? currentTotals.conversionRate : 0} previousValue={prevTotals.conversionRate ? prevTotals.conversionRate : 0}
                      change={changePercentage.conversionRate ? changePercentage.conversionRate : 0} title="CVR" />
                  </Grid>
                  <Grid item xs={12} sm={4} md={2.5} className="custom-grid">
                    <MetricCard value={currentTotals.newUsers ? currentTotals.newUsers : 0} previousValue={prevTotals.newUsers ? prevTotals.newUsers : 0}
                      change={changePercentage.newUsers ? changePercentage.newUsers : 0} title="New Users" />

                    <MetricCard value={currentTotals.totalRevenue ? currentTotals.totalRevenue : 0} previousValue={prevTotals.totalRevenue ? prevTotals.totalRevenue : 0}
                      change={changePercentage.totalRevenue ? changePercentage.totalRevenue : 0} title="Total Revenue" />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3.5} display={"flex"} justifyContent={"space-between"} className="custom-grid">
                    <FilterCard title="Period:" optionList={TimePeriod} selected={selectedTimePeriod} handleChange={handlePeriodChange} />
                    <FilterCard title="Devices:" optionList={Devices} selected={selectedDevice} handleChange={handleDeviceChange} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} sx={{ padding: "2%", pr: { xs: 0, md: 2 } }}>
            <Card className="card">
              <Grid container row>
                <Grid item xs={8}>
                  <CardTitle
                    sx={{
                      paddingBottom: "3%",
                      color: "#414141",
                      fontSize: { xs: "1rem", sm: "1.125rem" },
                      fontWeight: "bold",
                    }}
                  >
                    Google Ads / Mircosoft Ads Performance
                  </CardTitle>
                </Grid>
                <Grid item xs={4} textAlign={"right"}>
                  <Tooltip title={<Typography fontSize={"0.875rem"}>{clientName}</Typography>} placement="top">
                    <Chip label={clientName} sx={{ borderRadius: "4px", background: "#EFEFEF", fontSize: "1rem", color: "#414141" }} />
                  </Tooltip>
                </Grid>
              </Grid>
              <hr className="hrDivider"></hr>
              {googleBingPerformanceData && googleBingPerformanceData.length > 0 ?
                <Typography pb={1} style={{ fontWeight: 500, color: "#1E334E", opacity: "0.7" }}>As of {moment().subtract(1, "days").format("Do MMMM")}</Typography> : ""
              }

              {googleBingPerformanceData && googleBingPerformanceData.length > 0 ? (
                <PerformanceCard performanceData={googleBingPerformanceData} userInformation={userInformation} />
              ) : (
                <Typography align="center">No data available</Typography>
              )}
            </Card>
          </Grid>

          <Grid item xs={12} md={6} sx={{ padding: "2%" }}>
            <Card className="card">
              <Grid container row>
                <Grid item xs={6}>
                  <CardTitle
                    sx={{
                      paddingBottom: "3%",
                      color: "#414141",
                      fontSize: { xs: "1rem", sm: "1.125rem" },
                      fontWeight: "bold",
                    }}
                  >
                    Task List
                  </CardTitle>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <Link
                    to={`/tasks/`}
                    onClick={() => handleChange(history)}
                    style={{ textDecoration: "none" }}
                  >
                    View Details &nbsp; {icons.ViewDetailsND}{" "}
                  </Link>
                </Grid>
              </Grid>
              <hr className="hrDivider"></hr>
              {taskListData != undefined ? (
                <TaskList data={taskListData} />
              ) : (
                <Typography align="center">No data available</Typography>
              )}
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}
          sx={{
            position: {
              xs: 'static', // Regular positioning on smaller screens
              md: 'grid',  // Fixed positioning on medium and larger screens
            },
            right: 0,
            // top: { xs: 'auto', md: "5%" }, // Adjust top depending on your layout

            overflowY: { md: 'auto' }, // Allows scrolling if content exceeds viewport
          }}
        >

          <IntelliChatbot current_date_range={"" + format(new Date(localStorage.getItem("startDate")), 'yyyy/MM/dd') + " ~ " + format(new Date(localStorage.getItem("endDate")), 'yyyy/MM/dd') + ""}
            previous_date_range={"" + format(new Date(localStorage.getItem("compareStart")), 'yyyy/MM/dd') + " ~ " + format(new Date(localStorage.getItem("compareEnd")), 'yyyy/MM/dd') + ""}

          />

        </Grid>
      </Grid>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  fetchChannelPerformanceReportDataReducer: state.fetchChannelPerformanceReportDataReducer,
  connectionsDataReducer: state.connectionsDataReducer,
  userInformation: state.userInformation,
  fetchGoogleBingPerformanceDataReducer: state.fetchGoogleBingPerformanceDataReducer,
})
const mapDispatchToProps = (dispatch) => ({
  fetchChannelPerformanceReportData: (accountId, propertyId, startDate, endDate, timeUnit, channels, devices, products, locations, gender, sortBy, direction) =>
    dispatch(fetchChannelPerformanceReportData(accountId, propertyId, startDate, endDate, timeUnit, channels, devices, products, locations, gender, sortBy, direction)),
  fetchConnectionRelatedInfo: () => dispatch(fetchConnectionRelatedInfo()),
  fetchGoogleBingPerformanceData: (clientId, startDate, endDate) => dispatch(fetchGoogleBingPerformanceData(clientId, startDate, endDate)),
  updateViewValue: (viewValue) => {
    return dispatch({
      type: types.DASHBOARD_VIEW_VALUE_UPDATED,
      viewValue: viewValue,
    });
  },
  fetchIntelliChatBotResponse: (
    user_id,
    message,
    ppc_id,
    clientId,
    current_date_range,
    previous_date_range, section
  ) =>
    dispatch(
      fetchIntelliChatBotResponse(
        user_id,
        message,
        ppc_id,
        clientId,
        current_date_range,
        previous_date_range, section
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChannelPerformance);
