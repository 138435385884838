


import * as types from "../actions/actionTypes";

export function fetchJWTTokenURLReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.APIGATEWAY_AUTH_URL_REQUESTED:
        return Object.assign({}, state, {
          message: "APIGATEWAY_AUTH_URL_REQUESTED",
        });
      case types.APIGATEWAY_AUTH_URL_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "APIGATEWAY_AUTH_URL_RECEIVED",
        });
      case types.APIGATEWAY_AUTH_URL_FAILED:
        return Object.assign({}, state, {
          message: "APIGATEWAY_AUTH_URL_FAILED",
        });
      default:
        // console.log("The state is Connections Data= ", state);
        return state;
    }
  }