import { Grid, MenuItem, Select, Typography,Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TicketTypeContainer from "./ticketTypeContainer";
import TicketsTable from "./ticketsTable";
import { fetchAllTickets } from "../../actions/ticketsActions";
import { fetchAllTicketsReducer } from "../../reducers/ticketReducer";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "25px 20px 50px 20px",
      backgroundColor: "#F9FAFB",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      backgroundColor: "#F9FAFB",
    },
  },
});

function RaisedTickets(props) {
  const classes = props.classes;
  const { fetchAllTickets, fetchAllTicketsReducer, userInformation } = props;
  const [allTicketsData, setAllTicketsData] = useState([]); //store all tickets (closed and open)
  const [closedTickets, setClosedTickets] = useState([]); //store closed tickets
  const [openTickets, setOpenTickets] = useState([]); //store open tickets
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [clientId, setClientId] = useState(
    userInformation ? userInformation.clientId : 0
  );
  const history = useHistory();

  const [ticketTypes, setTicketTypes] = useState([
    {
      type: "Total Tickets",
      count: 0,
    },
    {
      type: "Open Tickets",
      count: 0,
    },
    {
      type: "Closed Tickets",
      count: 0,
    },
  ]);

  //fetch all tickets from API only after getting client id
  useEffect(() => {
    setLoading(true);
    if (userInformation.clientId != null) {
      fetchAllTicketsData(userInformation.clientId, selectedFilter);
      setClientId(userInformation.clientId);
    }
    setLoading(false);
  }, [userInformation.clientId]);

  const fetchAllTicketsData = async (client, filter) => {
    const data = await fetchAllTickets(client, filter);
    if (data != null && data.length > 0) {
      const allTickets = data.map((ticket) => ({
        ...ticket.expertHelpRequest,
        subject: ticket.subject,
      }));
      setAllTicketsData(allTickets);
    }
  };

  useEffect(() => {
    if (allTicketsData != null && allTicketsData.length > 0) {
      setLoading(true);
      const open = allTicketsData.filter(
        (ticket) => ticket.requestStatus !== "Closed"
      );
      const closed = allTicketsData.filter(
        (ticket) => ticket.requestStatus === "Closed"
      );
      setOpenTickets(open);
      setClosedTickets(closed);
      setTicketTypes([
        {
          type: "Total Tickets",
          count: allTicketsData.length,
        },
        {
          type: "Open Tickets",
          count: open.length,
        },
        {
          type: "Closed Tickets",
          count: closed.length,
        },
      ]);
      setLoading(false);
    }
  }, [allTicketsData]);
console.log("open tickets ======>", openTickets);
const handleBackBtn = () => {
  // history.go("/intelli-Dashboard");
  history.goBack()
}
  return (
    <div className={classes.mainDiv}>
      <Grid container columnSpacing={2}>
      <Grid item display={"flex"} alignItems="center" justifyItems="flex-start">
              <Button variant="text" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBackBtn} sx={{ textTransform: "none", fontSize: "1rem", mb: 2 }}>
                Back to Dashboard
              </Button>
            </Grid>
        <Grid container item direction={"row"} xs={12} rowSpacing={3}>
          <Grid item xs={12} display={"flex"} width={"100%"} justifyContent={"space-between"}>
            <Grid
              container
              item
              direction={"row"}
              justifyContent={"flex-start"}
              columnGap={3}
            >
              {ticketTypes.map((ticketType) => (
                <TicketTypeContainer
                  type={ticketType.type}
                  count={ticketType.count}
                  loading={loading}
                />
              ))}
            </Grid>
            <Grid item xs={2} display={"flex"} alignItems={"center"} sx={{float:"right"}}>
              <Typography>
                Show: &nbsp;
                <Select
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "select tickets" }}
                  size="small"
                  defaultValue="All"
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Closed"}>Closed</MenuItem>
                  <MenuItem value={"Open"}>Open</MenuItem>
                </Select>
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <TicketsTable
              ticketDetails={allTicketsData}
              openTickets={openTickets}
              closedTickets={closedTickets}
              filter={selectedFilter}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInformation: state.userInformation.selectedClientInfo,
  fetchAllTicketsReducer: state.fetchAllTicketsReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAllTickets: (clientId, filter) =>
    dispatch(fetchAllTickets(clientId, filter)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RaisedTickets));
