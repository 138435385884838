import * as types from "./actionTypes";
import * as _ from "lodash";
import { calculateOrderBy } from "../components/admin/templates";
import { FilterCenterFocus } from "@mui/icons-material";
import { dispatch } from "d3";
import Cookies from "js-cookie";
// import {daysCount} from "../selectors/dashboard-selector"

let moment = require("moment");

function fixServerResponse(data) {
  data.tasks = data.tasks.filter((task) => task.taskDataList); // Keep only tasks which have taskData

  /*
   * Fix issues with server response:
   *   server's TaskData does not have correct taskTypeId, so copy it from Task
   *   For 'new' TaskData, status is null in db and hence is not rendered in json. So set status to 0 if missing.
   *   We also need TaskName and taskDescription in TaskData. So copy it from task
   *
   * Note the use of 'spread operator' and 'concise properties' (https://davidwalsh.name/merge-objects, https://gist.github.com/yesvods/4c7251f060029c8a5b07)
   * Also note order of spreads and elements.
   * */
  data.tasks.forEach(
    (task) =>
    (task.taskDataList = task.taskDataList.map((taskData) => ({
      ...task,
      ...taskData,
      taskTypeId: task.taskTypeId,
      status: taskData.status || 0,
    })))
  );

  /*
   * Current server does not have seperate fields for costForecast and costSavingForecast.
   * So move costForecast to costSavingForecast for appropriate tasy types.
   *
   * */
  data.tasks
    .filter((task) => ![8, 16, 33].includes(task.taskTypeId))
    .forEach(
      (task) =>
      (task.taskDataList = task.taskDataList.map((td) => ({
        ...td,
        costSavingForecast: td.costForecast,
        costForecast: 0,
      })))
    );

  /*
   * Special hanling for Task Type 35 (Change Hourly Bid Modifiers)
   * It contains multiple TaskData elements (day-hour combinations)for same campaign
   * But we want to consider the whole schedule as single/atomic TaskData
   * So we create a 'manufactured' TaskData object and and keep all real TaskData objects inside an array in this object
   * taskDataId of this manufactured object is comma seperated values of real taskDataId's
   * */
  data.tasks
    .filter((task) => task.taskTypeId === 35)
    .forEach((task) => {
      const grouped = _.groupBy(task.taskDataList, "campaignId");
      console.log("grouped=", grouped);
      task.taskDataList = _.map(grouped, (taskDataList) => ({
        ...taskDataList[0],
        taskDataList: taskDataList,
        taskDataIdList: _.map(taskDataList, "taskDataId"),
      }));
      console.log("task = ", task);
    });

  if (window.lxrOptions && window.lxrOptions.adjustHistoryDates) {
    data.history = _.chain(data.history)
      .groupBy("day")
      .values()
      .map((list, i) =>
        list.map((v) => ({
          ...v,
          day: moment()
            .subtract(i + 1, "d")
            .format("YYYY-MM-DD"),
        }))
      )
      .flatten()
      .value();
  }
}

const getAccountStats = (googleAdsReportData) => {
  if (googleAdsReportData == null) {
    return;
  }
  console.log("googleAdsReportData=", googleAdsReportData);
  /*accountStatsData = accountStatsData.map((v) => ({
        ...v,
        ctr: Number(v.ctr),
        conversions: Number(v.conversions),
        conversionValue: Number(v.conversionValue),
    }));
    return {
        impressions: _.sumBy(accountStatsData, "impressions"),
        clicks: _.sumBy(accountStatsData, "clicks"),
        ctr: _.sumBy(accountStatsData, "ctr"),
        conversions: _.sumBy(accountStatsData, "conversions"),
        cost: _.sumBy(accountStatsData, "cost"),
        revenue: _.sumBy(accountStatsData, "conversionValue"),
    }*/
  return {
    impressions: googleAdsReportData.impressions,
    clicks: googleAdsReportData.clicks,
    ctr: googleAdsReportData.ctr,
    conversions: googleAdsReportData.conversions,
    cost: googleAdsReportData.cost,
    revenue: googleAdsReportData.revenue,
  };
};

export function dispatchDashboardData(data, dispatch) {
  console.log("data is = ", data);
  fixServerResponse(data);
  console.log("final data is = ", data);

  dispatch({
    type: types.PERF_DATA_RECEIVED,
    data,
  });
}

function calculateFetchDates(perf, dateRange) {
  // console.error("dateRange", dateRange);
  let fromDate = moment(dateRange.fromDate);
  let toDate = moment(dateRange.toDate);
  let dateDiffCount = moment(toDate).diff(moment(fromDate), "days") + 1;
  // console.error("dateDiffCount=", dateDiffCount);
  fromDate = fromDate.subtract(dateDiffCount, "days");
  // console.error("fromDate=", fromDate.format("YYYY-MM-DD"));
  const { history } = perf;
  let historyDates = history.map((h) => moment(h.day));
  let historyMinDate = moment.min(historyDates);
  // console.log("historyMinDate= " + historyMinDate.format("YYYY-MM-DD"));
  toDate = moment(historyMinDate).subtract(1, "days");
  // console.error("toDate=",toDate.format("YYYY-MM-DD"))
  return { toDate, fromDate };
}

/*export function fetchPerfData() {
    return async function (dispatch, getState) {
        const {perf, selectedDateRange} = getState();
        console.log("selectedDateRange=", selectedDateRange);
        var dateFormat = "YYYY-MM-DD";
        if (perf.history.length != 0) {
            const {history} = perf;
            let historyDates = history.map(h => moment(h.day));
            let historyMinDate = moment.min(historyDates);
            var toDate = moment(historyMinDate).subtract(1, 'days');
            var fromDate = moment(selectedDateRange.fromDate);
            if (toDate <= fromDate) {
                return
            }
            toDate = toDate.format(dateFormat);
            fromDate = fromDate.format(dateFormat);
        } else {
            var toDate = moment().subtract(1, "days").format(dateFormat);
            var fromDate = moment().subtract(90, "days").format(dateFormat);
        }

        console.log("fetching perf data");
        setTimeout(async () => {
            try {
                const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
                dispatch({
                    type: types.PERF_DATA_REQUESTED
                });
                let url = useDummyData ? "/dashboard-data-local.JSON" : "/dashboard-data.json";
                url = `${url}??withTaskData=true&fromDate=${fromDate}&toDate=${toDate}`;
                let response = await fetch(url, {
                    headers: {
                        'Accept': 'application/json',
                    },
                    'credentials': 'include'
                });
                let data = await response.json();
                console.log("date respone=", data);
                dispatchDashboardData(data, dispatch);
            } catch (e) {
                dispatch({type: types.PERF_DATA_REQUEST_FAILED});
            }
        }, 0);
    }
}*/
export function fetchPerfData(isGraphDate, fromEcommerceDashboard) {
  return async function (dispatch, getState) {
    const { perf, selectedDateRange, userInformation } = getState();
    const selectedPpcId = userInformation.selectedAccountInfo.ppcId
      ? userInformation.selectedAccountInfo.ppcId
      : 0;

    let dateRange = selectedDateRange;
    if (isGraphDate) {
      const { selectedGraphDateRange } = getState();
      dateRange = selectedGraphDateRange;
    }
    var dateFormat = "YYYY-MM-DD";

    if (perf.history.length != 0) {
      //if this is not triggered from channel performance page, use the existing data, otherwise fetch new data
      if (fromEcommerceDashboard == false || fromEcommerceDashboard == undefined) {
        ({ toDate, fromDate } = calculateFetchDates(perf, dateRange));

        if (toDate <= fromDate) {
          console.log("toDate is <= fromDate");
          return;
        }

        toDate = toDate.format(dateFormat);
        fromDate = fromDate.format(dateFormat);
      } else {
        toDate = moment().subtract(1, "days").format(dateFormat);
        fromDate = moment().subtract(90, "days").format(dateFormat);
      }
    } else {
      console.error("Came to else section for taking 90 days");
      var toDate = moment().subtract(1, "days").format(dateFormat);
      var fromDate = moment().subtract(90, "days").format(dateFormat);
    }
    console.log("fetching perf data from database toDate:",toDate,"---fromDate",fromDate);
    setTimeout(async () => {
      try {
        const useDummyData =
          window.lxrOptions && window.lxrOptions.useDummyData;
        dispatch({
          type: isGraphDate
            ? types.PERF_KPI_GRAPH_DATA_REQUESTED
            : types.PERF_DATA_REQUESTED,
        });
        let url = useDummyData
          ? "/dashboard-data-local-latest.JSON"
          : "/dashboard-data.json";
        url = `${url}??withTaskData=true&fromDate=${fromDate}&toDate=${toDate}&ppcId=${selectedPpcId}`;
        let response = await fetch(url, {
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        let data = await response.json();
        console.log("fetchPerfData date respone=", data);
        dispatchDashboardData(data, dispatch);
      } catch (e) {
        dispatch({ type: types.PERF_DATA_REQUEST_FAILED });
      }
    }, 0);
  };
}

export function selectDateRange(dateRange) {
  const dateRanges = {
    fromDate: moment()
      .subtract(dateRange + 1, "d")
      .format("YYYY-MM-DD"),
    toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    itemValue: dateRange,
  };
  return async (dispatch) => {
    await dispatch({
      type: types.SELECT_DATE_RANGE,
      dateRanges,
    });
  };
}

export function customDateRange(dateRanges) {
  return async (dispatch) => {
    await dispatch({
      type: types.SELECT_DATE_RANGE,
      dateRanges,
    });
    dispatch(fetchPerfData());
  };
}

export function resetDateRange() {
  return async (dispatch) => {
    await dispatch({
      type: types.RESET_DATE_RANGE,
    });
  };
}

export function executeTasks(taskDataList) {
  console.log("taskDataList=", taskDataList);
  // return null;
  return async function (dispatch) {
    const url = "/executeTasks.json";
    const taskDataIdList = _.flatten(
      taskDataList.map((td) => td.taskDataIdList || td.taskDataId)
    );
    console.log(taskDataIdList);
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
      },
      credentials: "include",
      body: new URLSearchParams({ taskDataIds: taskDataIdList.join(",") }),
    });
    let data = await response.json();
    // let data = _.chain(taskDataIdList).keyBy().mapValues(() => 1).value();

    console.log("data = ", data);
    dispatch({
      type: types.UPDATE_TASK_DATA_STATUS,
      data,
    });
    return data;
  };
}

export function executeAddAdcopyTask(adCopyObj) {
  console.log("adCopyObj=", adCopyObj);
  return async function (dispatch) {
    try {
      const url = "/execute-add-adCopy-task.json";
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: JSON.stringify(adCopyObj),
      });
      let data = await response.json();
      dispatch({
        type: types.UPDATE_TASK_DATA_STATUS_TASK_TYPE_51,
        data,
      });
    } catch (e) {
      console.log("exception", e);
    }
  };
}

export function saveRequestDemo(demoObj) {
  return async function (dispatch) {
    console.log("saving Demo request...", demoObj);
    try {
      const url = "/save-request-demo.json";
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: JSON.stringify(demoObj),
      });
      let data = await response.json();
      console.log("Demo reuest saved = ", data);
      return data;
    } catch (e) {
      console.log("exception", e);
    }
    // let data = _.chain(taskDataIdList).keyBy().mapValues(() => 1).value();

    /* dispatch({
             type: types.UPDATE_TASK_DATA_STATUS,
             data
         });*/
  };
}

export function saveRequestJumpstartData(jumpstartObj) {
  return async function (dispatch) {
    console.log("saving jumpstart request...", jumpstartObj);
    try {
      const url = "/save-request-jumpstart.json";
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(jumpstartObj),
      });
      let data = await response.json();
      console.log("jump start request data saved = ", data);
      return data;
    } catch (e) {
      console.log("exception", e);
    }
    // let data = _.chain(taskDataIdList).keyBy().mapValues(() => 1).value();

    /* dispatch({
             type: types.UPDATE_TASK_DATA_STATUS,
             data
         });*/
  };
}

export function saveRequestExpertHelpData(expertHelpRequest) {
  return async function (dispatch) {
    console.log("saving jumpstart request...", expertHelpRequest);
    try {
      const url = "/expert-help-request.json";
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(expertHelpRequest),
      });
      let data = await response.json();
      console.log("jump start request data saved = ", data);
      return data;
    } catch (e) {
      console.log("exception", e);
    }
    // let data = _.chain(taskDataIdList).keyBy().mapValues(() => 1).value();

    /* dispatch({
             type: types.UPDATE_TASK_DATA_STATUS,
             data
         });*/
  };
}

export function taskSeenDate(taskIds) {
  return async function (dispatch) {
    const url = "/taskSeenUpdate.json";
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": Cookies.get('CSRF-TOKEN'),
      },
      credentials: "include",
      body: new URLSearchParams({ taskId: taskIds }),
    });
    let data = taskIds;
    console.log("data = ", data);
    dispatch({
      type: types.SEEN_TASK,
      data,
    });
  };
}

export function trackTour(pageId) {
  return async function (dispatch) {
    console.log("calling track tour");
    const url = "/track-tour.json";
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
      },
      credentials: "include",
      body: new URLSearchParams({ trackTourPageId: pageId }),
    });
    let data = pageId;
    console.log("data = ", data);
    dispatch({
      type: types.UPDATE_PRODUCT_TOUR_TRACK,
      pageId,
    });
    return data;
  };
}

export function storeFeedback(description, starRating) {
  return async function (dispatch) {
    const url = "/feedbackByUser.json";
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
      },
      credentials: "include",
      body: new URLSearchParams({
        description: description,
        starRating: starRating,
      }),
    });
    let data = await response.json();
    console.log("data = ", data);
    dispatch({
      type: types.UPDATE_FEEDBACK_STATUS,
    });
    return data;
  };
}

export function fetchTaskData(taskId) {
  return async function (dispatch) {
    console.log("fetchTaskData = ", taskId);
    dispatch({
      type: types.FETCH_TASK_DATA,
      taskId,
    });

    try {
      let response = await fetch(`/task-data.${taskId}.json`, {
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
      let data = await response.json();
      console.log("taskData = ", taskId, data);
      dispatch({
        type: types.TASK_DATA_RECEIVED,
        taskId,
        data,
      });
    } catch (e) {
      console.log("fetchTaskData failed", taskId, e);
      dispatch({
        type: types.TASK_DATA_FETCH_FAILED,
        taskId,
      });
    }
  };
}

// Action for Industry Data
export function fetchIndustryInsightIndusryData() {
  return async function (dispatch) {
    console.log("inside fetchIndustryInsightIndusryData");
    const url = "/industry-type.json";
    dispatch({
      type: types.FETCH_INDUSTRY_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      let data = await response.json();
      // console.log("The industry data is ", data);
      dispatch({
        type: types.FETCH_INDUSTRY_DATA_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_INDUSTRY_DATA_FAILED,
      });
    }
  };
}

// Action for Industry Insights
export function fetchIndustryInsightData(industry) {
  console.log("fetchIndustry", industry);
  return async function (dispatch) {
    const url = "/industry-details.json";
    dispatch({
      type: types.FETCH_INDUSTRY_INSIGHT_DATA_REQUESTED,
      industry,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          industry: industry,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_INDUSTRY_INSIGHT_DATA_RECEIVED,
        data,
        industry,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_INDUSTRY_INSIGHT_DATA_FAILED,
        industry,
      });
    }
  };
}

//action for autotaskfetch

export function fetchAutoTaskData(ppcId) {
  return async function (dispatch) {
    console.log("fetchAutoTaskData ppcId is = ", ppcId);
    // const url = "/auto-posting-data-local.JSON"; //local json
    const url = "/fetch-autoPost-task.json";
    dispatch({
      type: types.FETCH_AUTO_TASK_DATA_REQUESTED,
      ppcId,
    });

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          ppcId: ppcId,
        }),
      });
      let data = await response.json();
      console.log("autoTaskData = ", ppcId, data);
      dispatch({
        type: types.FETCH_AUTO_TASK_DATA_RECEIVED,
        ppcId,
        data,
      });
      return data;
    } catch (e) {
      console.log("fetchAutoTaskData failed", ppcId, e);
      dispatch({
        type: types.FETCH_AUTO_TASK_DATA_FAILED,
        ppcId,
      });
    }
  };
}
////end of action for autotaskfetch

//save the taskids
export function saveAutoTaskData(ppcId, selectedTaskIds) {
  return async function (dispatch) {
    console.log(
      "saveAutoTaskData ppcId is = ",
      ppcId,
      "---selectedTaskIds::",
      selectedTaskIds
    );
    const url = "/update-autoPost-task.json";
    dispatch({
      type: types.SAVE_AUTO_TASK_DATA_REQUESTED,
      ppcId,
    });

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          ppcId: ppcId,
          taskTypeIds: selectedTaskIds,
        }),
      });
      let data = await response.json();
      console.log("save autoTaskData = ", ppcId, data);
      dispatch({
        type: types.SAVE_AUTO_TASK_DATA_RECEIVED,
        ppcId,
        data,
      });
      return data;
    } catch (e) {
      console.log("saveAutoTaskData failed", ppcId, e);
      dispatch({
        type: types.SAVE_AUTO_TASK_DATA_FAILED,
        ppcId,
      });
    }
  };
}
// Action for GA4 and GA UA URLs
export function fetchGA_URL() {
  return async function (dispatch) {
    console.log("inside fetchGA_URL");
    const url = "/import-analytics-account.json";
    dispatch({
      type: types.FETCH_GOOGLE_ANALY_URL_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      let data = await response.json();

      console.log("The GA URLs data is ", data);
      dispatch({
        type: types.FETCH_GOOGLE_ANALY_URL_DATA_RECEIVED,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALY_URL_DATA_FAILED,
      });
    }
  };
}

//Action for the getting Connection Data
export function fetchConnectionRelatedInfo() {
  return async function (dispatch) {
    console.log("inside fetchConnectionRelatedInfo");
    const url = "/client-account-connections.json";

    dispatch({
      type: types.FETCH_CONNECTION_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      let data = await response.json();

      console.log("The GA connctions data is e ", data);
      dispatch({
        type: types.FETCH_CONNECTION_DATA_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_CONNECTION_DATA_FAILED,
      });
    }
  };
}
//Action for Deleting the Google Analytics Connection -- /delete-analytics-account.json
export function deleteAnalyticsConnection(analyticsId) {
  return async function (dispatch) {
    console.log(
      "Inside deleteAnalyticsConnection for analytics account id--->",
      analyticsId
    );

    const url = "/delete-analytics-account.json";
    dispatch({
      type: types.DELETE_ANALY_CONNECTION_REQUESTED,
      analyticsId,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          analyticsId: analyticsId,
        }),
      });
      let data = await response.json();

      console.log("Delete connection o/p data is ", data);
      dispatch({
        type: types.DELETE_ANALY_CONNECTION_RECEIVED,
        analyticsId,
        data,
      });
      console.log("Delete connection o/p data is 2: ", data);
      return data;
    } catch (e) {
      dispatch({
        type: types.DELETE_ANALY_CONNECTION_FAILED,
        analyticsId,
      });
    }
  };
}
// end of Action for Deleting the Google Analytics Connection -- /delete-analytics-account.json

export function storeSearchEngineId(engineId) {
  return async function (dispatch) {
    console.log("inside fetchGaDataStoreSearchEngineId--->", engineId);

    const url = "/store-engine-id.json";
    dispatch({
      type: types.FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_REQUESTED,
      engineId,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          engineId: engineId,
        }),
      });
      let data = await response.json();

      console.log("engine data is ", data);
      dispatch({
        type: types.FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_RECEIVED,
        engineId,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_FAILED,
        engineId,
      });
    }
  };
}

///for date range///
export function fetchDateRangeForGAData(selectedPeriod) {
  return async function (dispatch) {
    console.log("inside fetchDateRangeForGA--->", selectedPeriod);

    const url = "/get-date-range-for-selected-period.json";
    dispatch({
      type: types.FETCH_DATE_RANGE_REQUESTED,
      selectedPeriod,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          selectedPeriod: selectedPeriod,
        }),
      });
      let data = await response.json();

      console.log("Date range data is ", data);
      dispatch({
        type: types.FETCH_DATE_RANGE_RECEIVED,
        selectedPeriod,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_DATE_RANGE_FAILED,
        selectedPeriod,
      });
    }
  };
}
///end of date range api//
// Action for fetching properties and views
// export function fetchGaDataViewsPropertiesAction() {
//   return async function (dispatch) {
//     console.log("inside fetchGaDataViewsProperties");
//     const url = "/fetch-gadata-views-properties.json";
//     dispatch({
//       type: types.FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_REQUESTED,
//     });
//     try {
//       console.log("inside try");
//       let response = await fetch(url, {
//         method: "GET",
//         headers: {
//           Accept: "application/json",
//         },
//       });
//       console.log("response=====>", response);
//       let data = await response.json();
//       console.log("after data ", data);
//       console.log("The GA views and data is ", data);
//       dispatch({
//         type: types.FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_RECEIVED,
//         status,
//         accountId,
//         data,
//       });
//       return data;
//     } catch (e) {
//       dispatch({
//         type: types.FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_FAILED,
//       });
//     }
//   };
// }
export function fetchGaDataViewsPropertiesAction(status, GA_accountId) {
  return async function (dispatch) {
    console.log("The GA_accountId and Status is", GA_accountId, status);
    const url = "/fetch-gadata-views-properties.json";
    dispatch({
      type: types.FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_REQUESTED,
      status,
      GA_accountId,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          addAccountStatus: status, //use the same param names given in request mapping params
          analyticsId: GA_accountId,
        }),
      });
      let data = await response.json();
      console.log("Data after the IDS", data);
      dispatch({
        type: types.FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_RECEIVED,
        data,
        GA_accountId,
        status,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_FAILED,
        GA_accountId,
        status,
      });
    }
  };
}
export function fetchGAModelData(accountId, propertyId, viewId) {
  return async function (dispatch) {
    console.log(
      "The accId, propId, and ViewId is",
      accountId,
      propertyId,
      viewId
    );
    const url = "/store-user-acc-data.json";
    dispatch({
      type: types.FETCH_GA_MODEL_DATA_REQUESTED,
      accountId,
      propertyId,
      viewId,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          accountId: accountId,
          propertyId: propertyId,
          viewId: viewId,
        }),
      });
      let data = await response.json();
      console.log("Data after the IDS", data);
      dispatch({
        type: types.FETCH_GA_MODEL_DATA_RECEIVED,
        data,
        accountId,
        propertyId,
        viewId,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GA_MODEL_DATA_FAILED,
        accountId,
        propertyId,
        viewId,
      });
    }
  };
}

export function fetchGASessionsAndTransactionsData(
  accountIdConnection,
  propertyIdAPI,
  viewIdAPI,
  selectedPeriod
) {
  return async function (dispatch) {
    const url = "/sessions-transactions-by-day.json";
    console.log(
      "inside fetchGASessionsAndTransactionsData",
      "accountId:",
      accountIdConnection,
      "-propertyId:",
      propertyIdAPI,
      "-viewId:",
      viewIdAPI,
      "-selectedPeriod:",
      selectedPeriod
    );
    dispatch({
      type: types.FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_REQUESTED,
      accountIdConnection,
      propertyIdAPI,
      viewIdAPI,
      selectedPeriod,
    });
    try {

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          accountId: accountIdConnection,
          propertyId: propertyIdAPI,
          viewId: viewIdAPI,
          selectedPeriod: selectedPeriod,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_RECEIVED,
        data,
        // accountIdConnection,
        // propertyIdAPI,
        // viewIdAPI,
        // selectedPeriod,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_FAILED,
        // accountIdConnection,
        // propertyIdAPI,
        // viewIdAPI,
        // selectedPeriod,
      });
      console.log("error occured at fetchGASessionsAndTransactionsData::", e);
    }
  };
}
///// for saving the data in store-user-acc-data.json
export function storeUserAnalyAccData(
  accountId,
  accountName,
  propertyId,
  websiteUrl,
  propertyName,
  viewId,
  viewName
) {
  return async function (dispatch) {
    const url = "/store-user-acc-data.json";

    console.log(
      "inside storeUserAnalyAccData===>",
      accountId,
      accountName,
      propertyId,

      propertyName,
      viewId,
      viewName,
      websiteUrl
    );
    dispatch({
      type: types.STORE_ANALY_USER_ACC_DATA_REQUESTED,
      accountId,
      accountName,
      propertyId,

      propertyName,
      viewId,
      viewName,
      websiteUrl,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          accountId,
          accountName,
          propertyId,

          propertyName,
          viewId,
          viewName,
          websiteUrl,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.STORE_ANALY_USER_ACC_DATA_RECEIVED,
        data,
        accountId,
        accountName,
        propertyId,
        propertyName,
        viewId,
        viewName,
        websiteUrl,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.STORE_ANALY_USER_ACC_DATA_FAILED,
        accountId,
        accountName,
        propertyId,
        propertyName,
        viewId,
        viewName,
        websiteUrl,
      });
    }
  };
}
//For getting the syncheckID
export function fetchSynCheckId() {
  return async function (dispatch) {
    const url = "/analytics-integration.json";
    dispatch({
      type: types.FETCH_SYNCHECKID_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_SYNCHECKID_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_SYNCHECKID_FAILED,
      });
    }
  };
}

//For getting the value of status and percenatge for bar
export function fetchStatusForProgressBar(synCheckId) {
  return async function (dispatch) {
    const url = "/analytics-download-status.json";
    dispatch({
      type: types.FETCH_STATUS_FOR_PROGRESS_BAR_REQUESTED,
      synCheckId,
    });
    try {
      console.log("inside fetchStatusForProgressBar=>", synCheckId);
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          syncCheckId: synCheckId,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_STATUS_FOR_PROGRESS_BAR_RECEIVED,
        data,
        synCheckId,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_STATUS_FOR_PROGRESS_BAR_FAILED,
        synCheckId,
      });
    }
  };
}
export function fetchStatesSessionAndBounceRateData(
  accountIdConnection,
  propertyIdAPI,
  viewIdAPI,
  selectedPeriod
) {
  return async function (dispatch) {
    const url = "/get-state-sessions-bounce-rate.json";
    dispatch({
      type: types.FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_REQUESTED,
      accountIdConnection,
      propertyIdAPI,
      viewIdAPI,
      selectedPeriod,
    });
    try {
      console.log(
        "fetchStatesSessionAndBounceRateData==>accountId:",
        accountIdConnection,
        "::propertyId::",
        propertyIdAPI,
        "::viewId::",
        viewIdAPI,
        "::selectedPeriod::",
        selectedPeriod
      );

      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",

        body: new URLSearchParams({
          accountId: accountIdConnection,
          propertyId: propertyIdAPI,
          viewId: viewIdAPI,
          selectedPeriod: selectedPeriod,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_FAILED,
      });
      console.log("error occured at fetchStatesSessionAndBounceRateData::", e);
    }
  };
}

export function fetchHealthScorecardData(externalUserId) {
  return async function (dispatch) {
    const url = "/get-website-health-scorecard-data.json";
    dispatch({
      type: types.FETCH_HEALTH_SCORECARD_DATA_REQUESTED,
      externalUserId,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          externalUserId: externalUserId,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_HEALTH_SCORECARD_DATA_RECEIVED,
        data,
        externalUserId,
      });
     
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_HEALTH_SCORECARD_DATA_FAILED,
      });
    }
  };
}
// for /record-whs-user-log.json
export function recordWhsUserLog() {
  console.log("recordWhsUserLog ===");
  return async function (dispatch) {
    const url = "/record-whs-user-log.json";
    dispatch({
      type: types.WHS_USER_LOG_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
      });
      let data = await response.json();
      dispatch({
        type: types.WHS_USER_LOG_DATA_RECEIVED,
        data,
      });
      console.log("The WHS_USER_LOG_DATA_RECEIVED data is", data);
      return data;
    } catch (e) {
      dispatch({
        type: types.WHS_USER_LOG_DATA_FAILED,
      });
    }
  };
}
export function sendScorecardMail(userId, userType) {
  console.log("Called in Actions for sendScorecardMail");
  return async function (dispatch) {
    const url = "/website-health-score-contact-support.json";
    dispatch({
      type: types.FETCH_HEALTH_SCORECARD_EMAIL_DATA_REQUESTED,
    });
    try {
      console.log("send mail whc", url);
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          userId: userId,
          userType: userType,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_HEALTH_SCORECARD_EMAIL_DATA_RECEIVED,
        data,
      });
     
      return data;
    } catch (e) {
      console.log("issue in send mail whc", e);
      dispatch({
        type: types.FETCH_HEALTH_SCORECARD_EMAIL_DATA_FAILED,
      });
    }
  };
}

export function toggleSelection(taskIds, selected) {
  return {
    type: types.TOGGLE_SELECTION,
    data: {
      taskIds,
      selected,
    },
  };
}

export function toggleSelectionForServicePack(taskIds, adCopyObj) {
  console.log("taskIds,", taskIds);
  console.log("adCopyObj,", adCopyObj);
  return async function (dispatch) {
    const name = "Ad Copy";
    const type = "Ad Copy";
    const campaign = adCopyObj.campaignName;
    const adgroupTheme = adCopyObj.adGroupName;
    await dispatch({
      type: types.TOGGLE_SELECTION_FOR_SERVICE_PACK,
      data: {
        taskIds,
        adCopyObj,
      },
    });
  };
}

export function addAdCopyMiniService() {
  return async function (dispatch, getState) {
    const { selectedServicePackBytTaskDataIds } = getState();
    const selectedItems = _.filter(
      _.values(selectedServicePackBytTaskDataIds),
      "selected"
    );
    console.log("selectedItems=", selectedItems);
    await selectedItems.forEach(async (item, index) => {
      await dispatch({
        type: types.UPDATE_MINI_SERVICE_ITEM_COUNT,
        data: { name: "Ad Copy", counts: index + 1 },
      });
      const formInput = {
        type: "Ad Copy",
        label: `Ad Copy${index + 1}`,
        campaign: item.campaignName,
        adgroupTheme: item.adGroupName,
        taskDataId: item.taskDataId,
      };
      await dispatch({
        type: types.UPDATE_MINI_SERVICE_INPUTS,
        data: formInput,
      });
    });

    console.log("getState().inputFroms = ", getState().inputFroms);
  };
}

export function saveOnDemandFroms(miniService) {
  console.log("itemcount server", miniService);
  return async function (dispatch, getState) {
    const { onDemandPayment } = getState();
    miniService = {
      ...miniService,
      isPremierServicePackMonthly: onDemandPayment.isPremierServicePackMonthly,
    };
    const url = "/save-inputs.json";
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
      },
      credentials: "include",
      body: new URLSearchParams({
        miniService: JSON.stringify(miniService),
      }),
    });
  };
}

export function onDemandPayment(miniService, paymentDetailId, isCTPFund, promoCode) {
  return async function (dispatch) {
    
    const url = "/on-demand-payment.json";
    dispatch({
      type: types.ON_DEMAND_PAYMENT_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          miniService: JSON.stringify(miniService),
          paymentDetailId: paymentDetailId,
          isCTPFund: isCTPFund,
          promoCode: promoCode,
        }),
      });
      let data = await response.json();
      console.log("ON_DEMAND_PAYMENT_RECEIVED= ", data);
      dispatch({
        type: types.ON_DEMAND_PAYMENT_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.ON_DEMAND_PAYMENT_FAILED,
      });
    }
  };
}

export function updateItemCount(name, counts) {
  return {
    type: types.UPDATE_MINI_SERVICE_ITEM_COUNT,
    data: { name, counts },
  };
}

export function updateSearchTerm(searchTerm) {
  return {
    type: types.ADMIN_USER_TABLE_SEARCH_TERM,
    data: { searchTerm: searchTerm },
  };
}

export function resetItemCount(initialState) {
  return {
    type: types.RESET_CART_COUNT,
    data: initialState,
  };
}

export function updateFromInputs(
  label,
  type,
  campaign,
  adgroupTheme,
  businessDetails,
  url,
  adgroupMap,
  taskDataId
) {
  return {
    type: types.UPDATE_MINI_SERVICE_INPUTS,
    data: {
      label,
      type,
      campaign,
      adgroupTheme,
      businessDetails,
      url,
      adgroupMap,
      taskDataId,
    },
  };
}

export function getAccountStructure() {
  return async function (dispatch, getState) {
    dispatch({
      type: types.ACCOUNT_STRUCTURE_REQUESTED,
    });
    try {
      const url = "/account-structure.json";
      /* const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
             let url = useDummyData ? "/account-structure-local.JSON" : "/account-structure.json";*/
      console.log("url = ", url);
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
      let data = await response.json();
      console.log("accountStructure = ", data);
      /* emptying the saved item counts input forms obj when it is coming from task */
      if (_.sum(_.values(getState().miniServiceItemCount)) > 0) {
        data.itemCounts = {};
        data.inputFroms = {};
      }
      dispatch({
        type: types.ACCOUNT_STRUCTURE_RECEIVED,
        data,
      });
    } catch (e) {
      dispatch({
        type: types.ACCOUNT_STRUCTURE_REQUESTED_FAILED,
      });
      console.log("fetch CardNo failed", e);
    }
  };
}

export function onDemandPaymentStatus() {
  return async function (dispatch, getState) {
    const url = "/account-structure.json";
    dispatch({
      type: types.ON_DEMAND_PAYMENT_STATUS,
    });
  };
}

export function deleteDatatInServer() {
  return async function (dispatch) {
    const url = "delete-status.json";
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
      },
      credentials: "include",
    });
  };
}

export function resetInputForms() {
  return {
    type: types.RESET_INPUT_FORMS,
  };
}

export function handleDeleteUserAlert() {
  return {
    type: types.HANDLE_DELETE_USER_ALERT,
  };
}

export function shoppingFeedBack(shoppingRequest, userFeedback, taskId) {
  console.log(userFeedback);
  return async function (dispatch) {
    const url = "/shopping-task-gen.json";
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
      },
      credentials: "include",
      body: new URLSearchParams({
        userFeedback: userFeedback,
        shoppingRequest: shoppingRequest,
        taskId: taskId,
      }),
    });
  };
}

export function updateUserNote(note, userId, adminName) {
  return async function (dispatch) {
    const url /**/ = "/update-user-note.json";
    /*dispatch({
            type: types.UPDATE_USER_NOTE_REQUESTED,
        });*/
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          note: note,
          userId: userId,
          adminName: adminName,
        }),
      });
      let data = await response.json();
      console.log("data = ", data);
      dispatch({
        type: types.UPDATE_USER_NOTE_RECEIVED,
        userId: userId,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.UPDATE_USER_NOTE_REQUESTED_FAILED,
      });
    }
  };
}

export function getUserNote(userId) {
  return async function (dispatch) {
    const url = "/user-note.json";
    dispatch({
      type: types.GET_USER_NOTE_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ userId: userId }),
      });
      let data = await response.json();
      console.log("data = ", data);
      dispatch({
        type: types.GET_USER_NOTE_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.GET_USER_NOTE_REQUESTED_FAILED,
      });
    }
  };
}

export function endJumpStart(userId) {
  return async function (dispatch) {
    const url = "/end-jump-start.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ userId: userId }),
      });
      let data = await response.json();
      console.log("data = ", data);
      if (data.error == "") {
        await dispatch({
          type: types.RESET_TABLE_ROWS,
        });
      }
      dispatch({
        type: types.UPDATE_USER_INFORMATION,
        userId: userId,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.END_USER_JUMPSTART_REQUEST_FAILED,
      });
    }
  };
}

export function disableAccount(userId) {
  return async function (dispatch) {
    const url = "/disableAccount.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ userId: userId }),
      });
      let data = await response.json();
      console.log("data = ", data);
      if (data.error == "") {
        await dispatch({
          type: types.RESET_TABLE_ROWS,
        });
      }
      dispatch({
        type: types.UPDATE_USER_INFORMATION,
        userId: userId,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.END_DISABLE_USER_REQUEST_FAILED,
      });
    }
  };
}

export function loadUserInfo(userId) {
  return async function (dispatch) {
    const url = "/admin/load-user-info.json";
    dispatch({
      type: types.USER_INFO_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ userId: userId }),
      });
      let data = await response.json();
      console.log("data = ", data);
      /* if (data.error == "") {
                 await dispatch({
                     type: types.RESET_TABLE_ROWS,
                 });
             }*/
      dispatch({
        type: types.ADD_USER_INFORMATION,
        data,
      });
    } catch (e) {
      dispatch({
        type: types.GET_USER_REQUEST_FAILED,
      });
    }
  };
}

export function deleteUser(userId) {
  return async function (dispatch) {
    const url = "/delete-user.json";
    dispatch({
      type: types.RESET_TABLE_ROWS,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ userId: userId }),
      });
      let data = await response.json();
      console.log("data = ", data);
      dispatch({
        type: types.UPDATE_USER_INFORMATION,
        userId: userId,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.DELETE_USER_REQUEST_FAILED,
      });
    }
  };
}

export function pageElements(pageSize, currentPage, rows) {
  let startingId = pageSize * currentPage;
  console.log("startingId=", startingId);
  let rowIdsInPage = [];
  for (var n = 0; n < pageSize; n++) {
    rowIdsInPage.push(startingId + n);
  }
  console.log("rowIdsInPage=", rowIdsInPage);
  let users = rowIdsInPage.map((id) => rows[id]);
  users = users.filter((user) => !!user);
  return { rowIdsInPage, users };
}

export function adminUserTable(
  currentPage,
  currentSorting,
  sortingFilterPresent,
  statusFilter,
  searchTerm,
  channelFilter,
  userTypeToggle
) {
  return async function (dispatch, getState) {
    const { pageSize, sorting } = getState().adminUserTable;
    let rows = getState().adminUserTable.rows;
    let { rowIdsInPage, users } = pageElements(pageSize, currentPage, rows);

    if (sortingFilterPresent == true) {
      currentPage = 0;
      rows = [];
    }
    dispatch({
      type: types.SET_CURRENT_AND_SORTING_STATE,
      currentPage: currentPage,
      sorting: currentSorting,
      statusFilter: statusFilter,
      searchTerm: searchTerm,
      channelFilter: channelFilter,
      userTypeToggle: userTypeToggle,
      rows: rows,
    });
    // if (users.length == rowIdsInPage.length && (currentSorting[0].columnName == sorting[0].columnName && currentSorting[0].direction == sorting[0].direction)) {
    if (users.length == rowIdsInPage.length && !sortingFilterPresent) {
      return;
    }
    const url = "/admin-user-table-data.json";
    setTimeout(async () => {
      try {

        let url = "/admin-user-table-data.json";
        if (channelFilter == 10) {
          dispatch({
            type: types.ADMIN_PROV_USER_TABLE_DATA_REQUESTED,
          });
        } else {
          dispatch({
            type: types.ADMIN_USER_TABLE_DATA_REQUESTED,
          });
        }
        const { sortDirectionString, columnName } =
          calculateOrderBy(currentSorting);
        url = `${url}?pageSize=${pageSize}&skip=${pageSize * currentPage
          }&orderby=${columnName}&direction=${sortDirectionString}&statusFilter=${statusFilter}&searchTerm=${searchTerm}&channelFilter=${channelFilter}&userTypeToggle=${userTypeToggle}`;
        let response = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        });
        let data = await response.json();
        console.log("admin table date respone=", data);
        if (channelFilter == 10) {
          try {
            dispatch({
              type: types.ADMIN_PROV_USER_TABLE_DATA_RECEIVED,
              startRowId: pageSize * currentPage,
              data,
            });
          }
          catch (e) {
            console.log("error", e)
          }

        } else {
          dispatch({
            type: types.ADMIN_USER_TABLE_DATA_RECEIVED,
            startRowId: pageSize * currentPage,
            data,
          });
        }
      } catch (e) {
        console.log("e=", e);
        dispatch({ type: types.ADMIN_USER_TABLE_DATA_REQUESTED_FAILED });
      }
    }, 0);
  };
}

export function fetchProvUserDetails(emailId) {
  return async function () {
    const url = "/get-prov-user-details.json";
    console.log("url=", url);
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ emailId: emailId }),
      });
      let data = await response.json();
      console.log("data=", data);
      return data;
    } catch (e) {
      console.log("exception", e);
    }
  };
}
// dispatch({
//   type: types.SET_CURRENT_AND_SORTING_STATE,
//   currentPage: currentPage,
//   sorting: currentSorting,
//   statusFilter: statusFilter,
//   searchTerm: searchTerm,
//   channelFilter: channelFilter,
//   userTypeToggle: userTypeToggle,
//   rows: rows,
// });
//     // if (users.length == rowIdsInPage.length && (currentSorting[0].columnName == sorting[0].columnName && currentSorting[0].direction == sorting[0].direction)) {
//     if (users.length == rowIdsInPage.length && !sortingFilterPresent) {
//       return;
//     }
//     const url = "/admin-user-table-data.json";
//     setTimeout(async () => {
//       try {
//         dispatch({
//           type: types.ADMIN_USER_TABLE_DATA_REQUESTED,
//         });
//         let url = "/admin-user-table-data.json";
//         const { sortDirectionString, columnName } =
//           calculateOrderBy(currentSorting);
//         url = `${url}?pageSize=${pageSize}&skip=${
//           pageSize * currentPage
//         }&orderby=${columnName}&direction=${sortDirectionString}&statusFilter=${statusFilter}&searchTerm=${searchTerm}&channelFilter=${channelFilter}&userTypeToggle=${userTypeToggle}`;
//         let response = await fetch(url, {
//           method: "GET",
//           headers: {
//             Accept: "application/json",
//           },
//           credentials: "include",
//         });
//         console.log("date respone=", response);
//         let data = await response.json();
//         console.log("date respone=", data);
//         dispatch({
//           type: types.ADMIN_USER_TABLE_DATA_RECEIVED,
//           startRowId: pageSize * currentPage,
//           data,
//         });
//       } catch (e) {
//         console.log("e=", e);
//         dispatch({ type: types.ADMIN_USER_TABLE_DATA_REQUESTED_FAILED });
//       }
//     }, 0);
//   };
// }

export function addUser(addUserForm) {
  return async function (dispatch) {
    console.log("Adding New User...", addUserForm);
    try {
      const url = "/add-user.json";
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: JSON.stringify(addUserForm),
      });
      let data = await response.json();

      console.log("new user details = ", data);
      // below code to add new user to top of array list
      if (data && data.isEmail != true && data.error == "") {
        dispatch({
          type: types.ADD_USER_INFORMATION,
          data,
        });
      }
      return data;
    } catch (e) {
      console.log("exception", e);
    }
  };
}

export function getAmount(planId) {
  return async function () {
    const url = "/get-amount.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ planId: planId }),
      });
      let data = await response.json();
      console.log("data=", data);
      return data;
    } catch (e) {
      console.log("exception", e);
    }
  };
}

export function getTasksByAccount(ppcId, accountId) {
  return async function (dispatch, getState) {
    console.log("fetching account tasks ppcId", ppcId);
    dispatch({
      type: types.ACCOUNT_TASKS_REQUESTED,
    });
    const { tasksMap } = getState().taskAnalysis;
    const selectMap = tasksMap[ppcId];
    console.log("The selected map is", selectMap);
    console.log("empty check= ", !_.isEmpty(selectMap));
    // TODO: commented below code for HOD task data issue fixing but have to recheck this logic
    // if (!_.isEmpty(selectMap)) {
    //     const data = {tasks: selectMap};
    //     dispatchAdminTaskData(data, dispatch, ppcId, accountId);
    //     return
    // }

    const url = "/admin/get-tasks.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ ppcId: ppcId, accountId: accountId }),
      });
      let data = await response.json();
      console.log("data=", data);
      dispatchAdminTaskData(data, dispatch, ppcId, accountId);
    } catch (e) {
      dispatch({
        type: types.ACCOUNT_TASKS_FAILED,
      });
      console.log("exception", e);
    }
  };
}

export function dispatchAdminTaskData(data, dispatch, ppcId, accountId) {
  fixServerResponse(data);

  console.log("data after update", data);
  dispatch({
    type: types.ACCOUNT_TASKS_RECEIVED,
    ppcId: ppcId,
    accountId: accountId,
    data,
  });
}

export function updateTaskDisplayStatus(taskId, displayType) {
  return async function (dispatch, getState) {
    const { taskAnalysis } = getState();
    const { tasksMap, selectedPpcId } = taskAnalysis;
    dispatch({
      type: types.UPDATE_TASK_DISPLAY_REQUESTED,
    });
    const url = "/admin/update-task-display-status.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ taskId: taskId, displayType: displayType }),
      });
      let data = await response.json();
      console.log("data=", data);
      if (displayType != 1) {
        let oldTasksList = tasksMap[selectedPpcId];
        const newTaskList = oldTasksList.map((oldTask) =>
          oldTask.taskId == data.task.taskId ? data.task : oldTask
        );
        const updatedData = { tasks: newTaskList };
        console.log("newTaskList=", newTaskList);
        await dispatch({
          type: types.UPDATE_TASK_DISPLAY_RECEIVED,
          newTaskList,
          task: data.task,
          displayType: displayType,
        });
        // dispatchAdminTaskData(updatedData, dispatch, selectedPpcId);
      }
    } catch (e) {
      dispatch({
        type: types.UPDATE_TASK_DISPLAY_FAILED,
      });
      console.log("exception", e);
    }
  };
}

export function getUserAccounts(userId) {
  return async function (dispatch, getState) {
    const { selectedDateRange } = getState();
    const { fromDate, toDate } = selectedDateRange;
    console.log("selectedDateRange12345=", selectedDateRange);
    const url = "/admin/admin-get-selectedUser-accounts.json";
    try {
      dispatch({
        type: types.USER_ACCOUNT_REQUESTED,
      });
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          userId: userId,
          fromDate: fromDate,
          toDate: toDate,
        }),
      });
      let data = await response.json();
      console.log("data=", data);
      dispatch({
        type: types.USER_ACCOUNT_RECEIVED,
        userId: userId,
        data,
      });
      // return data;
    } catch (e) {
      dispatch({
        type: types.USER_ACCOUNT_FAILED,
      });
      console.log("exception", e);
    }
  };
}

export function getGoogleAccountPerformance(graderReportId) {
  console.log("graderReportId=", graderReportId);
  return async function (dispatch) {
    const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
    const url = useDummyData
      ? "/google-ads-data-local.JSON"
      : "/get-google-result.json?graderReportId=" + graderReportId;
    dispatch({
      type: types.GOOGLE_ADS_PERFORMANCE_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        // method: useDummyData ? "GET" : "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
        // body: new URLSearchParams({graderReportId: graderReportId})
      });
      let data = await response.json();
      console.log("data = ", data);
      // let accountStats = await getAccountStats(data.accountStatsData);
      let accountStats = await getAccountStats(data.googleAdsReportData);
      data = {
        ...data,
        accountStats,
      };
      dispatch({
        type: types.GOOGLE_ADS_PERFORMANCE_RECEIVED,
        data,
      });
    } catch (e) {
      console.log("error in getGoogleAccountPerformance=", e);
      dispatch({
        type: types.GOOGLE_ADS_PERFORMANCE_FAILED,
      });
    }
  };
}

export function getGraderIntegrationStatus() {
  return async function (dispatch) {
    const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
    const url = useDummyData
      ? "/integration-status-local.JSON"
      : "/grader-integration-status.json";
    dispatch({
      type: types.GRADER_INTEGRATION_STATUS_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
        // body: new URLSearchParams({userId: userId})
      });
      let data = await response.json();
      console.log("data = ", data);
      dispatch({
        type: types.GRADER_INTEGRATION_STATUS_RECEIVED,
        data,
      });
    } catch (e) {
      dispatch({
        type: types.GRADER_INTEGRATION_STATUS_FAILED,
      });
    }
  };
}

export function updateSelectedDate(selectedDate) {
  return {
    type: types.UPDATE_SELECTED_DATE,
    data: { selectedDate },
  };
}

export function bookGraderMeeting(selectedDate, accountLevelGraderUserId) {
  return async function (dispatch) {
    var dateFormat = "YYYY-MM-DD HH:mm:ss";
    var time = "11:30:00";
    console.log("selectedDate1=", selectedDate);
    console.log(
      "selectedDate1=",
      moment(selectedDate + " " + time).format(dateFormat)
    );
    selectedDate = moment(selectedDate + " " + time).format(dateFormat);
    console.log("selectedDate=", selectedDate);
    const url = `/update-selected-date.json?meetingDate=${selectedDate}&accountLevelGraderUserId=${accountLevelGraderUserId}`;
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        // body: new URLSearchParams({meetingDate: selectedDate})
      });
      let data = await response.json();
      console.log("data = ", data);
      return data;
    } catch (e) {
      console.log("exception in booking meeting=", e);
    }
  };
}

export function sendGraderMail() {
  return async function (dispatch) {
    const url = "/grader-email.json";
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
      },
      credentials: "include",
      // body: new URLSearchParams({taskId: taskIds})
    });
    let data = await response.json();
    console.log("data = ", data);
    return data;
  };
}

export function saveGraderDetails(
  emailId,
  userName,
  phoneNumber,
  url,
  dateRange,
  customerId,
  monthlySpend
) {
  return async function (dispatch) {
    const apiUrl = "/save-grader-user-details.json";
    let response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
      },
      credentials: "include",
      body: new URLSearchParams({
        emailId: emailId,
        name: userName,
        phoneNo: phoneNumber,
        url: url,
        dateRange: dateRange,
        customerId: customerId,
        monthlySpend: monthlySpend,
        companyName: "",
      }),
    });
    let data = await response.json();
    return data;
    // let data = _.chain(taskDataIdList).keyBy().mapValues(() => 1).value();

    console.log("data = ", data);
    dispatch({
      type: types.UPDATE_TASK_DATA_STATUS,
      data,
    });
  };
}

export function getSearchConsoleAuthUrl(taskId, taskDataId) {
  console.log("Came to auth url function", taskId);
  console.log("Came to auth url function", taskDataId);
  return async function (dispatch) {
    console.log("Came to inside block");
    const url = "/get-search-console-auth-url.json";
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
        // body: new URLSearchParams()
      });
      let data = await response.json();
      dispatch({
        type: types.UPDATE_KEYWORD_HIERARCHY,
        taskId: taskId,
        taskDataId: taskDataId,
      });
      console.log("data=", data);
      return data;
    } catch (e) {
      console.log("exception", e);
    }
  };
}

export function importStatusSearchConsole() {
  return async function (dispatch, getState) {
    const { keywordHierarchyTaskState } = getState();
    const { taskId, taskDataId } = keywordHierarchyTaskState;
    const url = "/import-status-search-console.json";
    try {
      console.log("Came to import-status-search-console.json block");
      dispatch({
        type: types.KEYWORD_HIERARCHY_STATUS_REQUESTED,
      });
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({ taskId: taskId, taskDataId: taskDataId }),
      });
      let data = await response.json();
      console.log("data=", data);
      dispatch({
        type: types.KEYWORD_HIERARCHY_STATUS_RECEIVED,
        // userId: userId,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.KEYWORD_HIERARCHY_STATUS_FAILED,
      });
      console.log("exception", e);
    }
  };
}

export function getFreeTrial() {
  return async function (dispatch) {
    const url = "/free-trial-data.json";
    try {
      let response = await fetch(url, {
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
      let data = await response.json();
      dispatch({
        type: types.UPDATE_CURRENCY,
        // userId: userId,
        currency: data.currency,
      });
      return data;
    } catch (e) {
      console.log("exception", e);
      return { error: "Something went wrong" };
    }
  };
}

export function getsSiteGraderResult(id, userUrl, type) {
  return async function (dispatch, getState) {
    const { siteGrader } = getState();
    const { urlInfo } = siteGrader;
    let { domainId } = urlInfo != null ? urlInfo : { domainId: null };
    let { url } = urlInfo != null ? urlInfo : { url: null };
    if (domainId == null) {
      domainId = 0;
      url = "www.demo.com";
    }
    const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
    domainId = id ? id : domainId;
    url = userUrl ? userUrl : url;
    // const apiUrl = "/site-grader-data.json" + "?domainId=" + domainId + "&domainUrl=" + url + "&type=" + type;
    const apiUrl = useDummyData
      ? "/site-grader-data-local.JSON"
      : "" +
      "/site-grader-data.json" +
      "?domainId=" +
      domainId +
      "&domainUrl=" +
      url +
      "&type=" +
      type;
    dispatch({
      type: types.SITE_GRADER_RESULT_REQUESTED,
    });
    console.log("apiUrl=", apiUrl);
    try {
      let response = await fetch(apiUrl, {
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });

      let data = await response.json();
      console.log("data = ", data);
      await dispatch({
        type: types.SITE_GRADER_RESULT_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      console.log("error in getGoogleAccountPerformance=", e);
      dispatch({
        type: types.SITE_GRADER_RESULT_FAILED,
      });
    }
  };
}

export function getsSiteGraderInternalResult() {
  return async function (dispatch, getState) {
    // const apiUrl = "/site-grader-data-internal.json";
    dispatch({
      type: types.SITE_GRADER_RESULT_REQUESTED,
    });
    const useDummyData = window.lxrOptions && window.lxrOptions.useDummyData;
    const apiUrl = useDummyData
      ? "/site-grader-data-local.JSON"
      : "" + "/site-grader-data-internal.json";
    console.log("apiUrl=", apiUrl);
    try {
      let response = await fetch(apiUrl, {
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });

      let data = await response.json();
      console.log("data = ", data);
      dispatch({
        type: types.SITE_GRADER_RESULT_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      console.log("error in getGoogleAccountPerformance=", e);
      dispatch({
        type: types.SITE_GRADER_RESULT_FAILED,
      });
    }
  };
}

export function siteGraderServerError() {
  return {
    type: types.SET_SITE_GRADER_ERROR,
    data: { serverError: "Something went wrong" },
  };
}

export function saveSiteGraderReportDetails(
  emailId,
  name,
  phoneNumber,
  domainId
) {
  return async function (dispatch) {
    const apiUrl = "/site-grader-report.json";
    try {
      let response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          emailId: emailId,
          name: name,
          phoneNo: phoneNumber,
          domainId: domainId,
        }),
      });
      let data = await response.json();
      console.log("data = ", data);
      return data;
    } catch (e) {
      console.log("error in getGoogleAccountPerformance=", e);
      return { errorMessage: "Something went wrong" };
    }
  };
}

export function getDomainInfo(domainName, type, emailId, phoneNumber) {
  return async function (dispatch) {
    dispatch({
      type: types.SITE_GRADER_URL_INFO_REQUESTED,
    });
    const apiUrl =
      "/site-grader-url-info.json" +
      "?domainURL=" +
      domainName +
      "&type=" +
      type +
      "&emailId=" +
      emailId +
      "&phoneNumber=" +
      phoneNumber;
    try {
      let response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
        /*body: new URLSearchParams({
                    domainURL: domainName,
                })*/
      });
      let data = await response.json();
      console.log("data = ", data);
      dispatch({
        type: types.SITE_GRADER_URL_INFO_RECEIVED,
        data,
      });
    } catch (e) {
      dispatch({
        type: types.SITE_GRADER_URL_INFO_FAILED,
      });
      console.log("error in getGoogleAccountPerformance=", e);
    }
  };
}

export function updateDomainInfoFromSession() {
  return async function (dispatch) {
    dispatch({
      type: types.SITE_GRADER_URL_INFO_REQUESTED,
    });
    const apiUrl = "/site-grader-url-info-session.json";
    try {
      let response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      let data = await response.json();
      console.log("data = ", data);
      dispatch({
        type: types.SITE_GRADER_URL_INFO_RECEIVED,
        data,
      });
    } catch (e) {
      dispatch({
        type: types.SITE_GRADER_URL_INFO_FAILED,
      });
      console.log("error in getGoogleAccountPerformance=", e);
    }
  };
}

export function updateActiveStepSEODetails(activeStep) {
  return {
    type: types.UPDATE_ACTIVE_STEP_SEO_DETAILS,
    activeStep: activeStep,
  };
}

export function saveSEOUserDetails(seoUserDetails) {
  return async function (dispatch) {
    dispatch({
      type: types.SAVE_SEO_USER_REQUESTED,
    });
    const url = "/site-grader-services.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          seoServiceDetails: JSON.stringify(seoUserDetails),
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.SAVE_SEO_USER_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.SAVE_SEO_USER_FAILED,
      });
      console.log("error in /site-grader-services.json= ", e);
    }
  };
}

export function emailValidation(emailId) {
  return async function (dispatch) {
    const url = "/email-valid.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          emailId: emailId,
        }),
      });

      let data = await response.json();
      return data;
    } catch (e) {
      console.log("error in getGoogleAccountPerformance=", e);
    }
  };
}

export function contactSupportForOnDemandServices(emailId, phoneNumber) {
  return async function (dispatch) {
    const url = "/contact-support.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          emailId: emailId,
          phoneNumber: phoneNumber,
        }),
      });

      let data = await response.json();
      return data;
    } catch (e) {
      console.log("error in getGoogleAccountPerformance=", e);
    }
  };
}

export function getUserServices() {
  return async function (dispatch) {
    dispatch({
      type: types.SEO_USER_SERVICES_DETAILS_REQUESTED,
    });
    const url = "/seo-user-services.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        /*body: new URLSearchParams({
                    userId: userId,
                })*/
      });

      let data = await response.json();
      dispatch({
        type: types.SEO_USER_SERVICES_DETAILS_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.SEO_USER_SERVICES_DETAILS_FAILED,
      });
      console.log("error in UserServices", e);
    }
  };
}

export function updateSelectedSEOPackage(selectedPackage) {
  return {
    type: types.UPDATE_SELECTED_SEO_PACKAGE,
    selectedPackage: selectedPackage,
  };
}

export function getUserPaymentInfo() {
  return async function (dispatch) {
    const url = "/user-payment-details.json";
    dispatch({
      type: types.USER_PAYMENT_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
      });
      let data = await response.json();
      console.log("data", data);
      dispatch({
        type: types.USER_PAYMENT_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      console.log("exception", e);
      dispatch({
        type: types.USER_PAYMENT_FAILED,
      });
      return { error: "Something went wrong" };
    }
  };
}

export function onDemandPaymentForActiveUser(paymentDetailId, planId) {
  return async function (dispatch) {
    dispatch({
      type: types.SEO_USER_SERVICES_DETAILS_REQUESTED,
    });
    const url = "/seo-onDemand-internal.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          paymentDetailId: paymentDetailId,
          planId: planId,
        }),
      });

      let data = await response.json();
      dispatch({
        type: types.SEO_USER_SERVICES_DETAILS_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.SEO_USER_SERVICES_DETAILS_FAILED,
      });
      console.log("error in UserServices", e);
    }
  };
}

export function seoReportStatusUpdation(reportStatus, clientId) {
  return async function (dispatch) {
    dispatch({
      type: types.SITE_GRADER_SEO_REPORT_STATUS_REQUESTED,
    });
    const url = "/seo-internal-report-status-update.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          reportStatus: reportStatus,
          clientId: clientId,
        }),
      });

      let data = await response.json();
      dispatch({
        type: types.SITE_GRADER_SEO_REPORT_STATUS_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.SITE_GRADER_SEO_REPORT_STATUS_FAILED,
      });
      console.log(
        "error in updating client information table with seo report status",
        e
      );
    }
  };
}

export function getClientDetailsForExpertHelp() {
  return async function (dispatch) {
    const apiUrl = "/expert-help-client-details.json";
    try {
      let response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
      let data = await response.json();
      console.log("data = ", data);
      return data;
    } catch (e) {
      console.log("error in getGoogleAccountPerformance=", e);
      return { errorMessage: "Something went wrong" };
    }
  };
}

export function siteGraderReset() {
  return {
    type: types.SITE_GRADER_RESET,
  };
}

export function onChangeKPIGraphType(KPIType) {
  return {
    type: types.UPDATE_KPI_TYPE_UI,
    KPIType,
  };
}

export function onChangeReportKPIGraphType(KPIType) {
  return {
    type: types.UPDATE_REPORT_KPI_TYPE_UI,
    KPIType,
  };
}

export function selectGraphDateRange(dateRange) {
  const dateRanges = {
    fromDate: moment()
      .subtract(dateRange + 1, "d")
      .format("YYYY-MM-DD"),
    toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    itemValue: dateRange,
  };
  return async (dispatch) => {
    await dispatch({
      type: types.SELECT_GRAPH_DATE_RANGE,
      dateRanges,
    });
  };
}

export function customGraphDateRange(dateRanges) {
  return async (dispatch) => {
    await dispatch({
      type: types.SELECT_GRAPH_DATE_RANGE,
      dateRanges,
    });
    dispatch(fetchPerfData(true));
  };
}

export function toggleDrawer() {
  return {
    type: types.TOGGLE_DRAWER,
  };
}

export function updateDrawer(value) {
  return {
    type: types.UPDATE_DRAWER,
    value,
  };
}

export function selectReportGraphDateRange(dateRange) {
  const dateRanges = {
    fromDate: moment()
      .subtract(dateRange + 1, "d")
      .format("YYYY-MM-DD"),
    toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    itemValue: dateRange,
  };
  return async (dispatch) => {
    await dispatch({
      type: types.SELECT_REPORT_GRAPH_DATE_RANGE,
      dateRanges,
    });
  };
}

export function customReportGraphDateRange(dateRanges) {
  return async (dispatch) => {
    await dispatch({
      type: types.SELECT_REPORT_GRAPH_DATE_RANGE,
      dateRanges,
    });
    // todo fetch based on the requried data
    // dispatch(fetchPerfData());
  };
}

export function buyHolidayPackage(selectedPlan) {
  return async function (dispatch) {
    const url = "/holiday-package-service.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          monthsPlan: selectedPlan,
        }),
      });
      let data = await response.json();
      console.log("data = ", data);
      return data;
    } catch (e) {
      return { error: "something went wrong" };
    }
  };
}

export function martechService(selectedPlan) {
  return async function (dispatch) {
    const url = "/martech-services.json";
    try {
      let response = await fetch(url, {
        // method: "POST",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
      let data = await response.json();
      console.log("data = ", data);
      return data;
    } catch (e) {
      return { error: "something went wrong" };
    }
  };
}

//Action for generating RSA adcopy
export function generateAdCopy(brandName, productDescription) {

  return async function (dispatch) {
    const url = "adcopy.json";
    dispatch({
      type: types.GENERATE_RSA_ADCOPY_REQUESTED,
    });

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
          // "Content-Type": "application/json",
        },
        credentials: "include",
        body: new URLSearchParams({
          brandName: brandName,
          productDescription: productDescription,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.GENERATE_RSA_ADCOPY_RECEIVED,
        data,
        brandName,
        productDescription,
      });
      console.log("GENERATE_RSA_ADCOPY_RECEIVED data is", data);
      return data;
    }
    catch (e) {
      dispatch({
        type: types.GENERATE_RSA_ADCOPY_FAILED,
      });
    }
  }
}
//Fetching RSA Ad copy
export function fetchAdCopy(campaignId, adgroupId) {
  return async function (dispatch) {
    const url = "fetch-adcopy.json";
    dispatch({
      type: types.FETCH_RSA_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          campaignId: campaignId,
          adgroupId: adgroupId,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_RSA_DATA_RECEIVED,
        data,
        campaignId,
        adgroupId,
      });
      console.log("FETCH_RSA_DATA_RECEIVED data is", data);
      return data;
    }
    catch (e) {
      dispatch({
        type: types.FETCH_RSA_DATA_FAILED,
      });
    }
  }
}

//Saving RSA ad copy
export function saveAdCopy(rsaCopyContent) {
  return async function (dispatch) {
    dispatch({
      type: types.SAVE_RSA_DATA_REQUESTED,

    });
    const url = "/save-adcopy.json";
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: JSON.stringify(rsaCopyContent),
      });
      let data = await response.json();
      dispatch({
        type: types.SAVE_RSA_DATA_RECEIVED,
        data,
      });
      console.log("SAVE_RSA_DATA_RECEIVED data is ", data);
      return data;
    } catch (e) {
      dispatch({
        type: types.SAVE_RSA_DATA_FAILED,
      });
      console.log("error in saving rsa data ", e);
    }
  };
}
//Fetch WHS Expert Data
export function fetchWHSExpertData(userId, userType) {
  return async function (dispatch) {
    const url = "/get-expert-help-data.json";
    dispatch({
      type: types.WHS_EXPERT_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token':Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          userId: userId,
          userType: userType,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.WHS_EXPERT_DATA_RECEIVED,
        data,
      });
      console.log("WHS_EXPERT_DATA_RECEIVED data is", data);
      return data;
    }
    catch (e) {
      dispatch({
        type: types.WHS_EXPERT_DATA_FAILED,
      });
    }
  }
}

//Refresh WHS DATA
export function refreshWHSData() {
  return async function (dispatch) {
    const url = "/refresh-website-health-data.json";
    dispatch({
      type: types.WHS_REFRESH_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
      });
      let data = await response.json();
      dispatch({
        type: types.WHS_REFRESH_DATA_RECEIVED,
        data,
      });
      console.log("WHS_REFRESH_DATA_RECEIVED data is", data);
      return data;
    }
    catch (e) {
      dispatch({
        type: types.WHS_REFRESH_DATA_FAILED,
      });
    }
  }
}

//Get all on demand packages
export function fetchOnDemandPackages() {
  return async function (dispatch) {
    const url = "/fetch-on-demand-packages.json";
    dispatch({
      type: types.ON_DEMAND_PACKAGE_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "X-CSRF-Token":Cookies.get('CSRF-TOKEN'),
        },
      });
      let data = await response.json();
      dispatch({
        type: types.ON_DEMAND_PACKAGE_RECEIVED,
        data,
      });
      console.log("ON_DEMAND_PACKAGE_RECEIVED data is", data);
      return data;
    }
    catch (e) {
      dispatch({
        type: types.ON_DEMAND_PACKAGE_FAILED,
      });
    }
  }
}

//website health scorecard refresh status
export function websiteHealthRefreshStatus(syncCheckId) {
  return async function (dispatch) {
    const url = "/website-scorecard-download-status.json";
    dispatch({
      type: types.WHS_REFRESH_DOWNLOAD_STATUS_REQUESTED,
    });
    try {
      console.log("Inside websiteHealthRefreshStatus action=>", syncCheckId);
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          syncCheckId: syncCheckId,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.WHS_REFRESH_DOWNLOAD_STATUS_RECEIVED,
        data,
        syncCheckId,
      });
      console.log("WHS_REFRESH_DOWNLOAD_STATUS_RECEIVED data is", data);
      return data;
    }
    catch (e) {
      dispatch({
        type: types.WHS_REFRESH_DOWNLOAD_STATUS_FAILED,
      });
    }
  }
}

export function WHSExternalUserDetails(domainURL, emailId, phoneNumber) {
  return async function (dispatch) {
    const url = "/website-scorecard-external.json";
    dispatch({
      type: types.WHS_EXTERNAL_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          domainURL: domainURL,
          emailId: emailId,
          phoneNumber: phoneNumber,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.WHS_EXTERNAL_DATA_RECEIVED,
        data,
      });
      console.log("WHS_EXTERNAL_DATA_RECEIVED data is", data);
      return data;
    }
    catch (e) {
      dispatch({
        type: types.WHS_EXTERNAL_DATA_FAILED,
      });
    }
  }
}
export function onDemandPromocodeApply(promoCode, actualAmount) {
  return async function (dispatch) {
    const url = "/validate-on-demand-promo-code.json";
    dispatch({
      type: types.PROMOCODE_CONFIRMATION_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
        body: new URLSearchParams({
          promoCode: promoCode,
          actualAmount: actualAmount,
        }),
      });
      let data = await response.json();
      dispatch({
        type: types.PROMOCODE_CONFIRMATION_RECEIVED,
        data,
        promoCode, actualAmount,
      });
      console.log("PROMOCODE_CONFIRMATION_RECEIVED data is", data);
      return data;
    }
    catch (e) {
      dispatch({
        type: types.PROMOCODE_CONFIRMATION_FAILED,
      });
    }
  }
}


export function fetchSystemMaintenanceBannerDetails() {
  return async function (dispatch) {
    const url = "/fetch-maintenance-banner-data.json";
    dispatch({
      type: types.SYSTEM_MAINTENANCE_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "X-CSRF-Token": Cookies.get('CSRF-TOKEN'),
        },
        credentials: "include",
      });
      let data = await response.json();
      dispatch({
        type: types.SYSTEM_MAINTENANCE_DATA_RECEIVED,
      });
      return data;
    }
    catch (e) {
      dispatch({
        type: types.SYSTEM_MAINTENANCE_DATA_FAILED,
      });
    }
  }
}


export function fetchCSRFToken() {
  return async function (dispatch) {
    const url = "/csrf-token.json";
    dispatch({
      type: types.FETCH_CSRF_TOKEN_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_CSRF_TOKEN_RECEIVED,
      });
      return data;
    }
    catch (e) {
      dispatch({
        type: types.FETCH_CSRF_TOKEN_FAILED,
      });
    }
  }
}


