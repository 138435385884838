
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { fetchGoogleAnalyticsIntegrationNewDashboard, fetchGoogleAnalyticsDownloadStatusNewDashboard } from "../../actions/googleAnalyticsNewDashboard";
import LinearProgressBar from "../graphComponents/LinearProgressBar";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

const GoogleAnalyticsIntegrationLoader = (props) => {
    const {
        fetchGoogleAnalyticsIntegrationNewDashboard,
        fetchGoogleAnalyticsIntegrationNewDashboardReducer,
        storeGoogleAnalyticsUserAccountReducer,
        fetchGoogleAnalyticsDownloadStatusNewDashboardReducer,
        fetchGoogleAnalyticsDownloadStatusNewDashboard
    } = props;

    const navigate = useHistory();
    const [synCheckId, setSynCheckId] = useState("");
    const [completedValue, setCompletedValue] = useState(
        fetchGoogleAnalyticsDownloadStatusNewDashboardReducer?.complete_value || 0
    );
    const [completedStatus, setCompletedStatus] = useState(
        fetchGoogleAnalyticsDownloadStatusNewDashboardReducer?.status || null
    );
    const countRef = useRef(0); // Keep track of attempts

    // Fetch integration when analyticsId is available
    useEffect(() => {
        if (storeGoogleAnalyticsUserAccountReducer.analyticsId) {
            fetchGoogleAnalyticsIntegrationNewDashboard(storeGoogleAnalyticsUserAccountReducer.analyticsId);
        }
    }, [storeGoogleAnalyticsUserAccountReducer, fetchGoogleAnalyticsIntegrationNewDashboard]);

    // Update synCheckId when reducer data changes
    useEffect(() => {
        if (fetchGoogleAnalyticsIntegrationNewDashboardReducer.syncCheckId) {
            setSynCheckId(fetchGoogleAnalyticsIntegrationNewDashboardReducer.syncCheckId);
        }
    }, [fetchGoogleAnalyticsIntegrationNewDashboardReducer]);

    // Poll download status at intervals
    useEffect(() => {
        if (synCheckId) {
            const interval = setInterval(() => {
                countRef.current += 1; // Increment the attempt count
                if (countRef.current >= 30 || completedValue >= 100) { // Stop after 30 attempts or 100% completion
                    clearInterval(interval);
                    navigate.push("/intelli-Dashboard");
                } else {
                    fetchGoogleAnalyticsDownloadStatusNewDashboard(synCheckId);
                }
            }, 10000); // 10-second interval

            return () => clearInterval(interval); // Clean up on unmount
        }
    }, [synCheckId, fetchGoogleAnalyticsDownloadStatusNewDashboard, completedValue, navigate]);

    // Update progress bar values when reducer data changes
    useEffect(() => {
        if (fetchGoogleAnalyticsDownloadStatusNewDashboardReducer.complete_value) {
            setCompletedValue(fetchGoogleAnalyticsDownloadStatusNewDashboardReducer.complete_value);
            setCompletedStatus(fetchGoogleAnalyticsDownloadStatusNewDashboardReducer.status);
        }
    }, [fetchGoogleAnalyticsDownloadStatusNewDashboardReducer]);

    return (
        <Box justifyItems={"center"} alignItems={"center"} sx={{p: 10}}>
            <LinearProgressBar status={completedStatus} percentage={completedValue} />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    fetchGoogleAnalyticsIntegrationNewDashboardReducer:
        state.fetchGoogleAnalyticsIntegrationNewDashboardReducer,
    storeGoogleAnalyticsUserAccountReducer:
        state.storeGoogleAnalyticsUserAccountReducer,
    fetchGoogleAnalyticsDownloadStatusNewDashboardReducer:
        state.fetchGoogleAnalyticsDownloadStatusNewDashboardReducer,
});

const mapDispatchToProps = (dispatch) => ({
    fetchGoogleAnalyticsIntegrationNewDashboard: (analyticsId) =>
        dispatch(fetchGoogleAnalyticsIntegrationNewDashboard(analyticsId)),
    fetchGoogleAnalyticsDownloadStatusNewDashboard: (syncCheckId) =>
        dispatch(fetchGoogleAnalyticsDownloadStatusNewDashboard(syncCheckId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAnalyticsIntegrationLoader);
