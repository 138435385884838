
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Grid, Box } from '@mui/material';
import "../NewHomeDashboard/NewDashBoard.css";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { channel, sessions } = payload[0].payload;
    return (
      <div style={{
        backgroundColor: "white",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      }}>
        <p className="custom-tool-tip-value">{channel}</p>
        <p style={{ margin: 0 }}>
          Sessions: {sessions.toLocaleString("en-US", { minimumFractionDigits: 0 })}
        </p>
      </div>
    );
  }
  return null;
};
const renderCustomizedLegend = (props) => {
  const { payload } = props;
  const total = payload.reduce((sum, entry) => sum + entry.payload.value, 0); // Calculate total value

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px", margin: "10px 10px 10px 10px", width: "100%" }}>
      {payload.map((entry, index) => {
        const percentage = ((entry.payload.value / total) * 100).toFixed(1); // Calculate percentage
        return (
          <div
            key={`item-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "0.875rem",
              color: "#333",
            }}
          >{
              console.log("entry.payload.bgcolor==>", entry.payload.payload.fill)
            }
            {/* Color box */}
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: entry.payload.payload.fill,
                marginRight: "8px",
              }}
            ></div>
            {/* Label and percentage */}
            <span style={{ flex: 3 }}>{entry.value}</span>
            <span>{percentage}%</span>
          </div>
        );
      })}
    </div>
  );
};

const PieChartComponent = ({ data, colors }) => {
  const chartData = data.map(entry => ({
    ...entry,
    channel: entry.channel.replace("Merged ", ""),
  }));
  const totalSessions = chartData.reduce((sum, entry) => sum + entry.sessions, 0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12} style={{ height: 300 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                // labelLine={renderCustomLabelLine}
                outerRadius="80%"
                fill="#8884d8"
                dataKey="sessions"
                nameKey="channel"
              // label={renderCustomizedLabel}
              >
                {chartData.map((entry, index) => (
                  <Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              {/* <Legend layout={isMobile ? "horizontal" : "vertical"} align={isMobile ? "center" : "right"} verticalAlign={isMobile ? "bottom" : "middle"} /> */}
              <Legend
                content={renderCustomizedLegend} // Use custom legend
                layout={isMobile ? 'horizontal' : 'vertical'}
                align={isMobile ? 'center' : 'right'}
                verticalAlign={isMobile ? 'bottom' : 'middle'}
              />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PieChartComponent;
