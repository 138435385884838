import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, SnackbarContent, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { fetchSystemMaintenanceBannerDetails } from "../../actions";
import { formatDate } from "../commonFunctions/commonFunctions";
import withStyles from "@mui/styles/withStyles";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import "./systemDowntimeBanner.css";
import Cookies from "js-cookie";
const CustomSnackbarContent = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: theme.spacing(1),
    justifyContent: "center",
    background: "#FBE8D1",
  },
}))(SnackbarContent);

function SystemDowntimeBanner(props) {
  const {
    fetchSystemMaintenanceBannerDetails,
    fetchSystemMaintenanceBannerDetailsReducer,
  } = props;

  const [hideBanner, setHideBanner] = useState(false);
  const [bannerDetails, setBannerDetails] = useState();
  const [message, setMessage] = useState();
  const [startTime, setStartTime] = useState();

  const handleHideBanner = () => {
    setHideBanner(!hideBanner);
  };

  useEffect(async () => {
    if (Cookies.get('CSRF-TOKEN') !== undefined || Cookies.get('CSRF-TOKEN') !== null) {
      const data = await fetchSystemMaintenanceBannerDetails();
      if (data != null) {
        setBannerDetails(data);
        setMessage(data.message ? data.message : "");
        setStartTime(data ? formatDate(data.startTime) : "");
      }
    }
  }, []);


  return (
    <div className={bannerDetails == "" || bannerDetails == undefined ? "hideBanner" : "showBanner"}>
      <Grid container>
        <Grid item md={12}>
          <CustomSnackbarContent
            message={
              <Grid container>
                <Grid item md={12} sm={12} xs={12} className="flexDisplay">
                  <WarningAmberOutlinedIcon htmlColor="#CF0505" style={{ fontSize: "1.875rem" }} />
                  <Typography color="#1E334E" fontWeight={600} paddingLeft={"10px"}>
                    {message}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fetchSystemMaintenanceBannerDetailsReducer:
    state.fetchSystemMaintenanceBannerDetailsReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchSystemMaintenanceBannerDetails: () => {
    return dispatch(fetchSystemMaintenanceBannerDetails());
  },
});

export default withStyles({ withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(SystemDowntimeBanner)
);

{/* <Grid container direction="row" alignItems="flex-start" justifyContent="center">
<Grid item>
  <WarningAmberOutlinedIcon htmlColor="#CF0505"/>
</Grid>
<Grid item>
  <Typography color="#1E334E" fontWeight={600} paddingLeft={"10px"}>
    {message}
  </Typography>
</Grid>
</Grid> */}