import React, { useState } from 'react';
import './chatbottest.css';
import chatbot from "../../assets/img/chat-bot.gif";
import { motion } from "framer-motion";
import { useLocation } from 'react-router-dom';


const ChatBubble = ({ onClick, isOpen, closeIcon }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const location = useLocation()
  const hiddenPaths = ["/", "/channel-performance","/intelli-Dashboard"];
  const isHidden = hiddenPaths.includes(location.pathname);

  return (
    <div
      className="chat-bubble"
      onClick={onClick}
      // onMouseDown={handleMouseDown}
      style={{ position: 'fixed',zIndex:"999" }}
    >
      {!closeIcon && !isHidden ? (
        <img className="chat-styles"

          src={chatbot}
          id="chat-icon"
          width={"5%"} height={"auto"}


        />) : (
        <div style={{ width: "5%", height: "auto" }} className='chat-styles1' >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ rotate: 180, scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20
            }}
          >
            {!isHidden ?
              <i className="fa fa-times" aria-hidden="true" /> : null}
          </motion.div>
        </div>
      )
      }

    </div >
  );

};

export default ChatBubble;
