import { Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { count } from "d3";
import * as _ from "lodash";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { createHashHistory, createBrowserHistory } from "history";
import {
  defaultRouteValue,
  isHeaderRenderIn,
  showHeader,
  HeaderCondition,
} from "../../index";
import { connect } from "react-redux";
import LinearProgressBar from "../graphComponents/LinearProgressBar";
import { Link } from "react-router-dom";
import {
  fetchGaDataViewsPropertiesAction,
  fetchGASessionsAndTransactionsData,
  fetchStatesSessionAndBounceRateData,
  storeUserAnalyAccData,
  fetchSynCheckId,
  fetchStatusForProgressBar,
} from "../../actions";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectCancelBlueBtn,
} from "../../components/buttons";
import GoogleAnalyticsDashboardPage from "../../NewInterfacePages/googleAnalyticsDashboard/google-analytics-dashboard-page";
import Paragraph from "../graphComponents/Paragraph";
import icons from "../../components/icons";
import {
  fetchGoogleAnalyticsViewsAndPropertiesNewDashboard,
  storeGoogleAnalyticsUserAccount,
} from "../../actions/googleAnalyticsNewDashboard";
const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "15px 30px 30px 30px",
    },
    [theme.breakpoints.down("xl")]: {
      padding: "15px",
    },
    backgroundColor: "#F9FAFF",
  },
  iconHeading: {
    font: "lato",
    fontWeight: "700",
    fontSize: "20px",
  },
  heading: {
    font: "lato",
    fontWeight: "700",
    fontSize: "18px",
    color: "#1E334E",
  },
  subHeading: {
    font: "lato",
    fontWeight: "700",
    fontSize: "16px",
    textAlign: "left",
  },
  radioButtonColor: {
    // accentColor:"#F58120",
  },
  fontAccountId: {
    fontSize: "14px",
    fontWeight: "400",
  },
  details: {
    font: "lato",
    fontWeight: "500",
    fontSize: "14px",
    textAlign: "left",
  },
  hrPadding: {
    marginTop: "4rem",
  },
  editSection: {
    borderStyle: "groove",
  },
  saveButton: {
    width: "100px",
    height: "30px",
    border: "solid #FF8735 1px",
    backgroundColor: "#FF8735",
    borderRadius: "6px",
  },
  editButton: {
    width: "100px",
    height: "30px",
    border: "solid #E3E6F4 1px",
    backgroundColor: "#E3E6F4",
    borderRadius: "6px",
  },
  marginEditSection: {
    margin: "2rem",
  },
  widthConstraint: {
    width: "90%",
  },
  progressBar: {},
});

var data;
var propertiesContainer;
var profilesContainer;
var selectedAccountNameShow;
var selectedAccountIdShow;

let selectedPeriod = 15;
let synCheckId = "";
function propertiesAndViews(props) {
  // window.location = window.location.host+"/#/properties-views";
  // const history =
  //   isHeaderRenderIn && !showHeader
  //     ? createHashHistory()
  //     : createBrowserHistory();
  const classes = props.classes;
  const {
    fetchGaDataViewsPropertiesAction,
    fetchPropertiesAndViewsDataReducer,
    fetchGASessionsAndTransactionsData,
    fetchGASessionsAndTransactionsDataReducer,
    fetchStatesSessionAndBounceRateData,
    fetchStatesSessionAndBounceRateDataReducer,
    storeUserAnalyAccData,
    saveAnalyAccDataReducer,
    fetchSynCheckId,
    fetchSynCheckIDReducer,
    fetchStatusForProgressBar,
    fetchStatusForProgressBarReducer,
    fetchGoogleAnalyticsViewsAndPropertiesNewDashboard,
    fetchGoogleAnalyticsViewsAndPropertiesNewDashboardReducer,
    userInformationReducer,
    storeGoogleAnalyticsUserAccount,
    storeGoogleAnalyticsUserAccountReducer,
  } = props;

  const [propertiesStatus, setPropertiesStatus] = useState(false);
  const [profilesStatus, setProfilesStatus] = useState(false);

  // Another try using useState
  const [saveReducerObj, setSaveReducerObj] = useState(false);
  useEffect(() => {
    if (saveAnalyAccDataReducer["success"]) {
      setSaveReducerObj(
        saveAnalyAccDataReducer["success"]
          ? saveAnalyAccDataReducer["success"]
          : null
      );
    }
  }, [saveAnalyAccDataReducer["success"]]);

  // data = fetchPropertiesAndViewsDataReducer;
  data = fetchGoogleAnalyticsViewsAndPropertiesNewDashboardReducer;
  let accountIdAndName = [];
  let propertyIdAndNameTEST = [];
  let profileIdAndNameTEST = [];
  let applicationName = "LXRGuide"
  const [propertiesList, setPropertiesList] = useState([]);
  const [profilesList, setProfilesList] = useState([]);
  const [selectedPropertyId, setSelectedPropertyId] = useState([]);
  const [selectedWebsite, setSelectedWebsite] = useState();
  const [selectedPropertyName, setSelectedPropertyName] = useState([]);
  const [selectedViewId, setSelectedViewId] = useState([]);
  const [selectedViewName, setSelectedViewName] = useState([]);

  const [showAccountList, setShowAccountList] = useState(true);
  const [showPropertiesAndViews, setShowPropertiesAndViews] = useState(false);
  const [showViewOnly, setShowViewOnly] = useState(false);

  const [editComponent, setEditComponent] = useState(false);

  const [nextButtondisabledStatus, setNextButtondisabledStatus] =
    useState(true);
  const [pvNextButtondisabledStatus, setPvNextButtondisabledStatus] =
    useState(true);
  const [clientId, setClientId] = useState();

  _.forEach(data.account_details, (d, i) =>
    accountIdAndName.push({ key: d.accountId, value: d.accountName })
  );

  useEffect(() => {
    setClientId(
      userInformationReducer.selectedClientInfo
        ? userInformationReducer.selectedClientInfo.clientId
        : null
    );
    setSelectedWebsite(
      userInformationReducer.selectedClientInfo
        ? userInformationReducer.selectedClientInfo.clientWebsite
        : "Your Website"
    );
  }, [userInformationReducer.selectedClientInfo]);

  const [analyticsId, setAnalyticsId] = useState(); 
  
  useEffect(() => {
    if (clientId){
      fetchGoogleAnalyticsViewsAndPropertiesNewDashboard(true, clientId, 0, applicationName);
    }
  }, [clientId]);

  const handleSearchProperties = (selectedAccountId, selectedAccountName) => {
    setSelectedPropertyName("");
    setSelectedPropertyId("");
    setSelectedViewName("");
    setSelectedViewId("");
    setProfilesList([]);
    setPropertiesList([]);
    selectedAccountNameShow = selectedAccountName;
    selectedAccountIdShow = selectedAccountId;

    data.account_details.length > 0 &&
      data.account_details.map((items, index) => {
        if (items.accountId === selectedAccountId) {
          propertiesContainer = items.properties;
          _.forEach(propertiesContainer, (d, i) =>
            propertyIdAndNameTEST.push({
              key: d.propertyId,
              value: d.propertyName,
              website: d.websiteUrl,
            })
          );
          setPropertiesStatus(true);
          setPropertiesList(propertyIdAndNameTEST);
        }
      });
    setNextButtondisabledStatus(false);

    setPvNextButtondisabledStatus(true);
  };

  const handleSearchPofiles = (
    selectedPropertyId,
    selectedPropertyName,
    website
  ) => {
    setSelectedViewName("");
    setSelectedViewId("");
    setShowViewOnly(true);

    setSelectedPropertyName(selectedPropertyName);
    setSelectedPropertyId(selectedPropertyId);
    // setSelectedWebsite(website);

    propertiesContainer.length > 0 &&
      propertiesContainer.map((items1, index) => {
        if (items1.propertyId === selectedPropertyId) {

          profilesContainer = items1.profiles;
          _.forEach(profilesContainer, (d, i) =>
            profileIdAndNameTEST.push({
              key: d.profileId,
              value: d.profileName,
            })
          );
          setProfilesStatus(true);
          setProfilesList(profileIdAndNameTEST);
          setPvNextButtondisabledStatus(false);
        }
      });
  };

  const handleShowProfile = (selectedProfileId, selectedProfileName) => {
    setSelectedViewName(selectedProfileName);
    setSelectedViewId(selectedProfileId);
    setPvNextButtondisabledStatus(false);
  };

  const handleReset = () => {
    setEditComponent(false);
    setShowAccountList(true);
    setShowPropertiesAndViews(false);
    setNextButtondisabledStatus(false);
    setPvNextButtondisabledStatus(false);
    setSelectedPropertyName("");
    setSelectedPropertyId("");
    setSelectedWebsite("");
    setSelectedViewName("");
    setSelectedViewId("");
  };

  const handleSave = (
    selectedAccountIdShow,
    selectedAccountNameShow,
    selectedPropertyId,

    selectedPropertyName
  ) => {
 

    //used below for the google analytics dashboard
    // storeUserAnalyAccData(
    //   selectedAccountIdShow,
    //   selectedAccountNameShow,
    //   selectedPropertyId,
    //   selectedWebsite,
    //   selectedPropertyName,
    //   selectedViewId,
    //   selectedViewName
    // );
    storeGoogleAnalyticsUserAccount(
      clientId,
      selectedAccountIdShow,
      selectedAccountNameShow,
      selectedPropertyId,
      selectedPropertyName,
      selectedWebsite
    );
  };
  const handleNext = () => {
    setShowPropertiesAndViews(true);
    setShowAccountList(false);
    if (selectedPropertyName) {
      setPvNextButtondisabledStatus(false);
    } else {
      setPvNextButtondisabledStatus(true);
    }
  };
  const handlePrev = () => {
    setShowPropertiesAndViews(false);
    setShowAccountList(true);
    setNextButtondisabledStatus(false);
  };
  const handleNextPropertiesAndView = () => {
    setShowPropertiesAndViews(false);
    setEditComponent(true);
  };
  const handleReviewPrev = () => {
    setPvNextButtondisabledStatus(false);
    setEditComponent(false);
    setShowPropertiesAndViews(true);
  };

  return (
    <div className={classes.mainDiv}>
      {/* {completedStatus === null ? 
      ( */}
      <div>
        <div style={{ marginTop: "20px", backgroundColor: "#D9D9D9" }}>
          {/* below content is for the select details section */}
          {/* <Grid container>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Typography
                  className={classes.heading}
                  style={{ padding: "10px 0px 0px 10px" }}
                >
                  Selected Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <label className={classes.subHeading}>
                 
                  {selectedAccountNameShow ? selectedAccountNameShow : null}
                </label>
                <Typography
                 
                  className={classes.details}
                >
                  {selectedAccountIdShow ? selectedAccountIdShow : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <label className={classes.subHeading}>
                 
                  {selectedPropertyName ? selectedPropertyName : null}
                </label>
                <Typography
                  
                  className={classes.details}
                >
                  {selectedPropertyId ? selectedPropertyId : null}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <label className={classes.subHeading}>
                  {selectedViewName ? selectedViewName : null}
                </label>
                <Typography className={classes.details}>
                  {selectedViewId ? selectedViewId : null}
                </Typography>
              </Grid>
            </Grid> */}
          {/* end of select details section */}
        </div>
        {showAccountList === true && showPropertiesAndViews === false ? (
          <div name="dynamicLoadingOf">
            <div>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paragraph
                    title="Select Account"
                    para1="LXRGuide now offers an easy-to-use Analytics dashboard with personalized insights that can be vital to your decision-making. You will need to complete the simple connection process below for it to be generated. First, please select the appropriate Google Analytics 4 account. "
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ textAlign: "center" }}>{icons.pvOneDotofthree}</div>

            <div style={{ marginTop: "20px" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div className={classes.heading}>
                    1. Select one Account from list of Google Analytics Accounts
                    (GA 4):
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={12}
                  style={{ marginTop: "30px" }}
                >
                  {accountIdAndName.length > 0 &&
                    accountIdAndName.map((items, index) => (
                      <Fragment>
                        <input
                          id={`${items.key}-${index}`}
                          className={classes.radioButtonColor}
                          title="Please select one of the Account"
                          type="radio"
                          value={items.value}
                          name="Account Name"
                          checked={
                            items.key === selectedAccountIdShow ? true : false
                          }
                          onChange={() =>
                            handleSearchProperties(items.key, items.value)
                          }
                          required
                        />
                        <label
                          htmlFor={`${items.key}-${index}`}
                          key={index}
                          className={classes.subHeading}
                        >
                          &nbsp;{items.value}
                        </label>
                        <Typography
                          style={{ margin: "5px 0px 15px 25px" }}
                          className={classes.details}
                        >
                          ID: {items.key}
                        </Typography>
                      </Fragment>
                    ))}
                  <hr className={classes.hrPadding}></hr>
                  <NewOrangeContainedBtn
                    disabled={nextButtondisabledStatus}
                    onClick={handleNext}
                  >
                    Next
                  </NewOrangeContainedBtn>
                </Grid>
              </Grid>
            </div>
          </div>
        ) : showPropertiesAndViews === true ? (
          <div>
            <div>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paragraph
                    title="Select Property"
                    para1="You may have created multiple properties in your Google Analytics 4 account. Please select the one that is most relevant, in use, and displays a holistic view of your business."
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ textAlign: "center" }}>{icons.pvTwoDotofthree}</div>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "30px" }}
            >
              <div>
                <Typography className={classes.heading}>
                  1. Selected account:
                  <div style={{ margin: "5px 0px 15px 25px" }}>
                    <div
                      className={classes.subHeading}
                      style={{ color: "#949EAB" }}
                    >
                      Account Name: {selectedAccountNameShow}
                    </div>
                    <div
                      className={classes.fontAccountId}
                      style={{ color: "#949EAB" }}
                    >
                      ID: {selectedAccountIdShow}
                    </div>
                  </div>
                  {/* Google Analytics Account(UA) */}
                </Typography>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Typography className={classes.heading}>
                  {/* 2. Select one property respective view for a/the selected
                  account: */}
                  2. Select one property for the selected account:
                </Typography>
              </div>
              {propertiesList.length > 0 && showPropertiesAndViews === true ? (
                propertiesList.length > 0 &&
                propertiesList.map((items, index) => (
                  <Fragment>
                    <input
                      id={`${items.key}-${index}`}
                      type="radio"
                      title="Please select one of the Properties"
                      className={classes.radioButtonColor}
                      value={items.value}
                      name="Property Name"
                      checked={items.key === selectedPropertyId}
                      onChange={() =>
                        handleSearchPofiles(
                          items.key,
                          items.value,
                          items.website
                        )
                      }
                      required
                    />
                    <label
                      htmlFor={`${items.key}-${index}`}
                      className={classes.subHeading}
                    >
                      {" "}
                      &nbsp;{items.value}
                    </label>
                    <Typography
                      style={{ margin: "5px 0px 15px 25px" }}
                      className={classes.details}
                    >
                      ID: {items.key}
                    </Typography>
                    {/* {items.key === selectedPropertyId ? (
                      <Grid
                        container
                        spacing={2}
                        style={{ paddingLeft: "75px" }}
                      >
                        {profilesList.length > 0 ? (
                          profilesList.length > 0 &&
                          profilesList.map((itemsprofiles, index) => (
                            <Grid item>
                              <label key={index} className={classes.subHeading}>
                                <input
                                  type="radio"
                                  title="Please select one of the Views"
                                  className={classes.radioButtonColor}
                                  value={itemsprofiles.value}
                                  checked={
                                    itemsprofiles.value === selectedViewName
                                  }
                                  name="Profile Name"
                                  onChange={() =>
                                    handleShowProfile(
                                      itemsprofiles.key,
                                      itemsprofiles.value
                                    )
                                  }
                                  required
                                />
                                &nbsp;
                                {itemsprofiles.value}
                              </label>
                              <Typography
                                style={{
                                  margin: "5px 0px 15px 25px",
                                }}
                                className={classes.details}
                              >
                                ID: {itemsprofiles.key}
                              </Typography>
                            </Grid>
                          ))
                        ) : (<p> </p>
                          // <p>Please select or change Properties </p>
                        )}
                      </Grid>
                    ) : (
                      ""
                    )} */}
                  </Fragment>
                ))
              ) : (
                <p>
                  The selected Google Analytics 4 account does not have any
                  properties. Please select a different Google Analytics 4
                  account.
                </p>
              )}
            </Grid>

            <hr className={classes.hrPadding} />
            <div>
              <NewOutLinedRectCancelBlueBtn onClick={handlePrev}>
                Prev
              </NewOutLinedRectCancelBlueBtn>
              <NewOrangeContainedBtn
                disabled={pvNextButtondisabledStatus}
                onClick={handleNextPropertiesAndView}
              >
                Next
              </NewOrangeContainedBtn>
            </div>
          </div>
        ) : editComponent === true &&
          showAccountList === false &&
          showPropertiesAndViews === false ? (
          <div>
            <div>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paragraph
                    title="Review Selection"
                    para1="Please review your selections for accuracy and click save. In the next 10 to 15 minutes, the Analytics dashboard will be created for you!"
                  />
                </Grid>
              </Grid>
            </div>
            <div style={{ textAlign: "center" }}>{icons.pvThreeDotofthree}</div>
            <h2 style={{ color: "#1E334E" }}>Review Selection:</h2>
            <div className={classes.editSection}>
              <Grid
                container
                className={classes.marginEditSection}
                style={{ wordBreak: "break-all" }}
              >
                <Grid item lg={4} className={classes.heading}>
                  <p>Google Analytics Account (GA 4)</p>
                  <div className={classes.widthConstraint}>
                    <div className={classes.subHeading}>
                      {selectedAccountNameShow}
                    </div>
                    <div className={classes.fontAccountId}>
                      {selectedAccountIdShow}
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} className={classes.heading}>
                  <p>Property and App</p>
                  <div className={classes.widthConstraint}>
                    <div className={classes.subHeading}>
                      {selectedPropertyName}
                    </div>
                    <div className={classes.fontAccountId}>
                      {selectedPropertyId}
                    </div>
                  </div>
                </Grid>
                {/* <Grid item lg={4} className={classes.heading}>
                  <p>View</p>
                  <div className={classes.widthConstraint}>
                    <div className={classes.subHeading}>
                      {" "}
                      {selectedViewName}
                    </div>
                    <div className={classes.fontAccountId}>
                      {" "}
                      {selectedViewId}
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </div>
            <hr className={classes.hrPadding} />
            <div>
              <NewOutLinedRectCancelBlueBtn onClick={() => handleReviewPrev()}>
                Prev
              </NewOutLinedRectCancelBlueBtn>
              <NewOrangeContainedBtn
                component={Link}
                // to={"/google-analytics-dashboard"}
                to={"/integration-loader"}
                onClick={() =>
                  handleSave(
                    selectedAccountIdShow,
                    selectedAccountNameShow,
                    selectedPropertyId,
                    selectedPropertyName
                  )
                }
              >
                Save
              </NewOrangeContainedBtn>
              &nbsp;&nbsp;&nbsp;
              <NewOutLinedRectCancelBlueBtn onClick={() => handleReset()}>
                Reset
              </NewOutLinedRectCancelBlueBtn>
              &nbsp;&nbsp;&nbsp;
            </div>
          </div>
        ) : (
          <div>worst condition occured while selecting</div>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  fetchPropertiesAndViewsDataReducer: state.fetchPropertiesAndViewsDataReducer,
  fetchGASessionsAndTransactionsDataReducer:
    state.fetchGASessionsAndTransactionsDataReducer,
  fetchStatesSessionAndBounceRateDataReducer:
    state.fetchStatesSessionAndBounceRateDataReducer,
  saveAnalyAccDataReducer: state.saveAnalyAccDataReducer,
  fetchSynCheckIDReducer: state.fetchSynCheckIDReducer,
  fetchStatusForProgressBarReducer: state.fetchStatusForProgressBarReducer,
  fetchGoogleAnalyticsViewsAndPropertiesNewDashboardReducer:
    state.fetchGoogleAnalyticsViewsAndPropertiesNewDashboardReducer,
  userInformationReducer: state.userInformation,
  storeGoogleAnalyticsUserAccountReducer:
    state.storeGoogleAnalyticsUserAccountReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchGaDataViewsPropertiesAction: (dummyStatus, dummyAccountId) =>
    dispatch(fetchGaDataViewsPropertiesAction(dummyStatus, dummyAccountId)),
  fetchGASessionsAndTransactionsData: (
    accountId,
    propertyId,
    profileId,
    selectedPeriod
  ) =>
    dispatch(
      fetchGASessionsAndTransactionsData(
        accountId,
        propertyId,
        profileId,
        selectedPeriod
      )
    ),
  fetchStatesSessionAndBounceRateData: (
    accountId,
    propertyId,
    profileId,
    selectedPeriod
  ) =>
    dispatch(
      fetchStatesSessionAndBounceRateData(
        accountId,
        propertyId,
        profileId,
        selectedPeriod
      )
    ),
  storeUserAnalyAccData: (
    selectedAccountIdShow,
    selectedAccountNameShow,
    selectedPropertyId,
    selectedWebsite,
    selectedPropertyName,
    selectedViewId,
    selectedViewName
  ) =>
    dispatch(
      storeUserAnalyAccData(
        selectedAccountIdShow,
        selectedAccountNameShow,
        selectedPropertyId,
        selectedWebsite,
        selectedPropertyName,
        selectedViewId,
        selectedViewName
      )
    ),
  fetchSynCheckId: () => dispatch(fetchSynCheckId()),
  fetchStatusForProgressBar: (synCheckId) =>
    dispatch(fetchStatusForProgressBar(synCheckId)),
  fetchGoogleAnalyticsViewsAndPropertiesNewDashboard: (addAccountStatus, clientId, analyticsId, applicationName) =>
    dispatch(
      fetchGoogleAnalyticsViewsAndPropertiesNewDashboard( addAccountStatus,clientId,analyticsId,applicationName)
    ),
  storeGoogleAnalyticsUserAccount: (
    clientId,
    selectedAccountIdShow,
    selectedAccountNameShow,
    selectedPropertyId,
    selectedPropertyName,
    selectedWebsite
  ) =>
    dispatch(
      storeGoogleAnalyticsUserAccount(
        clientId,
        selectedAccountIdShow,
        selectedAccountNameShow,
        selectedPropertyId,
        selectedPropertyName,
        selectedWebsite
      )
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(propertiesAndViews));
