import React from "react";
import * as _ from "lodash";
import { Checkbox, Grid, Paper, Typography, Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { connect } from "react-redux";
import {
  getSearchConsoleAuthUrl,
  importStatusSearchConsole,
} from "../../actions";
import { store } from "../../reducers/common";
// import CustomDialog from '../../components/admin/dialog';
import CustomDialog from "../../components/dialog-generic";
import { ErrorTypography } from "../typography";
import { withRouter } from "react-router";
import { TOGGLE_IS_ACCESS } from "../../actions/actionTypes";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import icons from "../icons";
import {
  NewOrangeContainedBtn,
  NewOrangeLargeBtn,
  OrangeBtnNew,
} from "../buttons";
import { fetchSearchConsoleAcc } from "../../actions/connectionPageActions";

// const content = ["Open the property selector dropdown menu in any Search Console page.", "Select “+ Add property” in the dropdown menu.", "Choose the type of property to add: URL-prefix property, domain property, or Google-hosted property.", "Choose one of the verification methods provided and follow the prompted steps. You can verify immediately or pause and resume the process later.", "Data should begin appearing in your property within a few days."];
const content = [
  "Enter your website’s domain (or URL-Prefix) to add a property. We recommend you set up Google Search Console using a domain.",
  "Setting up Search Console via domain option means you’re creating a single property that includes all subdomains and protocol prefixes (http:// or https://) associated with your domain.",
  "If you want to keep your subdomain data separate, such as blog.yourdomain.com or shop.yourdomain.com, please use the URL-Prefix option.",
  "Choose one of the verification methods provided and follow the instructions. There is only one way to verify a domain-level property, and that’s through adding a DNS record.",
  "Once you complete all the steps correctly, you should see the message “Ownership Verified”.",
  "Keep in mind, updating DNS records can take up to 72 hours. If your ownership isn’t verified immediately, come back in a few hours or the next day, and check again.",
];

const CustomListItem = withStyles((theme) => ({
  root: {
    paddingTop: 1,
    paddingBottom: 1,
    alignItems: "baseline",
    paddingLeft: 0,
  },
}))(ListItem);
const CustomListItemText = withStyles((theme) => ({
  root: {
    color: "inherit",
  },
}))(ListItemText);

class _KeywordHierarchy extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.response = await this.props.getSearchConsoleAuthUrl(
      this.props.summary.taskId,
      this.props.data.dataIdList[0]
    );
  }

  handleSubmit = async () => {
    let url = this.response.authUrl;
    if (this.response && this.response.authUrl && url != "") {
      console.log("The auth url is:", url);
      let newWindow = window.open(
        url,
        "Search Console Authentication",
        "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no, width=700, height=600, top=30"
      );

      const checkWindowStatus = setInterval(async () => {
        if (newWindow && newWindow.closed) {
         
          clearInterval(checkWindowStatus);
          const data = await this.props.fetchSearchConsoleAcc();
        }
      }, 3000);
    }
  };
  handleCustomAlertDialog = () => {
    const { history } = this.props;
    console.log("Came to ok method inside block ");
    this.props.toggleIsAccess();
    window.location.href = "/switch-ui.html";
    // history.push("/");
  };
  handlePauseToggle = () => {
    console.log("Came to close method inside block ");
    this.props.state = { isAccessStatus: false };
  };

  render() {
    const { keywordHierarchyTaskState, actionStatus ,fetchSearchConsoleAccReducer} = this.props;
   
    return (
      <Grid container style={{ padding: 15 }}>
        <Grid item md={12}>
          <Typography
            variant={"h4"}
            color={"textPrimary"}
            style={{ lineHeight: 1.5 }}
            gutterBottom
          >
            Google Search Console provides a wealth of information that is
            crucial for effective PPC management. This includes keywords your
            website ranks for, your position rank for those keywords, and how
            often users are clicking your website link after searching for
            specific queries. After linking to your Search Console account,
            LXRGuide automation and machine learning analyzes your data to
            identify relevant queries for which your site is struggling to rank
            organically. LXRGuide is programmed to boost performance for these
            keywords through paid search.
          </Typography>
          <br />
          <Typography variant={"h4"} color={"textPrimary"} gutterBottom>
            {/* <b>If you don’t have a Search Console account, you can set one up by following these steps:</b> */}
            <b>
              {/* below one is the updated content */}
              If you do not have an active search console account, you can set
              up one by signing in to Search Console{" "}
              <a
                style={{ color: "blue" }}
                href="https://search.google.com/search-console/welcome"
                target="_blank"
              >
                (sign in here){" "}
              </a>
              with your Google account:
            </b>
          </Typography>
          <Typography>
            {/* {content.map((value, index) => {
                            return <CustomListItem>
                                <ListItemIcon style={{margin: 0}}>
                                    {icons.dot}
                                </ListItemIcon>
                                <CustomListItemText>
                                    {value}
                                </CustomListItemText>
                            </CustomListItem>
                        })} */}
            {/* 1 */}
            <CustomListItem>
              <ListItemIcon style={{ marginLeft: "14px" ,minWidth:"2%"}}>
                {icons.dot}
              </ListItemIcon>
              <CustomListItemText>
                Enter your website’s domain (or URL-Prefix) to add a property.
                We recommend you set up Google Search Console using a domain.
              </CustomListItemText>
            </CustomListItem>
            {/* 2 */}
            <CustomListItem style={{ paddingLeft: "4rem" }}>
              <ListItemIcon style={{ marginLeft: "14px",minWidth:"2%" }}>
                {icons.dot}
              </ListItemIcon>
              <CustomListItemText>
                {/* start child */}
                Setting up Search Console via domain option means you’re
                creating a single property that includes all subdomains and
                protocol prefixes (http:// or https://) associated with your
                domain.
              </CustomListItemText>
            </CustomListItem>
            {/* 3 */}
            <CustomListItem style={{ paddingLeft: "4rem" }}>
              <ListItemIcon style={{ marginLeft: "14px",minWidth:"2%" }}>
                {icons.dot}
              </ListItemIcon>
              <CustomListItemText>
                If you want to keep your subdomain data separate, such as
                blog.yourdomain.com or shop.yourdomain.com, please use the
                URL-Prefix option.
              </CustomListItemText>
            </CustomListItem>

            {/* end of start child */}

            {/* 4 */}
            <CustomListItem>
              <ListItemIcon style={{ marginLeft: "14px",minWidth:"2%" }}>
                {icons.dot}
              </ListItemIcon>
              <CustomListItemText>
                Choose one of the verification methods provided and follow the
                instructions. There is only one way to verify a domain-level
                property, and that’s through adding a DNS record.
              </CustomListItemText>
            </CustomListItem>
            {/* 5 */}
            <CustomListItem>
              <ListItemIcon style={{ marginLeft: "14px",minWidth:"2%" }}>
                {icons.dot}
              </ListItemIcon>
              <CustomListItemText>
                Once you complete all the steps correctly, you should see the
                message “Ownership Verified”.
              </CustomListItemText>
            </CustomListItem>

            {/* 6 */}
            <CustomListItem style={{ paddingLeft: "4rem" }}>
              <ListItemIcon style={{ marginLeft: "14px",minWidth:"2%" }}>
                {icons.dot}
              </ListItemIcon>
              <CustomListItemText>
                Keep in mind, updating DNS records can take up to 72 hours. If
                your ownership isn’t verified immediately, come back in a few
                hours or the next day, and check again.
              </CustomListItemText>
            </CustomListItem>
          </Typography>
        </Grid>
        <Typography style={{ padding: "1%" }} />
        <Grid item md={12}>
          <NewOrangeLargeBtn
            variant="contained"
            color="primary"
            onClick={this.handleSubmit}
            size={"large"}
            disabled={actionStatus == 2}
          >
            Link
          </NewOrangeLargeBtn>
        </Grid>
        <Grid item md={12}>
          <br />
          {fetchSearchConsoleAccReducer.error !== null && actionStatus !== 2 ? (
            <ErrorTypography>
              <ErrorTypography component={"p"}>
                LXRGuide failed to link with your Google Search Console
                property.
              </ErrorTypography>
              {keywordHierarchyTaskState.error}
            </ErrorTypography>
          ) : null}
        </Grid>
        <Grid>
          <CustomDialog
            title={"Success!"}
            content={
              <Typography>
                LXRGuide is successfully linked with your Google Search Console
                property.
              </Typography>
            }
            open={fetchSearchConsoleAccReducer.error ? fetchSearchConsoleAccReducer.error == null : false}
            handleClose={this.handlePauseToggle}
            handleAction={this.handleCustomAlertDialog}
            hideSecoundaryBtn
            primaryBtnContent={"ok"}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSearchConsoleAuthUrl: (taskId, taskDataId) => {
    return dispatch(getSearchConsoleAuthUrl(taskId, taskDataId));
  },
  importStatusSearchConsole: () => {
    return dispatch(importStatusSearchConsole());
  },
  fetchSearchConsoleAcc: () => dispatch(fetchSearchConsoleAcc()),
  toggleIsAccess: () => dispatch({ type: TOGGLE_IS_ACCESS }),
});
const mapStateToProps = (state, ownProps) => ({
  fetchSearchConsoleAccReducer: state.fetchSearchConsoleAccReducer,
  keywordHierarchyTaskState: state.keywordHierarchyTaskState,
  actionStatus: ownProps.dataIdList.filter(
    (id) => state.taskDataById[id].status === 0
  ).length
    ? 0
    : 2,
});
const KeywordHierarchy = withRouter(_KeywordHierarchy);

export default connect(mapStateToProps, mapDispatchToProps)(KeywordHierarchy);
