import React, { useEffect, useState } from 'react';
import icons from "../../components/icons";
import './chatbottest.css'; // Ensure you create this CSS file

const ChatInput = ({ onSend, initialButtonValue }) => {
  const [input, setInput] = useState('');

  useEffect(() => {
    if (initialButtonValue) {
      setInput(initialButtonValue);
    }
  }, [initialButtonValue]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSend(input);
      setInput('');
    }
  };

  const handleSendClick = () => {
    onSend(input);
    setInput('');
  };

  return (
    <div className="chat-footer">
      <div className="input-container">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
          onKeyDown={handleKeyDown}
          style={{border:"none"}}
        />
        <button className="send-button" onClick={handleSendClick}>
          {icons.ChatbotSendIcon}
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
