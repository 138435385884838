import React, { useEffect, useState } from "react";
import {
  Typography,
  MenuItem,
  Select,
  Grid,
  Divider,
  Button,
} from "@mui/material";
import icons from "../../components/icons";
import { formatNumberToK } from "../commonFunctions/commonFunctions";
import ProgressBar from "./CustomProgessBar";
import { Link } from "react-router-dom";
import { updateSelectedAccount } from "../../actions/fetchingUserData";
import { fetchPerfData } from "../../actions/index";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ConnectionCard } from "./ConnectionCard.jsx";
import * as types from "../../actions/actionTypes";

const PerformanceCard = (props) => {
  const { performanceData, userInformation, updateSelectedAccount, fetchPerfData, statsHistory } = props;
  const [googlePerformanceData, setGooglePerformanceData] = useState();
  const [bingPerformanceData, setBingPerformanceData] = useState();
  const [googlePPCID, setGooglePPCID] = useState();
  const [bingPPCID, setBingPPCID] = useState();
  const history = useHistory();

  useEffect(() => {
    if (performanceData && performanceData.length > 0) {
      const googleData = performanceData.filter((data) => data.engineId === 1);
      setGooglePerformanceData(googleData ? googleData : []);
      const bingData = performanceData.filter((data) => data.engineId === 2);
      setBingPerformanceData(bingData ? bingData : []);
    }
  }, [performanceData]);

  useEffect(() => {
    if (userInformation?.userInfo?.clientInfo[0]?.accounts)
      if (
        userInformation?.userInfo?.clientInfo[0]?.accounts?.find(
          (account) => account.searchEngineId === 1
        )
      ) {
        setGooglePPCID(
          userInformation?.userInfo?.clientInfo[0]?.accounts?.find(
            (account) => account.searchEngineId === 1
          )?.ppcId || null
        );
      }
    if (
      userInformation?.userInfo?.clientInfo[0]?.accounts?.find(
        (account) => account.searchEngineId === 2
      )
    ) {
      setBingPPCID(
        userInformation?.userInfo?.clientInfo[0]?.accounts?.find(
          (account) => account.searchEngineId === 2
        )?.ppcId || null
      );
    }
  }, [userInformation]);
  const handleRespectiveDashboardAccount = async (ppcId) => {
    let data = await updateSelectedAccount(ppcId);
    await props.updateViewValue(0);
    await fetchPerfData(undefined, true);
  };

  const redirectToConnectionPage = (platform) => {
    localStorage.setItem("platform", platform);
    history.push("/connections-page");
  };

  return (
    <>
      <Grid
        container
        direction="row"
        rowGap={2}
        justifyContent="space-between"
        alignItems="center"
      >
        {googlePerformanceData && googlePerformanceData.length > 0 && (
          <>
            <Grid item container xs={12}>
              <Grid item xs={8}>
                {icons.GoogleAdsNew}
              </Grid>
              <Grid item xs={4}>
                <Link
                  to="/dashboard"
                  onClick={async () =>
                    await handleRespectiveDashboardAccount(googlePPCID)
                  }
                  style={{ textDecoration: "none", cursor: "pointer", float: "right" }}
                >
                  View Details &nbsp; {icons.ViewDetailsND}{" "}
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ProgressBar
                TextValue="Revenue"
                value={(googlePerformanceData[0].currentRevenue / googlePerformanceData[0].revenueTarget) * 100}
                ScoreValue={`$${formatNumberToK(googlePerformanceData[0].currentRevenue)} / $${formatNumberToK(googlePerformanceData[0].revenueTarget)}`}
                progressColor="#1EBC61"
              />
              <ProgressBar
                TextValue="Spend"
                value={(googlePerformanceData[0].currentSpend / googlePerformanceData[0].spendBudget) * 100}
                ScoreValue={`$${formatNumberToK(googlePerformanceData[0].currentSpend)} / $${formatNumberToK(googlePerformanceData[0].spendBudget)}`}
                progressColor="#1098F7"
              />
            </Grid>
          </>
        )}

        {/* {bingPerformanceData && bingPerformanceData.length > 0 && (
          <>
            <Grid item xs={12}>
              <Divider sx={{ borderWidth: "thin" }} />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={8}>
                {icons.BingAdsNew}
              </Grid>
              <Grid item xs={4}>
                <Link
                  to="/dashboard"
                  onClick={async () =>
                    await handleRespectiveDashboardAccount(bingPPCID)
                  }
                  style={{ textDecoration: "none", cursor: "pointer" , float:"right"}}
                >
                  View Details &nbsp; {icons.ViewDetailsND}{" "}
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ProgressBar
                TextValue="Revenue"
                value={(bingPerformanceData[0].currentRevenue / bingPerformanceData[0].revenueTarget) * 100}
                ScoreValue={`${formatNumberToK(bingPerformanceData[0].currentRevenue)} / ${formatNumberToK(bingPerformanceData[0].revenueTarget)}`}
                progressColor="#14B8A6"
              />
              <ProgressBar
                TextValue="Spend"
                value={(bingPerformanceData[0].currentSpend / bingPerformanceData[0].spendBudget) * 100}
                ScoreValue={`${formatNumberToK(bingPerformanceData[0].currentSpend)} / ${formatNumberToK(bingPerformanceData[0].spendBudget)}`}
                progressColor="#FACC15"
              />
            </Grid>
          </>
        )} */}
        {bingPerformanceData && bingPerformanceData.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Divider sx={{ borderWidth: "thin" }} />
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={8}>
                {icons.BingAdsNew}
              </Grid>
              <Grid item xs={4}>
                <Link
                  to="/dashboard"
                  onClick={async () => await handleRespectiveDashboardAccount(bingPPCID)}
                  style={{ textDecoration: "none", cursor: "pointer", float: "right" }}
                >
                  View Details &nbsp; {icons.ViewDetailsND}{" "}
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ProgressBar
                TextValue="Revenue"
                value={(bingPerformanceData[0].currentRevenue / bingPerformanceData[0].revenueTarget) * 100}
                ScoreValue={`$${formatNumberToK(bingPerformanceData[0].currentRevenue)} / $${formatNumberToK(bingPerformanceData[0].revenueTarget)}`}
                progressColor="#1EBC61"
              />
              <ProgressBar
                TextValue="Spend"
                value={(bingPerformanceData[0].currentSpend / bingPerformanceData[0].spendBudget) * 100}
                ScoreValue={`$${formatNumberToK(bingPerformanceData[0].currentSpend)} / $${formatNumberToK(bingPerformanceData[0].spendBudget)}`}
                progressColor="#1098F7"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={12} >
            <Divider sx={{ borderWidth: "thin" }} />
            {/* <Typography variant="body1" align="center" color="textSecondary"> */}
            <div style={{ paddingTop: "4%" }}>
              <ConnectionCard

                title={"Bing Ads"}
                icon1={icons.BingAds}
                icon2={icons.BingAds}
                description="Add Bing from the Connections page or by clicking the button below."
                buttonText="Connect Now"
                onClick={() => redirectToConnectionPage("Amazon Ads")}
                cardSize={{ xs: 12, md: 12 }} // Custom size
              />
            </div>
            {/* </Typography> */}
          </Grid>
        )}

      </Grid>
    </>
  );
};
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = (dispatch) => ({
  updateSelectedAccount: (ppcId) => dispatch(updateSelectedAccount(ppcId)),
  fetchPerfData: (isGraph,isTriggeredFromChannel) => dispatch(fetchPerfData(isGraph,isTriggeredFromChannel)),
  updateViewValue: (viewValue) => {
    return dispatch({
      type: types.DASHBOARD_VIEW_VALUE_UPDATED,
      viewValue: viewValue,
    });
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PerformanceCard);
