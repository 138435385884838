import * as types from "../actions/actionTypes";
export default function industryInsightsDataReducers(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_INDUSTRY_INSIGHT_DATA_REQUESTED:
     
      return Object.assign({}, state, {
        message: "FETCH_INDUSTRY_INSIGHT_DATA_REQUESTED",
      });

    case types.FETCH_INDUSTRY_INSIGHT_DATA_RECEIVED:
     
      let { data } = action;
     
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_INDUSTRY_INSIGHT_DATA_RECEIVED",
      });

    case types.FETCH_INDUSTRY_INSIGHT_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_INDUSTRY_INSIGHTS_DATA_FAILED",
      });
      
    default:
     
      return state;
  }
}
