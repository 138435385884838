
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { fetchAmazonDownloadStatus, startAmazonAccIntegration } from "../../actions/amazonActions";
import { Divider, Grid, Typography, Button, Card, LinearProgress, CircularProgress } from "@mui/material";
import { BigTitle } from "./NewDashBoardTypography";
import "./NewDashBoard.css";
import icons from "../../components/icons";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const AmazonIntegrationLoader = (props) => {
    const { syncCheckId, setSyncCheckId, width, fetchAmazonDownloadStatus, fetchAmazonDownloadStatusReducer } = props;
    const [completedValue, setCompletedValue] = useState(
        fetchAmazonDownloadStatusReducer && fetchAmazonDownloadStatusReducer.complete_value ? Number(fetchAmazonDownloadStatusReducer.complete_value) : 5
    );
    const [completedStatus, setCompletedStatus] = useState(
        fetchAmazonDownloadStatusReducer && fetchAmazonDownloadStatusReducer.status ? fetchAmazonDownloadStatusReducer.status : "Integration process has started:");
    const countRef = useRef(0); // Keep track of attempts

    // Poll download status at intervals
    useEffect(() => {
        if (syncCheckId) {
            fetchAmazonDownloadStatus(syncCheckId); // first call
            const interval = setInterval(() => {
                countRef.current += 1;
                if (countRef.current >= 30 || completedValue >= 60) {
                    clearInterval(interval);
                    window.location.reload();
                } else {
                    fetchAmazonDownloadStatus(syncCheckId); //interval calls
                }
            }, 300000);
            return () => clearInterval(interval);
        }
    }, [syncCheckId, fetchAmazonDownloadStatus, completedValue]);

    // Update progress bar values when reducer data changes
    useEffect(() => {
        if (fetchAmazonDownloadStatusReducer && fetchAmazonDownloadStatusReducer.message == "AMAZON_ADS_DOWNLOAD_STATUS_RECEIVED") {
            setCompletedValue(Number(fetchAmazonDownloadStatusReducer.complete_value));
            setCompletedStatus(fetchAmazonDownloadStatusReducer.complete_status);
        }
    }, [fetchAmazonDownloadStatusReducer]);


    useEffect(() => {
        if (completedValue >= 60) {
            setSyncCheckId("");
            window.location.reload();
        }
    }, [completedValue])

    return (
        <Grid item xs={12} md={6}>
            <Card className="card">
                <BigTitle style={{ display: "flex", alignItems: "center" }}>
                    Marketplace Trend
                    <span style={{ marginLeft: "8px", display: "flex", justifyContent: "center" }}>
                        {icons.AmazonMarketplaceIcon}
                    </span>
                </BigTitle>
                <Grid container spacing={2} direction="row" paddingTop={4} alignContent={"center"} justifyContent={"center"}>
                    <Grid item xs={12} display={"flex"} justifyContent="center" alignItems={"center"} flexDirection={"row"}>
                    <div style={{width:width}}>
                            <CircularProgressbar
                                value={completedValue}
                                text={`${completedValue}%`}
                                styles={buildStyles({
                                    textColor: "#1E334E",
                                    pathColor:
                                        completedValue === 0
                                            ? "none"
                                            : completedValue > 0 && completedValue <= 25
                                                ? "#1098F7"
                                                : completedValue > 25 && completedValue <= 50
                                                    ? "#A2B6C3"
                                                    : completedValue > 50 && completedValue <= 75
                                                        ? "#F58120"
                                                        : "#1EBC61",
                                    trailColor: "grey",
                                })}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" fontSize={"1rem"} fontWeight={500} lineHeight={1.8} color={'#808080'}>
                            Integration is in progress: 
                        <span style={{color:"#f58120"}}> {completedValue}% completed</span></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" fontSize={"0.875rem"} fontWeight={500} lineHeight={1.8} color={"#9C9C9C"}>This may take up to 3 hours to complete</Typography>
                    </Grid>
                </Grid>
            </Card>
        </Grid> 
    );
};

const mapStateToProps = (state) => ({
    fetchAmazonDownloadStatusReducer: state.fetchAmazonDownloadStatusReducer,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAmazonDownloadStatus: (syncCheckId) => dispatch(fetchAmazonDownloadStatus(syncCheckId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AmazonIntegrationLoader);
