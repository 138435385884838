
// theme.js
export const ChatBotTheme = {
    colors: {
      primary: '#333',
      secondary: "#FF7A00",
    },
    lineHeights: {
      heading1: '1.2',
      heading2: '1.3',
      bodyText: '1.5',
    },
    fontWeights: {
      bold: 'bold',
      normal: 'normal',
      welcomeFont:'500',
    },
    fontFamily:{
      primary:"Montserrat",
    }
   
  };
  