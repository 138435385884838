import React, {useEffect, useState, useRef} from "react";
import robotImg from "../../assets/img/gif/robot2-gif.gif";
import chatbot from "../../assets/img/chat-bot.gif";
import {motion} from "framer-motion";
import {campaignsSnapshotPrevious} from "../../selectors/dashboard-selectors";
import {sortTasks} from "../../reducers/common";
import {connect} from "react-redux";
import * as _ from "lodash";


// function usePrevious(value) {
//     const ref = useRef();
//     useEffect(() => {
//         ref.current = value;
//     });
//     return ref.current;
// }

function ChatBot(props) {
    const [isDialog, setDialog] = useState(false);
    const [counter, setCounter] = useState(0);
    const {userId, username, clientId, ppcId, userInfo} = props;

    const pricingPlanId = (userInfo && userInfo.pricingPlan) ? userInfo.pricingPlan.pricingPlanId : null;
    const permission = (userInfo && userInfo.permission) ? userInfo.permission : false;
    // const [isIntercom, setIntercom] = useState(false);
    /* if (isIntercom == true) {
         document.getElementById("custom-intercom").style.display = "none"
     } else {
         document.getElementById("custom-intercom").style.display = "block"
     }*/
    console.log("userId===", userId);
    console.log("username===", username);
    const node = useRef();
    const handleClick = e => {
        if (node.current.contains(e.target)) {
            // inside click
            return;
        }
        // outside click
        setDialog(false);
    };



    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    useEffect(() => {
        setCounter(-1);
    }, [ppcId])


// const userId
    return (
        <div id={"custom-chat"} ref={node} style={{display:"none"}}>
            <div>
                {isDialog ? <div className={"chat-styles1"} style={{width: "5%", height: "auto"}}
                                 onClick={() => setDialog(!isDialog)}>
                        <motion.div
                            initial={{scale: 0}}
                            animate={{rotate: 180, scale: 1}}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20
                            }}
                        ><i className="fa fa-times"
                            aria-hidden="true"></i>
                        </motion.div>
                    </div>
                    :
                    (permission && pricingPlanId!=null && pricingPlanId!=7)?
                        <img className={"chat-styles"}
                             onClick={() => {
                                 setDialog(!isDialog)
                                 setCounter(counter + 1)
                             }}
                             src={chatbot}
                             id="chat-icon"
                             width={"5%"} height={"auto"}

                        />:""}
                {/*<i className="fa-comments  fa chat-styles" id="chat-icon" aria-hidden="true"
               onClick={() => setDialog(!isDialog)}></i>*/}
                {isDialog ? <div id="lxrChat" style={{display: isDialog ? "block" : "none"}}>
                    <iframe
                        class="chat-dialog"
                        allow="microphone;"
                        width={"85%"}
                        style={{ maxWidth: "360px" }}
                        height="430"
                        // src={`http://65.1.85.60:3000/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                        // src={`https://chatbot.netelixir.com/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                        // src={`http://localhost:3000/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                        // src={`https://testchatbot.netelixir.com/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                        //src={`https://chatbot.netelixir.com/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                        // src={`https://chatbot.netelixir.com/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                        // src={`https://testchatbot.netelixir.com/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                        // src={`http://localhost:3000/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                        // src={`https://testchatbot.netelixir.com/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                        src={`https://chatbot.netelixir.com/#/lxrg/${userId}/${clientId}/${ppcId}/${counter}`}>
                    </iframe>

                </div> : null}
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    userId: !_.isEmpty(state.userInformation.userInfo) ? state.userInformation.userInfo.userId : undefined,
    userInfo: !_.isEmpty(state.userInformation.userInfo) ? state.userInformation.userInfo : undefined,
    clientId: !_.isEmpty(state.userInformation.selectedClientInfo) ? state.userInformation.selectedClientInfo.clientId : undefined,
    ppcId: !_.isEmpty(state.userInformation.selectedAccountInfo) ? state.userInformation.selectedAccountInfo.ppcId : undefined,
    username: !_.isEmpty(state.userInformation.userInfo) ? state.userInformation.userInfo.username : undefined,
});
export default connect(mapStateToProps, null)(ChatBot);
