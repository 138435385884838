import * as types from "../actions/actionTypes";

export function fetchAllTicketsReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_ALL_TICKETS_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_ALL_TICKETS_REQUESTED",
        });
      case types.FETCH_ALL_TICKETS_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_ALL_TICKETS_RECEIVED",
        });
      case types.FETCH_ALL_TICKETS_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_ALL_TICKETS_FAILED",
        });
      default:
        return state;
    }
  }


  export function fetchTicketSummaryReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_TICKET_DETAILS_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_TICKET_DETAILS_REQUESTED",
        });
      case types.FETCH_TICKET_DETAILS_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_TICKET_DETAILS_RECEIVED",
        });
      case types.FETCH_TICKET_DETAILS_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_TICKET_DETAILS_FAILED",
        });
      default:
        return state;
    }
  }

  export function updateTicketSummaryReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.UPDATE_TICKET_DETAILS_REQUESTED:
        return Object.assign({}, state, {
          message: "UPDATE_TICKET_DETAILS_REQUESTED",
        });
      case types.UPDATE_TICKET_DETAILS_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "UPDATE_TICKET_DETAILS_RECEIVED",
        });
      case types.UPDATE_TICKET_DETAILS_FAILED:
        return Object.assign({}, state, {
          message: "UPDATE_TICKET_DETAILS_FAILED",
        });
      default:
        return state;
    }
  }