import {
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import icons from "../../components/icons";
import IntelliChatbot from "../NewHomeDashboard/IntelliChatbot";
import {
  TicketDate,
  TicketHeading,
  TicketSubHeading,
  CustomTextField,
  CommentTextField,
  CustomSnackbar
} from "./customTicketComponents";
import {
  fetchTicketSummary,
  updateTicketSummary,

} from "../../actions/ticketsActions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {ArrowBack } from "@mui/icons-material";
const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "25px 20px 50px 20px",
      backgroundColor: "#F9FAFB",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      backgroundColor: "#F9FAFB",
    },
  },
});

function TicketDetails(props) {
  const classes = props.classes;
  const {
    fetchTicketSummary,
    updateTicketSummary,
    fetchTicketSummaryReducer,
    fetchAllTicketsReducer,
    updateTicketSummaryReducer,
    userInformation,
  } = props;
  const [disabled, setDisabled] = useState(true);
  const [editTicket, setEditTicket] = useState(false);
  const [ticketSum, setTicketSum] = useState();
  const [clientName, setClientName] = useState("");
  const [clientId, setClientId] = useState(null);
  const [status, setStatus] = useState("Closed");
  const [loading, setLoading] = useState(false);
  const [createdDate , setCreatedDate] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const { id } = useParams();

  useEffect(() => {
    if (
      userInformation.clientId != null &&
      userInformation.clientName != null
    ) {
      setClientId(userInformation.clientId);
      setClientName(userInformation.clientName);
    }
  }, [userInformation]);

  const handleSnackbar=(error, message, open)=>{
    setError(error);
    setSnackbarMessage(message);
    setOpenSnackbar(open);
  }

  useEffect(async () => {
    setLoading(true);
    if (id != null && clientId != null) {
      const data = await fetchTicketSummary(clientId, id);
      console.log("data here", data);
      setTicketSum(data);
    }
    setLoading(false);
  }, [id, clientId]);

  useEffect(() => {
    if (fetchAllTicketsReducer.message === "FETCH_ALL_TICKETS_RECEIVED") {
      const ticketsArray = Object.values(fetchAllTicketsReducer).filter(
        (item) => item && typeof item === "object" && item.expertHelpRequest
      );

      const ticket = ticketsArray.find(
        (ticket) => ticket.expertHelpRequest.id === Number(id)
      );

      if (ticket) {
        setStatus(ticket.expertHelpRequest.requestStatus);
        setCreatedDate(ticket.expertHelpRequest.requestDate);
      }
    }
  }, [fetchAllTicketsReducer, id, ticketSum]);

  const updateComment = async (event) => {
    const newComment = {
      name: clientName,
      date: new Date().toLocaleString("en-CA", {
        hour12: false,
      }),
      comment: event.target.value,
    };
    const updatedComments = [...ticketSum.comments, newComment];
    const newTicketSum = { ...ticketSum, comments: updatedComments };
    setTicketSum(newTicketSum);
    const data = await updateTicketSummary(newTicketSum, newTicketSum.ticketId);
    if(data == undefined || data && data.responseStatus != 200){
      handleSnackbar(true, "Failed to add comment", true);
    }
  };

  const updateField = (event, field) => {
    setTicketSum({ ...ticketSum, [field]: event.target.value });
  };

  console.log("ticketSum", ticketSum);
  const getStatusColor = (status) => {
    switch (status) {
      case "Open":
        return "error";
      case "Closed":
        return "success";
      default:
        return "error";
    }
  };

  const handleSaveButtonClick = async () => {
    setDisabled((prevState) => !prevState);
     const data = await updateTicketSummary(ticketSum,ticketSum.ticketId);
     if(data == undefined || data && data.responseStatus != 200){
      handleSnackbar(true, "Error in updating ticket", true);
      }
  };
  const history = useHistory();
  const handleBackBtn = () => {
    history.goBack();
  };
  return (
    <div className={classes.mainDiv}>
    <Button variant="text" sx={{textTransform: "none", fontSize:"1rem", mb: 1}} onClick={()=>handleBackBtn()}><ArrowBack fontSize="small"/> &nbsp; Back</Button>

      {/* main container */}
      <Grid container spacing={2}>
        <Grid container item xs={12} direction={"column"} rowGap={2}>
          <Paper elevation={2} sx={{ padding: "2%" }}>
            <Grid
              item
              container
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item display={"flex"} alignItems={"center"} columnGap={2}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <TicketHeading>
                    ID: #{ticketSum ? ticketSum.ticketId : 0}
                  </TicketHeading>
                )}
                {loading ? (
                  <Skeleton />
                ) : (
                  <TicketDate>
                    {createdDate ? new Date(createdDate).toLocaleString("en-CA", {hour12: false})  : ""}
                  </TicketDate>
                )}
              </Grid>
              <Grid item display={"flex"} alignItems={"center"} columnGap={1}>
                {loading ? (
                  <Skeleton />
                ) : (
                  <>
                    {status !== "Closed" && (
                      <>
                        {disabled ? (
                          <EditOutlinedIcon
                            onClick={() => setDisabled(!disabled)}
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                          />
                        ) : (
                          <SaveOutlinedIcon
                            onClick={() => handleSaveButtonClick()}
                            fontSize="small"
                            sx={{ cursor: "pointer" }}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                {loading ? (
                  <Skeleton />
                ) : (
                  <Chip
                    label={status ? status : "Open"}
                    color={getStatusColor(status ? status : "Open")}
                    sx={{ fontSize: "0.875rem" }}
                  />
                )}
              </Grid>
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <TicketBodySection
              subject={ticketSum ? ticketSum.subject : "NA"}
              description={ticketSum ? ticketSum.description : "NA"}
              images={ticketSum ? ticketSum.images : "NA"}
              disabled={disabled}
              updateField={updateField}
              loading={loading}
            />
          </Paper>
          <Grid item></Grid>
          <Paper elevation={2} sx={{ padding: "1%" }}>
            <CommentTextField
              id="outlined-multiline-flexible"
              placeholder="Leave a comment"
              multiline
              maxRows={4}
              fullWidth
              hiddenLabel
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  updateComment(e);
                  e.target.value = "";
                  e.target.blur();
                }
              }}
            />
          </Paper>
          <Paper elevation={2} sx={{ padding: "2%" }}>
            <CommentsSection comments={ticketSum ? ticketSum.comments : []} />
          </Paper>
        </Grid>
      </Grid>
      <CustomSnackbar
        message={snackbarMessage}
        error={error}
        open = {openSnackbar}
        handleClose={handleClose}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  fetchTicketSummaryReducer: state.fetchTicketSummaryReducer,
  fetchAllTicketsReducer: state.fetchAllTicketsReducer,
  updateTicketSummaryReducer: state.updateTicketSummaryReducer,
  userInformation: state.userInformation.selectedClientInfo,
});
const mapDispatchToProps = (dispatch) => ({
  fetchTicketSummary: (clientId, ticketId) =>
    dispatch(fetchTicketSummary(clientId, ticketId)),
  updateTicketSummary: (summary, ticketId) => dispatch(updateTicketSummary(summary, ticketId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TicketDetails));

function CommentsSection(props) {
  const classes = props.classes;
  const { comments } = props;

  return (
    <>
      {comments.length > 0 ? (
        <>
          {comments.map((comment, index) => (
            <Grid key={index} container item direction="column">
              <Grid item display={"flex"} alignItems={"center"} columnGap={2}>
                <Typography fontSize={"1rem"} fontWeight={"500"}>
                  {comment.name}
                </Typography>
                <TicketDate>{comment.date}</TicketDate>
              </Grid>
              <Divider sx={{ mt: 1, mb: 1 }} />
              <Typography color={"#8A8A8A"}>
                {comment.comment} <br /> <br />
              </Typography>
            </Grid>
          ))}
        </>
      ) : (
        <Typography fontSize={"1rem"} fontWeight={"500"} color={"rgba(0, 0, 0, 0.38)"}>
          No comments
        </Typography>
      )}
    </>
  );
}


function TicketBodySection(props) {
  const {
    subject,
    description,
    images,
    disabled,
    updateField,
    loading,
  } = props;
  return (
    <Grid item container direction={"column"} rowGap={3}>
      <Grid item container direction={"column"} rowGap={1}>
        {loading ? <Skeleton /> : <TicketSubHeading>Subject:</TicketSubHeading>}
        {loading ? (
          <Skeleton />
        ) : (
          <CustomTextField
            hiddenLabel
            id="subject"
            multiline
            value={subject}
            fullWidth
            disabled={disabled}
            onChange={(e) => updateField(e,"subject")}
          />
        )}
      </Grid>

      <Grid item container direction={"column"} rowGap={1}>
        {loading ? (
          <Skeleton />
        ) : (
          <TicketSubHeading>Description:</TicketSubHeading>
        )}
        {loading ? (
          <Skeleton />
        ) : (
          <CustomTextField
            hiddenLabel
            id="description"
            multiline
            value={ description == null ? "NA" : description }
            fullWidth
            disabled={disabled}
            onChange={(e) => updateField(e,"description")}
          />
        )}
      </Grid>
    </Grid>
  );
}
