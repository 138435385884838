import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Snackbar,
  Alert,
} from "@mui/material";
import JumpstartMain from "../jumpstartMain.jsx";
import PageHeader from "../commonComponents/pageHeaderDetails.jsx";
import icons from "../../../components/icons.js";
import CampaignStructureSideBar from "./jsCampaignSideBar.jsx";
import CampaignStructureBreadcrumbs from "./campaignStructureBreadcrumb.jsx";
import Callouts from "./callouts.jsx";
import Sitelinks from "./sitelinks.jsx";
import DataTable from "./keywords.jsx";
import Keywords from "./keywords.jsx";
import MatchType from "./matchType.jsx";
import GenerateAdCopy from "./adcopyGeneration.jsx";
import {
  JsOrangeContainedBtn,
  JsTextAsButton,
} from "../../../components/buttons.jsx";
import { useParams } from "react-router-dom";
import {
  fetchCampaignDetails,
  fetchAdgroupStructure,
  submitCampaignStructure,
  campaignFrameworkDownload,
  fetchJsAccountById,
} from "../../../actions/jumpstartRevamped.js";
import { InfoOutlined } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { AccountStatus, Authority } from "../commonComponents/jumpstartEnum.js";
import { domainUrl } from "../../../actions/jumpstartDomain.js";
import { useHistory } from "react-router-dom";
import { sanitizeCompanyName } from "../commonComponents/companyNameCheck.js";
import KeywordsWithFilter from "./keywordsWithFilter.jsx";
import Cookies from "js-cookie";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CampaignStructureMain(props) {
  const {
    fetchCampaignDetails,
    fetchAdgroupStructure,
    fetchAdgroupStructureReducer,
    fetchCampaignDetailsReducer,
    submitCampaignStructure,
    submitCampaignStructureReducer,
    fetchJsAccountByIdReducer,
    fetchUserInfoReducer,
    campaignFrameworkDownload,
    fetchJsAccountById,
  } = props;
  const { id } = useParams();
  const [token, setToken] = useState(Cookies.get("js_jwtToken"));
  const history = useHistory();

  const [open, setOpen] = useState(true);
  const [sectionName, setSectionName] = useState("Callouts");
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [showMatchType, setShowMatchType] = useState(false);
  const [showAdCopy, setShowAdCopy] = useState(false);

  const [structureId, setStructureId] = useState();
  const [campaignId, setCampaignId] = useState();

  const [keywords, setKeywords] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const [
    selectedKeywordsForMatchType,
    setSelectedKeywordsForMatchType,
  ] = useState([]);
  const [businessName, setBusinessName] = useState();
  const [brandName, setBrandName] = useState();
  const [authority, setAuthority] = useState();
  const [currencySymbol, setCurrencySymbol] = useState();

  //Snackbar
  const [snackbarMsg, setSnackbarMsg] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
    setSnackbarOpen(false);
  };

  //Delete dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [disableStructure, setdisableStructure] = useState(false);
  const InReviewTooltipLabel = "Review is in progress"
  const CustomPendingItemTooltipLabel = () => (
    <div>
      You must complete all the pending items{" "}
      <CircleIcon sx={{ color: "#F8C79E", fontSize: "0.6rem" }} /> before
      submitting for review
    </div>
  );
  useEffect(() => {
    if (fetchUserInfoReducer.message == "JS_USER_INFO_RECEIVED") {
      setAuthority(fetchUserInfoReducer.authority);
    }
  }, [fetchUserInfoReducer]);

  useEffect(() => {
    fetchJsAccountById(token, id);
  }, []);

  useEffect(() => {
    if (
      fetchJsAccountByIdReducer.status != "" ||
      (fetchJsAccountByIdReducer.status != "JS_ACCOUNT_BY_ID_FAILED" &&
        fetchJsAccountByIdReducer.id == id)
    ) {
      setBusinessName(fetchJsAccountByIdReducer.companyName);
      setCurrencySymbol(fetchJsAccountByIdReducer.currencySymbol);
      if (
        fetchJsAccountByIdReducer.status ==
          AccountStatus.CAMPAIGN_STRUCTURE_IN_REVIEW &&
        (authority == Authority.ANALYST || authority == Authority.RWF)
      ) {
        setdisableStructure(true);
      }
    }
  }, [fetchJsAccountByIdReducer]);

  const fetchCampaignDetailsAPI = async () => {
    fetchCampaignDetails(token, id);
  };

  useEffect(() => {
    async function fetchData() {
      await fetchCampaignDetails(token, id);
      await fetchAdgroupStructure(token, id);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (
      fetchAdgroupStructureReducer.message ===
        "FETCH_ADGROUP_STRUCTURE_RECEIVED" &&
      fetchCampaignDetailsReducer.message === "FETCH_CAMPAIGN_DETAILS_RECEIVED"
    ) {
      const {
        status: completionStatus,
        message: campaignMessage,
        ...campaignData
      } = fetchCampaignDetailsReducer;
      const {
        status: adgroupStatus,
        message: adgroupMessage,
        ...adgroupData
      } = fetchAdgroupStructureReducer;

      const mergedData = Object.values(campaignData).filter((campaign) => campaign.jsAccountId == id).map((campaign) => {
        const adgroups = Object.values(adgroupData).filter(
          (adgroup) => adgroup.jsCampaignId == campaign.id
        );
        setDisableSubmit(campaign.completionStatus == true ? false : true);
        return {
          campaignId: campaign.id,
          campaignName: campaign.name,
          jsAccountId: campaign.jsAccountId,
          campaignStatus: campaign.completionStatus,
          adgroups: adgroups.map((adgroup) => ({
            adgroupId: adgroup.id,
            adgroupName: adgroup.name,
          })),
        };
      });
      setCampaignDetails(mergedData);
    }
  }, [fetchCampaignDetailsReducer, fetchAdgroupStructureReducer]);

  const [selected, setSelected] = useState();

  const handleSectionChange = (section, id, nextId) => {
    setSectionName("");
    if (section !== null) {
      setSectionName(section);
      setStructureId(id);
      if (section == "Callouts") {
        setSelected(businessName);
        setCampaignId(nextId);
      } else {
        setSelected(id);
      }
    }
  };

  const handleBtnClick = () => {
    setOpen(!open);
  };

  const handleSubmitForReview = async () => {
    const data = await submitCampaignStructure(token, id);
    if (data && data.status == 200) {
      setError(false);
      history.push(`/campaign-structure-completed/${id}`);
    } else {
      setError(true);
      setSnackbarMsg("Error in submitting campaign structure for review");
      setSnackbarOpen(true);
    }
  };
  const handleDownload = () => {
    const data = campaignFrameworkDownload(token, id, businessName);
  };

  return (
    <JumpstartMain>
      {/* Page header */}
      <div className="pageContent">
        <PageHeader sectionHeading={"Campaign Structure"} token={token} />
      </div>

      <div className="pageContainer">
        {/* Breadcrumbs section */}
        <Grid
          container
          direction="row"
          style={{ padding: "1%", borderBottom: "1px solid #E6E6E6" }}
        >
          <Grid item xs={8}>
            {/* <CampaignStructureBreadcrumbs /> */}
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Grid item>
              {/* <a href="`/download-account-structure/js-account/{id}"> */}
              <JsTextAsButton color="inherit" onClick={() => handleDownload()}>
                <span>{icons.ExcelDownload}</span>&nbsp;Download
              </JsTextAsButton>
              {/* </a> */}
            </Grid>
            <Divider orientation="vertical" flexItem />
            {/*  <Grid item>
              <JsTextAsButton color="inherit">
                <span>{icons.ExcelUpload}</span>&nbsp;Upload
              </JsTextAsButton>
            </Grid> */}
            <Grid item>
              <Tooltip title={disableSubmit && !disableStructure ? <CustomPendingItemTooltipLabel /> : ""}>
                <span>
                  <JsOrangeContainedBtn
                    disabled={disableSubmit || disableStructure}
                    style={{ fontSize: "0.875rem" }}
                    onClick={() => handleOpenDialog()}
                  >
                    {disableSubmit ? (
                      <InfoOutlined sx={{ fontSize: "0.875rem" }} />
                    ) : (
                      ""
                    )}
                    &nbsp;
                    {authority != Authority.ANALYST &&
                    authority != Authority.RWF
                      ? "Approve" : (authority == Authority.ANALYST ||
                      authority == Authority.RWF) && disableStructure ?  "Submitted For Review" : "Submit For Review"}
                  </JsOrangeContainedBtn>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        {/* Content Part */}
        <Grid container direction="row">
          <Grid
            item
            xs={3}
            style={{
              display: open ? "block" : "none",
              borderRight: "1px solid #E6E6E6",
              padding: "2% 0",
            }}
          >
            <CampaignStructureSideBar
              handleContentChange={handleSectionChange}
              campaignData={campaignDetails}
              selected={selected}
              businessName={businessName}
              sectionName={sectionName}
            />
            <div
              onClick={handleBtnClick}
              style={{ float: "right", cursor: "pointer" }}
            >
              {icons.JsCloseIcon}
            </div>
          </Grid>
          <Grid
            item
            xs={open ? 9 : 12}
            style={{
              display: "flex",
              alignItems: "center",
              borderLeft: "1px solid #E6E6E6",
              padding: "2% 0",
            }}
          >
            <div
              onClick={handleBtnClick}
              style={{
                display: open ? "none" : "block",
                float: "right",
                cursor: "pointer",
              }}
            >
              {icons.JsOpenIcon}
            </div>
            {sectionName === "Callouts" ? (
              <Callouts
                id={id}
                token={token}
                handleSectionChange={handleSectionChange}
                nextId={campaignId}
                disableStructure={disableStructure}
              />
            ) : sectionName === "Sitelinks" ? (
              <Sitelinks
                campaignDetails={campaignDetails}
                id={structureId}
                jsAccId={id}
                token={token}
                disableStructure={disableStructure}
              />
            ) : sectionName === "Keywords" ? (
              <KeywordsWithFilter
                id={structureId}
                token={token}
                handleKeywordsNextButton={handleSectionChange}
                newSelected={keywords}
                handleNewSelected={setKeywords}
                  setSelectedKeywordsForMatchType={
                  setSelectedKeywordsForMatchType
                }
                 disableStructure={disableStructure}
                 currencySymbol={currencySymbol}
              />
            ) : sectionName === "MatchType" ? (
              <MatchType
                id={structureId}
                token={token}
                handleButton={handleSectionChange}
                selectedKeywordsForMatchType={selectedKeywordsForMatchType}
                disableStructure={disableStructure}
                setSelectedKeywordsForMatchType={
                  setSelectedKeywordsForMatchType
                }
              />
            ) : sectionName === "AdCopy" ? (
              <GenerateAdCopy
                adgroupId={structureId}
                token={token}
                disableStructure={disableStructure}
                authority={authority}
                backId={structureId}
                handleButton={handleSectionChange}
                fetchCampaignDetailsAPI={fetchCampaignDetailsAPI}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={error ? "error" : "success"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMsg}
          </Alert>
        </Snackbar>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleCloseDialog()}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            {"Are you sure you want to submit the Campaign Structure?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => handleCloseDialog()}>Disagree</Button>
            <Button onClick={() => handleSubmitForReview()}>Agree</Button>
          </DialogActions>
        </Dialog>
      </div>
    </JumpstartMain>
  );
}

const mapStateToProps = (state) => ({
  fetchCampaignDetailsReducer: state.fetchCampaignDetailsReducer,
  fetchAdgroupStructureReducer: state.fetchAdgroupStructureReducer,
  submitCampaignStructureReducer: state.submitCampaignStructureReducer,
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
  fetchUserInfoReducer: state.fetchUserInfoReducer,
  campaignFrameworkDownloadReducer: state.campaignFrameworkDownloadReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCampaignDetails: (token, id) =>
    dispatch(fetchCampaignDetails(token, id)),
  fetchAdgroupStructure: (token, id) =>
    dispatch(fetchAdgroupStructure(token, id)),
  submitCampaignStructure: (token, id) =>
    dispatch(submitCampaignStructure(token, id)),
  campaignFrameworkDownload: (jwtToken, id, companyName) =>
    dispatch(campaignFrameworkDownload(jwtToken, id, companyName)),
  fetchJsAccountById: (jwtToken, id) =>
    dispatch(fetchJsAccountById(jwtToken, id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignStructureMain);
