import * as types from "../actions/actionTypes";

export function fetchAmazonAuthURLReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.AMAZON_AUTH_URL_REQUESTED:
        return Object.assign({}, state, {
          message: "AMAZON_AUTH_URL_REQUESTED",
        });
      case types.AMAZON_AUTH_URL_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "AMAZON_AUTH_URL_RECEIVED",
        });
      case types.AMAZON_AUTH_URL_FAILED:
        return Object.assign({}, state, {
          message: "AMAZON_AUTH_URL_FAILED",
        });
      default:
        return state;
    }
  }


  export function fetchAmazonProfilesReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.AMAZON_PROFILES_REQUESTED:
        return Object.assign({}, state, {
          message: "AMAZON_PROFILES_REQUESTED",
        });
      case types.AMAZON_PROFILES_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "AMAZON_PROFILES_RECEIVED",
        });
      case types.AMAZON_PROFILES_FAILED:
        return Object.assign({}, state, {
          message: "AMAZON_PROFILES_FAILED",
        });
      default:
        return state;
    }
  }


  export function SaveAmazonProfileReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.SAVE_AMAZON_PROFILES_REQUESTED:
        return Object.assign({}, state, {
          message: "SAVE_AMAZON_PROFILES_REQUESTED",
        });
      case types.SAVE_AMAZON_PROFILES_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "SAVE_AMAZON_PROFILES_RECEIVED",
        });
      case types.SAVE_AMAZON_PROFILES_FAILED:
        return Object.assign({}, state, {
          message: "SAVE_AMAZON_PROFILES_FAILED",
        });
      default:
        return state;
    }
  }

  export function startAmazonAccIntegrationReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.START_AMAZON_ACC_INTEGRATION_REQUESTED:
        return Object.assign({}, state, {
          message: "START_AMAZON_ACC_INTEGRATION_REQUESTED",
        });
      case types.START_AMAZON_ACC_INTEGRATION_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "START_AMAZON_ACC_INTEGRATION_RECEIVED",
        });
      case types.START_AMAZON_ACC_INTEGRATION_FAILED:
        return Object.assign({}, state, {
          message: "START_AMAZON_ACC_INTEGRATION_FAILED",
        });
      default:
        return state;
    }
  }
  export function fetchAmazonAccRevenuePerformanceDataReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_AMAZON_ACC_DATA_REV_PERF_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_AMAZON_ACC_DATA_REV_PERF_REQUESTED",
        });
      case types.FETCH_AMAZON_ACC_DATA_REV_PERF_RECEIVED:
        let { data } = action;
        
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_AMAZON_ACC_DATA_REV_PERF_RECEIVED",
        });
      case types.FETCH_AMAZON_ACC_DATA_REV_PERF_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_AMAZON_ACC_DATA_REV_PERF_FAILED",
        });
      default:
        return state;
    }
  }

  
  export function fetchAmazonDownloadStatusReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.AMAZON_ADS_DOWNLOAD_STATUS_REQUESTED:
        return Object.assign({}, state, {
          message: "AMAZON_ADS_DOWNLOAD_STATUS_REQUESTED",
        });
      case types.AMAZON_ADS_DOWNLOAD_STATUS_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "AMAZON_ADS_DOWNLOAD_STATUS_RECEIVED",
        });
      case types.AMAZON_ADS_DOWNLOAD_STATUS_FAILED:
        return Object.assign({}, state, {
          message: "AMAZON_ADS_DOWNLOAD_STATUS_FAILED",
        });
      default:
        return state;
    }
  }


  export function fetchAmazonSyncCheckIdReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_AMAZON_SYNC_CHECK_ID_REQUESTED:
        return Object.assign({}, state, {
          message: "FETCH_AMAZON_SYNC_CHECK_ID_REQUESTED",
        });
      case types.FETCH_AMAZON_SYNC_CHECK_ID_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_AMAZON_SYNC_CHECK_ID_RECEIVED",
        });
      case types.FETCH_AMAZON_SYNC_CHECK_ID_FAILED:
        return Object.assign({}, state, {
          message: "FETCH_AMAZON_SYNC_CHECK_ID_FAILED",
        });
      default:
        return state;
    }
  }