
import React, { useEffect, useRef, useState } from "react";
import ChatInput from "./ChatInput";
import "./chatbottest.css";
import styled from "styled-components";
import ChatbotInnerHeader from "./ChatbotInnerheader";
import ChatbotTabs from "./ChatbotTabs";
import ChatbotFooter from "./ChatbotFooter";

const MessageBubble = styled.div`
  background: ${({ isUser }) => (isUser ? "#F58120" : "#FFFFFF")};
  color: ${({ isUser }) => (isUser ? "#FFFFFF" : "#1E334E")};
  word-wrap: break-word;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
  max-width: 80%;
  align-self: ${({ isUser }) => (isUser ? "flex-end" : "flex-start")};
`;

const ChatConversionalAI = ({
  onClose,
  onSend,
  messages,
  chatWindowRef
}) => {
  const messagesEndRef = useRef(null);

  const [selectedTab, setSelectedTab] = useState("");

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const tabs = [
    "View Performance Insights",
    "Tasks",
    "Paid Search Trivia",
    "LXRGuide related FAQs",
    "LXRGuide Tour",
    // Add more tabs as needed
  ];

  const handleTabClick = (tab) => {
    // setSelectedTab(tab);
    onSend(tab); // Send the tab value immediately when clicked
  };

  return (
    <div
      className="chat-window"
      ref={chatWindowRef}
      style={{
        bottom: 160,
        right: 100,
        position: "fixed",
      }}
    >
      <ChatbotInnerHeader headerMessage={"Conversional AI"} onClose={onClose} />
      <div className="chat-body">
        <ChatbotTabs tabs={tabs} onTabClick={handleTabClick} />
        {messages.map((msg, index) => (
          <MessageBubble
            key={index}
            isUser={msg.isUser}
            className={`chat-message ${msg.sender}`}
          >
            {msg.text}
          </MessageBubble>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <ChatInput onSend={onSend} initialButtonValue={selectedTab} />
      {/* <ChatbotFooter onHomeIconClick={onHomeIconClick} /> */}
    </div>
  );
};

export default ChatConversionalAI;
