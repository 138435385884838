
import { Button, Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useState, useEffect } from "react";
import Paidsectionbg from "../../assets/img/paidsearchbg.png";
import Threeimages from "../../assets/img/3images.png";
import Homepageimage from "../../assets/img/Homepagefrontimage.png";
import {
  OrangeTextSize12,
  HeadLineTypography,
  BlueTitleTypography,
  ParagraphTypography,
  TaglineForHeadline,
} from "../../components/typography";
import { GraderOutLineButton, SkyBlueBtn } from "../../components/buttons.jsx";
import { ImportantDevices } from "@mui/icons-material";
import icons from "../../components/icons";
import { Link } from "react-router-dom";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  section1: {
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      padding: 10,
      paddingTop: 45,
      textTransform: "none",
      backgroundImage: `url(${Paidsectionbg})`,
      backgroundSize: "contain",
      backgroundPosition: "top center",
      backgroundColor: theme.palette.common.darkGreybg,
      // height: "610px",
    },
    [theme.breakpoints.up("xl")]: {
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.down("xl")]: {
      textAlign: "center",
      // paddingTop: 25,
      padding: 25,
      // padding: 55,
      textTransform: "none",
      // backgroundImage: `unset`,
      zoom:"90%",
      backgroundColor: theme.palette.common.darkGreybg,
      // height: "610px",
    },
  },
  paraLine: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    textAlign: "center",
    color: "#1E334E",
    lineHeight: "150%",
  },
  Buttondiv: {
    [theme.breakpoints.down("xl")]: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 30,
      paddingBottom: 30,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  Button2: {
    [theme.breakpoints.up("xs")]: {
      fontFamily: "Lato",
      backgroundColor: "#1098F7",
      color: "white",
      fontSize: "14px",
      textTransform: "none",
      fontStyle: "bold",
      marginTop: 10,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      fontFamily: "Lato",
      backgroundColor: "#1098F7",
      color: "white",
      fontSize: "14px",
      textTransform: "none",
      fontStyle: "bold",
      marginLeft: 10,
    },
  },
  upsGAdsMAdsImageDIV: {
    [theme.breakpoints.up("xs")]: {
      background: "#D8DBDF",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      background: "#D8DBDF",
      textAlign: "center",
      height: "90px",
      paddingLeft: 250,
      paddingRight: 250,
      alignItems: "center",
    },
    [theme.breakpoints.down("lg")]: {
      height: 120,
      padding: 10,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: 120,
      padding: 10,
    },
  },
  upsGAdsMAdsImage: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 10,
      paddingBottom: 10,
      width: "auto",
    },
  },
  proudpartnerof: {
    // background: 'linear-gradient(90.25deg, #F58120 0.05%, #FF7400 100%)',
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
      color: "#1E334E",
      fontSize: 10,
      fontWeight: 700,
      padding: "0 !important",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      color: "#1E334E",
      fontSize: 20,
      fontWeight: 700,
      padding: "0 !important",
    },
  },
  paidText: {
    margin: "0px",
    fontsize: 20,
    fontWeight: 700,
    [theme.breakpoints.down("lg")]: {
      fontsize: 14,
    },
  },
  heading: {
    color: "textPrimary",
    fontSize: "1.25rem",
    fontWeight: "700",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});

function PaidSearchSection(props) {
  const { classes, width } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Grid container className={classes.section1}>
        <Grid item xs={12} md={12}>
        
          {screenWidth <= 599
                ? icons.HomePageNewHeroLogo("100%")
                : icons.HomePageNewHeroLogo("auto")}
          <br />
          <OrangeTextSize12>
          STRATEGIC DASHBOARD, AI-DRIVEN INTELLICHAT, SEARCH MARKETING TOOLS
          </OrangeTextSize12>
       
          <Typography className={classes.heading}>
          STRATEGIC MARKETING PLATFORM FOR SMALL BUSINESSES <br />
          TO DRIVE ONLINE SALES

          </Typography>
        
          <TaglineForHeadline style={{paddingBottom:"2%"}}>
          View All Ecommerce Metrics in One Dashboard.  <br />{" "}
          Make Smarter Decisions in Minutes.

          </TaglineForHeadline>

          {/* <div className={classes.Buttondiv}>
            <SkyBlueBtn
              component={Link}
              to={"/google-ads-grader"}
              fullWidth={width == "sm" || width == "xs" ? true : false}
            >
              Grade my Google Ads Account for Free
            </SkyBlueBtn>
          </div> */}
        </Grid>
      </Grid>
      <div>
        <Grid
          container
          spacing={2}
          className={classes.upsGAdsMAdsImageDIV}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            className={classes.proudpartnerof}
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
          >
            <Typography className={classes.paidText} align={"center"}>
              PROUD PARTNER OF
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
            <a
              href="https://www.google.com/partners/agency?id=1430663498"
              target="_blank"
            >
              {screenWidth <= 599
                ? icons.googleSig("100%")
                : icons.googleSig("auto")}
            </a>
          </Grid>
          <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
            <a
              href="https://learninglab.about.ads.microsoft.com/certification/membership-directory/"
              target="_blank"
            >
              {screenWidth <= 599
                ? icons.microsoftNewlogoft("100%")
                : icons.microsoftNewlogoft("147px")}
            </a>
          </Grid>
          <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
            <a href="https://www.netelixir.com/ups/" target="_blank">
              {screenWidth <= 599
                ? icons.upsWithBlackBorder("100%")
                : icons.upsWithBlackBorder("auto")}
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(PaidSearchSection)
);
