import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { store } from "../../reducers/common";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  deleteBingAdsConnection,
  deleteGoogleAdsConnection,
} from "../../actions/connectionPageActions";

import {
  getAuthUrls,
  accessStatus,
  updateRefreshAccessSuccess,
} from "../../actions/fetchAccountSettingsData";

import MySnackbarWrapper from "../snackbars";
import { CircularProgress } from "@mui/material/CircularProgress";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectCancelBlueBtn,
} from "../../components/buttons";
import {ConnectionTypeTypography}  from "../../components/typography";
import SnackbarWrapperConnections from "../snackbars";
import icons from "../../components/icons";
const styles = (theme) => ({
  GaGrid: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "88px",
      border: "solid 2px #BBBBBB",
      borderRadius: "6px",
      padding: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
      width: "100%",
      height: "300px",
      border: "solid 1px #BBBBBB",
      borderRadius: "3px",
      textAlign: "center",
    },
  },
  TextCenter: {
    textAlign: "Center",
    fontSize: "16px",
  },

  gridItems: {
    margin: "Auto 0",
  },
  buttons: {
    backgroundColor: "#F58120",
    border: "solid 1px #1E334E",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  dataStyle: {
    fontWeight: "500",
    color: "#1E334E",
    wordBreak: "break-word",
  },
  verticalLine: {
    paddingRight: "7px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});
const PopUpOrangeBtn = withStyles(theme => ({
  root: {
      fontWeight: "bold",
      color: theme.palette.orangeColor
  }

}))(Button);
export const PopUpCommonBtn = withStyles(theme => ({
  root: {
      fontWeight: "400",
      color: theme.palette.text.primary,
  }

}))(Button);
const DialogHead = withStyles(theme => ({
  root: {
      fontWeight: "900",
      color: theme.palette.text.primary,
      fontFamily: "Lato",
  }
}))(DialogTitle);
const DialogText = withStyles(theme => ({
  root: {
      fontWeight: "lighter",
      fontSize: 16,
      color: theme.palette.text.primary,
      fontFamily: "Lato"
  }
}))(DialogContentText);
const CustomFormControlLabel = withStyles(theme => ({
  label: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: "bold",
      fontFamily: "Lato"
  }
}))(FormControlLabel);
function GleBingConnection(props) {
  window.scrollTo(0, 0);
  const classes = props.classes;
  const {
    accountSettingsData,
    getAuthUrls,
    deleteGleAdsAccountReducer,
    deleteBingAdsAccountReducer,
    deleteGoogleAdsConnection,
    deleteBingAdsConnection,
    userDetails,
    googleAdsData,
    bingAdsData,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    getAuthUrls();
  }, []);

  //Google Ads
  const [googleAccountName, setGoogleAccountName] = useState("");
  const [googleAccountId, setGoogleAccountId] = useState("");
  const [ppcId, setPpcId] = useState("");

  //Bing Ads
  const [bingAccountName, setBingAccountName] = useState("");
  const [bingAccountId, setBingAccountId] = useState("");
  const [bingPpcId, setBingPpcId] = useState("");

  //Delete confirmation dialog box
  const [openGle, setOpenGle] = React.useState(false);
  const [openBing, setOpenBing] = React.useState(false);

  const [message, setMessage] = React.useState("");

  const googleAuthUrls = accountSettingsData.googleAuthUrl;
  const bingAuthUrl = accountSettingsData.bingAuthUrl;
  const { accessSuccess, errorMsg } = accountSettingsData;

  //Refresh dialog box alert
  const [alert, setAlert] = useState(false);
  const [variantType, setVariantType] = useState("info");

  //Snackbar alerts
  const [alertBing, setAlertBing] = useState(false);
  const [alertGle, setAlertGle] = useState(false);
  const [adsDeleteError, setAdsDeleteError] = useState(false);

  //Pricing plan alert
  const [adsType,setAdsType] = useState("");
  const [bingDialog , setBingDialog] = useState(false);
  const [bingWarningChek, setBingWarningChek] = useState(false);

  useEffect(() => {
    if (googleAdsData) {
      //Google Ads
      setGoogleAccountId(
        googleAdsData.seAccountId ? googleAdsData.seAccountId : "-"
      );

      setGoogleAccountName(
        googleAdsData.clientName ? googleAdsData.clientName : "-"
      );

      setPpcId(googleAdsData.ppcId ? googleAdsData.ppcId : "-");
    }
  }, [googleAdsData]);

  useEffect(() => {
    //Bing Ads
    if (bingAdsData) {
      setBingAccountId(bingAdsData.seAccountId ? bingAdsData.seAccountId : "-");

      setBingAccountName(bingAdsData.clientName ? bingAdsData.clientName : "-");

      setBingPpcId(bingAdsData.ppcId ? bingAdsData.ppcId : "-");
    }
  }, [bingAdsData]);

  const handleDialogToOpenGle = () => {
    setOpenGle(true);
  };
  const handleDialogToOpenBing = () => {
    setOpenBing(true);
  };
  const handleDialogToCloseGle = () => {
    setOpenGle(false);
  };
  const handleDialogToCloseBing = () => {
    setOpenBing(false);
  };
  const handleOpenBingDialog = () => {
    if(googleAccountId === "" || googleAccountId === "-"){
      window.location.href="/edit-settings.html"
    } else{
      console.log("handleOpenBingDialog")
      setAdsType("Bing");
        window.location.href="/edit-settings.html"
    // setBingDialog(true);
    }
  }
  const handleOpenGoogleDialog = () => {
    if(bingAccountId === "" || bingAccountId === "-"){
      window.location.href="/edit-settings.html"
    } else{
      setAdsType("Google");
      setBingDialog(true);
    }
  }
  const handleCloseBingDialog = () => {
    setBingDialog(false);
  }
  const handleCloseAlert = async () => {
    await setAlert(false);
    setMessage("");
    props.updateRefreshAccessSuccess();
  };
  const handleCloseAlertAds = async () => {
    setAlertBing(false);
    setAlertGle(false);
  };

  window.refreshAfterSuccess = () => {
    console.log("Google access");
    store.dispatch(accessStatus(1));
  };

  window.refreshAfterBingSuccess = () => {
    console.log("Bing access");
    store.dispatch(accessStatus(2));
  };

  const [deleteAdsAccountId, setDeleteAdsAccountId] = useState("");

  //Delete function for google ads
  const handleGleDeleteConnection = async (accountId, ppcId) => {
    setDeleteAdsAccountId(accountId);
    setOpenGle(false);
    const data = await deleteGoogleAdsConnection(ppcId);
    if (data === true) {
      setAdsDeleteError(false);
    } else {
      setAdsDeleteError(true);
    }
    setAlertGle(true);
  };

  //Delete function for bing ads
  const handleBingDeleteConnection = async (accountId, ppcId) => {
    setDeleteAdsAccountId(accountId);
    setOpenBing(false);
    const data = await deleteBingAdsConnection(ppcId);
    if (data === true) {
      setAdsDeleteError(false);
    } else {
      setAdsDeleteError(true);
    }
    setAlertBing(true);
  };

  //Google Ads & Bing Ads
  useEffect(() => {
    if (deleteGleAdsAccountReducer.message === "DELETE_GLE_ADS_RECEIVED") {
      console.log(
        "deleteGleAdsAccountReducer ",
        deleteGleAdsAccountReducer.message
      );
      if (deleteAdsAccountId === googleAccountId) {
        setGoogleAccountId("");
        setGoogleAccountName("");
      }
    }
  }, [deleteGleAdsAccountReducer]);

  useEffect(() => {
    if (deleteBingAdsAccountReducer.message === "DELETE_BING_ADS_RECEIVED") {
      console.log(
        "deleteBingAdsAccountReducer ",
        deleteBingAdsAccountReducer.message,
        deleteAdsAccountId,
        bingAccountId
      );
      if (deleteAdsAccountId === bingAccountId) {
        setBingAccountId("");
        setBingAccountName("");
      }
    }
  }, [deleteBingAdsAccountReducer]);
console.log("testing",googleAccountId, typeof(googleAccountId));
  return (
    <div style={{ marginTop: "20px" }}>
      {/* Below lines of code is for the Google Ads */}
      <Grid container className={classes.GaGrid} >
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
          <div className={classes.flexCenter}>
            {icons.GoogleAds}
            <ConnectionTypeTypography>Google Ads</ConnectionTypeTypography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
          {googleAccountName === "" || googleAccountName === "-" ? (
            <Typography></Typography>
          ) : (
            <div className={classes.flexCenter}>
              <div className={classes.verticalLine}>
                {icons.SegregationLine}
              </div>
              <Typography style={{ color: "#747474" }}>
                Account Name:
                <br />
                <span className={classes.dataStyle}>{googleAccountName}</span>
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {googleAccountId === "" || googleAccountId === "-" ? (
            <Typography></Typography>
          ) : (
            <div className={classes.flexCenter}>
              <div className={classes.verticalLine}>
                {icons.SegregationLine}
              </div>
              <Typography style={{ color: "#747474" }}>
                {" "}
                Account Id:
                <br />
                <span className={classes.dataStyle}>{googleAccountId}</span>
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {googleAdsData !== undefined &&
          (deleteGleAdsAccountReducer["message"] === "" ||
            deleteGleAdsAccountReducer["message"] ===
              "DELETE_GLE_ADS_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn
                onClick={() =>
                  window.open(
                    googleAuthUrls,
                    "authorizationUrl",
                    "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no"
                  )
                }
              >
                Refresh Token
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography></Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {googleAdsData !== undefined &&
          (deleteGleAdsAccountReducer["message"] === "" ||
            deleteGleAdsAccountReducer["message"] ===
              "DELETE_GLE_ADS_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn
                onClick={handleDialogToOpenGle}
                disabled
              >
                Delete Account
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography>
              {/* <a href="/edit-settings.html" name="UPDATE1" id="update1"> */}
                <NewOrangeContainedBtn
                  // onClick={() => (window.location.href = "/edit-settings.html")}
                  onClick={handleOpenGoogleDialog}
                  type={"button"}
                >
                  +Add Account
                </NewOrangeContainedBtn>
              {/* </a> */}
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Below lines of code is for the Bing Ads */}
      <Grid container className={classes.GaGrid} style={{ marginTop: "20px" }}>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
          <div className={classes.flexCenter}>
            {icons.BingAds}
            <ConnectionTypeTypography>Microsoft(Bing) Ads</ConnectionTypeTypography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
          {bingAccountName === "" || bingAccountName === "-" ? (
            <Typography></Typography>
          ) : (
            <div className={classes.flexCenter}>
              <div className={classes.verticalLine}>
                {icons.SegregationLine}
              </div>
              <Typography style={{ color: "#747474" }}>
                Account Name:
                <br />{" "}
                <span className={classes.dataStyle}>{bingAccountName}</span>
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {bingAccountId === "" || bingAccountId === "-" ? (
            <Typography></Typography>
          ) : (
            <div className={classes.flexCenter}>
              <div className={classes.verticalLine}>
                {icons.SegregationLine}
              </div>
              <Typography style={{ color: "#747474" }}>
                {" "}
                Account Id:
                <br />
                <span className={classes.dataStyle}>{bingAccountId}</span>
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {bingAdsData !== undefined &&
          (deleteBingAdsAccountReducer["message"] === "" ||
            deleteBingAdsAccountReducer["message"] ===
              "DELETE_BING_ADS_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn
                onClick={() =>
                  window.open(
                    bingAuthUrl,
                    "authorizationUrl",
                    "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no"
                  )
                }
              >
                Refresh Token
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography></Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {bingAdsData !== undefined &&
          (deleteBingAdsAccountReducer["message"] === "" ||
            deleteBingAdsAccountReducer["message"] ===
              "DELETE_BING_ADS_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn
                onClick={handleDialogToOpenBing}
                disabled
              >
                Delete Account
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography>
                <NewOrangeContainedBtn
                  onClick={handleOpenBingDialog}
                  type={"button"}
                >
                  +Add Account
                </NewOrangeContainedBtn>
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* Google Ads Delete Dialog Box */}
      <Dialog open={openGle} onClose={handleDialogToCloseGle}>
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete Google Ads integration in LXRGuide?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleGleDeleteConnection(googleAccountId, ppcId)}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleDialogToCloseGle} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* Bing Ads Delete Dialog Box */}
      <Dialog open={openBing} onClose={handleDialogToCloseBing}>
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete Bing Ads integration in LXRGuide?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleBingDeleteConnection(bingAccountId, bingPpcId)}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleDialogToCloseBing} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <MySnackbarWrapper
        open={alert || accessSuccess || (errorMsg != null && errorMsg != "")}
        onClose={handleCloseAlert}
        message={
          errorMsg != null && errorMsg != ""
            ? "Error in updating refresh tokens"
            : accessSuccess
            ? "Refresh tokens updated"
            : message
        }
        verticalAlign={"bottom"}
        horizontalAlign={"right"}
        duration={6000}
        variant={
          errorMsg != null && errorMsg != ""
            ? "error"
            : accessSuccess
            ? "success"
            : variantType
        }
      />
      <MySnackbarWrapper
        open={alertGle}
        onClose={handleCloseAlertAds}
        message={
          adsDeleteError
            ? "Unable to delete Google Ads account"
            : "Google Ads account deleted successfully"
        }
        verticalAlign={"bottom"}
        horizontalAlign={"right"}
        duration={6000}
        variant={adsDeleteError ? "error" : "success"}
      />
      <MySnackbarWrapper
        open={alertBing}
        onClose={handleCloseAlertAds}
        message={
          adsDeleteError
            ? "Unable to delete Bing Ads account"
            : "Bing Ads account deleted successfully"
        }
        verticalAlign={"bottom"}
        horizontalAlign={"right"}
        duration={6000}
        variant={adsDeleteError ? "error" : "success"}
      />
      <Dialog
        open={bingDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleCloseBingDialog}
      >
        <DialogHead>{"ADD ACCOUNT"}</DialogHead>
        <DialogContent>
          <DialogText id="alert-dialog-description">
            <b>
              LXRGuide will optimize your {adsType == "" ? "Bing" : adsType} Ads account for free for the rest
              of the billing cycle, then you'll be charged $950 every month for
              both Google Ads and Bing Ads until you cancel.
            </b>
          </DialogText>
          <CustomFormControlLabel
            control={
              <Checkbox
                checked={bingWarningChek}
                onChange={(selected) =>
                  setBingWarningChek(!bingWarningChek)
                }
              />
            }
            label={"Accept the Pricing Plan Change"}
          />
        </DialogContent>
        <DialogActions>
          <PopUpCommonBtn onClick={handleCloseBingDialog}>
            Disagree
          </PopUpCommonBtn>
          <PopUpOrangeBtn
            onClick={() => (window.location.href = "/edit-settings.html")}
            disabled={!bingWarningChek}
            autoFocus
          >
            Continue
          </PopUpOrangeBtn>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accountSettingsData: state.accountSettings,
  deleteGleAdsAccountReducer: state.deleteGleAdsAccountReducer,
  deleteBingAdsAccountReducer: state.deleteBingAdsAccountReducer,
  userDetails: state.userInformation,
});
const mapDispatchToProps = (dispatch) => ({
  deleteGoogleAdsConnection: (ppcId) =>
    dispatch(deleteGoogleAdsConnection(ppcId)),
  deleteBingAdsConnection: (ppcId) => dispatch(deleteBingAdsConnection(ppcId)),
  getAuthUrls: () => dispatch(getAuthUrls()),
  updateRefreshAccessSuccess: () => dispatch(updateRefreshAccessSuccess()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(GleBingConnection));
