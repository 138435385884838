
import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Typography, Grid, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { CustomColorButton } from "../../components/buttons";
import icons from "../../components/icons";
import { connect } from "react-redux";
import {
  renderList,
  renderTextWithBoldAndNewlines,
} from "../commonFunctions/commonFunctions";
import "./NewDashBoard.css";
import { fetchIntelliChatBotNormalResponse } from "../../actions/intelliChatbot.js";

const IntelliChatbot = (props) => {
  const {
    userInformation,
    fetchIntelliChatBotResponseReducer,
    fetchIntelliChatBotNormalResponse,
    fetchIntelliChatBotNormalResponseReducer,
    previous_date_range,
    current_date_range,
  } = props;

  const [message, setMessage] = useState("");
  const [user_id, setUser_id] = useState("");
  const [clientId, setClientId] = useState(
    userInformation.selectedClientInfo?.clientId || null
  );
  const [clientName, setClientName] = useState(
    userInformation.selectedClientInfo?.clientName || null
  );
  const [ppc_id, setPpc_id] = useState("");
  const [intelliChatData, setIntelliChatData] = useState({
    currentRevenue: null,
    previousRevenue: null,
    greenFlags: null,
    redFlags: null,
    recommendations: null,
  });
  const [percentageChange, setPercentageChange] = useState();
  const [messages, setMessages] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [flag, setFlag] = useState(false);
  const snackbarTimeout = useRef(null);
  const messageContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true); // Loader state

  // Restore messages from localStorage on mount
  useEffect(() => {
    const storedMessages = JSON.parse(localStorage.getItem("chatHistory")) || [];
    if (storedMessages.length > 0) {
      setMessages(storedMessages);
      console.log("Loaded chat history:", storedMessages);
    } else {
      console.log("No chat history found in localStorage.");
    }
  }, []);
  // Save messages to localStorage when updated
  useEffect(() => {
    if (messages.length > 0) {
      console.log("Saving chat history:", messages);
      localStorage.setItem("chatHistory", JSON.stringify(messages));
    }
  }, [messages]);

  useEffect(() => {
    if (userInformation.selectedClientInfo) {
      setClientId(userInformation.selectedClientInfo.clientId);
      setClientName(userInformation.selectedClientInfo.clientName);
      setUser_id(userInformation.userInfo.userId);
      setPpc_id(userInformation.selectedAccountInfo.ppcId);
    }
  }, [userInformation]);

  useEffect(() => {
    if (
      fetchIntelliChatBotResponseReducer.message ===
      "INTELLI_CHATBOT_RESPONSE_RECEIVED"
    ) {

      const data = fetchIntelliChatBotResponseReducer[0];
      setIntelliChatData({
        currentRevenue: data?.custom?.current_revenue ?? null,
        previousRevenue: data?.custom?.previous_revenue ?? null,
        greenFlags: data?.custom?.top_green_flags ?? null,
        redFlags: data?.custom?.top_red_flags ?? null,
        recommendations: data?.custom?.recommended_actions ?? null,
      });
      setPercentageChange(
        data?.custom?.previous_revenue !== 0
          ? ((data?.custom?.current_revenue - data?.custom?.previous_revenue) /
            data?.custom?.previous_revenue) *
          100
          : 0
      );
      scrollToBottom();
      setIsLoading(false)
    }
  }, [fetchIntelliChatBotResponseReducer]);

  useEffect(() => {
    if (
      fetchIntelliChatBotNormalResponseReducer.message ===
      "INTELLI_CHATBOT_NORMAL_RESPONSE_RECEIVED" && flag === true
    ) {
      debugger;
      console.log("Reducer Data:", fetchIntelliChatBotNormalResponseReducer);

      // Remove typing indicator
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => !msg.isTyping)
      );

      // Append bot messages
      const formattedMessages =
        (fetchIntelliChatBotNormalResponseReducer.data || []).map((message) => ({
          text: message.text,
          sender: "bot",
        }));

      setMessages((prevMessages) => [...prevMessages, ...formattedMessages]);
      scrollToBottom();
      setFlag(false)
    }
  }, [fetchIntelliChatBotNormalResponseReducer]);
  const handleSend = () => {
    if (!message.trim()) {
      setShowSnackbar(true);
      if (snackbarTimeout.current) clearTimeout(snackbarTimeout.current);
      snackbarTimeout.current = setTimeout(() => setShowSnackbar(false), 3000);
      return;
    }
    setFlag(true)

    // Add user message and typing indicator to chat

    // Add user message and typing indicator to chat
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, sender: "user" },
      { text: "Typing...", isTyping: true },
    ]);
    // Call the chatbot API
    fetchIntelliChatBotNormalResponse(user_id, message, ppc_id, clientId).catch(
      (error) => {
        console.error("Error sending message to chatbot:", error);
      }
    );
    // Clear input field
    setMessage("");
    scrollToBottom();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    return () => {
      if (snackbarTimeout.current) clearTimeout(snackbarTimeout.current);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: 3,
        height: "calc(100vh - 7%)",
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        width: "auto",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          backgroundColor: "#1E334E",
          textAlign: "center",
          color: "white",
        }}
      >
        LXRGuide IntelliChat &nbsp; <sup className="beta">beta</sup>
        {/* <sup> <BetaButton>&nbsp; beta &nbsp;</BetaButton></sup> */}
      </Typography>
      <Box sx={{ padding: "10px" }}>
        <Link to="/tickets"   style={{ textDecoration: "none" }}>
          {/* <SignUpButton sx={{ width: "100%" }}>
            {icons.Tickets} &nbsp; View All Raised Tickets
          </SignUpButton> */}
          <CustomColorButton
            color="white"
            text="View All Raised Tickets"
            backgroundColor="#F58120"
            textTransform="capitalize"
            boxShadow={2}
            width="100%"
            id="view-ticket"
            textAlign="center"
            fontSize="1rem"
            fontWeight="Bold"
            padding="1%"
          />
        </Link>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: "10px",
          scrollBehavior: "smooth",
        }}
        ref={messageContainerRef}
      >
        <Typography className="why-text">
          What <span style={{ color: "#8E8E8E" }}>happened :</span>
        </Typography>
        <div className="revenue-section">
          <Grid container row>
            <Grid item xs={6}>
              <Typography className="revenue-chatbot">
                ${" "}
                {intelliChatData.currentRevenue != null
                  ? intelliChatData.currentRevenue.toLocaleString("en-US")
                  : "-" || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <span
                className="percentagechange"
                style={{
                  color: percentageChange < 0 ? "red" : "green",
                }}
              >
                {percentageChange
                  ? Math.round(Math.abs(percentageChange))
                  : " - "}{" "}
                % {percentageChange < 0 ? "↓" : "↑"}
              </span>
            </Grid>
          </Grid>
          <Typography className="previous-revenue-chatbot">
            ${" "}
            {intelliChatData.previousRevenue != null
              ? intelliChatData.previousRevenue.toLocaleString("en-US")
              : "-" || "-"}
          </Typography>
          <hr className="hr-intellichatbot" />
          <Typography variant="body2" className="intelli-chat-blacktext">
            {`For the selected period: ${current_date_range}, compared to the period: ${previous_date_range}`}
          </Typography>
        </div>
        <Typography className="why-text">
          Why <span style={{ color: "#8E8E8E" }}>it happened:</span>
        </Typography>
        {/* {isLoading ? ( // Display loader while fetching data
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : ( */}
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress />
          </Box>) : (
          <Box
            sx={{
              marginTop: "10px",
              backgroundColor: "#F9FFFA",
              border: `2px solid #D9FFDF`,
            }}
          >
            {/* <Typography className="header-subsection">
            We believe the top reasons contributing to the revenue are:
          </Typography> */}
            {renderList(
              intelliChatData.greenFlags,
              "#F9FFFA",
              "#D9FFDF",
              "#15AF2D"
            )}
          </Box>
        )
        }
        {/* {intelliChatData.greenFlags !== undefined ? (
          <Box
            sx={{
              marginTop: "10px",
              backgroundColor: "#F9FFFA",
              border: `2px solid #D9FFDF`,
            }}
          >
            <Typography className="header-subsection">
              We believe the top reasons contributing to the revenue are:
            </Typography>
            {renderList(
              intelliChatData.greenFlags,
              "#F9FFFA",
              "#D9FFDF",
              "#15AF2D"
            )}
          </Box>
        ) : (
          "No data"
        )} */}
        <Typography className="why-text">
          What <span style={{ color: "#8E8E8E" }}>were the Red flags:</span>
        </Typography>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress />
          </Box>) :
          (
            <Box
              sx={{
                marginTop: "10px",
                backgroundColor: "#FFFBF9",
                border: `2px solid #FFD4BE`,
              }}
            >
              <Typography className="header-subsection">
                {/* The top red flags or warning signals are: */}
              </Typography>
              {renderList(
                intelliChatData.redFlags,
                "#FFFBF9",
                "#FFD4BE",
                "#CF9070"
              )}
            </Box>
          )
        }


        {/* {intelliChatData.redFlags !== undefined ? (
          <Box
            sx={{
              marginTop: "10px",
              backgroundColor: "#FFFBF9",
              border: `2px solid #FFD4BE`,
            }}
          >
            <Typography className="header-subsection">
              The top red flags or warning signals are:
            </Typography>
            {renderList(
              intelliChatData.redFlags,
              "#FFFBF9",
              "#FFD4BE",
              "#CF9070"
            )}
          </Box>
        ) : (
          "No data"
        )} */}
        <Typography className="why-text">Recommendations</Typography>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <CircularProgress /> </Box>) : (
          <Box
            sx={{
              marginTop: "10px",
              backgroundColor: "#FFFEF1",
              border: "2px solid #FFFABE",
            }}
          >
            {/* <Typography className="header-subsection">
               Top recommended as per your data:
             </Typography> */}
            {renderList(
              intelliChatData.recommendations,
              "#FFFEF1",
              "#FFFABE",
              "#FACC15"
            )}
          </Box>
        )}

        {/* {intelliChatData.recommendations !== undefined ? (
          <Box
            sx={{
              marginTop: "10px",
              backgroundColor: "#FFFEF1",
              border: "2px solid #FFFABE",
            }}
          >
            <Typography className="header-subsection">
              Top recommended as per your data:
            </Typography>
            {renderList(
              intelliChatData.recommendations,
              "#FFFEF1",
              "#FFFABE",
              "#FACC15"
            )}
          </Box>
        ) : (
          "No data"
        )} */}
        {messages.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: msg.sender === "user" ? "flex-end" : "flex-start",
              mt: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                backgroundColor: msg.sender === "user" ? "#1E334E" : "#F2F2F2",
                padding: "8px",
                borderRadius: "8px",
                maxWidth: "70%",
                color: msg.sender === "user" ? "white" : "#838383",
              }}
            >
              {renderTextWithBoldAndNewlines(msg.text)}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #ddd",
          p: 2,
        }}
      >
        <input
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message here..."
          style={{
            width: "100%",
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
          }}
          onKeyDown={handleKeyPress}
        />
        <Button
          variant="contained"
          onClick={handleSend}
          color="primary"
          sx={{ ml: 1 }}
          disabled={message.trim() === ""}
        >
          Send
        </Button>
        {showSnackbar && (
          <div
            style={{
              position: "fixed",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#323232",
              color: "#fff",
              padding: "10px 20px",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
          >
            Oops! You forgot to type a message.
          </div>
        )}
      </Box>
    </Box>
  );
};

// Redux connection
const mapStateToProps = (state) => ({
  fetchIntelliChatBotResponseReducer: state.fetchIntelliChatBotResponseReducer,
  fetchIntelliChatBotNormalResponseReducer:
    state.fetchIntelliChatBotNormalResponseReducer,
  userInformation: state.userInformation,
});

const mapDispatchToProps = (dispatch) => ({
  fetchIntelliChatBotResponse: (
    user_id,
    message,
    ppc_id,
    clientId,
    current_date_range,
    previous_date_range
  ) =>
    dispatch({
      type: "FETCH_INTELLI_CHATBOT_RESPONSE",
      payload: {
        user_id,
        message,
        ppc_id,
        clientId,
        current_date_range,
        previous_date_range,
      },
    }),
  fetchIntelliChatBotNormalResponse: (user_id, message, ppc_id, clientId) =>
    dispatch(
      fetchIntelliChatBotNormalResponse(user_id, message, ppc_id, clientId)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntelliChatbot);
