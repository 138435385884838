
import { React, useState } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";
import { K_Formatter } from "../commonFunctions/commonFunctions";
import "../NewHomeDashboard/NewDashBoard.css";
import moment from "moment";
import { Typography } from "@mui/material";
const CustomAreaChart = ({
  color,
  compareColor,
  compare,
  data = [],
  pastData = [],
  areaColor,
}) => {
  console.log("pastData==>",pastData)
  const [valueType, setValueType] = useState();

  let currentKey = "";
  let pastKey = "";

  let dollor = ['transactionRevenue', 'revenue']

  //sorting the data before merging it together
  const sortedData = data.sort((a, b) => new Date(a.fetchDate || a.date) - new Date(b.fetchDate || b.date));
  const sortedPastData = pastData.sort((a, b) => new Date(a.fetchDate || a.date) - new Date(b.fetchDate || b.date));

  const mergedData = sortedData.map((currentItem, index) => {
    const pastItem = sortedPastData[index] || {};
    
    const keys = ['transactionRevenue', 'revenue', 'orders', 'transactions', 'overallScore'];
    currentKey = keys.find((key) => currentItem[key] !== undefined);
    pastKey = keys.find((key) => pastItem[key] !== undefined);

    return {
      fetchDate: new Date(currentItem.fetchDate || currentItem.date).toLocaleDateString(
        "en-US",
        { day: "2-digit", month: "short" }
      ),
      currentDate: currentItem.date || currentItem.fetchDate,
      prevDate: pastItem.date || pastItem.fetchDate,
      currentValue: currentKey ? currentItem[currentKey] : null,
      prevValue: pastKey ? pastItem[pastKey] : null,
      OtherToolTip: currentItem.OtherToolTip || null, // Include OrdersGAToolTip
      OtherPrevToolTip:pastItem.OtherPrevToolTip || null,
    };
  });

  // Define the keys that should be formatted with a dollar sign
  const dollarKeys = ['transactionRevenue', 'revenue'];
  const noDollarKeys = ['orders', 'transactions'];
  const overallScore = ['overallScore'];
console.log("currentKey===>",currentKey)
  // Check if the currentKey is one of the dollar keys
  const isDollarKey = dollarKeys.includes(currentKey);
  const isNoDollarKey = noDollarKeys.includes(currentKey);
  const isOverallScoreKey = overallScore.includes(currentKey);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {

      return (
        <div className="custom-tool-tip">
          <p className="custom-tool-tip-value">
            {payload[0].payload.currentValue
              ? isDollarKey
                ? (`Curr. Revenue: $${payload[0].payload.currentValue.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}, Curr. Order: ${payload[0].payload.OtherToolTip}`)
                : (isOverallScoreKey ? 'Curr. Score: ' + payload[0].payload.currentValue : (' Curr. Order: ' + payload[0].payload.currentValue.toLocaleString("en-US")) + ', Curr Revenue: ' + payload[0].payload.OtherToolTip.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                }))
              : null}

          </p>
          <p>{moment(payload[0].payload.currentDate).format("MMM D, YYYY")}</p>

          {compare && payload.length > 1 ? (
            <div style={{}}>
              <p className="prev_custom-tool-tip-value">
                {payload[0].payload.prevValue != null
                  ? isDollarKey
                    ? `Prev. Revenue: $${payload[0].payload.prevValue.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}, Prev. Order: ${payload[0].payload.OtherPrevToolTip}`
                    : (isOverallScoreKey ? 'Prev. Score: ' + payload[0].payload.prevValue :' Prev. Order: ' + payload[0].payload.prevValue.toLocaleString("en-US") + ', Prev. Revenue: ' + payload[0].payload.OtherPrevToolTip.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    }))
                  : null}
              </p>
              <p>{moment(payload[0].payload.prevDate).format("MMM D, YYYY")}</p>
            </div>
          ) : null}
        </div>
      );
    }

    return null;
  };
  // const CustomTooltip = ({ active, payload }) => {
  //   if (active && payload && payload.length) {
  //     const { currentValue, OtherGAToolTip, currentDate, prevValue, prevDate } = payload[0].payload;
  // console.log("payload[0].payload-->",payload[0].payload)
  //     return (
  //       <div className="custom-tool-tip">
  //         <p className="custom-tool-tip-value">
  //           {currentValue
  //             ? `Curr. Revenue: $${currentValue.toLocaleString("en-US", { minimumFractionDigits: 2 })}, Curr. Order: ${OtherGAToolTip}`
  //             : null}
  //         </p>
  //         <p>{moment(currentDate).format("MMM D, YYYY")}</p>
  
  //         {compare && payload.length > 1 ? (
  //           <div>
  //             <p className="prev_custom-tool-tip-value">
  //               {prevValue != null
  //                 ? `Prev. Revenue: $${prevValue.toLocaleString("en-US", { minimumFractionDigits: 2 })}`
  //                 : null}
  //             </p>
  //             <p>{moment(prevDate).format("MMM D, YYYY")}</p>
  //           </div>
  //         ) : null}
  //       </div>
  //     );
  //   }
  
  //   return null;
  // };
  
  const gradientId = `colorValue-${color.replace("#", "")}`;
  const compareGradientId = `colorValue-${compareColor.replace("#", "")}`;

  // Dynamically determine the keys for the AreaChart
  const keys = ['currentValue'];
  const dataKey = keys.find((key) => mergedData[0] && mergedData[0][key] !== null);
  const keysPast = ['prevValue'];
  const dataKeyPast = keysPast.find((key) => mergedData[0] && mergedData[0][key] !== null);


  const tickFormatter = (value) => {
    if (isDollarKey) {
      return `$${value > 1000 ? K_Formatter(value) : value}`;
    }
    return value > 1000 ? K_Formatter(value) : value;
  };
  return (
    <ResponsiveContainer width="100%" height={300}>
      {mergedData.length ? (
        <AreaChart data={mergedData} margin={{ top: 10, right: 30, left: 10, bottom: 0 }}>
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.8} />
              <stop offset="95%" stopColor={color} stopOpacity={0} />
            </linearGradient>
            {compare === "true" ? (
              <linearGradient id={compareGradientId} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={compareColor} stopOpacity={0.8} />
                <stop offset="95%" stopColor={compareColor} stopOpacity={0} />
              </linearGradient>
            ) : null}
          </defs>
          <XAxis dataKey="fetchDate" />
          <YAxis tickFormatter={tickFormatter} domain={[0, 100]} />

          <CartesianGrid stroke="#000"        // Set a darker color for the grid lines (e.g., black)
            strokeOpacity={0.1}  // Increase opacity for better visibility
            strokeDasharray="5"  // Keep dashed lines, or set "0" for solid lines
            vertical={false}      // Show vertical lines (aligned with the Y-axis)
            horizontal={true} />

          <Tooltip content={<CustomTooltip />} />
          <Area
            type="monotone"
            dataKey={dataKey}
            stroke={color}
            strokeWidth={2} // Increase the thickness of the line

            fillOpacity={areaColor ? 1 : 0}
            fill={areaColor ? `url(#${gradientId})` : "none"}
          />

          {compare === true ? (
            <Area
              type="monotone"
              dataKey={dataKeyPast}
              stroke={compareColor}
              strokeWidth={2} // Increase the thickness of the line

              // fillOpacity={areaColor ? 0 : 1}
              fill={areaColor ? '#EFEFEF' : "none"}
            />
          ) : null}
        </AreaChart>
      ) : (
        <Typography align="center">No data available</Typography>
      )}
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;
