import { Grid, Hidden } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { Fragment } from "react";
import expertservicesupport from "../../assets/img/expertservicesupport.png";
import googleadsgrader from "../../assets/img/googleadsgrader.png";
import guidedwalkthrough from "../../assets/img/guidedwalkthrough.png";
import jumpstart from "../../assets/img/jumpstart.png";
import toolonboarding from "../../assets/img/toolonboarding.png";
import s from "../../assets/img/S.png";
import reverses from "../../assets/img/reverseS.png";
import {
  OrangeTxt,
  OrangeTextSize12,
  HeadLineTypography,
  BlueTitleTypography,
  CatalinaBlue,
  SubHeadingTypo,
  GrayText,
} from "../../components/typography.jsx";
import { Typography } from "@mui/material/index";

import useMediaQuery from "@mui/material/useMediaQuery";
import icons from "../../components/icons";


// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  GridPara: {
    [theme.breakpoints.up("xs")]: {
      lineHeight: "1.54",
      fontSize: 21,
      paddingLeft: 10,
      color: "#888888",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "1.54",
      fontSize: 21,
      color: "#888888",
      textAlign: "initial",
    },

    // paddingLeft:'40px',
    // paddingRight:'40px',
  },
  GridHeader: {
    fontSize: "1.471rem",
    color: "#1E334E",

    [window.screen.width >= 1280]: {
      display: "none",
    },
  },
  GridImage: {
    textAlign: "center",
  },
  learnmorelinkstyle: {
    color: "#F58120",
  },
  root: {
    backgroundColor: "#ECEFF3",
    paddingTop: 40,
    paddingBottom: 40,
    textAlign: "center",
  },
  centerstyle: {
    textAlign: "center",
    paddingLeft: 86,
    paddingRight: 86,
    [theme.breakpoints.down("xl")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  paddingLeftAndRight: {
    [theme.breakpoints.down("xl")]: {
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: "center !important",
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 40,
      paddingLeft: 60,
      paddingRight: 60,
      alignItems: "center !important",
    },
  },
  dottedImage: {
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      width: "65%",
    },
  },
  imagedottedlinestyle: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      display: "block",
      width: "65%",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      display: "none",
    },
  },
  SubHeadingTypoStyle: {
    fontSize: 30,
  },
  OrangeTxtStyle: {
    fontSize: 38,
  },
  heading: {
    color: "textPrimary",
    fontSize: "1.25rem",
    fontWeight: "700",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.75rem",
    },
  },
});

function GrowthModel(props) {
  const classes = props.classes;
  const { width, theme } = props;
  const isTabletResponsive = useMediaQuery(
    theme.breakpoints.between("sm", "xl")
  );
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12} xs={12}>
          <div className={classes.centerstyle}>
            <OrangeTextSize12 gutterBottom>GROWTH JOURNEY</OrangeTextSize12>
            <br />
            <Typography
              className={classes.heading}
              // color={"textPrimary"}
              // variant={width == "sm" || width == "xs" ? "h3" : "subtitle1"}
              // style={{ fontWeight: "700" }}
            >
              HOW DOES LXRGUIDE'S GROWTH MODEL WORK?
            </Typography>
            <br />
            <CatalinaBlue className={classes.FontStyle}>
            When you sign up with LXRGuide, you gain access to a Strategic Growth Dashboard and LXRGuide Intellichat. The Growth Dashboard consolidates all your ecommerce activity, while LXRGuide Intellichat’s AI helps you spot opportunities and make faster decisions. Tailored for small businesses, the LXRGuide Growth Model includes monthly consultations with experts to guide you in scaling your business online.

            </CatalinaBlue>

            <OrangeTxt>
              {" "}
              Here’s What Your Growth Journey Would Look Like:
            </OrangeTxt>
            <br />
            <br />
          </div>
        </Grid>
      </Grid>

      <Grid container className={classes.paddingLeftAndRight}>
        <Grid className={classes.GridImage} item md={4} xs={12}>
          {width == "sm" || width == "xs" ? (
            <Fragment>
              <br />
              <br />
            </Fragment>
          ) : null}
          {/* <img src={googleadsgrader} /> */}
          {icons.InitialDiagnostics}
        </Grid>
        <Grid item md={8} xs={12} className={classes.GridPara}>
          {width == "sm" || width == "xs" ? <br /> : null}
          <SubHeadingTypo
            gutterBottom={width == "sm" || width == "xs" ? true : false}
          >
            Identify Key Opportunities and Gaps
          </SubHeadingTypo>
          {width != "sm" && width != "xs" ? <br /> : null}
          <CatalinaBlue className={classes.FontStyle}>
          The Strategic Growth Dashboard connects all your ecommerce data sources, including Google Analytics 4, Google Ads, Microsoft Ads, Amazon Advertising, and Google Merchant Center. With minimal setup, you can visualize all your data in a single intuitive dashboard. LXRGuide Intellichat, integrated with the dashboard, keeps you updated on opportunities and actions you need to take. It’s like having a digital marketing expert by your side, 24/7, to guide your decisions.

          </CatalinaBlue>
          {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
        </Grid>
        <Grid className={classes.imagedottedlinestyle} md={12}>
          <img src={s} className={classes.dottedImage} />
        </Grid>
        <Hidden only={["md", "lg", "xl"]}>
          <Grid item md={4} xs={12} className={classes.GridImage}>
            <br />
            <br />
            {/* <img src={jumpstart} /> */}
            {icons.AccountOnBoard}
          </Grid>
        </Hidden>
        <Grid item md={8} xs={12} className={classes.GridPara}>
          {width == "sm" || width == "xs" ? <br /> : null}
          <SubHeadingTypo
            gutterBottom={width == "sm" || width == "xs" ? true : false}
          >
           Channel-Specific Action Items You Can Do Yourself or Opt for Service

          </SubHeadingTypo>
          {width != "sm" && width != "xs" ? <br /> : null}
          <CatalinaBlue className={classes.FontStyle}>
            {" "}
            Our AI-driven LXRGuide Intellichat leverages proprietary technology to act as your Digital Marketing Companion. Unlike public Gen AI tools, it ensures the highest level of data security. LXRGuide Intellichat learns from your actions and suggests the best strategies to scale your business online. Complete actionable insights yourself or call for expert support to receive 1-on-1 assistance from our human experts who can help execute tasks on your behalf.

          </CatalinaBlue>
          {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
        </Grid>
        {/*  <Hidden only={['sm', 'lg']}>
                    <Paper className={classes.paper}>Hidden on sm and lg</Paper>
                </Hidden>*/}
        <Hidden only={["sm", "xs"]}>
          <Grid item md={4} xs={12} className={classes.GridImage}>
            {/* <img src={jumpstart} /> */}
            {icons.AccountOnBoard}
          </Grid>
        </Hidden>
        <Grid className={classes.imagedottedlinestyle} md={12}>
          <img src={reverses} className={classes.dottedImage} />
        </Grid>

        <Grid item md={4} xs={12} className={classes.GridImage}>
          {width == "sm" || width == "xs" ? (
            <Fragment>
              <br />
              <br />
            </Fragment>
          ) : null}
          {/* <img src={toolonboarding} /> */}
          {icons.JumpstartHome}
        </Grid>
        <Grid item md={8} xs={12} className={classes.GridPara}>
          {width == "sm" || width == "xs" ? <br /> : null}
          <SubHeadingTypo
            gutterBottom={width == "sm" || width == "xs" ? true : false}
          >
            Jumpstart Your Paid Search Growth
          </SubHeadingTypo>
          {width != "sm" && width != "xs" ? <br /> : null}
          <CatalinaBlue className={classes.FontStyle}>
            {" "}
            We have analyzed over 10,000 paid search accounts to create a proven framework for growth campaigns. After signing up for LXRGuide, our human experts review your Google Ads and Microsoft Ads setup to streamline your account for success. Our advanced AI-powered recommendation engine then monitors your campaigns and provides weekly action steps. With just a few clicks, you can optimize your ad campaigns in minutes—no prior paid ad management experience required.

          </CatalinaBlue>
          {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
        </Grid>
        <Grid className={classes.imagedottedlinestyle} md={12}>
          <img src={s} className={classes.dottedImage} />
        </Grid>
        <Hidden only={["md", "lg", "xl"]}>
          <Grid item md={4} xs={12} className={classes.GridImage}>
            <br />
            <br />
            {/* <img src={CampaignManagment} /> */}
            {icons.CampaignManagment}
          </Grid>
        </Hidden>
        <Grid item md={8} xs={12} className={classes.GridPara}>
          {width == "sm" || width == "xs" ? <br /> : null}
          <SubHeadingTypo
            gutterBottom={width == "sm" || width == "xs" ? true : false}
          >
           Profitably Grow Your Online Sales{" "}
          </SubHeadingTypo>
          {width != "sm" && width != "xs" ? <br /> : null}
          <CatalinaBlue className={classes.FontStyle}>
            {" "}
            The Strategic Growth Dashboard provides intuitive visualizations, giving you access to all key ecommerce metrics in one place. LXRGuide Intellichat, the first AI-powered chatbot designed for small online businesses, acts as your digital marketing companion, interpreting performance data and delivering actionable insights to guide smarter decisions. LXRGuide combines cutting-edge technology with decades of digital marketing expertise to help small businesses scale efficiently. Need extra support? Our award-winning expert service team is just a call away, ready to consult or optimize your account on your behalf.

          </CatalinaBlue>
          {/* <p ><a className={classes.learnmorelinkstyle} href="">LEARN MORE</a></p> */}
        </Grid>
        <Hidden only={["sm", "xs"]}>
          <Grid item md={4} xs={12} className={classes.GridImage}>
            {/* <img src={guidedwalkthrough} /> */}
            {icons.CampaignManagment}
          </Grid>
        </Hidden>
     

       
      </Grid>
    </div>
  );
}

export default withWidth()(
  withStyles(styles, { withTheme: true })(GrowthModel)
);
