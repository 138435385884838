import React, { useEffect } from "react";
import {
  BlueTitleTypography,
  HeadLineTypography,
  CatalinaBlue,
  OrangeTxt,
  OrangeText,
  SubHeadingTypo,
} from "../../components/typography";
import withStyles from "@mui/styles/withStyles";
import icons from "../../components/icons";
import {
  OrangeBtnNew,
  NewOrangeLargeBtn,
  PricingButton,
} from "../../components/buttons.jsx";
import { Card, CardContent, CardActions, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import * as types from "../../actions/actionTypes";
import { connect } from "react-redux";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      paddingLeft: 50,
      paddingRight: 50,
      // paddingBottom: 68,
    },
  },
  gridboaredstyle: {
    textAlign: "initial",
    borderStyle: "solid",
    borderColor: "#C4C4C4",
    // borderRadius: 10,
    boxShadow: "unset",
    // border: "1px solid" + theme.palette.common.border,
    borderWidth: 1,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.down("xl")]: {
      marginBottom: 10,
    },
  },
  NewOrangeLargeBtnstyle: {
    textAlign: "center",
    marginTop: 8,
    marginBottom: 26,
    width: "85%",
  },
  imgstyle: {
    paddingTop: 37,
    // paddingLeft: 32,
    paddingBottom: 29,
  },
  BlueTitleTypographyStyle: {
    paddingTop: 28.5,
    paddingBottom: 50,
  },
  TypographyStyle: {
    fontSize: 20,
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
    minHeight: 370,
    [theme.breakpoints.down("xl")]: {
      minHeight: 400,
    },
  },
  boxTitle: {
    fontSize: 20,
    [theme.breakpoints.down("xl")]: {
      fontSize: 20,
    },
    [theme.breakpoints.between("sm", "xl")]: {
      fontSize: 22,
    },
  },
  mainTitle: {
    color: theme.mainColor,
    textAlign: "center",
    fontWeight: "700",
    textTransform: "uppercase",
    // paddingBottom: "10px",
    fontSize: "2.75rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem",
    },
  },
});

function Freegradertoolsblocks(props) {
  const { width, classes } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.freeToolsPageTrack();
  }, []);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Grid container className={classes.root}>
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            className={classes.BlueTitleTypographyStyle}
          >
            <Typography className={classes.mainTitle} gutterBottom>
              Free Grader for your business
            </Typography>
          </Grid>
          {/* <Grid item md={2}></Grid> */}
        </Grid>
        {/* //Google Grader */}
        <Grid container justifyContent={"center"} columnSpacing={4} className={classes.root}>
          <Grid item md={4}>
            <Card className={classes.gridboaredstyle}>
              <CardContent className={classes.cardContent}>
                <div className={classes.imgstyle}>{icons.graderHomeIcon}</div>

                <Typography
                  align={"left"}
                  color={"textPrimary"}
                  className={classes.boxTitle}
                >
                  <b>GOOGLE ADS GRADER</b>
                </Typography>
                <Typography className={classes.TypographyStyle}>
                  Our grader can give you a free technical Paid Search audit
                  report in under 5 minutes. Use the information to optimize
                  your account performance & costs and increase conversions.
                </Typography>
                <br />
                {/*<div className={"growable"}>

                    </div>*/}
              </CardContent>
              <CardActions disableSpacing className={"justifyCenter"}>
                <div className={classes.NewOrangeLargeBtnstyle}>
                  <NewOrangeLargeBtn
                    fullWidth
                    component={Link}
                    to={"/google-ads-grader"}
                  >
                    GRADE
                  </NewOrangeLargeBtn>
                </div>
              </CardActions>
            </Card>
          </Grid>
          {/* //SEO Grader */}
          {/* <Grid item md={4}>
            <Card className={classes.gridboaredstyle}>
              <CardContent className={classes.cardContent}>
                <div className={classes.imgstyle}>
                  {icons.seoGraderHomeIcon}
                </div>
                <Typography
                  align={"left"}
                  color={"textPrimary"}
                  className={classes.boxTitle}
                >
                  <b> SEO GRADER</b>
                </Typography>
                <Typography className={classes.TypographyStyle}>
                  Our grader can give you a free technical SEO audit in under 5
                  minutes. Use the information to optimize your website
                  performance and Ranking on Google.
                </Typography>
                <br />
              </CardContent>
              <CardActions disableSpacing className={"justifyCenter"}>
                <div className={classes.NewOrangeLargeBtnstyle}>
                  <NewOrangeLargeBtn
                    fullWidth
                    component={Link}
                    to={"/seo-site-grader"}
                  >
                    GRADE
                  </NewOrangeLargeBtn>
                </div>
              </CardActions>
            </Card>
          </Grid> */}
          <Grid item md={4}>
            <Card className={classes.gridboaredstyle}>
              <CardContent className={classes.cardContent}>
                <div className={classes.imgstyle}>{icons.WHSIconNew}</div>

                <Typography
                  align={"left"}
                  color={"textPrimary"}
                  className={classes.boxTitle}
                >
                  <b>WEBSITE HEALTH SCORECARD</b>
                </Typography>
                <Typography className={classes.TypographyStyle}>
                  Our grader can give you a free technical Website Health
                  Scorecard report in under 5 minutes. Use the information to
                  optimize your website performance.
                </Typography>
                <br />
              </CardContent>
              <CardActions disableActionSpacing className={"justifyCenter"}>
                <div className={classes.NewOrangeLargeBtnstyle}>
                  <NewOrangeLargeBtn
                    fullWidth
                    component={Link}
                    to={"/website-health-score-card"}
                  >
                    GRADE
                  </NewOrangeLargeBtn>
                </div>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const mapDispatchToProps = (dispatch) => ({
  freeToolsPageTrack: () => dispatch({ type: types.FREE_TOOLS_PAGE_TRACK }),
});

export default connect(
  null,
  mapDispatchToProps
)(withWidth()(withStyles(styles)(Freegradertoolsblocks)));
