import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchAmazonAuthURL,
  fetchAmazonProfiles,
  storeAmazonProfile,
  startAmazonAccIntegration,
  fetchAmazonAccDetails,
  updateAmazonAccessToken,
} from "../../actions/amazonActions";
import { deleteAmazonAdsAcc } from "../../actions/connectionPageActions";
import MySnackbarWrapper from "../snackbars";
import {
  NewOrangeContainedBtn,
  NewOutLinedRectCancelBlueBtn,
} from "../../components/buttons";
import icons from "../../components/icons";
import { ConnectionTypeTypography } from "../../components/typography";
import { useHistory } from "react-router-dom";
import deleteAmazonAccReducer from "../../reducers/connectionPageReducers";

const styles = (theme) => ({
  GaGrid: {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "88px",
      border: "solid 2px #BBBBBB",
      borderRadius: "6px",
      padding: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
      width: "100%",
      height: "300px",
      border: "solid 1px #BBBBBB",
      borderRadius: "3px",
      textAlign: "center",
    },
  },
  TextCenter: {
    textAlign: "Center",
    fontSize: "16px",
  },

  gridItems: {
    margin: "Auto 0",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  buttons: {
    backgroundColor: "#F58120",
    border: "solid 1px #1E334E",
  },
  dataStyle: {
    fontWeight: "500",
    color: "#1E334E",
    wordBreak: "break-word",
  },
  verticalLine: {
    paddingRight: "7px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

function AmazonConnection(props) {
  window.scrollTo(0, 0);
  const classes = props.classes;

  const {
    fetchAmazonAuthURL,
    fetchAmazonAuthURLReducer,
    fetchAmazonProfiles,
    storeAmazonProfile,
    startAmazonAccIntegration,
    fetchAmazonAccDetails,
    deleteAmazonAdsAcc,
    deleteAmazonAdsAccReducer,
    updateAmazonAccessToken,
    userInformation,
    amazonData,
    setAmazonData,
    deleteAmazonAccReducer,
  } = props;

  const appName = "LXRGuide";
  //amazon data
  const [amazonAuthUrl, setAmazonAuthUrl] = useState();
  const [amazonAccName, setAmazonAccName] = useState("");
  const [profileId, setProfileId] = useState(0);
  const [amazonId, setAmazonId] = useState(0);
  const [clientId, setClientId] = useState(
    userInformation ? userInformation.clientId : 0
  );

  const [loading, setLoading] = useState(false);
  const [profileList, setProfileList] = useState();
  const [openProfilesDialog, setOpenProfilesDialog] = useState(false);
  const [selectedAmazonProfile, setSelectedAmazonProfile] = useState();
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    if (amazonData != undefined && amazonData != "-") {
      setAmazonAccName(amazonData.accountName);
      setProfileId(amazonData.profileId);
      setAmazonId(amazonData.amazonId);
    }
  }, [amazonData]);

  useEffect(async () =>{
    if(userInformation != null){
      await setClientId(userInformation.clientId);
      const data = await fetchAmazonAuthURL(userInformation.clientId, appName);
      setAmazonAuthUrl(data);
    }
  },[userInformation])

  //delete confirmation dialog
  const [open, setOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState();

  const handleDialogToClose = () => {
    setOpen(false);
  };
  const handleDialogToOpen = () => {
    setOpen(true);
  };

  const handleCloseProfilesDialog = () => {
    setOpenProfilesDialog(false);
    setLoading(false)
  };
  //snackbar
  const [alert, setAlert] = useState(false);

  const handleOpenAlert = () => {
    setAlert(true);
  };
  const handleCloseAlert = () => {
    setAlert(false);
  };

  const [error, setError] = useState(false);
  const [deletedAmazonAccId, setDeletedAmazonAccId] = useState("");

  const handleAmazonDeleteConnection = async (amazonId, amazonAccName) => {
    setDeletedAmazonAccId(amazonId);
    setOpen(false);
    const data = await deleteAmazonAdsAcc(amazonData);
    if (data === true) {
      setAmazonAccName("");
      setProfileId("");
      setAmazonId("");
      setAmazonData();
      setError(false);
      setSnackbarMsg("Amazon ads account deleted successfully");
    } else {
      setError(true);
      setSnackbarMsg("Error in deleting Amazon ads account");
    }
    setAlert(true);
  };

  useEffect(() => {
    if (deleteAmazonAccReducer.message === "DELETE_AMAZON_ACC_DATA_RECEIVED") {
      if (deletedAmazonAccId === amazonId) {
        setAmazonId("");
        setAmazonAccName("");
        setProfileId("");
      }
    }
  }, [deleteAmazonAccReducer]);

  const setErrorMessage = (data) => {
    if (
      data == "Refresh tokens updated successfully" || data == "Successfully added amazon account"
    ) {
      setError(false);
    } else {
      setError(true);
    }
    setSnackbarMsg(
      data != null && data != ""
        ? data
        : "Something went wrong"
    );
    setAlert(true);
  };

  const handleAddAccount = async (status) => {
    if (amazonAuthUrl != undefined && amazonAuthUrl != "") {
      let success = false;
      let newWindow = window.open(
        amazonAuthUrl,
        "Amazon Ads Authentication",
        "menubar=no,status=no,location=no,toolbar=no,scrollbars=yes,resizable=no, width=700, height=600, top=30"
      );
      window.addEventListener("message", function (event) {
        if (event.data === "amazonAuthSuccess") {
          success = true;
        } else if (event.data === "amazonAuthFailed") {
          success = false;
        }
      });
      const checkWindowStatus = setInterval(async () => {
        if (newWindow && newWindow.closed) {
          clearInterval(checkWindowStatus);
          setLoading(true);
          if (success) {
            const data = await fetchAmazonProfiles(status, amazonId, clientId, appName);
            setProfileList(data.profiles);
            if (status == true && data != null) {
              setOpenProfilesDialog(true);
            } else if (status == false && data != null) {
              if(data.refreshTokenUpdate){
                setErrorMessage("Refresh tokens updated successfully");
                setError(false);
                setAlert(true);
                setLoading(false);
              }else{
                setErrorMessage("Error in updating refresh tokens.");
                setError(true);
                setAlert(true);
                setLoading(false);
              }
            }
          } else {
            setErrorMessage("Access denied.");
            setError(true);
            setAlert(true);
            setLoading(false);
          }
        }
      }, 3000);
    }
  };

  const handleProfileChange = (event) => {
    setSelectedValue(event.target.value);
    setSelectedAmazonProfile(
      profileList.find(
        (profile) => profile.profileId === Number(event.target.value)
      )
    );
  };


  const handleSaveProfile = async () => {
    if (selectedAmazonProfile != null) {
      setOpenProfilesDialog(false);
      const data = await storeAmazonProfile(selectedAmazonProfile,clientId, appName);
      if (data != null) {
        if (!data.success) {
          setError(true);
          setSnackbarMsg("Error in adding amazon account");
          setAlert(true);
        } else {
          setAmazonData(data.amazonAccountDetails);
          const amazonData = await startAmazonAccIntegration(data.amazonAccountDetails.amazonId);
          if(amazonData != null){
            sessionStorage.setItem("amazonSyncCheckId", amazonData.amazonSyncCheckId);
          }
          setError(false);
          setSnackbarMsg("Amazon ads account added successfully. Integration may take up to 3 hours.");
          setAlert(true);
        }
      }
      setLoading(false);
    }
  };
  return (
    <div style={{ marginTop: "20px" }}>
      <Grid container className={classes.GaGrid}>
        <Grid item xs={12} sm={12} md={3} lg={3} className={classes.gridItems}>
          <div className={classes.flexCenter}>
            {icons.AmazonAds}
            <ConnectionTypeTypography>Amazon Ads</ConnectionTypeTypography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.gridItems}>
          {amazonAccName === "" || amazonAccName === "-" ? (
            <Typography></Typography>
          ) : (
            <div className={classes.flexCenter}>
              <div className={classes.verticalLine}>
                {icons.SegregationLine}
              </div>
              <Typography style={{ color: "#747474" }}>
                Amazon Ads Account Name:
                <br />
                <span className={classes.dataStyle}>{amazonAccName}</span>
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {amazonData &&
          (deleteAmazonAccReducer["message"] === "" ||
            deleteAmazonAccReducer["message"] ===
              "DELETE_AMAZON_ACC_DATA_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn
                onClick={() => handleAddAccount(false)}
                disabled={loading}
              >
                Refresh Token
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography></Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2} className={classes.gridItems}>
          {amazonData &&
          (deleteAmazonAccReducer["message"] === "" ||
            deleteAmazonAccReducer["message"] ===
              "DELETE_AMAZON_ACC_DATA_FAILED") ? (
            <Typography>
              <NewOutLinedRectCancelBlueBtn onClick={handleDialogToOpen}>
                Delete Account
              </NewOutLinedRectCancelBlueBtn>
            </Typography>
          ) : (
            <Typography>
              <NewOrangeContainedBtn
                onClick={() => handleAddAccount(true)}
                type={"button"}
                disabled={loading}
              >
                +Add Account
              </NewOrangeContainedBtn>
            </Typography>
          )}
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleDialogToOpen}>
        <DialogTitle>{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete Amazon Ads integration in LXRGuide?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              handleAmazonDeleteConnection(amazonId, amazonAccName)
            }
            color="primary"
            autoFocus
          >
            Delete
          </Button>
          <Button onClick={handleDialogToClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/*Delete Snackbar*/}
      <MySnackbarWrapper
        open={alert}
        onClose={handleCloseAlert}
        message={snackbarMsg}
        verticalAlign={"bottom"}
        horizontalAlign={"right"}
        duration={6000}
        variant={error ? "error" : "success"}
      />

      {/* Amazon Profiles Dialog */}
      <Dialog
        open={openProfilesDialog}
        onClose={handleCloseProfilesDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={"sm"}
      >
        <DialogTitle id="alert-dialog-title">
          {"Select an amazon profile:"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl>
              {profileList && profileList.length > 0 ? (
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={selectedValue}
                  onChange={handleProfileChange}
                >
                  {profileList.map((profile) => (
                    <FormControlLabel
                      value={profile.profileId}
                      control={<Radio />}
                      label={`${profile.accountName} (${profile.accountType})`}
                      key={profile.profileId}
                    />
                  ))}
                </RadioGroup>
              ) : (
                ""
              )}
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProfilesDialog}>Cancel</Button>
          <Button
            autoFocus
            onClick={handleSaveProfile}
            disabled={selectedValue === "" || selectedValue === null}
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fetchAmazonAuthURLReducer: state.fetchAmazonAuthURLReducer,
  userInformation: state.userInformation.selectedClientInfo,
  deleteAmazonAccReducer: state.deleteAmazonAccReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAmazonAuthURL: (clientId, appName) => dispatch(fetchAmazonAuthURL(clientId, appName)),
  fetchAmazonProfiles: (status, amazonId, clientId, appName) => dispatch(fetchAmazonProfiles(status, amazonId, clientId, appName)),
  storeAmazonProfile: (data,clientId,appName) =>
    dispatch(storeAmazonProfile(data, clientId,appName)),
  startAmazonAccIntegration: (amazonId) =>
    dispatch(startAmazonAccIntegration(amazonId)),
  fetchAmazonAccDetails: (clientId) =>
    dispatch(fetchAmazonAccDetails(clientId)),
  deleteAmazonAdsAcc: (accountData) =>
    dispatch(deleteAmazonAdsAcc(accountData)),
  updateAmazonAccessToken: (accountData) =>
    dispatch(updateAmazonAccessToken(accountData)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(AmazonConnection));
