import React, { useState } from 'react';
import ChatBubble from './ChatBubble';
import ChatWindow from './ChatWindow';
import './chatbottest.css';

const ChatApp = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [closeIcon, setCloseIcon] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    setCloseIcon(!isChatOpen);
  };

  return (
    <div className="chat-app">
      <ChatBubble onClick={toggleChat} isOpen={isChatOpen} closeIcon={closeIcon} />
      <ChatWindow isOpen={isChatOpen} onClose={toggleChat} />
    </div>
  );
};

export default ChatApp;
