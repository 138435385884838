
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@mui/material';
import "./NewDashBoard.css";
const TableWithScroll = ({ data }) => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (data.length > 10) {
      setRows(data.slice(0, 10));
    } else {
      setRows(data);
    }
  }, [data.length]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper} className="custom-table-container">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="styled-header-cell" align="left" width={20}>
                  Product ID
                </TableCell>
                <TableCell className="styled-header-cell" width={200}>
                  Product
                </TableCell>
                <TableCell className="styled-header-cell" align="left" width={20}>
                  Revenue
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="styled-body-cell">
                      {row.productId}
                    </TableCell>
                    <TableCell className="styled-body-cell" width={200}>
                      {row.productName}
                    </TableCell>
                    <TableCell className="styled-body-cell" align="left" width={20}>
                      ${row.itemRevenue.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="styled-body-cell" colSpan={3} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default TableWithScroll;
