import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./chatbottest.css";
import { Typography } from "@mui/material";

const ChatbotInnerHeader =({headerMessage,onClose})=>{

    return(
        <>
        
         <div className="chat-header">
         <Typography variant="h6" align="center">{headerMessage}</Typography>
                <button onClick={onClose}>Close</button>
            </div>
            
        </>
    );
}
export default ChatbotInnerHeader;