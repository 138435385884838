import * as types from "../actions/actionTypes";

export function fetchIntelliChatBotResponseReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.INTELLI_CHATBOT_RESPONSE_REQUESTED:
        return Object.assign({}, state, {
          message: "INTELLI_CHATBOT_RESPONSE_REQUESTED",
        });
      case types.INTELLI_CHATBOT_RESPONSE_RECEIVED:
        let { data } = action;
        return Object.assign({}, state, {
          ...action.data,
          message: "INTELLI_CHATBOT_RESPONSE_RECEIVED",
        });
      case types.INTELLI_CHATBOT_RESPONSE_FAILED:
        return Object.assign({}, state, {
          message: "INTELLI_CHATBOT_RESPONSE_FAILED",
        });
      default:
        return state;
    }
  }

  // export function fetchIntelliChatBotNormalResponseReducer(
  //   state = {
  //     messages: [], // Store messages explicitly
  //     message: "",
  //   },
  //   action
  // ) {
  //   switch (action.type) {
  //     case types.INTELLI_CHATBOT_NORMAL_RESPONSE_REQUESTED:
  //       return {
  //         ...state,
  //         message: "INTELLI_CHATBOT_NORMAL_RESPONSE_REQUESTED",
  //       };
  //     case types.INTELLI_CHATBOT_NORMAL_RESPONSE_RECEIVED:
  //       const { data } = action;
  //       console.log("data at reducer:", data);
  
  //       // Assuming `data.messages` contains the new messages
  //       return {
  //         ...state,
  //         messages: [...state.messages, ...data.messages], // Append new messages to existing messages
  //         message: "INTELLI_CHATBOT_NORMAL_RESPONSE_RECEIVED",
  //       };
  //     case types.INTELLI_CHATBOT_NORMAL_RESPONSE_FAILED:
  //       return {
  //         ...state,
  //         message: "INTELLI_CHATBOT_NORMAL_RESPONSE_FAILED",
  //       };
  //     default:
  //       return state;
  //   }
  // }
  export function fetchIntelliChatBotNormalResponseReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.INTELLI_CHATBOT_NORMAL_RESPONSE_REQUESTED:
        return Object.assign({}, state, {
          message: "INTELLI_CHATBOT_NORMAL_RESPONSE_REQUESTED",
        });
      case types.INTELLI_CHATBOT_NORMAL_RESPONSE_RECEIVED:
        let { data } = action;
        console.log("data normal chat=",data)
        return Object.assign({}, state, {
         data,
          message: "INTELLI_CHATBOT_NORMAL_RESPONSE_RECEIVED",
        });
      case types.INTELLI_CHATBOT_NORMAL_RESPONSE_FAILED:
        return Object.assign({}, state, {
          message: "INTELLI_CHATBOT_NORMAL_RESPONSE_FAILED",
        });
      default:
        return state;
    }
  }
