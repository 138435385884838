import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SnackbarContent from "@mui/material/SnackbarContent";
import withStyles from '@mui/styles/withStyles';
import ExpandMore from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { addUser, fetchProvUserDetails, getAmount, fetchCSRFToken } from "../../actions";
import icons from "../icons";
import { ErrorTypography } from "../typography";
import { initialState, suggestionTagLine } from "./templates";
import { storeTokensInCookies } from "../../actions/jumpstartDomain";
const userFormStyles = (theme) => ({
  tagLine: {
    fontWeight: "900",
    fontSize: "0.8rem",
    margin: theme.spacing(1),
  },
});
const CustomSnackbarContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success,
    fontWeight: "bold",
  },
}))(SnackbarContent);
const CustomTagLine = withStyles((theme) => ({
  root: {
    color: theme.palette.pricingPlanTagLine,
    fontWeight: "bold",
  },
}))(Typography);

class UserFrom extends React.PureComponent {
  constructor(props) {
    super();
    this.state = {
      ...initialState,
      successAlert: false,
      flagforRadiochannelChange: false,
      options: [
        "UPS : Canada",
        "UPS : Central Plains",
        "UPS : Chesapeake",
        "UPS : Desert Mountain",
        "UPS : Florida",
        "UPS : Great Lakes",
        "UPS : Illinois",
        "UPS : Mid Atlantic",
        "UPS : MidSouth",
        "UPS : North Atlantic",
        "UPS : North California",
        "UPS : Northeast",
        "UPS : North Plains",
        "UPS : Northwest",
        "UPS : Ohio Valley",
        "UPS : Red River",
        "UPS : South Atlantic",
        "UPS : South California",
      ],
    };
  }

  async componentDidMount() {
    // for admin section
    let csrf = await this.props.fetchCSRFToken();
    await storeTokensInCookies('CSRF-TOKEN',csrf ? csrf.csrfToken : undefined, "strict");
    await this.getSuggestion();
  }

  async getSuggestion() {
    let response = await this.props.getPlan(this.state.pricingPlan);
    let tagLine = suggestionTagLine(this.state.pricingPlan, response);
    this.setState({ tagLine: tagLine });
  }

  handleRadiochannelChange = (event) => {
    this.setState({ type: event.target.value });
    console.log("handleRadiochannelChange==>", this.state.type);
  };
  handleClose = () => {
    this.setState({ successAlert: false });
  };
  handleRadioUserTypeChange = async (event) => {
    if (event.target.value == "true") {
      await this.setState({ userType: event.target.value, pricingPlan: "11" });
    } else {
      await this.setState({ userType: event.target.value, pricingPlan: "8" });
    }
    await this.getSuggestion();
  };
  handleRadioPricingChange = async (event) => {
    await this.setState({ pricingPlan: event.target.value });
    await this.getSuggestion();
  };

  handleInputs = async (event) => {
    event.preventDefault();
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log("User form State Updated=", this.state);
  };

  fetchProvUserDetails = async () => {
    let isError = false;
    const errors = {};

    if (this.state.emailId.length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.length > 0) {
      let result = this.state.emailId.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );

      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      }
    }
    if (!isError) {
      errors.emailIdError = "";
      let response = await this.props.fetchProvUserDetails(this.state.emailId);

      //we are checking whether response it null or not
      if (response.provisionalUser.pvslEmailAddress == null) {
        errors.emailIdError = "Failed, The Email is not Found in the Database";
      } else {
        this.setState({
          userName: response.provisionalUser.pvslUserName,
          emailId: response.provisionalUser.pvslEmailAddress,
          // website: response.provisionalUser.pvslCompany,
          companyName: response.provisionalUser.pvslCompany,
          phoneNumber: response.provisionalUser.pvslPhoneNumber,
          supportMailId: response.provisionalUser.neEmailId,
          contractSigned: response.provisionalUser.contractSigned,
          upsSalesProfessionalName:
            response.provisionalUser.upsSalesProfessionalName,
          // upsRegion: response.provisionalUser.upsRegion,
        });
      }
    }

    this.setState({
      ...this.state,
      ...errors,
    });
  };

  onCancel = async () => {
    this.setState({
      ...initialState,
    });
    this.props.handleUserDialogClose();
    this.props.toggle();
  };

  //   dropDropOnChange
  handleUpsRegionChange = (e) => {
    this.setState({ upsRegion: e.target.value });
  };
  //

  validate = () => {
    let isError = false;
    const errors = {};
    if (this.state.userName.length == 0) {
      isError = true;
      errors.userNameError = "This field is required";
    } else {
      errors.userNameError = "";
    }
    //error for upsRegion
    if (this.state.type != "1") {
      console.log("upsRegion------------->", this.state.upsRegion);
      console.log("type ------------->", this.state.type);

      if (this.state.upsRegion.length == 0) {
        console.log("ups region is empty");
        isError = true;
        errors.upsRegionError = "This field is required";
      } else {
        errors.upsRegionError = "";
      }
    }
    //error for upsRegion

    if (this.state.emailId.length == 0) {
      isError = true;
      errors.emailIdError = "This field is required";
    } else if (this.state.emailId.length > 0) {
      let result = this.state.emailId.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (!result) {
        isError = true;
        errors.emailIdError = "Please enter valid email";
      } else {
        errors.emailIdError = "";
      }
    } else {
      errors.emailIdError = "";
    }
    if (this.state.supportMailId.length == 0) {
      isError = true;
      errors.supportMailIdError = "This field is required";
    } else if (this.state.supportMailId.length > 0) {
      let result = this.state.supportMailId.match(
        /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
      );
      if (!result) {
        isError = true;
        errors.supportMailIdError = "Please enter valid email";
      } else {
        errors.supportMailIdError = "";
      }
    } else {
      errors.supportMailIdError = "";
    }
    if (this.state.type == "3") {
      if (this.state.companyName.length == 0) {
        isError = true;
        errors.companyNameError = "This field is required";
      } else {
        errors.companyNameError = "";
      }
      if (this.state.ctpAmount.length == 0) {
        isError = true;
        errors.ctpAmountError = "This field is required";
      } else {
        errors.ctpAmountError = "";
      }
      if (this.state.ctpCode.length == 0) {
        isError = true;
        errors.ctpCodeError = "This field is required";
      } else {
        errors.ctpCodeError = "";
      }
      if (this.state.ctpAddress.length == 0) {
        isError = true;
        errors.ctpAddressError = "This field is required";
      } else {
        errors.ctpAddressError = "";
      }
    }
    if (isError) {
      this.setState({
        ...this.state,
        ...errors,
      });
    }
    return isError;
  };

  onSubmit = async () => {
    let {
      type,
      userType,
      pricingPlan,
      userName,
      emailId,
      website,
      phoneNumber,
      supportMailId,
      upsSalesProfessionalName,
      ctpCode,
      ctpAmount,
      ctpAddress,
      companyName,
      note,
      contractSigned,
      upsRegion,
    } = this.state;
    let error;

    error = this.validate();
    if (!error) {
      this.state.response = await this.props.addUser({
        type,
        userType,
        pricingPlan,
        userName,
        emailId,
        website,
        phoneNumber,
        supportMailId,
        upsSalesProfessionalName,
        ctpCode,
        ctpAmount,
        ctpAddress,
        companyName,
        note,
        contractSigned,
        upsRegion,
      });
      const { response } = this.state;
      let serverError = "";
      let emailError = "";
      if (response.error) {
        serverError = response.error;
      }
      if (response && response.isEmail) {
        emailError = "Email Address already exists";
        this.setState({ emailIdError: "Email Id already exists" });
      }
      if (response && response.error == "") {
        await this.props.toggle();
        this.setState({ successAlert: true, ...initialState });
      }
      await this.setState({
        serverError: serverError,
        emailIdError: emailError,
      });
    }
  };

  render() {
    const { serverError, tagLine, provUser, value, options } = this.state;
    const { classes } = this.props;
    console.log("provUser data", this.props.provUser);
    if (this.props.provUser) {
      this.setState({
        userName: this.props.provUser.pvslUserName,
        emailId: this.props.provUser.pvslEmailAddress,
        companyName: this.props.provUser.pvslCompany,
        phoneNumber: this.props.provUser.pvslPhoneNumber,
        supportMailId: this.props.provUser.neEmailId,
        contractSigned: this.props.provUser.contractSigned,
        upsSalesProfessionalName: this.props.provUser.upsSalesProfessionalName,
        // upsRegion: this.props.provUser.upsRegion,
      });
    }

    return (
      <div>
        <Dialog
          open={this.props.userDialog}
          onClose={this.onCancel}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle>User Form</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
                        To subscribe to this website, please enter your email address here. We will send
                        updates occasionally.
                    </DialogContentText>*/}

            <TextField
              autoFocus
              margin="dense"
              label="Name"
              name="userName"
              type="text"
              fullWidth
              value={this.state.userName}
              onChange={this.handleInputs}
              error={this.state.userNameError != ""}
              helperText={this.state.userNameError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      <Tooltip title="Full Name of the Client" aria-label="Add">
                        <icons.help />
                      </Tooltip>
                    }
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="dense"
              label="Email Address"
              type="email"
              name="emailId"
              fullWidth
              value={this.state.emailId}
              onChange={this.handleInputs}
              error={this.state.emailIdError != ""}
              helperText={this.state.emailIdError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      <Tooltip
                        title="Client's business email. Must be a valid email, this will be used to login LXRGuide and to send the signup link."
                        aria-label="Add"
                      >
                        <icons.help />
                      </Tooltip>
                    }
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={this.fetchProvUserDetails}
              color="primary"
              style={{ textDecorationLine: "underline" }}
            >
              Check Provisional User
            </Button>

            <TextField
              margin="dense"
              name="website"
              label="Website"
              type="text"
              fullWidth
              value={this.state.website}
              onChange={this.handleInputs}
              error={this.state.websiteError != ""}
              helperText={this.state.websiteError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      <Tooltip
                        title="Account website to be linked with LXRGuide"
                        aria-label="Add"
                      >
                        <icons.help />
                      </Tooltip>
                    }
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="dense"
              name="phoneNumber"
              label="Phone Number"
              type="number"
              fullWidth
              value={this.state.phoneNumber}
              onChange={this.handleInputs}
              error={this.state.phoneNumberError != ""}
              helperText={this.state.phoneNumberError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      <Tooltip
                        title="Client's valid phone number. It will be used for important communication"
                        aria-label="Add"
                      >
                        <icons.help />
                      </Tooltip>
                    }
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="dense"
              name="supportMailId"
              label="NetElixir Email Address"
              type="email"
              fullWidth
              value={this.state.supportMailId}
              onChange={this.handleInputs}
              error={this.state.supportMailIdError != ""}
              helperText={this.state.supportMailIdError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      <Tooltip
                        title="Email Address of NetElixir sales representative"
                        aria-label="Add"
                      >
                        <icons.help />
                      </Tooltip>
                    }
                  </InputAdornment>
                ),
              }}
            />
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Sales Channel{" "}
                  {
                    <Tooltip
                      title="Choose sales channel category"
                      aria-label="Add"
                    >
                      <icons.help />
                    </Tooltip>
                  }
                </FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="type"
                  value={this.state.type}
                  onChange={this.handleRadiochannelChange}
                  row
                >
                  <FormControlLabel
                    value="2"
                    control={<Radio color="primary" />}
                    label="UPS"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label="NON-UPS"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="3"
                    control={<Radio color="primary" />}
                    label="CTP"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {this.state.type == "3" ? (
              <div>
                <TextField
                  margin="dense"
                  name="ctpCode"
                  label="CTP Code"
                  type="text"
                  fullWidth
                  value={this.state.ctpCode}
                  onChange={this.handleInputs}
                  error={this.state.ctpCodeError != ""}
                  helperText={this.state.ctpCodeError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          <Tooltip
                            title="Customer Technology Programme code"
                            aria-label="Add"
                          >
                            <icons.help />
                          </Tooltip>
                        }
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="dense"
                  name="ctpAmount"
                  label="CTP Amount"
                  type="number"
                  fullWidth
                  value={this.state.ctpAmount}
                  onChange={this.handleInputs}
                  error={this.state.ctpAmountError != ""}
                  helperText={this.state.ctpAmountError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          <Tooltip title="Enter CTP amount" aria-label="Add">
                            <icons.help />
                          </Tooltip>
                        }
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="dense"
                  name="ctpAddress"
                  label="Customer Address"
                  type="text"
                  fullWidth
                  value={this.state.ctpAddress}
                  onChange={this.handleInputs}
                  error={this.state.ctpAddressError != ""}
                  helperText={this.state.ctpAddressError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          <Tooltip title="Customer Address" aria-label="Add">
                            <icons.help />
                          </Tooltip>
                        }
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="dense"
                  name="companyName"
                  label="Company Name"
                  type="text"
                  fullWidth
                  value={this.state.companyName}
                  onChange={this.handleInputs}
                  error={this.state.companyNameError != ""}
                  helperText={this.state.companyNameError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          <Tooltip title="Company Name" aria-label="Add">
                            <icons.help />
                          </Tooltip>
                        }
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            ) : null}
            {this.state.type == "2" || this.state.type == "3" ? (
              <TextField
                margin="dense"
                name="upsSalesProfessionalName"
                label="UPS Sales Professional"
                type="text"
                fullWidth
                value={this.state.upsSalesProfessionalName}
                onChange={this.handleInputs}
                error={this.state.upsSalesProfessionalNameError != ""}
                helperText={this.state.upsSalesProfessionalNameError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {
                        <Tooltip
                          title="Full name of UPS sales person"
                          aria-label="Add"
                        >
                          <icons.help />
                        </Tooltip>
                      }
                    </InputAdornment>
                  ),
                }}
              />
            ) : null}
            {/* {this.state.type == "2" || this.state.type == "3" ? (
              <TextField
                margin="dense"
                name="upsRegion"
                label="UPS Region*"
                type="text"
                fullWidth
                value={this.state.upsRegion}
                onChange={this.handleInputs}
              />
            ) : null} */}
            {/* {this.state.type == "2" || this.state.type == "3" ? (
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    UPS Regions
                    {
                      <Tooltip title="Choose UPS region" aria-label="Add">
                        <icons.help />
                      </Tooltip>
                    }
                   
                    <select
                      name="upsRegion"
                      placeholder="Select a Region"
                      value={this.state.upsRegion}
                      id="options"
                      onChange={this.handleUpsRegionChange}
                    >
                      {options.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    <Typography>
                      {this.state.upsRegion.length == 0
                        ? this.state.upsRegionError
                        : null}
                    </Typography>
                  </FormLabel>
                </FormControl>
              </div>
            ) : null} */}

            {/* test */}
            {this.state.type == "2" || this.state.type == "3" ? (
              <div>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    style={{ paddingBottom: "10px", paddingTop: "10px" }}
                  >
                    UPS Regions
                    {
                      <Tooltip title="Select UPS region" aria-label="Add">
                        <icons.help />
                      </Tooltip>
                    }
                  </FormLabel>
                  <Select
                    input={
                      <OutlinedInput
                        style={{ padding: "14.5px 14px !important" }}
                        name="age"
                        id="outlined-age-simple"
                      />
                    }
                    disableUnderline
                    value={this.state.upsRegion}
                    onChange={this.handleUpsRegionChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    IconComponent={ExpandMore}
                    error={Boolean(this.state.upsRegionError)}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "bottom",
                      },

                      transformOrigin: { vertical: "top", horizontal: "top" },
                    }}
                  >
                    <MenuItem value={""} disabled>
                      Select Region
                    </MenuItem>
                    {options.map((val, index) => {
                      return (
                        <MenuItem key={index} value={val}>
                          {val}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.upsRegion.length == 0
                      ? this.state.upsRegionError
                      : null}
                  </FormHelperText>
                </FormControl>
              </div>
            ) : null}
            {/* test end */}

            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  User Type
                  {
                    <Tooltip title="Choose user category" aria-label="Add">
                      <icons.help />
                    </Tooltip>
                  }
                </FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="userType"
                  value={this.state.userType}
                  onChange={this.handleRadioUserTypeChange}
                  row
                >
                  <FormControlLabel
                    value={"false"}
                    control={<Radio color="primary" />}
                    label="INDIVIDUAL"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={"true"}
                    control={<Radio color="primary" />}
                    label="AGENCY"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Pricing plan
                  {
                    <Tooltip title="Choose a Pricing plan" aria-label="Add">
                      <icons.help />
                    </Tooltip>
                  }
                </FormLabel>
                <RadioGroup
                  aria-label="position"
                  name="pricingPlan"
                  value={this.state.pricingPlan}
                  onChange={this.handleRadioPricingChange}
                  row
                >
                  {this.state.userType == "false" ? (
                    <FormControlLabel
                      value={"8"}
                      control={<Radio color="primary" />}
                      label="STANDARD"
                      labelPlacement="end"
                    />
                  ) : null}
                  {this.state.userType == "false" ? (
                    <FormControlLabel
                      value={"7"}
                      control={<Radio color="primary" />}
                      label="JUMPSTART"
                      labelPlacement="end"
                    />
                  ) : null}
                  {this.state.userType == "true" ? (
                    <FormControlLabel
                      value={"11"}
                      control={<Radio color="primary" />}
                      label="PROFESSIONAL"
                      labelPlacement="end"
                    />
                  ) : null}
                  {this.state.userType == "true" ? (
                    <FormControlLabel
                      value={"12"}
                      control={<Radio color="primary" />}
                      label="ELITE"
                      labelPlacement="end"
                    />
                  ) : null}
                  {this.state.userType == "true" ? (
                    <FormControlLabel
                      value={"13"}
                      control={<Radio color="primary" />}
                      label="PREMIER"
                      labelPlacement="end"
                    />
                  ) : null}
                </RadioGroup>
              </FormControl>
            </div>

            <TextField
              placeholder="200"
              inputProps={{ maxLength: 200 }}
              margin="dense"
              name="note"
              label="Note"
              type="text"
              multiline={true}
              rows={4}
              fullWidth
              value={this.state.note}
              onChange={this.handleInputs}
              error={this.state.noteError != ""}
              helperText={this.state.noteError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {
                      <Tooltip
                        title="Please Provide Extra Information if Required."
                        aria-label="Add"
                      >
                        <icons.help />
                      </Tooltip>
                    }
                  </InputAdornment>
                ),
              }}
            />
            {tagLine != "" && this.state.type != "3" ? (
              <CustomTagLine className={classes.tagLine}>
                {this.state.tagLine}
              </CustomTagLine>
            ) : null}
            {serverError != "" ? (
              <ErrorTypography align={"center"}>{serverError}</ErrorTypography>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCancel} color="primary">
              CANCEL
            </Button>
            <Button onClick={this.onSubmit} color="primary">
              REGISTER
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.successAlert}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          autoHideDuration={6000}
        >
          <CustomSnackbarContent
            onClose={this.handleClose}
            variant="success"
            message={
              <span className={"flex alignItemsCenter"}>
                <icons.CheckCircle />
                &nbsp;{"User Registered Successfully"}
              </span>
            }
          />
        </Snackbar>
      </div>
    );
  }
}

UserFrom.propTypes = {
  handleUserDialogClose: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  addUser: (userInfo) => {
    return dispatch(addUser(userInfo));
  },
  getPlan: (planId) => {
    return dispatch(getAmount(planId));
  },
  fetchProvUserDetails: (emailId) => {
    return dispatch(fetchProvUserDetails(emailId));
  },
  fetchCSRFToken: () => dispatch(fetchCSRFToken()),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(userFormStyles)(UserFrom));
