import React from "react";
import PaidSearchSection from "./paidSearchSection.jsx";
import IndustryTrendSection from "./IndustryTrendSection.jsx";
import LxrguideGrowthModel from "./lxrguidegrowthmodel.jsx";
import GrowthModel from "./GrowModelWork.jsx";
import Testimonial from "./homepageTestimonal.jsx";
import CompaniesImage from "./companiesImages.jsx";
import { Card, CardContent } from "@mui/material";
import CookiePopUp from "../cookiePopUp";
import { connect } from "react-redux";
import * as types from "../../actions/actionTypes";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

class HomeDashboard extends React.PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.homePageTrack();
    if(Cookies.get('CSRF-TOKEN') != null || Cookies.get('CSRF-TOKEN') != undefined){
      Cookies.remove('CSRF-TOKEN');
    }
  }

  render() {
    const width = this.props;
    return (
      <Card style={{ boxShadow: "unset" }}>
        <CardContent style={{ padding: 0, paddingBottom: 0 }}>
          <Helmet>
            <title>
              {" "}
              LXRGuide PPC Management Tool | Google Ads & Microsoft Advertising
              | LXRGuide
            </title>
            <meta
              name="description"
              content="Sign up for LXRGuide to experience one of the best PPC management tools. Grow your business and win more customers with our easy-to-use, self-service platform."
            />
            {/*<meta name="google-site-verification" content="3Fgg4DrSVg_Z2KGn2z671kgsIUhyuBWjngt514uLpzw" />*/}
          </Helmet>
          <PaidSearchSection />
          {/* we are commenting this section as the discussion with team */}
          {/* <IndustryTrendSection/> */}
          {/* <LxrguideGrowthModel /> */}
          <GrowthModel />
          <Testimonial />
          <CompaniesImage />
          <CookiePopUp />
          {/* <Slider/> */}
        </CardContent>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  homePageTrack: () => dispatch({ type: types.HOME_PAGE }),
});
export default connect(null, mapDispatchToProps)(withWidth()(HomeDashboard));
