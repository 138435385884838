import React from "react";
import withStyles from "@mui/styles/withStyles";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import icons from "../components/icons.js";
import className from "classnames";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import * as types from "../actions/actionTypes";
import { connect } from "react-redux";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => (WrappedComponent) => (props) =>
  <WrappedComponent {...props} width="xl" />;

const styles = {
  root: {
    width: 500,
  },
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    boxShadow: "0px 6px 30px rgba(0, 0, 0, 0.25)",
  },
};

class MobileFooter extends React.PureComponent {
  async componentDidMount() {
    const {
      location: { pathname },
    } = this.props;
    const { viewValue } = this.props;
    if (pathname.includes("dashboard") && viewValue != 0) {
      await this.props.updateViewValue(0);
    } else if (pathname.includes("tasks") && viewValue != 1) {
      await this.props.updateViewValue(1);
    } else if (pathname.includes("reports") && viewValue != 2) {
      await this.props.updateViewValue(2);
    } else if (pathname.includes("activity-report") && viewValue != 3) {
      await this.props.updateViewValue(3);
    }
    else if(pathname.includes("/intelli-Dashboard") && viewValue != 4){
      await this.props.updateViewValue(4)
    }
  }

  handleChange = (event, value) => {
    this.props.updateViewValue(value);
  };

  render() {
    const { classes, width, theme } = this.props;
    const { viewValue } = this.props;
    var selectedColor = theme.palette.secondary.main;
    const defaultColor = theme.palette.text.primary;
    const dashboardColour = viewValue == 0 ? selectedColor : defaultColor;
    const tasksColour = viewValue == 1 ? selectedColor : defaultColor;
    const reportsColour = viewValue == 2 ? selectedColor : defaultColor;
    const activityReportColour = viewValue == 3 ? selectedColor : defaultColor;
    return (
      <div style={{ height: 50 }}>
        {width == "sm" ||
          (width == "xs" && (
            <BottomNavigation
              value={viewValue}
              onChange={this.handleChange}
              showLabels
              className={className(classes.root, classes.stickToBottom)}
            >
              <BottomNavigationAction
                label={"Dashboard"}
                icon={icons.dashboard(dashboardColour)}
                component={Link}
                to={`/dashboard`}
              />
              <BottomNavigationAction
                label={"Tasks"}
                icon={icons.tasks1(tasksColour)}
                component={Link}
                to={`/tasks`}
              />
              <BottomNavigationAction
                label={"Reports"}
                icon={icons.reports(reportsColour)}
                to={`/reports`}
              />
              <BottomNavigationAction
                label={"Activity Reports"}
                icon={icons.activityReports(activityReportColour)}
                component={Link}
                to={`/activity-report`}
              />
            </BottomNavigation>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  viewValue: state.dashboard.viewValue,
});
const mapDispatchToProps = (dispatch) => ({
  updateViewValue: (viewValue) => {
    return dispatch({
      type: types.DASHBOARD_VIEW_VALUE_UPDATED,
      viewValue: viewValue,
    });
  },
});

MobileFooter.propTypes = {};
const _MobileFooter = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileFooter);
export default withRouter(
  withWidth()(withStyles(styles, { withTheme: true })(_MobileFooter))
);
