import { Divider, Grid, Typography, Button, Card } from "@mui/material";
import { BigTitle } from "./NewDashBoardTypography";
import "./NewDashBoard.css";

export const ConnectionCard = ({ title, icon1, icon2, description, buttonText, onClick ,cardSize = { xs: 12, md: 6 } }) => (
  <Grid item {...cardSize}>
    <Card className="card">
      <BigTitle style={{ display: "flex", alignItems: "center" }}>
        {title}
        <span style={{ marginLeft: "8px", display: "flex", justifyContent: "center" }}>
          {icon1}
        </span>
      </BigTitle>
      <Grid container spacing={2} direction="row" paddingTop={4} paddingX={2}>
        <Grid item display="flex" alignItems="center" justifyContent="space-evenly" xs={3} sm={3}>
          {icon2}
          <Divider orientation="horizontal" sx={{ borderWidth: "thin", height: "100%" }} />
        </Grid>
        <Grid item container direction="column" rowGap={1} xs={9} sm={9}>
          <Grid item>
            <Typography fontSize="1.25rem" fontWeight="bold">Action Required:</Typography>
          </Grid>
          <Grid item>
            <Typography color="#777777">{description}</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={onClick}
              color="secondary"
              sx={{ textTransform: "none" }}
            >
              <Typography color={"#fff"} fontFamily={"Lato"}>{buttonText}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  </Grid>
);