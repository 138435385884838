import React, { useState, useEffect, useRef } from "react";
import ChatInput from "./ChatInput";
import "./chatbottest.css";
import { OrangeTextCB } from "./ChatbotUIElements"
import ChatbotFooter from "./ChatbotFooter";

const ChatWelcomePage = ({ onSend, onHomeIconClick, userName, chatWindowRef }) => {
  return (
    <div
      className="chat-window"
      style={{
        bottom: 170,
        right: 50,
        position: "fixed",
        background:
          "linear-gradient(315deg, rgb(255, 244, 235) 0%, rgb(249, 246, 242) 100%)",
        textAlign: "center",
        justifyContent: "center",
      }}
      ref={chatWindowRef}
    >
      <OrangeTextCB> Welcome {userName} </OrangeTextCB>
      <OrangeTextCB style={{ fontSize: "1.5rem" }}>How can I help you today? </OrangeTextCB>
      <div style={{ position: "absolute", bottom: "0px",width:"100%" }}>
        <ChatInput onSend={onSend} />
        {/* <ChatbotFooter onHomeIconClick={onHomeIconClick} /> */}
      </div>
    </div>
  );
};
export default ChatWelcomePage;
