import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography, DialogTitle } from "@mui/material";
import {
  NewTextRectOrangeTextBtn,
  NewOrangeSaveContainedBtn,
  NewOutLinedRectBlueBtn,
} from "../../components/buttons";
import {
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  TextField,
} from "@mui/material/index";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import {
  resumeAccount,
  unSubscribeAccount,
  updateSubscriptionByType,
} from "../../actions/fetchUserProfile";
import { ErrorTypography } from "../../components/typography";
import MySnackbarWrapper from "../snackbars";
import { InputFields } from "../../components/input-forms";

let moment = require("moment");

const CustomDialogContentText = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: 16,
    // minWidth:200,
    // minHeight:100
  },
}))(DialogContentText);

function Subscribe(props) {
  // const [state, setState] = useState({
  //   dialog: false,
  //   // isPayAndSubscribeLoading: false,
  //   commonError: "",
  //   alert: false,
  //   message: "",
  //   variantType: "info",
  //   unSubscribeComment: "",
  //   unSubscribeCommentError: "",
  // });


  const [dialog, setDialog] = useState(false);
  const [isPayAndSubscribeLoading, setIsPayAndSubscribeLoading] = useState(false);
  const [commonError, setCommonError] = useState("");
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [variantType, setVariantType] = useState("info");
  const [unSubscribeComment, setUnSubscribeComment] = useState("")
  const [unSubscribeCommentError, setUnSubscribeCommentError] = useState("");
  const [subscriptionType, setSubscriptionType] = useState("");

  const handlePayAndSubScribe = async (subscriptionType) => {
    
    if (subscriptionType == "reSubscribe") {
      // await setState({
      //   dialog: true,
      //   subscriptionType: subscriptionType,
      //   isPayAndSubscribeLoading: true,
      // });
      setDialog(true)
      setSubscriptionType(subscriptionType)
      setIsPayAndSubscribeLoading(true);

      await handleAgree();
    } else {
      // setState({ dialog: true, subscriptionType: subscriptionType });
      setDialog(true)
      setSubscriptionType(subscriptionType)

    }
  };

  const validate = () => {
    let isError = false;
    // const errors = {};
    if (
      unSubscribeComment &&
      unSubscribeComment.length === 0
      //    ||
      //   state.unSubscribeComment.trim() === ""
    ) {
      isError = true;
      setUnSubscribeCommentError("Please write the unsubscription reason");
    } else {
      setUnSubscribeCommentError("");
    }
    if (isError) {
      // setState({
      //   ...state,
      //   ...errors,
      // });
      setUnSubscribeCommentError(unSubscribeCommentError)
    }
    return isError;
  };

  const handleAgree = async () => {
   
    // setState({ isPayAndSubscribeLoading: true });
    setIsPayAndSubscribeLoading(true)
    console.log("props=", props);
    let response = await props.subscription(subscriptionType);
    if (
      response !== null &&
      response &&
      response.errorMsg &&
      response.errorMsg != ""
    ) {
      // setState({ commonError: response.errorMsg });
      setCommonError(response.errorMsg);
    } else if (
      response !== null &&
      response &&
      response.errorMsg &&
      response.errorMsg == ""
    ) {
      // setState({
      //   commonError: response.errorMsg,
      // });
      setCommonError(response.errorMsg)
    } else {
      // setState({
      //   dialog: false,
      //   commonError: "",
      //   alert: true,
      //   message: response.message,
      //   variantType: "success",
      //   isPayAndSubscribeLoading: false,
      // });

      setDialog(false)
      setCommonError("")
      setAlert(true)
      setMessage(response.message)
      setVariantType("success")
      setIsPayAndSubscribeLoading(false)


    }
  };

  const handleResumeAgree = async () => {
    // setState({ isPayAndSubscribeLoading: true });
    setIsPayAndSubscribeLoading(true)
    setIsPayAndSubscribeLoading(true)
    console.log("props=", props);
    let response = await props.resumeAccount();
    if (
      response !== null &&
      response &&
      response.errorMsg &&
      response.errorMsg != ""
    ) {
      // setState({ commonError: response.errorMsg });
      setCommonError(response.errorMsg)
    } else if (
      response !== null &&
      response &&
      response.errorMsg &&
      response.errorMsg == ""
    ) {
      // setState({
      //   commonError: response.errorMsg,
      // });
      setCommonError(response.errorMsg)
    } else {
      // setState({
      //   dialog: false,
      //   commonError: "",
      //   alert: true,
      //   message: response.message,
      //   variantType: "success",
      //   isPayAndSubscribeLoading: false,
      // });
      setDialog(false)
      setCommonError("")
      setAlert(true)
      setMessage(response.message)
      setVariantType("success")
      setIsPayAndSubscribeLoading(false)




    }
  };

  const handleUnsubscribeAgree = async (event) => {
    event.preventDefault();
    // const { unSubscribeComment } = state;
    let error;
    error = validate();
    if (!error) {
      // setState({ isPayAndSubscribeLoading: true });
      setIsPayAndSubscribeLoading(true);
      let response = await props.unSubscription(unSubscribeComment);
      if (
        response !== null &&
        response &&
        response.errorMsg &&
        response.errorMsg != ""
      ) {
        // setState({ commonError: response.errorMsg });
        setCommonError(response.errorMsg);
      } else if (
        response !== null &&
        response &&
        response.errorMsg &&
        response.errorMsg == ""
      ) {
        // setState({
        //   // dialog: false,
        //   commonError: response.errorMsg,
        // });
        setCommonError(response.errorMsg);
      } else {
        // setState({
        //   dialog: false,
        //   commonError: "",
        //   alert: true,
        //   message: "You have been successfully unsubscribed from LXRGuide",
        //   variantType: "success",
        //   isPayAndSubscribeLoading: false,
        // });
        setDialog(false)
        setCommonError("")
        setAlert(true)
        setMessage("You have been successfully unsubscribed from LXRGuide")
        setVariantType("success")
        setIsPayAndSubscribeLoading(false)




      }
    }
  };

  const handleCloseAlert = async () => {
    // setState((prevState) => ({
    //   ...prevState,
    //   alert: false,
    // }));
    setAlert(false)
    //  setState({ alert: false });
  };

  const handleChange = (event) => {
   
    // setState({ [unSubscribeComment]: event.target.value });

    setUnSubscribeComment(event.target.value)
  };

  const handleClose = () => {
    // setState({ dialog: false });
    setDialog(false)
  };

  const handleResume = () => {
    // setState({ dialog: true, subscriptionType: "resume" });
    setDialog(true)
    setSubscriptionType("resume")
  };

  const handleUnsubscribe = () => {
    // setState({ dialog: true, subscriptionType: "unSubscribe" });
    setDialog(true)
    setSubscriptionType("unSubscribe")
  };

  const getUnSubscribeDialogDetails = (commonError, unSubscribeComment) => {
    return (
      <div style={{ zoom: "90%" }}>
        <DialogTitle style={{ paddingBottom: 0 }}>
          <b>{"Unsubscription"}</b>
        </DialogTitle>
        <form onSubmit={handleUnsubscribeAgree}>
          <DialogContent>
            {commonError != "" ? (
              <ErrorTypography>{commonError}</ErrorTypography>
            ) : null}
            <Grid container>
              <Grid item md={12}>
                <Typography variant={"body1"} gutterBottom>
                  <b>Reason</b>
                </Typography>
                <FormControl className={"fullWidth"}>
                  <InputFields
                    // value={unSubscribeComment}
                    onChange={handleChange}
                    // onChange={(event) =>
                    //   setState({ unSubscribeComment: event.target.value })
                    // }
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={6}
                    rowsMax="10"
                    fullWidth
                    error={unSubscribeCommentError !== ""}
                    helperText={unSubscribeCommentError}
                    style={{ marginTop: 0 }}
                  // className={classes.inputFieldStyle}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <NewTextRectOrangeTextBtn onClick={handleClose} color="primary">
              Cancel
            </NewTextRectOrangeTextBtn>
            <NewTextRectOrangeTextBtn type="submit" autoFocus>
              OK
            </NewTextRectOrangeTextBtn>
          </DialogActions>
        </form>
      </div>
    );
  };

  const getResumeDialogDetails = (commonError) => {
    return (
      <div>
        <DialogTitle>{"Resume"}</DialogTitle>
        <DialogContent>
          {commonError != "" ? (
            <ErrorTypography>{commonError}</ErrorTypography>
          ) : null}
          <Typography variant={"body1"} color={"textPrimary"}>
            Please Click OK to resume your account
          </Typography>
        </DialogContent>
        <DialogActions>
          <NewTextRectOrangeTextBtn onClick={handleClose} color="primary">
            Cancel
          </NewTextRectOrangeTextBtn>
          <NewTextRectOrangeTextBtn onClick={handleResumeAgree} autoFocus>
            OK
          </NewTextRectOrangeTextBtn>
        </DialogActions>
      </div>
    );
  };

  const getSubscribeDialogDetails = (
    commonError,
    amount,
    nextPaymentDate,
    userInfo,
    advanceRemaining
  ) => {
    return (
      <div>
        <DialogTitle>{"Subscribe"}</DialogTitle>
        <DialogContent>
          {commonError != "" ? (
            <ErrorTypography>{commonError}</ErrorTypography>
          ) : null}
          <Typography color={"textPrimary"} variant={"body1"} gutterBottom>
            You will be charged $<b>{amount}</b>
          </Typography>
          <Typography color={"textPrimary"} variant={"body1"} gutterBottom>
            Your subscription begins today and will be renewed on (
            {nextPaymentDate})
          </Typography>
          {userInfo.advanceRemaining - amount >= 0 ? (
            <Typography color={"textPrimary"} variant={"body1"} gutterBottom>
              {" "}
              Your remaining CTP amount will be $
              <b>{advanceRemaining - amount}</b>
            </Typography>
          ) : null}
          {userInfo.advanceRemaining - amount < 0 &&
            advanceRemaining > 0 &&
            userInfo.advanceRemaining - amount < 0 ? (
            <Typography color={"textPrimary"} variant={"body1"} gutterBottom>
              Your remaining CTP amount will be $<b>0.00</b>
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <NewTextRectOrangeTextBtn onClick={handleClose} color="primary">
            Cancel
          </NewTextRectOrangeTextBtn>
          <NewTextRectOrangeTextBtn onClick={handleAgree} autoFocus>
            Pay
          </NewTextRectOrangeTextBtn>
        </DialogActions>
      </div>
    );
  };

  // const {
  //   // isPayAndSubscribeLoading,
  //   commonError,
  //   subscriptionType,
  //   unSubscribeComment,
  // } = state;
  const { userProfileInformation, userInfo } = props;
  const { amount, endDate, nextPaymentDate } = userProfileInformation;
  console.log("nextPaymentDate details ", nextPaymentDate);
  const advanceRemaining =
    userInfo && userInfo.advanceRemaining ? userInfo.advanceRemaining : 0;
  console.log("props=", props);

  const loadSubscribes = () => {
    const { userInfo } = props;
    if (userInfo.type != 7) {
      if (userInfo.unsubscribed == 0 && userInfo.type != 9) {
        return (
          <Fragment>
            <Typography variant={"body1"} gutterBottom>
              <NewOutLinedRectBlueBtn
                className={"fullWidth"}
                variant={"outlined"}
                onClick={handleUnsubscribe}
              >
                Unsubscribe
              </NewOutLinedRectBlueBtn>
            </Typography>
          </Fragment>
        );
      } else if (userInfo.unsubscribed == 3 && userInfo.permission == true) {
        return (
          <Typography variant={"body1"} gutterBottom className={"fullWidth"}>
            <NewOutLinedRectBlueBtn
              className={"fullWidth"}
              variant={"outlined"}
              onClick={handleResume}
            >
              Resume
            </NewOutLinedRectBlueBtn>
          </Typography>
        );
      } else if (
        userInfo.unsubscribed == 2 ||
        (userInfo.unsubscribed == 3 && userInfo.permission == false)
      ) {
        return (
          <Fragment>
            <Typography variant={"body1"} gutterBottom>
              <NewOutLinedRectBlueBtn
                className={"fullWidth"}
                variant={"outlined"}
                onClick={() => handlePayAndSubScribe("subscribe")}
              >
                Pay and Subscribe
              </NewOutLinedRectBlueBtn>
            </Typography>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {userInfo.type != 9 ? (
              <Typography variant={"body1"} gutterBottom>
                <NewOutLinedRectBlueBtn
                  className={"fullWidth"}
                  variant={"outlined"}
                  onClick={() => handlePayAndSubScribe("reSubscribe")}
                >
                  Resubscribe
                </NewOutLinedRectBlueBtn>
              </Typography>
            ) : null}
          </Fragment>
        );
      }
    }
  };

  return (
    <Grid container>
      <Grid item sm={8} xs={8} md={5}>
        {loadSubscribes()}
      </Grid>
      <Grid item>
        <Dialog
          open={dialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          // fullWidth={isPayAndSubscribeLoading ? false : true}
          maxWidth={
            isPayAndSubscribeLoading
              ? false
              : subscriptionType == "subscribe"
                ? "sm"
                : "xs"
          }
        >
          {isPayAndSubscribeLoading ? (
            <DialogContent>
              <CustomDialogContentText>
                <CircularProgress />
              </CustomDialogContentText>
            </DialogContent>
          ) : (
            <Fragment>
              {subscriptionType == "subscribe" &&
                getSubscribeDialogDetails(
                  commonError,
                  amount,
                  nextPaymentDate,
                  userInfo,
                  advanceRemaining
                )}
              {subscriptionType == "resume" &&
                getResumeDialogDetails(commonError)}
              {subscriptionType == "unSubscribe" &&
                getUnSubscribeDialogDetails(commonError, unSubscribeComment)}
            </Fragment>
          )}
        </Dialog>
        <MySnackbarWrapper
          open={alert}
          onClose={handleCloseAlert}
          message={message}
          verticalAlign={"bottom"}
          horizontalAlign={"right"}
          duration={2000}
          variant={variantType}
        />
      </Grid>
    </Grid>
  );
}

Subscribe.propTypes = {};
const mapDispatchToProps = (dispatch) => ({
  subscription: (subscriptionType) =>
    dispatch(updateSubscriptionByType(subscriptionType)),
  resumeAccount: () => dispatch(resumeAccount()),
  unSubscription: (comment) => dispatch(unSubscribeAccount(comment)),
});
export default connect(null, mapDispatchToProps)(Subscribe);
