import { Grid, Paper, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  fetchIndustryInsightData,
  fetchIndustryInsightIndusryData,
} from "../../actions";
import { OrangeTextSize12 } from "../../components/typography";

const styles = (theme) => ({
  orange: {
    display: "flex",
    justifyContent: "center",
  },
  gutBott: {
    fontSize: "18px",
  },
  heading: {
    display: "flex",
    justifyContent: "center",
    fontSize: "48px",
    fontWeight: "700",
  },
  small: {
    display: "flex",
    justifyContent: "center",
    fontSize: "17px",
  },
  summGrid: {
    backgroundColor: "#FFFFFF",
  },
  summary: {
    [theme.breakpoints.up("md")]: {
      font: "lato",
      fontSize: "18px",

      textAlign: "left",
      color: "#333333",
      paddingLeft: "18px",
      paddingRight: "20px",
    },
    [theme.breakpoints.down('xl')]: {
      font: "lato",
      fontSize: "18px",

      textAlign: "center",
      color: "#333333",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  summPoints: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "40px",
      font: "lato",
      fontSize: "18px",
      color: "#F58120",
    },
    [theme.breakpoints.down('xl')]: {
      font: "lato",
      fontSize: "18px",
      color: "#F58120",
    },
  },
  summPointsText: { color: "#333333", fontSize: "18px", paddingRight: "20px" },
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "70px",
    },
    [theme.breakpoints.down('xl')]: {
      padding: "20px",
    },
    backgroundColor: "#ECEFF3",
  },
  dropdown: {
    width: "250px",
    height: "35px",
    borderRadius: "5px",
    borderColor: "#A2B6C3",
    fontSize: "14px",
    color: "1E334E",
    fontWeight: "bold",
  },
  summTypo: {
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    font: "lato",
    fontSize: "20px",
  },
  gridTypos: {
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "center",
  },
  CPCNum: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  Comyr: {
    fontSize: "14px",
  },
  ComNum: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  ConNum: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  ComQuat: {
    fontSize: "14px",
  },
  yearRow: {
    fontSize: "14px",
    color: "#FFFFFF",
  },
  tableInternalValues: {
    fontSize: "16px",
    fontWeight: "700",
    borderBottom: "1px,dotted, #000",
    textDecoration: "none",
  },
    HRDivider: {
      width: "80%",
      color: "#E3E6F4",
      borderTop: "dotted 1px",
    },
  ToolTipDiv: {
    width: "180px",
    border: "1px solid #C4C4C4",
    borderRadius: "5px",
    backgroundColor: "white",
  },
  ToolTipPara1: {
    color: "#1098F7",
  },
  ToolTipPara2: {
    color: "#F58120",
  },
});

function IndustryTrendNewSection(props) {
  let selectedIndustry = "";
  const classes = props.classes;
  const {
    width,
    fetchIndustryInsightData,
    industryInsightsDataReducers,
    fetchIndustryInsightIndusryData,
    industryInsightsIndustryDataReducers,
  } = props;

  //Home page API call after refreshed
  useEffect(() => {
    if (industryInsightsIndustryDataReducers.message === "") {
      fetchIndustryInsightIndusryData();
    }
  }, []);
  console.log(
    "industryInsightsIndustryDataReducers=====>",
    industryInsightsIndustryDataReducers
  );
  const url = window.location.href;
  console.log("url is", url);
  let paramString = url.split("?")[1];
  let queryString = new URLSearchParams(paramString);
  console.log("queryString-->", queryString.get("industry"));

  var industryValue = paramString
    .substring(9, paramString.length)
    .replace(/%20/g, " ");
  console.log("industryValue===>", industryValue);

  var industryValueNoSplChar = industryValue.replace(/[^a-zA-Z &]/g, "");
  console.log("The industryValue is:::", industryValueNoSplChar);
  console.log("The match is:::", props.match.params);

  //Main page API call after refreshed
  useEffect(() => {
    window.scrollTo(0, 0);
    if (industryInsightsIndustryDataReducers.message !== "") {
      console.log("");
      fetchIndustryInsightData(industryValueNoSplChar);
    }
  }, [industryInsightsIndustryDataReducers]);

  const industryDetails = industryInsightsDataReducers.industry_details
    ? industryInsightsDataReducers.industry_details
    : null;

  // If someone change the code in backend than coming data may be changed.
  if (industryDetails) {
    industryDetails.sort((a, b) => {
      if (a.year === b.year) {
        return a.quarter > b.quarter ? -1 : 1;
      } else {
        return a.year > b.year ? -1 : 1;
      }
    });
  }

  //we are adding all industries from industry details and we are removing the duplicate industries
  //in the and there is only one industry is in activeIndustryArr.
  let activeIndustryArr = [];
  if (industryDetails) {
    for (let i = 0; i < Object.keys(industryDetails).length; i++) {
      activeIndustryArr.push(industryDetails[i].industry);
    }
    activeIndustryArr = activeIndustryArr.filter(function (
      item,
      index,
      activeIndustryArr
    ) {
      return activeIndustryArr.indexOf(item) == index;
    });
  }

  let industryArr = [];
  if (industryDetails) {
    console.log(
      "industryInsightsIndustryDataReducers",
      industryInsightsIndustryDataReducers
    );
    delete industryInsightsIndustryDataReducers["message"];
    for (
      let i = 0;
      i < Object.keys(industryInsightsIndustryDataReducers).length;
      i++
    ) {
      industryArr.push(industryInsightsIndustryDataReducers[i].industry);
    }
  }

  if (industryDetails) {
    var pyIndex = industryDetails.length - 1;
    var cyIndex = "0";
  }
  const [prevYr, setPrevYr] = useState(
    pyIndex ? industryDetails[pyIndex].year : null
  );
  const [currYr, setCurrYr] = useState(
    cyIndex ? industryDetails[cyIndex].year : null
  );
  const Gdata = [];
  if (industryDetails) {
    if (industryDetails[0] && Gdata.length === 0) {
      Gdata.push(industryDetails[0]);
      Gdata.push(industryDetails[1]);
      Gdata.push(industryDetails[2]);
      Gdata.push(industryDetails[3]);
    }
  }
  useEffect(() => {
    if (industryDetails && industryDetails[0] && Gdata.length === 0) {
      Gdata.push(industryDetails[0]);
      Gdata.push(industryDetails[1]);
      Gdata.push(industryDetails[2]);
      Gdata.push(industryDetails[3]);
    }
    if (industryDetails && pyIndex && industryDetails[pyIndex]) {
      setPrevYr(industryDetails[pyIndex].year);
    }
    if (industryDetails && cyIndex && industryDetails[cyIndex]) {
      setCurrYr(industryDetails[cyIndex].year);
    }
  }, [industryDetails]);

  console.log("The Graph data is", Gdata);
  const Gdata1 = Gdata.reverse();

  const CPCArray1 = [];
  for (var i in industryDetails) {
    if (industryDetails && industryDetails[i].year === prevYr) {
      CPCArray1.push(industryDetails[i]);
    }
  }

  CPCArray1.reverse();
  const QuatPy = [];
  for (var i in CPCArray1) {
    QuatPy[i] = CPCArray1[i].quarter;
  }
  var len1 = CPCArray1.length;
  while (len1 < 4) {
    if (!QuatPy.includes(1)) {
      CPCArray1.push({ quarter: "1", conv_rate: "-", avg_cpc: "-" });
      QuatPy.push(1);
    } else if (!QuatPy.includes(2)) {
      CPCArray1.push({ quarter: "2", conv_rate: "-", avg_cpc: "-" });
      QuatPy.push(2);
    } else if (!QuatPy.includes(3)) {
      CPCArray1.push({ quarter: "3", conv_rate: "-", avg_cpc: "-" });
      QuatPy.push(3);
    } else if (!QuatPy.includes(4)) {
      CPCArray1.push({ quarter: "4", conv_rate: "-", avg_cpc: "-" });
      QuatPy.push(4);
    }
    len1 = len1 + 1;
  }
  CPCArray1.sort((a, b) => a.quarter - b.quarter);

  const CPCArray2 = [];
  for (var i in industryDetails) {
    if (industryDetails && industryDetails[i].year === currYr) {
      CPCArray2.push(industryDetails[i]);
    }
  }
  CPCArray2.reverse();

  const QuatCy = [];
  for (var i in CPCArray2) {
    QuatCy[i] = CPCArray2[i].quarter;
  }

  var len2 = CPCArray2.length;
  while (len2 < 4) {
    if (!QuatCy.includes(1)) {
      CPCArray2.push({ quarter: "1", conv_rate: "-", avg_cpc: "-" });
      QuatCy.push(1);
    } else if (!QuatCy.includes(2)) {
      CPCArray2.push({ quarter: "2", conv_rate: "-", avg_cpc: "-" });
      QuatCy.push(2);
    } else if (!QuatCy.includes(3)) {
      CPCArray2.push({ quarter: "3", conv_rate: "-", avg_cpc: "-" });
      QuatCy.push(3);
    } else if (!QuatCy.includes(4)) {
      CPCArray2.push({ quarter: "4", conv_rate: "-", avg_cpc: "-" });
      QuatCy.push(4);
    }
    len2 = len2 + 1;
  }
  CPCArray2.sort((a, b) => a.quarter - b.quarter);

  const industryAverages = industryInsightsDataReducers.averages
    ? industryInsightsDataReducers.averages
    : null;

  const [currYrAvCPC, setCurrYrAvCPC] = useState(
    industryAverages ? industryAverages.currYear_avg_cpc : null
  );
  const [currYrAvCR, setCurrYrAvCR] = useState(
    industryAverages ? industryAverages.currYear_avg_cvr : null
  );
  const [prevYrAvCPC, setPrevYrAvCPC] = useState(
    industryAverages ? industryAverages.prevYear_avg_cpc : null
  );
  const [prevYrAvCR, setPrevYrAvCR] = useState(
    industryAverages ? industryAverages.prevYear_avg_cvr : null
  );
  useEffect(() => {
    if (industryAverages) {
      setCurrYrAvCPC(industryAverages.currYear_avg_cpc.toFixed(2));
      setCurrYrAvCR(industryAverages.currYear_avg_cvr.toFixed(2));
      setPrevYrAvCPC(industryAverages.prevYear_avg_cpc.toFixed(2));
      setPrevYrAvCR(industryAverages.prevYear_avg_cvr.toFixed(2));
    }
  }, [industryAverages]);

  const industrySummary = industryInsightsDataReducers.summary_contents
    ? industryInsightsDataReducers.summary_contents
    : null;
  const [summ, setSumm] = useState(
    industrySummary ? industrySummary.summary : null
  );
  const [summPoint1, setSummPoint1] = useState(
    industrySummary ? industrySummary.point1 : null
  );
  const [summPoint2, setSummPoint2] = useState(
    industrySummary ? industrySummary.point2 : null
  );
  const [summPoint3, setSummPoint3] = useState(
    industrySummary ? industrySummary.point3 : null
  );
  const [summPoint4, setSummPoint4] = useState(
    industrySummary ? industrySummary.point4 : null
  );
  useEffect(() => {
    if (industrySummary) {
      setSumm(industrySummary.summary);
      setSummPoint1(industrySummary.point1);
      setSummPoint2(industrySummary.point2);
      setSummPoint3(industrySummary.point3);
      setSummPoint4(industrySummary.point4);
    }
  }, [industrySummary]);

  const handleDropdown = (event) => {
    selectedIndustry = event.target.value;
    console.log("Changed industry is", selectedIndustry);
    fetchIndustryInsightData(selectedIndustry);
    window.location.search = `?industry=${selectedIndustry}`;
  };

  let DollarFormatter = (num) => {
    return "$" + num.toFixed(2);
  };
  let PercentageFormatter = (num) => {
    return num.toFixed(2) + "%";
  };

  const renderTooltipContent = ({ active, payload, label }) => {
    if (active && label && payload && payload.length) {
      return (
        <div className={classes.ToolTipDiv}>
          <p>{`${label}`}</p>
          <p className={classes.ToolTipPara1}>
            Average CPC: ${`${payload[0].value.toFixed(2)}`}
          </p>
          <p className={classes.ToolTipPara2}>
            Conversion Rate: {`${payload[1].value.toFixed(2)}`}%
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.mainDiv}>
      <div>
        <Typography className={classes.orange}>
          <OrangeTextSize12 gutterBottom className={classes.gutBott}>
            INDUSTRY TREND
          </OrangeTextSize12>
        </Typography>
        <Typography className={classes.heading}>Insight</Typography>
        <Typography className={classes.small}>
          See your customer base growing with LXRGuide
        </Typography>
        <div>
          <br />
          <br />
          <br />
          <div>
            <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
              LXRGuide - NetElixir’s Retail Benchmark (updated quarterly)
            </Typography>
          </div>
          <hr />
          <div>
            <select
              className={classes.dropdown}
              name="industry"
              id="industry"
              onChange={handleDropdown}
              value={activeIndustryArr[0]}
            >
              {industryArr.length > 0 &&
                industryArr.map((items) =>
                  items === activeIndustryArr[0] ? (
                    <option style={{ backgroundColor: "#F58120" }} key={items}>
                      {items}
                    </option>
                  ) : (
                    <option key={items}>{items}</option>
                  )
                )}
            </select>
          </div>
        </div>
      </div>
      <br />
      <div>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            style={{ textAlign: "center" }}
          >
            <Paper>
              <br />
              <Typography className={classes.gridTypos}>Trend</Typography>
              <br />
              <ResponsiveContainer width="100%" height={400}>
                <ComposedChart data={Gdata1}>
                  <CartesianGrid strokeDasharray="0 0" vertical={false} />
                  <XAxis dataKey="qy" />
                  <YAxis
                    yAxisId="left"
                    axisLine={false}
                    tickFormatter={DollarFormatter}
                    tickCount={10}
                    orientation="left"
                    label={{
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <YAxis
                    yAxisId="right"
                    axisLine={false}
                    tickFormatter={PercentageFormatter}
                    tickCount={10}
                    orientation="right"
                    label={{
                      angle: -90,
                      position: "insideRight",
                    }}
                  />
                  <Tooltip content={(props) => renderTooltipContent(props)} />
                  <Legend />
                  <Bar
                    yAxisId="left"
                    name="Average CPC"
                    barSize={20}
                    dataKey="avg_cpc"
                    fill="#1098F7"
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    strokeWidth={2}
                    name="Conversion Rate"
                    dataKey="conv_rate"
                    stroke="#F58120"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Paper
              style={{
                background: "none",
                boxShadow: "none",
                textAlign: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper>
                    <br />
                    <Typography className={classes.gridTypos}>
                      Average CPC
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          style={{ marginRight: "65px" }}
                          className={classes.CPCNum}
                        >
                          ${prevYrAvCPC}
                        </Typography>
                        <Typography
                          style={{ marginRight: "86px" }}
                          className={classes.Comyr}
                        >
                          {prevYr}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          style={{ marginLeft: "65px" }}
                          className={classes.CPCNum}
                        >
                          ${currYrAvCPC}
                        </Typography>
                        <Typography
                          style={{ marginLeft: "86px" }}
                          className={classes.Comyr}
                        >
                          {currYr}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          justifyContent: "center",
                          backgroundColor: "#1E334E",
                          marginTop: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography
                            style={{ marginRight: "20px" }}
                            className={classes.yearRow}
                          >
                            Year
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography className={classes.yearRow}>
                            Q1
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography className={classes.yearRow}>
                            Q2
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography className={classes.yearRow}>
                            Q3
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography className={classes.yearRow}>
                            Q4
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          justifyContent: "center",
                          marginTop: "10px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                            }}
                          >
                            {prevYr}
                          </Typography>
                        </Grid>
                        {CPCArray1.map((items) => {
                          return items.avg_cpc !== "-" ? (
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Typography>
                                <span className={classes.tableInternalValues}>
                                  ${items.avg_cpc.toFixed(2)}
                                </span>
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Typography>
                                <span>{items.avg_cpc}</span>
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <hr className={classes.HRDivider}></hr>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          justifyContent: "center",
                          marginLeft: "10px",
                          marginRight: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                            }}
                          >
                            {currYr}
                          </Typography>
                        </Grid>
                        {CPCArray2.map((items) => {
                          return items.avg_cpc !== "-" ? (
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Typography>
                                <span className={classes.tableInternalValues}>
                                  ${items.avg_cpc.toFixed(2)}
                                </span>
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Typography>
                                <span className={classes.tableInternalValues}>
                                  {items.avg_cpc}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <br />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper>
                    <br />
                    <Typography className={classes.gridTypos}>
                      Conversion Rate
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          style={{ marginRight: "65px" }}
                          className={classes.CPCNum}
                        >
                          {prevYrAvCR}%
                        </Typography>
                        <Typography
                          style={{ marginRight: "92px" }}
                          className={classes.Comyr}
                        >
                          {prevYr}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          style={{ marginLeft: "65px" }}
                          className={classes.CPCNum}
                        >
                          {currYrAvCR}%
                        </Typography>
                        <Typography
                          style={{ marginLeft: "92px" }}
                          className={classes.Comyr}
                        >
                          {currYr}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          justifyContent: "center",
                          backgroundColor: "#1E334E",
                          marginTop: "5px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography
                            style={{ marginRight: "20px" }}
                            className={classes.yearRow}
                          >
                            Year
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography className={classes.yearRow}>
                            Q1
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography className={classes.yearRow}>
                            Q2
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography className={classes.yearRow}>
                            Q3
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography className={classes.yearRow}>
                            Q4
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          justifyContent: "center",
                          marginTop: "10px",
                          marginLeft: "10px",
                          marginRight: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                            }}
                          >
                            {prevYr}
                          </Typography>
                        </Grid>
                        {CPCArray1.map((items) => {
                          return items.conv_rate !== "-" ? (
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Typography>
                                <span className={classes.tableInternalValues}>
                                  {items.conv_rate.toFixed(2)}%
                                </span>
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Typography>
                                <span className={classes.tableInternalValues}>
                                  {items.conv_rate}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <hr className={classes.HRDivider}></hr>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          justifyContent: "center",
                          marginLeft: "10px",
                          marginRight: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <Grid item xs={2} sm={2} md={2} lg={2}>
                          <Typography
                            style={{
                              textAlign: "left",
                              fontSize: "14px",
                            }}
                          >
                            {currYr}
                          </Typography>
                        </Grid>
                        {CPCArray2.map((items) => {
                          return items.conv_rate !== "-" ? (
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Typography>
                                <span className={classes.tableInternalValues}>
                                  {items.conv_rate.toFixed(2)}%
                                </span>
                              </Typography>
                            </Grid>
                          ) : (
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                              <Typography>
                                <span className={classes.tableInternalValues}>
                                  {items.conv_rate}
                                </span>
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <br />
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div>
        <br />
        <Grid container className={classes.summGrid}>
          <Grid item>
            <br />
            <Typography className={classes.summTypo}>Summary</Typography>
            <br />
            {summ && (
              <Typography className={classes.summary}>{summ}</Typography>
            )}
            <ul className={classes.summPoints}>
              <li>
                <Typography className={classes.summPointsText}>
                  {summPoint1}
                </Typography>
              </li>
              <br />
              <li>
                <Typography className={classes.summPointsText}>
                  {summPoint2}
                </Typography>
              </li>
              {summPoint3 ? (
                <Fragment>
                  <br />
                  <li>
                    <Typography className={classes.summPointsText}>
                      {summPoint3}
                    </Typography>
                  </li>
                </Fragment>
              ) : (
                ""
              )}
              {summPoint4 ? (
                <Fragment>
                  <br />
                  <li>
                    <Typography className={classes.summPointsText}>
                      {summPoint4}
                    </Typography>
                  </li>
                </Fragment>
              ) : (
                ""
              )}
              <br />
            </ul>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  industryInsightsDataReducers: state.industryInsightsDataReducers,
  industryInsightsIndustryDataReducers:
    state.industryInsightsIndustryDataReducers,
});
const mapDispatchToProps = (dispatch) => ({
  fetchIndustryInsightData: (selectedIndustry) =>
    dispatch(fetchIndustryInsightData(selectedIndustry)),
  fetchIndustryInsightIndusryData: () =>
    dispatch(fetchIndustryInsightIndusryData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(IndustryTrendNewSection));
