import { Typography, TextField, Snackbar, Alert } from "@mui/material";
export const TicketHeading = (props) => {
  const { sx = {}, ...otherProps } = props;
  return (
    <Typography
      sx={{
        fontWeight: "600",
        fontSize: { xs: "0.875rem", sm: "1rem" },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export const TicketSubHeading = (props) => {
  const { sx = {}, ...otherProps } = props;
  return (
    <Typography
      sx={{
        fontWeight: "500",
        fontSize: { xs: "0.875rem", sm: "1rem" },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export const TicketBody = (props) => {
  const { sx = {}, ...otherProps } = props;
  return (
    <Typography
      sx={{
        fontWeight: "400",
        color: "#8A8A8A",
        fontSize: { xs: "0.875rem", sm: "1rem" },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export const TicketDate = (props) => {
  const { sx = {}, ...otherProps } = props;
  return (
    <Typography
      sx={{
        fontWeight: "400",
        color: "#8A8A8A",
        fontSize: { xs: "0.75rem", sm: "0.875rem" },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export const CustomTextField = (props) => {
  const { sx: sxProp = {}, disabled, ...otherProps } = props;
  const sx = disabled
    ? {
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        ".MuiOutlinedInput-root": {
          padding: "0",
        },
        ...sxProp,
      }
    : {
        ".MuiOutlinedInput-root": {
          padding: "10px",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#1E344E",
          },
          "&:hover fieldset": {
            borderColor: "#1E344E",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#1E344E",
          },
        },
        ...sxProp,
      };
  return <TextField sx={sx} disabled={disabled} {...otherProps} />;
};

export const CommentTextField = (props) => {
  const { sx = {}, ...otherProps } = props;
  return (
    <TextField
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#FFF",
          },
          "&:hover fieldset": {
            borderColor: "#1E344E",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#1E344E",
          },
        },
        ...sx,
      }}
      {...otherProps}
    />
  );
};

export const CustomSnackbar = (props) => {
  const { message, error, open, handleClose } = props;
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}   anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
      <Alert
        onClose={handleClose}
        severity={error ? "error" : "success"}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
