import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/system";
import { Chip, Grid, Pagination, Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const HeaderCell = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "1rem",
});

function descendingComparator(a, b, orderBy) {
  const getValue = (obj) => {
    if (orderBy === "requestStatus") {
      return obj[orderBy] === null ? 'open' : obj[orderBy];
    }
    return obj[orderBy];
  };

  const aValue = getValue(a);
  const bValue = getValue(b);

  if (aValue === undefined || bValue === undefined) {
    return aValue === undefined ? 1 : -1;
  }

  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return bValue.localeCompare(aValue);
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}



function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: true,
    date: false,
    disablePadding: false,
    label: "Ticket ID",
  },
  {
    id: "subject",
    numeric: false,
    date: false,
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "requestStatus",
    numeric: false,
    date: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "requestDate",
    numeric: false,
    date: true,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "dateResolved",
    numeric: false,
    date: true,
    disablePadding: false,
    label: "Date Resolved",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <HeaderCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                "& .MuiTableSortLabel-icon": {
                  opacity: 1,
                  color: "#CCCFD1",
                },
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </HeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function TicketsTable(props) {
  const { ticketDetails, openTickets, closedTickets, loading, filter } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useHistory();
  const [rows, setRows] = React.useState(ticketDetails ? ticketDetails : []);

  React.useEffect(() => {
    setRows(
      loading
        ? []
        : filter === "Open"
          ? openTickets
          : filter === "Closed"
            ? closedTickets
            : ticketDetails
    );
  }, [ticketDetails, openTickets, closedTickets, loading, filter]);

  const handleRequestSort = (event, property) => {
    debugger;
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "Open":
        return "error";
      case "Closed":
        return "success";
      default:
        return "error";
    }
  };
  console.log("emptyrows", emptyRows);

  const handleRowClick = (row) => {
    history.push(`/ticket/${row.id}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer sx={{ minHeight: 400 }}>
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.length > 0 ? (
                visibleRows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => handleRowClick(row)}
                      key={row.id}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <Typography>#{row.id}</Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <Typography>
                            <LightTooltip
                              title={row.subject.length > 60 ? row.subject : ""}
                              placement="top"
                              arrow
                            >
                              {row.subject.length > 60
                                ? `${row.subject.substring(0, 60)}...`
                                : row.subject}
                            </LightTooltip>
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <Chip
                            label={row.requestStatus ? row.requestStatus : "Open"}
                            color={getStatusColor(row.requestStatus ? row.requestStatus : "Open")}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <Typography>
                            {new Date(row.requestDate).toLocaleString("en-CA", {
                              hour12: false,
                            })}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align={row.dateResolved ? "left" : "center"}>
                        {loading ? (
                          <Skeleton />
                        ) : (
                          <Typography>
                            {row.dateResolved
                              ? new Date(row.dateResolved).toLocaleString(
                                "en-CA",
                                {
                                  hour12: false,
                                }
                              )
                              : "-"}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography color="textSecondary">
                      No Tickets Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {loading ? (
          <Skeleton width={"30%"} />
        ) : (visibleRows && visibleRows.length > 0 &&
          <TablePagination
            rowsPerPageOptions={[]}
            component="Grid"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
}
