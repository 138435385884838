import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchConnectionRelatedInfo } from "../../actions";
import GoogleAnalyticsConnection from "./googleAnalyticsConnection";
import { CircularProgress } from "@mui/material";
import GleBingConnection from "./gleBingConnection";
import GmcConnection from "./gmcConnection";
import ShoppingFeedConnection from "./shoppingFeedConnection";
import SearchConsole from "./searchConsole";
import AmazonConnection from "./amazonConnection";
const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      padding: "15px 20px 50px 20px",
      backgroundColor: "#F9FAFB",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      backgroundColor: "#F9FAFB",
    },
  },
});

function connectionsPage(props) {
  window.scrollTo(0, 0);
  const classes = props.classes;

  const { fetchConnectionRelatedInfo, connectionsDataReducer, userInformation } = props;
  console.log("connection page props", props);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      setLoading(true);
      await fetchConnectionRelatedInfo();
      setLoading(false);
    }

    fetchData();
  }, []);
  console.log("connectionsDataReducer-->", connectionsDataReducer);

  const [googleAnalyticsData, setGoogleAnalyticsData] = useState();
  const [googleAdsData, setGoogleAdsData] = useState();
  const [bingAdsData, setBingAdsData] = useState();
  const [gmcData, setGmcData] = useState();
  const [shoppingFeedData, setShoppingFeedData] = useState();
  const [searchConsoleData, setSearchConsoleData] = useState();
  const [amazonData, setAmazonData] = useState();
  const [googleAnalyticsConnectionNewDashboard, setGoogleAnalyticsConnectionNewDashboard] = useState();
  useEffect(() => {
    if (
      connectionsDataReducer["message"] === "FETCH_CONNECTION_DATA_RECEIVED"
    ) {
      //Google-Analytics4
      if (connectionsDataReducer["Google-Analytics4"].length >= 1) {
        setGoogleAnalyticsData(
          connectionsDataReducer["Google-Analytics4"][0]
            ? connectionsDataReducer["Google-Analytics4"][0]
            : "-"
        );
      }

      //Google Ads
      if (connectionsDataReducer["Google-Ads"].length >= 1) {
        setGoogleAdsData(
          connectionsDataReducer["Google-Ads"][0]
            ? connectionsDataReducer["Google-Ads"][0]
            : "-"
        );
      }

      //Bing Ads
      if (connectionsDataReducer["Bing-Ads"].length >= 1) {
        setBingAdsData(
          connectionsDataReducer["Bing-Ads"][0]
            ? connectionsDataReducer["Bing-Ads"][0]
            : "-"
        );
      }

      //Google Merchant Center
      if (connectionsDataReducer["Google-Merchant-Center"].length >= 1) {
        setGmcData(
          connectionsDataReducer["Google-Merchant-Center"][0]
            ? connectionsDataReducer["Google-Merchant-Center"][0]
            : "-"
        );
      }

      //Shopping Feed Details
      if (connectionsDataReducer["Shopping-Feed-Details"].length >= 1) {
        setShoppingFeedData(
          connectionsDataReducer["Shopping-Feed-Details"][0]
            ? connectionsDataReducer["Shopping-Feed-Details"][0]
            : "-"
        );
      }

      //Search Console Details
      if (connectionsDataReducer["Search-Console-Details"].length >= 1) {
        setSearchConsoleData(
          connectionsDataReducer["Search-Console-Details"][0]
            ? connectionsDataReducer["Search-Console-Details"][0]
            : "-"
        );
      }

      //Amazon Ads Details
      if (connectionsDataReducer["Amazon-Ads-Details"].length >= 1) {
        setAmazonData(
          connectionsDataReducer["Amazon-Ads-Details"][0]
            ? connectionsDataReducer["Amazon-Ads-Details"][0]
            : "-"
        );
      }
      // if (!connectionsDataReducer["googleAnalyticsConnectionNewDashboard"]) {
      //   setGoogleAnalyticsConnectionNewDashboard(
      //     connectionsDataReducer["googleAnalyticsConnectionNewDashboard"][0]
      //       ? connectionsDataReducer["googleAnalyticsConnectionNewDashboard"][0]
      //       : "-"
      //   );
      // }
      
    }
  }, [connectionsDataReducer]);

  return (
    <div className={classes.mainDiv}>
      {loading === false &&
        (connectionsDataReducer["message"] === "FETCH_CONNECTION_DATA_FAILED" ||
          connectionsDataReducer["message"] ===
          "FETCH_CONNECTION_DATA_RECEIVED") ? (
        <div>
          <GoogleAnalyticsConnection
            googleAnalyticsData={googleAnalyticsData} userInformationReducer={userInformation}
          />
          <GleBingConnection
            googleAdsData={googleAdsData}
            bingAdsData={bingAdsData}
          />
          <GmcConnection gmcData={gmcData} />
          <ShoppingFeedConnection shoppingFeedData={shoppingFeedData} />
          <SearchConsole
            searchConsoleData={searchConsoleData}
            setSearchConsoleData={setSearchConsoleData}
          />
          <AmazonConnection amazonData={amazonData} setAmazonData={setAmazonData} />      
        </div>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color={"secondary"} />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  connectionsDataReducer: state.connectionsDataReducer,
  fetchAmazonAccDataReducer: state.fetchAmazonAccDataReducer,
  userInformation: state.userInformation,
});
const mapDispatchToProps = (dispatch) => ({
  fetchConnectionRelatedInfo: () => dispatch(fetchConnectionRelatedInfo()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(connectionsPage));
