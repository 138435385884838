import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { format } from 'date-fns';
import { Card } from "@mui/material";
import { fetchConnectionRelatedInfo } from "../../actions";
import TimePeriodSelector from "../../NewInterfacePages/commonFunctions/DatePeriod";
// import DateRangePicker from "../../NewInterfacePages/commonFunctions/DateRangePicker";
import CustomAreaChart from "../../NewInterfacePages/graphComponents/LineAreaGraph";
import IntelliChatbot from "./IntelliChatbot.jsx"; // Import the new Chatbot component
import TopProductsTable from "./CustomTable";
import PieChartComponent from "./PieChart";
import ProductFeedIssues from "./ProductFeedIssue";
import { NewDashboardCustomButton } from "../../components/buttons";
import "./NewDashBoard.css";
import { BigTitle, GraphTitle, CardTitle } from "./NewDashBoardTypography";
import { fetchAmazonAccRevenuePerformanceData, fetchAmazonSyncCheckId } from "../../actions/amazonActions";
import { fetchWHSScoresByDateRanges } from "../../actions/whsNewDashboard";
import icons from "../../components/icons.js";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchProductPerformanceReportData,
  fetchOverallPerformanceReportData,
  fetchChannelPerformanceReportData,
} from "../../actions/googleAnalyticsNewDashboard.js";
import CustomDateRangePicker from "../commonFunctions/DateRangePicker.jsx";
import { fetchProductFeedIssue } from "../../actions/fetchProductFeedIssue.js";
import {K_Formatter,formatNumberToK} from "../commonFunctions/commonFunctions.jsx";
import { fetchJWTTokenURL } from "../../actions/authControllerJWTToken.js";
import * as types from "../../actions/actionTypes";
import {
  fetchIntelliChatBotResponse,
  // fetchIntelliChatBotNormalResponse,
} from "../../actions/intelliChatbot.js";
import { isEqual } from "lodash";
import { ConnectionCard } from "./ConnectionCard.jsx";
import AmazonLoader from "./AmazonLoader.jsx";
import Cookies from "js-cookie";
import { storeTokensInCookies } from "../../actions/jumpstartDomain.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const DashboardPage = (props) => {
  const {
    fetchConnectionRelatedInfo,
    connectionsDataReducer,
    fetchWHSScoresByDateRangesReducer,
    fetchAmazonAccRevenuePerformanceData,
    fetchAmazonAccRevenuePerformanceDataReducer,
    fetchWHSScoresByDateRanges,
    fetchProductPerformanceReportData,
    fetchProductPerformanceReportDataReducer,
    fetchOverallPerformanceReportData,
    fetchOverallPerformanceReportDataReducer,
    fetchChannelPerformanceReportData,
    fetchChannelPerformanceReportDataReducer,
    fetchProductFeedIssue,
    fetchProductFeedIssueReducer,
    fetchJWTTokenURL,
    fetchJWTTokenURLReducer,
    userInformation,
    startAmazonAccIntegrationReducer,
    fetchAmazonDownloadStatusReducer,
    fetchIntelliChatBotResponse,
    fetchIntelliChatBotResponseReducer,
    fetchAmazonSyncCheckId,
    fetchAmazonSyncCheckIdReducer,
    // fetchIntelliChatBotNormalResponse,
    // fetchIntelliChatBotNormalResponseReducer,
  } = props;

  const section = "overall"
  const [user_id, setUser_id] = useState("");
  // const [message, setMessage] = useState("Intelli chat");
  const [ppc_id, setPpc_id] = useState("");
  const [WHSDataCurrentDateRange, setWHSDataCurrentDateRange] = useState();
  const [WHSDataPastDateRange, setWHSDataPastDateRange] = useState([]);

  const [amazonAccRevenuePerformanceData, setAmazonAccRevenuePerformanceData] =
    useState();
  const [amazonRevenueChangePercentage, setAmazonRevenueChangePercentage] =
    useState();
  const [amazonOrderChangePercentage, setAmazonOrderChangePercentage] =
    useState();
  const [amazonTotalRevenue, setAmazonTotalRevenue] = useState();
  const [amazonTotalOrders, setAmazonTotalOrders] = useState();
  const [websiteGATotalRevenue, setWebsiteGATotalRevenue] = useState();
  const [websiteGATotalOrders, setWebsiteGATotalOrders] = useState();
  const [websiteGATotalRevenuePast, setWebsiteGATotalRevenuePast] = useState();
  const [websiteGATotalOrdersPast, setWebsiteGATotalOrdersPast] = useState();
  const [websiteGApercentageOrders, setWebsiteGApercentageOrders] = useState();
  const [websiteGApercentageRevenue, setWebsiteGApercentageRevenue] =
    useState();

  const [amazonGraph, setamazonGraph] = useState([]);
  const [amazonGraphPast, setamazonGraphPast] = useState([]);
  const [GAGraph, setGAGraph] = useState([]);
  const [GAGraphPast, setGAGraphPast] = useState([]);
  const [googleBingPerformanceData, setGoogleBingPerformanceData] = useState(
    []
  );
  const [taskListData, setTaskListData] = useState();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clientId, setClientId] = useState(
    userInformation.selectedClientInfo
      ? userInformation.selectedClientInfo.clientId
      : null
  );
  const [clientName, setClientName] = useState(
    userInformation.selectedClientInfo
      ? userInformation.selectedClientInfo.clientName
      : null
  );
  const [compareStartDate, setCompareStartDate] = useState();
  const [compareEndDate, setCompareEndDate] = useState();
  const [timeUnit, setTimeUnit] = useState("SUMMARY");
  const [overalltimeUnit, setOveralltimeUnit] = useState("DAILY");

  const [channels, setChannels] = useState([]);
  const [devices, setDevices] = useState([]);
  const [products, setProducts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [gender, setGender] = useState([]);
  const [direction, setDirection] = useState("DESC");
  const [topProductsByRevenue, setTopProductsByRevenue] = useState();
  const [topPerformingChannels, setTopPerformingChannels] = useState();
  const [productFeedIssue, setProductFeedIssue] = useState();
  const [overallGAReportCurrent, setOverallGAReportCurrent] = useState();
  const [overallGAReportPast, setOverallGAReportPast] = useState();
  const [graphText, setGraphText] = useState("Revenue Trend");
  const [graphTextAmazon, setGraphTextAmazon] = useState("Revenue Trend");
  // amazon parameters
  // profileId,startDate,endDate,campareFlag,timeUnit,adProducts,reportType
  const [profileId, setProfileId] = useState();
  const [campareFlag, setCampareFlag] = useState(false);
  const [timeUnitAmazon, setTimeUnitAmazon] = useState(1);
  const [adProducts, setAdProducts] = useState([
    "SPONSORED_PRODUCTS",
    "SPONSORED_BRANDS",
    "SPONSORED_DISPLAY",
    "SPONSORED_TELEVISION",
  ]);
  const [reportType, setReportType] = useState(["campaign"]);
  const [jwt_token, setJwt_token] = useState();
  const productSortBy = "itemRevenue"; // for products API
  const channelSortBy = "sessions"; // for channels API
  const revenueSortBy = "date"; // for revenue and orders API
  const [websiteBigText, setWebsiteBigText] = useState("Website Trend");
  const [marketplaceBigText, setMarketplaceBigText] = useState("Marketplace Trend");
  const [compareFlag, setCompareFlag] = useState(false);
  const [amazonSyncCheckId, setAmazonSyncCheckId] = useState("");
  const history = useHistory();
  let topChannels = [
    "Merged Organic",
    "Merged Paid Media",
    "Direct",
    "Others",
    "Email",
    "Referral",
    "Paid Social",
  ];
  var whsScoresRequest = {
    startDate: startDate,
    endDate: endDate,
    compareStartDate: compareStartDate,
    compareEndDate: compareEndDate,
    compareFlag: compareFlag,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchConnectionRelatedInfo();
  }, []);

  useEffect(() => {
    if (fetchJWTTokenURLReducer.message === "APIGATEWAY_AUTH_URL_RECEIVED") {
      storeTokensInCookies('jwt_token',fetchJWTTokenURLReducer.jwt_token ? fetchJWTTokenURLReducer.jwt_token : undefined,'Strict');
    }
  }, [fetchJWTTokenURLReducer]);

  useEffect(() => {
    if (userInformation.selectedClientInfo) {
      setClientId(userInformation.selectedClientInfo.clientId);
      setClientName(userInformation.selectedClientInfo.clientName);
      setUser_id(userInformation.userInfo.userId);
      setPpc_id(userInformation.selectedAccountInfo.ppcId);
    }
  }, [userInformation]);
  const [analyticsAccountId, setAnalyticsAccountId] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [amazonIntegrated, setAmazonIntegrated] = useState(false);
  const [googleAnalyticsIntegrated, setGoogleAnalyticsIntegrated] =
    useState(false);
  const [messageIntelliChatbot, setMessageIntelliChatbot] = useState();

  useEffect(() => {
    if (connectionsDataReducer.message === "FETCH_CONNECTION_DATA_RECEIVED" 
      && startDate != null && endDate != null && compareStartDate != null && compareEndDate != null) {
      localStorage.setItem("compareStart", compareStartDate);
      localStorage.setItem("compareEnd", compareEndDate);
      localStorage.setItem("startDate", startDate);
      localStorage.setItem("endDate", endDate);
      
      if (connectionsDataReducer && Array.isArray(connectionsDataReducer["Google-Analytics4"]) &&
        connectionsDataReducer["Google-Analytics4"].length > 0
      ) {
        const googleAnalyticsInfo = connectionsDataReducer["Google-Analytics4"][0];
        setAnalyticsAccountId(googleAnalyticsInfo.accountId || null);
        setPropertyId(googleAnalyticsInfo.propertyId || null);
        setGoogleAnalyticsIntegrated(true);
        fetchProductPerformanceReportData(googleAnalyticsInfo.accountId,googleAnalyticsInfo.propertyId,startDate,endDate,timeUnit,channels,devices,
          products,
          locations,
          gender,
          productSortBy,
          direction,
        );
        fetchOverallPerformanceReportData(
          googleAnalyticsInfo.accountId,
          googleAnalyticsInfo.propertyId,
          startDate,
          endDate,
          overalltimeUnit,
          channels,
          devices,
          products,
          locations,
          gender,
          revenueSortBy,
          direction
        );
        fetchChannelPerformanceReportData(
          googleAnalyticsInfo.accountId,
          googleAnalyticsInfo.propertyId,
          startDate,
          endDate,
          timeUnit,
          topChannels,
          devices,
          products,
          locations,
          gender,
          channelSortBy,
          direction
        );

      }
      if (
        connectionsDataReducer && Array.isArray(connectionsDataReducer["Amazon-Ads-Details"]) &&
        connectionsDataReducer["Amazon-Ads-Details"].length > 0
      ) {
        setAmazonIntegrated(true);
        setProfileId(connectionsDataReducer["Amazon-Ads-Details"][0].profileId);
        fetchAmazonSyncCheckId(connectionsDataReducer["Amazon-Ads-Details"][0].amazonId);
        fetchAmazonAccRevenuePerformanceData(
          connectionsDataReducer["Amazon-Ads-Details"][0].profileId,
          startDate,
          endDate,
          compareFlag,
          timeUnitAmazon,
          adProducts,
          reportType
        );
      }
    }
    if (clientId && startDate != null && endDate != null && compareStartDate != null && compareEndDate != null) {
      fetchProductFeedIssue(clientId);
      fetchWHSScoresByDateRanges(clientId, whsScoresRequest);
    }
  }, [connectionsDataReducer, clientId, startDate, fetchJWTTokenURLReducer,endDate, compareFlag, compareEndDate, compareStartDate,]);

  //SET THE AMAZON SYNC CHECK ID
  useEffect(() => {
    if (fetchAmazonSyncCheckIdReducer.message === "FETCH_AMAZON_SYNC_CHECK_ID_RECEIVED") {
      setAmazonSyncCheckId(fetchAmazonSyncCheckIdReducer.syncCheckId);
    }
  }, [fetchAmazonSyncCheckIdReducer])

  useEffect(() => {
    if (clientId && user_id && ppc_id) {
      fetchIntelliChatBotResponse(
        user_id,
        "Intelli chat",
        ppc_id,
        clientId,
        [`${startDate}`, `${endDate}`],
        [`${compareStartDate}`, `${compareEndDate}`], section
      );
    }
  }, [ppc_id, startDate,
    endDate,])

  useEffect(() => {
    if (
      fetchWHSScoresByDateRangesReducer.message ===
      "FETCH_WHS_SCORES_BY_DATE_RANGE_RECEIVED"
    ) {
      setWHSDataCurrentDateRange(
        fetchWHSScoresByDateRangesReducer.currDateRangeScores
      );
      setWHSDataPastDateRange(fetchWHSScoresByDateRangesReducer.prevDateRangeScores)
    }
  }, [fetchWHSScoresByDateRangesReducer]);

  useEffect(() => {
    if (
      fetchProductPerformanceReportDataReducer.message ===
      "FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_RECEIVED"
    ) {
      setTopProductsByRevenue(fetchProductPerformanceReportDataReducer.current);
    }
  }, [fetchProductPerformanceReportDataReducer]);

  useEffect(() => {
    if (
      fetchChannelPerformanceReportDataReducer.message ===
      "FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_RECEIVED" &&
      !isEqual(
        topPerformingChannels,
        fetchChannelPerformanceReportDataReducer.current
      )
    ) {
      setTopPerformingChannels(
        fetchChannelPerformanceReportDataReducer.current
      );
    }
  }, [fetchChannelPerformanceReportDataReducer, compareFlag]);

  useEffect(() => {
    if (
      fetchAmazonAccRevenuePerformanceDataReducer.message ===
      "FETCH_AMAZON_ACC_DATA_REV_PERF_RECEIVED" && !(fetchAmazonAccRevenuePerformanceDataReducer.status == "404")
    ) {

      setAmazonAccRevenuePerformanceData(
        fetchAmazonAccRevenuePerformanceDataReducer
      );
      setAmazonRevenueChangePercentage(
        fetchAmazonAccRevenuePerformanceDataReducer.revenueChangePercentage
      );
      setAmazonOrderChangePercentage(
        fetchAmazonAccRevenuePerformanceDataReducer.orderChangePercentage < 1000
          ? fetchAmazonAccRevenuePerformanceDataReducer.orderChangePercentage
          : formatNumberToK(
            fetchAmazonAccRevenuePerformanceDataReducer.orderChangePercentage
          )
      );
      setAmazonTotalRevenue(
        fetchAmazonAccRevenuePerformanceDataReducer.totalRevenue < 1000
          ? (fetchAmazonAccRevenuePerformanceDataReducer.totalRevenue).toFixed(2)
          : formatNumberToK(
            fetchAmazonAccRevenuePerformanceDataReducer.totalRevenue
          )
      );
      setAmazonTotalOrders(
        fetchAmazonAccRevenuePerformanceDataReducer.totalOrders < 1000
          ? (fetchAmazonAccRevenuePerformanceDataReducer.totalOrders)
          : formatNumberToK(
            fetchAmazonAccRevenuePerformanceDataReducer.totalOrders
          )
      );
// Handling currentCampaignData
setamazonGraph(
  (
    fetchAmazonAccRevenuePerformanceDataReducer?.currentCampaignData || [] // Fallback to an empty array if undefined
  )
    .map(({ date, revenue, orders }) => ({
      date,
      revenue,
      OtherToolTip: orders,
    }))
    .sort((a, b) => new Date(a.date) - new Date(b.date)) // Sorting by date
);

// Handling prevCampaignData
setamazonGraphPast(
  (
    fetchAmazonAccRevenuePerformanceDataReducer?.prevCampaignData || [] // Fallback to an empty array if undefined
  )
    .map(({ date, revenue, orders }) => ({
      date,
      revenue,
      OtherPrevToolTip: orders,
    }))
);

      setGraphTextAmazon("Revenue Trend");
    }
  }, [fetchAmazonAccRevenuePerformanceDataReducer, campareFlag]);

  useEffect(() => {
    if (
      fetchProductFeedIssueReducer.message ===
      "FETCH_PRODUCT_FEED_ISSUE_RECEIVED"
    ) {
      setProductFeedIssue(fetchProductFeedIssueReducer);
    }
  }, [fetchProductFeedIssueReducer]);

  useEffect(() => {
    if (
      fetchOverallPerformanceReportDataReducer.message ===
      "FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_RECEIVED"
    ) {
      setOverallGAReportCurrent(
        fetchOverallPerformanceReportDataReducer.current
      );
      setOverallGAReportPast(fetchOverallPerformanceReportDataReducer.past);
      // Safely calculate total current transactions
      const totalCurrentTransactions = Array.isArray(fetchOverallPerformanceReportDataReducer.current)
        ? fetchOverallPerformanceReportDataReducer.current.reduce(
          (total, day) => total + (day.transactions || 0), // Fallback to 0 if transactions is undefined
          0
        )
        : 0; // Default to 0 if `current` is not a valid array

      // Safely calculate total current transaction revenue
      const totalCurrentTransactionsRevenue = Array.isArray(fetchOverallPerformanceReportDataReducer.current)
        ? fetchOverallPerformanceReportDataReducer.current.reduce(
          (total, day) => total + (day.transactionRevenue || 0), // Fallback to 0 if transactionRevenue is undefined
          0
        )
        : 0; // Default to 0 if `current` is not a valid array

      // Safely calculate total past transactions
      const totalPastTransactions = Array.isArray(fetchOverallPerformanceReportDataReducer.past)
        ? fetchOverallPerformanceReportDataReducer.past.reduce(
          (total, day) => total + (day.transactions || 0), // Fallback to 0 if transactions is undefined
          0
        )
        : 0; // Default to 0 if `past` is not a valid array

      // Safely calculate total past transaction revenue
      const totalPastTransactionsRevenue = Array.isArray(fetchOverallPerformanceReportDataReducer.past)
        ? fetchOverallPerformanceReportDataReducer.past.reduce(
          (total, day) => total + (day.transactionRevenue || 0), // Fallback to 0 if transactionRevenue is undefined
          0
        )
        : 0; // Default to 0 if `past` is not a valid array
      setWebsiteGATotalOrders(
        totalCurrentTransactions < 1000
          ? totalCurrentTransactions
          : formatNumberToK(totalCurrentTransactions)
      );
      setWebsiteGATotalRevenue(
        totalCurrentTransactionsRevenue < 1000
          ? totalCurrentTransactionsRevenue
          : formatNumberToK(totalCurrentTransactionsRevenue)
      );
      setWebsiteGATotalOrdersPast(totalPastTransactions);
      setWebsiteGATotalRevenuePast(totalPastTransactionsRevenue);

      setWebsiteGApercentageOrders(
        totalPastTransactions !== 0
          ? ((totalCurrentTransactions - totalPastTransactions) / totalPastTransactions) * 100
          : totalCurrentTransactions > 0
            ? 100
            : 0
      );
      
      setWebsiteGApercentageRevenue(
        totalPastTransactionsRevenue !== 0
          ? ((totalCurrentTransactionsRevenue - totalPastTransactionsRevenue) / totalPastTransactionsRevenue) * 100
          : totalCurrentTransactionsRevenue > 0
            ? 100
            : 0
      );

      setGAGraph(
        fetchOverallPerformanceReportDataReducer?.current?.map(
          ({ date, transactionRevenue, transactions }) => ({
            date,
            transactionRevenue,
            OtherToolTip: transactions, // Rename "transactions" to "OrdersTT"
          })
        ) || []
      );

      setGAGraphPast(
        fetchOverallPerformanceReportDataReducer?.past?.map(
          ({ date, transactionRevenue, transactions }) => ({ date, transactionRevenue, OtherPrevToolTip: transactions, })
        ) || []
      );

      setGraphText("Revenue Trend");
    }
  }, [fetchOverallPerformanceReportDataReducer, compareFlag]);
  const colors = [ "#CF0505","#F58120","#219653","#1098F7","#A2B6C3","#1E334E","#1EBC61","#60baa7", "#facc32", "#f19e34", "#e55598", "#6467f1", "#3b82f6", "#75cfff", "#0063ab", "#421808"];

  const handleTotalOrderButton = (clickedButton) => {
    if (clickedButton === "Amazon Total Orders") {
      if (
        fetchAmazonAccRevenuePerformanceDataReducer.message ===
        "FETCH_AMAZON_ACC_DATA_REV_PERF_RECEIVED"
      ) {
        setamazonGraph(
          fetchAmazonAccRevenuePerformanceDataReducer?.currentCampaignData?.map(
            ({ date, orders, revenue }) => ({ date, orders, OtherToolTip: revenue })
          ) || []
        );
        setamazonGraphPast(
          fetchAmazonAccRevenuePerformanceDataReducer?.prevCampaignData?.map(
            ({ date, orders, revenue }) => ({ date, orders, OtherPrevToolTip: revenue })
          ) || []
        );
        setGraphTextAmazon("Order Trend");
      } else {
        setamazonGraph([]);
        setamazonGraphPast([]);
      }
    } else if (clickedButton === "Amazon Total Revenue") {
      if (
        fetchAmazonAccRevenuePerformanceDataReducer.message ===
        "FETCH_AMAZON_ACC_DATA_REV_PERF_RECEIVED"
      ) {
        setamazonGraph(
          fetchAmazonAccRevenuePerformanceDataReducer?.currentCampaignData?.map(
            ({ date, revenue, orders }) => ({ date, revenue, OtherToolTip: orders })
          ) || []
        );
        setamazonGraphPast(
          fetchAmazonAccRevenuePerformanceDataReducer?.prevCampaignData?.map(
            ({ date, revenue, orders }) => ({ date, revenue, OtherPrevToolTip: orders })
          ) || []
        );
        setGraphTextAmazon("Revenue Trend");
      } else {
        setamazonGraph([]);
        setamazonGraphPast([]);
      }
    } else if (clickedButton === "GA Total Revenue") {
      if (
        fetchOverallPerformanceReportDataReducer.message ===
        "FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_RECEIVED"
      ) {
        setGAGraph(
          fetchOverallPerformanceReportDataReducer?.current?.map(
            ({ date, transactionRevenue, transactions }) => ({ date, transactionRevenue, OtherToolTip: transactions, })
          ) || []
        );
        setGAGraphPast(
          fetchOverallPerformanceReportDataReducer?.past?.map(
            ({ date, transactionRevenue, transactions }) => ({ date, transactionRevenue, OtherPrevToolTip: transactions, })
          ) || []
        );
        setGraphText("Revenue Trend");
      } else {
        setGAGraph([]);
        setGAGraphPast([]);
      }
    } else {
      if (
        fetchOverallPerformanceReportDataReducer.message ===
        "FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_RECEIVED"
      ) {
        setGAGraph(
          fetchOverallPerformanceReportDataReducer?.current?.map(
            ({ date, transactions, transactionRevenue }) => ({ date, transactions, OtherToolTip: transactionRevenue })
          ) || []
        );
        setGAGraphPast(
          fetchOverallPerformanceReportDataReducer?.past?.map(
            ({ date, transactions, transactionRevenue }) => ({ date, transactions, OtherPrevToolTip: transactionRevenue })
          ) || []
        );
        setGraphText("Order Trend");
      } else {
        setGAGraph([]);
        setGAGraphPast([]);
      }
    }
  };

  const handleChange = async (history) => {
    await props.updateViewValue(1);
    ("/tasks/");
  };

  const redirectToConnectionPage = (platform) => {
    localStorage.setItem("platform", platform);
    history.push("/connections-page");
  };
  const [current_date_range, setCurrent_date_range] = useState([
    `${startDate}`,
    `${endDate}`,
  ]);
  const [previous_date_range, setPrevious_date_range] = useState([
    `${compareStartDate}`,
    `${compareEndDate}`,
  ]);

  console.log("test amazon sync check id=====", amazonSyncCheckId);

  return (
    <Grid container spacing={2}
    // sx={{ mt: 1 }}
    >
      {/* Left side content */}
      <Grid item xs={12} md={9}>
        <Grid container spacing={2} sx={{ pl: 4,pt:4 }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <TimePeriodSelector
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setCompareStartDate={setCompareStartDate}
              setCompareEndDate={setCompareEndDate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomDateRangePicker
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              handleCompareStartDate={setCompareStartDate}
              handleCompareEndDate={setCompareEndDate}
              handleStartDateChange={setStartDate}
              handleEndDateChange={setEndDate}
              handleCompareFlagChange={setCompareFlag}
            />
          </Grid>
          {/* Charts and other components */}
          {/* website data GA */}
          {/* //=============================================================================    */}

          {googleAnalyticsIntegrated && amazonIntegrated ? (
            <>
              <Grid item xs={12} md={6}>
                <Card className="card">
                  <BigTitle style={{ display: "flex", alignItems: "center" }}>
                    {websiteBigText}
                    <span style={{ marginLeft: "8px", display: "flex", justifyContent: "center" }}>
                      {icons.GoogleAnalyticsNew}
                    </span>
                  </BigTitle>
                  <Grid container spacing={2} paddingTop={3}>
                    <Grid item xs={6} sm={6}>
                      <NewDashboardCustomButton
                        id="Total Revenue Button"
                        imageSrc={icons.TotalRevenueND}
                        title="Total Revenue"
                        amount={websiteGATotalRevenue}
                        percentage={websiteGApercentageRevenue}
                        onClick={() =>
                          handleTotalOrderButton("GA Total Revenue")
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      {/* Added item prop and sm breakpoint */}
                      <NewDashboardCustomButton
                        id="Total Orders"
                        imageSrc={icons.TotalOrdersND}
                        title="Total Orders"
                        amount={websiteGATotalOrders}
                        percentage={websiteGApercentageOrders}
                        onClick={() => handleTotalOrderButton("GA Total Order")}
                      />
                    </Grid>
                  </Grid>
                  <CardTitle
                    sx={{
                      paddingTop: "3%",
                      paddingBottom: "3%",
                      color: "#414141",
                      fontSize: { xs: "1rem", sm: "1.125rem" },
                      fontWeight: "bold",
                    }}
                  >
                    {graphText}
                  </CardTitle>
                  {GAGraph !== undefined ? (
                    <CustomAreaChart
                      color="#EDB17A"
                      compareColor="#B7B7B7"
                      compare={compareFlag}
                      data={GAGraph}
                      pastData={GAGraphPast}
                      areaColor={false}
                    />
                  ) : (
                    <Typography align="center">No data available</Typography>
                  )}
                </Card>
              </Grid>

              {amazonSyncCheckId != "" && amazonSyncCheckId !== undefined ? (
                <AmazonLoader
                  syncCheckId={amazonSyncCheckId}
                  setSyncCheckId={setAmazonSyncCheckId}
                  width={"36%"}
                />
              ) : (
                <Grid item xs={12} md={6}>
                  <Card className="card">
                    <BigTitle style={{ display: "flex", alignItems: "center" }}>
                      {marketplaceBigText}
                      <span
                        style={{
                          marginLeft: "8px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {icons.AmazonMarketplaceIcon}
                      </span>
                    </BigTitle>
                    <Grid container spacing={2} paddingTop={3}>
                      {" "}
                      {/* Changed spacing from 8 to 2 */}
                      <Grid item xs={6} sm={6}>
                        {" "}
                        {/* Added item prop and sm breakpoint */}
                        <NewDashboardCustomButton
                          imageSrc={icons.TotalRevenueND}
                          title="Total Revenue"
                          amount={amazonTotalRevenue}
                          percentage={amazonRevenueChangePercentage}
                          onClick={() =>
                            handleTotalOrderButton("Amazon Total Revenue")
                          }
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        {" "}
                        {/* Added item prop and sm breakpoint */}
                        <NewDashboardCustomButton
                          imageSrc={icons.TotalOrdersND}
                          title="Total Orders"
                          amount={amazonTotalOrders}
                          percentage={amazonOrderChangePercentage}
                          onClick={() =>
                            handleTotalOrderButton("Amazon Total Orders")
                          }
                        />
                      </Grid>
                    </Grid>
                    <CardTitle
                      sx={{
                        paddingTop: "3%",
                        paddingBottom: "3%",
                        color: "#414141",
                        fontSize: { xs: "1rem", sm: "1.125rem" },
                        fontWeight: "bold",
                      }}
                    >
                      {graphTextAmazon}
                    </CardTitle>
                    {amazonGraph !== undefined ? (
                      <CustomAreaChart
                        color="#EDB17A"
                        compareColor="#B7B7B7"
                        compare={compareFlag}
                        data={amazonGraph}
                        pastData={amazonGraphPast}
                        areaColor={false}
                      />
                    ) : (
                      <Typography align="center">No data available</Typography>
                    )}
                  </Card>
                </Grid>
              )}
            </>
          ) : googleAnalyticsIntegrated && !amazonIntegrated ? (
            <Grid item xs={12}>
              <Card className="card">
                {/* <BigTitle>Website</BigTitle> */}
                <BigTitle style={{ display: "flex", alignItems: "center" }}>
                    {websiteBigText}
                    <span style={{ marginLeft: "8px", display: "flex", justifyContent: "center" }}>
                      {icons.GoogleAnalyticsNew}
                    </span>
                  </BigTitle>
                <Grid container spacing={2} paddingTop={3}>
                  <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={12}>
                        <NewDashboardCustomButton
                          id="Total Revenue Button"
                          imageSrc={icons.TotalRevenueND}
                          title="Total Revenue"
                          amount={websiteGATotalRevenue}
                          percentage={websiteGApercentageRevenue}
                          onClick={() =>
                            handleTotalOrderButton("GA Total Revenue")
                          }
                        />
                      </Grid>
                      <Grid item xs={6} md={12}>
                        {/* Added item prop and sm breakpoint */}
                        <NewDashboardCustomButton
                          id="Total Orders"
                          imageSrc={icons.TotalOrdersND}
                          title="Total Orders"
                          amount={websiteGATotalOrders}
                          percentage={websiteGApercentageOrders}
                          onClick={() =>
                            handleTotalOrderButton("GA Total Order")
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <GraphTitle
                      sx={{
                        paddingBottom: "2%",
                        color: "#9C9C9C",
                        fontSize: { xs: "1rem", sm: "0.875rem" },
                        fontWeight: "bold",
                      }}
                    >
                      {graphText}
                    </GraphTitle>
                    {GAGraph !== undefined ? (
                      <CustomAreaChart
                        color="#EDB17A"
                        compareColor="#B7B7B7"
                        compare={compareFlag}
                        data={GAGraph}
                        pastData={GAGraphPast}
                        areaColor={false}
                      />
                    ) : (
                      "No Data"
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ) : !googleAnalyticsIntegrated && amazonIntegrated ? (
            <>
              {amazonSyncCheckId != "" && amazonSyncCheckId !== undefined ? (
                <>
                  <ConnectionCard
                    title={websiteBigText}
                    icon1={icons.GoogleAnalyticsNew}
                    icon2={icons.GoogleAnalytics}
                    description="Add Google Analytics from the Connections page or by clicking the button below."
                    buttonText="Connect Now"
                    onClick={() => redirectToConnectionPage("Google Analytics")}
                    cardSize={{ xs: 12, md: 6 }}
                  />
                  <AmazonLoader
                    syncCheckId={amazonSyncCheckId}
                    setSyncCheckId={setAmazonSyncCheckId}
                    width={"24%"}
                  />
                </>
              ) : (
                <Grid item xs={12}>
                  <Card className="card">
                    <BigTitle style={{ display: "flex", alignItems: "center" }}>
                      {marketplaceBigText}
                      <span
                        style={{
                          marginLeft: "8px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {icons.AmazonMarketplaceIcon}
                      </span>
                    </BigTitle>
                    <Grid container spacing={2} paddingTop={3}>
                      <Grid item xs={12} md={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={12}>
                            <NewDashboardCustomButton
                              imageSrc={icons.TotalRevenueND}
                              title="Total Revenue"
                              amount={amazonTotalRevenue}
                              percentage={amazonRevenueChangePercentage}
                              onClick={() =>
                                handleTotalOrderButton("Amazon Total Revenue")
                              }
                            />
                          </Grid>
                          <Grid item xs={6} md={12}>
                            {/* Added item prop and sm breakpoint */}
                            <NewDashboardCustomButton
                              imageSrc={icons.TotalOrdersND}
                              title="Total Orders"
                              amount={amazonTotalOrders}
                              percentage={amazonOrderChangePercentage}
                              onClick={() =>
                                handleTotalOrderButton("Amazon Total Orders")
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <GraphTitle
                          sx={{
                            paddingBottom: "2%",
                            color: "#9C9C9C",
                            fontSize: { xs: "1rem", sm: "0.875rem" },
                            fontWeight: "bold",
                          }}
                        >
                          {graphTextAmazon}
                        </GraphTitle>
                        {amazonGraph !== undefined ? (
                          <CustomAreaChart
                            color="#5C59E8"
                            compareColor="#B7B7B7"
                            compare={compareFlag}
                            data={amazonGraph}
                            pastData={amazonGraphPast}
                            areaColor={false}
                          />
                        ) : (
                          "No Data"
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              )}
            </>
          ) : (
            <>
              <ConnectionCard
                title={websiteBigText}
                icon1={icons.GoogleAnalyticsNew}
                icon2={icons.GoogleAnalytics}
                description="Add Google Analytics from the Connections page or by clicking the button below."
                buttonText="Connect Now"
                onClick={() => redirectToConnectionPage("Google Analytics")}
                cardSize={{ xs: 12, md: 6 }} // Custom size
              />
              <ConnectionCard
                title={marketplaceBigText}
                icon1={icons.AmazonMarketplaceIcon}
                icon2={icons.AmazonAds}
                description="Add Amazon Ads from the Connections page or by clicking the button below."
                buttonText="Connect Now"
                onClick={() => redirectToConnectionPage("Amazon Ads")}
                cardSize={{ xs: 12, md: 6 }} // Custom size
              />
            </>
          )}

          {/* //=============================================================================    */}
          {/* WHS Data */}
          <Grid item xs={12} md={6}>
            <Card className="card">
              <Grid container row>
                <Grid item xs={6}>
                  <CardTitle
                    sx={{
                      paddingBottom: "3%",
                      color: "#414141",
                      fontSize: { xs: "1rem", sm: "1.125rem" },
                      fontWeight: "bold",
                    }}
                  >
                    Website Health Score Trend
                  </CardTitle>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <Link
                    to="/website-health-scorecard"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    View Details &nbsp; {icons.ViewDetailsND}{" "}
                  </Link>
                </Grid>
              </Grid>

              <hr className="hrDivider"></hr>
              {WHSDataCurrentDateRange != undefined ? (
                <CustomAreaChart
                  color="#EDB17A"
                  compareColor="#B7B7B7"
                  compare={compareFlag}
                  data={WHSDataCurrentDateRange}
                  pastData={WHSDataPastDateRange}
                  areaColor={true}
                />
              ) : (
                <Typography align="center">No data available.<br/> Please check after 24 hours.</Typography>
              )}
            </Card>{" "}
          </Grid>
          {/* All Channels Data */}
          <Grid item xs={12} md={6}>
            <Card className="card">
              <Grid container row>
                <Grid item xs={6}>
                  <CardTitle
                    sx={{
                      paddingBottom: "3%",
                      color: "#414141",
                      fontSize: { xs: "1rem", sm: "1.125rem" },
                      fontWeight: "bold",
                    }}
                  >
                    Top Performing Channels
                  </CardTitle>
                </Grid>
                <Grid item xs={6} md={6} textAlign={"right"}>
                  <Link
                    to="/channel-performance"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    View Details &nbsp; {icons.ViewDetailsND}{" "}
                  </Link>
                </Grid>
              </Grid>
              <hr className="hrDivider"></hr>
              {topPerformingChannels != undefined ? (
                <PieChartComponent
                  data={topPerformingChannels}
                  colors={colors}
                />
              ) : (
                <Typography align="center">No data available.<br />  Integrate  <Link
                  to="/connections-page"
                  style={{ textDecoration: "none", color: "#E16B32" }}
                >
                  Google Analytics
                </Link> to view this data.</Typography>
              )}
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className="card">
              <CardTitle
                sx={{
                  paddingBottom: "3%",
                  color: "#414141",
                  fontSize: { xs: "1rem", sm: "1.125rem" },
                  fontWeight: "bold",
                }}
              >
                Top 10 Website Products
              </CardTitle>
              <hr className="hrDivider"></hr>
              {topProductsByRevenue != undefined ? (
                <TopProductsTable data={topProductsByRevenue} />
              ) : (
                <Typography align="center">No data available.<br />  Integrate  <Link
                  to="/connections-page"
                  style={{ textDecoration: "none", color: "#E16B32" }}
                >
                  Google Analytics
                </Link> to view this data.</Typography>
              )}
            </Card>
          </Grid>
          {/* ProductFeedIssues */}
          <Grid item xs={12} md={6}>
            <Card className="card">
              <Grid container row>
                <Grid item xs={6}>
                  <CardTitle
                    sx={{
                      paddingBottom: "3%",
                      color: "#414141",
                      fontSize: { xs: "1rem", sm: "1.125rem" },
                      fontWeight: "bold",
                    }}
                  >
                    Product Feed Status
                  </CardTitle>
                </Grid>
              </Grid>
              <hr className="hrDivider"></hr>
              {productFeedIssue != undefined ? (
                <ProductFeedIssues data={productFeedIssue} color={colors} />
              ) : (
                <Typography align="center">No data available</Typography>
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {/* Right side chatbot */}
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          position: {
            xs: "static", // Regular positioning on smaller screens
            md: "grid", // Fixed positioning on medium and larger screens
          },
          right: 0,
          // top: { xs: 'auto', md: "5%" }, // Adjust top depending on your layout
          overflowY: { md: "auto" }, // Allows scrolling if content exceeds viewport
        }}
      >
        {(startDate && endDate) != undefined ?
          <IntelliChatbot
            current_date_range={"" + format(new Date(startDate), 'yyyy/MM/dd') + " ~ " + format(new Date(endDate), 'yyyy/MM/dd') + ""}
            previous_date_range={"" + format(new Date(compareStartDate), 'yyyy/MM/dd') + " ~ " + format(new Date(compareEndDate), 'yyyy/MM/dd') + ""}
          /> : null
        }
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  userInformation: state.userInformation,
  connectionsDataReducer: state.connectionsDataReducer,
  fetchAmazonAccRevenuePerformanceDataReducer:
    state.fetchAmazonAccRevenuePerformanceDataReducer,
  fetchWHSScoresByDateRangesReducer: state.fetchWHSScoresByDateRangesReducer,
  fetchProductPerformanceReportDataReducer:
    state.fetchProductPerformanceReportDataReducer,
  fetchChannelPerformanceReportDataReducer:
    state.fetchChannelPerformanceReportDataReducer,
  fetchOverallPerformanceReportData: state.fetchOverallPerformanceReportData,
  fetchProductFeedIssueReducer: state.fetchProductFeedIssueReducer,
  fetchOverallPerformanceReportDataReducer:
    state.fetchOverallPerformanceReportDataReducer,
  fetchJWTTokenURLReducer: state.fetchJWTTokenURLReducer,
  fetchIntelliChatBotResponseReducer: state.fetchIntelliChatBotResponseReducer,
  // fetchIntelliChatBotNormalResponseReducer:
  //   state.fetchIntelliChatBotNormalResponseReducer,
  fetchAmazonDownloadStatusReducer: state.fetchAmazonDownloadStatusReducer,
  startAmazonAccIntegrationReducer: state.startAmazonAccIntegrationReducer,
  fetchAmazonSyncCheckIdReducer: state.fetchAmazonSyncCheckIdReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAmazonSyncCheckId: (amazonId) => dispatch(fetchAmazonSyncCheckId(amazonId)),
  fetchConnectionRelatedInfo: () => dispatch(fetchConnectionRelatedInfo()),
  fetchAmazonAccRevenuePerformanceData: (
    profileId,
    startDate,
    endDate,
    campareFlag,
    timeUnit,
    adProducts,
    reportType
  ) =>
    dispatch(
      fetchAmazonAccRevenuePerformanceData(
        profileId,
        startDate,
        endDate,
        campareFlag,
        timeUnit,
        adProducts,
        reportType
      )
    ),
  fetchWHSScoresByDateRanges: (clientId, whsScoresRequest) =>
    dispatch(fetchWHSScoresByDateRanges(clientId, whsScoresRequest)),
  fetchProductPerformanceReportData: (
    analyticsAccountId,
    propertyId,
    startDate,
    endDate,
    timeUnit,
    channels,
    devices,
    products,
    locations,
    gender,
    sortBy,
    direction
  ) =>
    dispatch(
      fetchProductPerformanceReportData(
        analyticsAccountId,
        propertyId,
        startDate,
        endDate,
        timeUnit,
        channels,
        devices,
        products,
        locations,
        gender,
        sortBy,
        direction,
      )
    ),
  fetchOverallPerformanceReportData: (
    analyticsAccountId,
    propertyId,
    startDate,
    endDate,
    timeUnit,
    channels,
    devices,
    products,
    locations,
    gender,
    sortBy,
    direction
  ) =>
    dispatch(
      fetchOverallPerformanceReportData(
        analyticsAccountId,
        propertyId,
        startDate,
        endDate,
        timeUnit,
        channels,
        devices,
        products,
        locations,
        gender,
        sortBy,
        direction
      )
    ), //fetchOverallPerformanceReportData
  fetchChannelPerformanceReportData: (
    analyticsAccountId,
    propertyId,
    startDate,
    endDate,
    timeUnit,
    channels,
    devices,
    products,
    locations,
    gender,
    sortBy,
    direction
  ) =>
    dispatch(
      fetchChannelPerformanceReportData(
        analyticsAccountId,
        propertyId,
        startDate,
        endDate,
        timeUnit,
        channels,
        devices,
        products,
        locations,
        gender,
        sortBy,
        direction
      )
    ),
  fetchProductFeedIssue: (clientId) =>
    dispatch(fetchProductFeedIssue(clientId)),
  fetchJWTTokenURL: () => dispatch(fetchJWTTokenURL()),
  updateViewValue: (viewValue) => {
    return dispatch({
      type: types.DASHBOARD_VIEW_VALUE_UPDATED,
      viewValue: viewValue,
    });
  },
  trackDashboardPages: (viewValue) => {
    return dispatch({
      type: types.DASHBOARD_VIEW_VALUE_TRACK,
      viewValue: viewValue,
    });
  },
  fetchIntelliChatBotResponse: (
    user_id,
    message,
    ppc_id,
    clientId,
    current_date_range,
    previous_date_range, section
  ) =>
    dispatch(
      fetchIntelliChatBotResponse(
        user_id,
        message,
        ppc_id,
        clientId,
        current_date_range,
        previous_date_range, section
      )
    ),
  // fetchIntelliChatBotNormalResponse: (user_id, message, ppc_id, clientId) =>
  //   dispatch(
  //     fetchIntelliChatBotNormalResponse(user_id, message, ppc_id, clientId)
  //   ),
});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
