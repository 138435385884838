import * as types from "../actions/actionTypes";



export function fetchAuthGoogleAnalytics_URLReducer(
    state = {
      message: "",
    },
    action
  ) {
    switch (action.type) {
      case types.FETCH_GOOGLE_ANALYTICS_URL_DATA_REQUESTED_NEWDASHBOARD:
        return Object.assign({}, state, {
          message: "FETCH_GOOGLE_ANALYTICS_URL_DATA_REQUESTED_NEWDASHBOARD",
        });
      case types.FETCH_GOOGLE_ANALYTICS_URL_DATA_RECEIVED_NEWDASHBOARD:
        let { data } = action;
        
        return Object.assign({}, state, {
          ...action.data,
          message: "FETCH_GOOGLE_ANALYTICS_URL_DATA_RECEIVED_NEWDASHBOARD",
        });
      case types.FETCH_GOOGLE_ANALYTICS_URL_DATA_FAILED_NEWDASHBOARD:
        return Object.assign({}, state, {
          message: "FETCH_GOOGLE_ANALYTICS_URL_DATA_FAILED_NEWDASHBOARD",
        });
      default:
        // console.log("The state is ", state);
        return state;
    }
  }
export function storeGoogleAnalyticsUserAccountReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALYTICS_STORE_USER_ACCOUNT_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALYTICS_STORE_USER_ACCOUNT_DATA_REQUESTED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_STORE_USER_ACCOUNT_DATA_RECEIVED:
      let { data } = action;
     
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_GOOGLE_ANALYTICS_STORE_USER_ACCOUNT_DATA_RECEIVED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_STORE_USER_ACCOUNT_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALYTICS_STORE_USER_ACCOUNT_DATA_FAILED",
      });
    default:
      // console.log("The state is ", state);
      return state;
  }
}

export function fetchGoogleAnalyticsViewsAndPropertiesNewDashboardReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALYTICS_VIEWS_PROPERTIES_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALYTICS_VIEWS_PROPERTIES_DATA_REQUESTED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_VIEWS_PROPERTIES_DATA_RECEIVED:
      let { data } = action;
     
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_GOOGLE_ANALYTICS_VIEWS_PROPERTIES_DATA_RECEIVED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_VIEWS_PROPERTIES_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALYTICS_VIEWS_PROPERTIES_DATA_FAILED",
      });
    default:
      // console.log("The state is ", state);
      return state;
  }
}

export function fetchGoogleAnalyticsIntegrationNewDashboardReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_REQUESTED_NEWDASHBOARD:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALYTICS_INTEGRATION_REQUESTED_NEWDASHBOARD",
      });
    case types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_RECEIVED_NEWDASHBOARD:
      let { data } = action;
      
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_GOOGLE_ANALYTICS_INTEGRATION_RECEIVED_NEWDASHBOARD",
      });
    case types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_FAILED_NEWDASHBOARD:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALYTICS_INTEGRATION_FAILED_NEWDASHBOARD",
      });
    default:
      // console.log("The state is ", state);
      return state;
  }
}
export function fetchGoogleAnalyticsDownloadStatusNewDashboardReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_DOWNLOAD_STATUS_NEWDASHBOARD_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALYTICS_INTEGRATION_DOWNLOAD_STATUS_NEWDASHBOARD_REQUESTED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_DOWNLOAD_STATUS_NEWDASHBOARD_RECEIVED:
      let { data } = action;
     
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_GOOGLE_ANALYTICS_INTEGRATION_DOWNLOAD_STATUS_NEWDASHBOARD_RECEIVED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_DOWNLOAD_STATUS_NEWDASHBOARD_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALYTICS_INTEGRATION_DOWNLOAD_STATUS_NEWDASHBOARD_FAILED",
      });
    default:
      // console.log("The state is ", state);
      return state;
  }
}

export function fetchProductPerformanceReportDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_REQUESTED:
      return Object.assign({}, state, {
        message:
          "FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_REQUESTED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_RECEIVED:
      let { data } = action;
     
      return Object.assign({}, state, {
        ...action.data,
        message:
          "FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_RECEIVED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_FAILED:
      return Object.assign({}, state, {
        message:
          "FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_FAILED",
      });
    default:
      // console.log("The state is ", state);
      return state;
  }
}
// export function fetchProductPerformanceReportDataReducer(
//   state = {
//     message: "",
//   },
//   action
// ) {
//   switch (action.type) {
//     case types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_REQUESTED:
//       return Object.assign({}, state, {
//         message:
//           "FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_REQUESTED",
//       });
//     case types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_RECEIVED:
//       let { data } = action;
      
//       return Object.assign({}, state, {
//         ...action.data,
//         message:
//           "FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_RECEIVED",
//       });
//     case types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_FAILED:
//       return Object.assign({}, state, {
//         message:
//           "FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_FAILED",
//       });
//     default:
//       // console.log("The state is ", state);
//       return state;
//   }
// }

export function fetchOverallPerformanceReportDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_REQUESTED:
      return Object.assign({}, state, {
        message:
          "FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_REQUESTED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_RECEIVED:
      let { data } = action;
     
      return Object.assign({}, state, {
        ...action.data,
        message:
          "FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_RECEIVED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_FAILED:
      return Object.assign({}, state, {
        message:
          "FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_FAILED",
      });
    default:
      // console.log("The state is ", state);
      return state;
  }
}

export function fetchChannelPerformanceReportDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_REQUESTED:
      return Object.assign({}, state, {
        message:
          "FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_REQUESTED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_RECEIVED:
      let { data } = action;
     
      return Object.assign({}, state, {
        ...action.data,
        message:
          "FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_RECEIVED",
      });
    case types.FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_FAILED:
      return Object.assign({}, state, {
        message:
          "FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_FAILED",
      });
    default:
      // console.log("The state is ", state);
      return state;
  }
}
