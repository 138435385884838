import { Grid, Menu, MenuItem, Paper, Typography, Switch } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  fetchConnectionRelatedInfo,
  fetchGASessionsAndTransactionsData,
  fetchStatesSessionAndBounceRateData,
  fetchDateRangeForGAData,
  fetchSynCheckId,
  fetchStatusForProgressBar,
} from "../../actions/index.js";
import { getLastDates } from "../../components/grader/templates.jsx";
import icons from "../../components/icons.js";
import LineGraph from "../graphComponents/LineGraph";
import GAMap from "../graphComponents/Map.jsx";
import ProgressBar from "../graphComponents/ProgressBar";
import { CircularProgress } from "@mui/material/index";
import { fetchGAModelDataReducer } from "../../reducers/googleAnalyticsData.js";
import LinearProgressBar from "../graphComponents/LinearProgressBar";
import { createHashHistory, createBrowserHistory } from "history";

const styles = (theme) => ({
  mainDiv: {
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "15px",
    },
    backgroundColor: "#F9FAFF",
  },
  duration: {
    font: "lato",
    fontWeight: "600",
    fontSize: "14px",
    color: "#FF8735",
    marginRight: "125px",
  },
  compare: {
    font: "lato",
    fontWeight: "600",
    fontSize: "14px",
    color: "#FF8735",
    marginRight: "125px",
  },
  heading: {
    [theme.breakpoints.up("sm")]: {
      font: "lato",
      fontWeight: "700",
      fontSize: "18px",
      marginTop: "20px",
      paddingLeft: "20px",
      color: "#1E334E",
    },
    [theme.breakpoints.down("lg")]: {
      font: "lato",
      fontWeight: "700",
      fontSize: "18px",
      color: "#1E334E",
    },
  },
  heading1: {
    [theme.breakpoints.up("sm")]: {
      font: "lato",
      fontWeight: "700",
      fontSize: "18px",
      marginTop: "20px",
      color: "#1E334E",
    },
    [theme.breakpoints.down("lg")]: {
      font: "lato",
      fontWeight: "700",
      fontSize: "18px",
      color: "#1E334E",
    },
  },
  paper: {
    [theme.breakpoints.up("sm")]: {
      border: "solid #E3E6F4 1px",
      borderRadius: "10px",
    },
    [theme.breakpoints.down("lg")]: {},
  },
  card: {
    width: "150px",
    height: "125px",
  },
  selectedDate: {
    width: "150px",
    height: "125px",
    border: "solid 2px #F7931E",
  },
  month: {
    font: "lato",
    fontWeight: "300",
    fontSize: "12px",
    textAlign: "left",
    marginTop: "5px",
    paddingLeft: "12px",
  },
  year: {
    font: "lato",
    fontWeight: "300",
    fontSize: "12px",
    textAlign: "right",
    marginTop: "5px",
    paddingRight: "12px",
  },
  date: {
    font: "lato",
    fontWeight: "700",
    fontSize: "30px",
    textAlign: "center",
  },
  day: {
    font: "lato",
    fontWeight: "300",
    fontSize: "12px",
    textAlign: "center",
  },
  time: {
    font: "lato",
    fontWeight: "700",
    fontSize: "12px",
    textAlign: "center",
  },
  button: {
    width: "326px",
    height: "50px",
    backgroundColor: "#F58120",
    border: "solid 2px #F58120",
    borderRadius: "3px",
  },
  btnTypography: {
    color: "#FFFFFF",
    font: "lato",
    fontWeight: "700",
    fontSize: "20px",
  },
  image: {
    height: "150px",
  },
  selectedCalender: {
    color: "#F58120",
  },
  S_with_T_TooltipDiv: {
    width: "180px",
    paddingLeft: "10px",
    border: "1px solid #C4C4C4",
    borderRadius: "5px",
    backgroundColor: "white",
  },
  S_with_T_TooltipDivPara1: {
    color: "#74839C",
  },
  S_with_T_TooltipDivPara2: {
    color: "#F58120",
  },
  bounceRateTooltipDiv: {
    width: "180px",
    paddingLeft: "10px",
    border: "1px solid #C4C4C4",
    borderRadius: "5px",
    backgroundColor: "white",
  },
  bounceRateTooltipDivPara1: {
    color: "#1E334E",
  },
  bounceRateTooltipDivPara2: {
    color: "#F58120",
  },
  bounceRateTooltipDivPara3: {
    color: "#B6C2D1",
  },
  content: {
    font: "lato",
    fontSize: "18px",
    lineHeight: "32px",
    // marginLeft: "42px",
  },
  bounceRateContentGrid: {
    backgroundColor: "#FFE47766",
    border: "solid 1px #FFE47766",
    borderRadius: "5px",
  },
});

let selectedPeriod = 7;

let selectedDays;

let dataForBothLineGraph = [];
let dataForBounceRateByChannel = [];
let dataForSesswithTranGraph = [];
let synCheckId = "";
function googleAnalyticsDashboardPage(props) {
  const classes = props.classes;
  const {
    fetchGASessionsAndTransactionsData,
    fetchGASessionsAndTransactionsDataReducer,
    fetchStatesSessionAndBounceRateData,
    fetchStatesSessionAndBounceRateDataReducer,
    fetchConnectionRelatedInfo,
    connectionsDataReducer,
    fetchDateRangeForGAData,
    fetchDateRangeForGADataReducers,
    saveAnalyAccDataReducer,
    fetchSynCheckId,
    fetchSynCheckIDReducer,
    fetchStatusForProgressBar,
    fetchStatusForProgressBarReducer,
  } = props;

  let calender = getLastDates();
  let date = new Date();
  var day = date.getDay();
  if (day === 5) {
    var today = 3;
  } else if (day === 6) {
    today = 2;
  } else {
    today = 1;
  }
  const [selected, setSelected] = useState(today);
  const [selectedDate, setSelectedDate] = useState(calender[today].date);
  const handleOnClick = (id, items) => {
    setSelected(id);
    setSelectedDate(items.date);
  };

  (() => {
    if (window.localStorage) {
      // If there is no item as 'reload'
      // in localstorage then create one &
      // reload the page
      if (!localStorage.getItem("reload")) {
        localStorage["reload"] = true;
        window.location.reload();
      } else {
        // If there exists a 'reload' item
        // then clear the 'reload' item in
        // local storage
        // localStorage.removeItem('reload');
      }
    }
  })();

  useEffect(() => {
    if (!Object.keys(connectionsDataReducer).includes("Universal-Analytics")) {
      console.log("!Object.keys(connectionsDataReducer) "); //this is called one time
      fetchConnectionRelatedInfo();
      fetchDateRangeForGAData(selectedPeriod);
    }
  }, []);

  const [startDateAPI, setStartDateAPI] = useState(
    fetchDateRangeForGADataReducers
      ? fetchDateRangeForGADataReducers.startDate
      : null
  );
  const [endDateAPI, setEndDateAPI] = useState(
    fetchDateRangeForGADataReducers
      ? fetchDateRangeForGADataReducers.endDate
      : null
  );
  const [startDatePrevPeriodAPI, setStartDatePrevPeriodAPI] = useState(
    fetchDateRangeForGADataReducers
      ? fetchDateRangeForGADataReducers.startDatePrevPeriod
      : null
  );

  const [endDatePrevPeriodAPI, setEndDatePrevPeriodAPI] = useState(
    fetchDateRangeForGADataReducers
      ? fetchDateRangeForGADataReducers.endDatePrevPeriodAPI
      : null
  );

  const [currPeriodYear1API, setCurrPeriodYear1API] = useState(
    fetchDateRangeForGADataReducers
      ? fetchDateRangeForGADataReducers.currPeriodYear1
      : null
  );

  const [currPeriodYear2API, setCurrPeriodYear2API] = useState(
    fetchDateRangeForGADataReducers
      ? fetchDateRangeForGADataReducers.currPeriodYear2
      : null
  );

  const [prevPeriodYear1API, setPrevPeriodYear1API] = useState(
    fetchDateRangeForGADataReducers
      ? fetchDateRangeForGADataReducers.prevPeriodYear1
      : null
  );

  const [prevPeriodYear2API, setPrevPeriodYear2API] = useState(
    fetchDateRangeForGADataReducers
      ? fetchDateRangeForGADataReducers.prevPeriodYear2
      : null
  );
  useEffect(() => {
    if (
      fetchDateRangeForGADataReducers["message"] === "FETCH_DATE_RANGE_RECEIVED"
    ) {
      setStartDateAPI(
        fetchDateRangeForGADataReducers.startDate
          ? fetchDateRangeForGADataReducers.startDate
          : null
      );
      setEndDateAPI(
        fetchDateRangeForGADataReducers.endDate
          ? fetchDateRangeForGADataReducers.endDate
          : null
      );
      setStartDatePrevPeriodAPI(
        fetchDateRangeForGADataReducers.startDatePrevPeriod
          ? fetchDateRangeForGADataReducers.startDatePrevPeriod
          : null
      );
      setEndDatePrevPeriodAPI(
        fetchDateRangeForGADataReducers.endDatePrevPeriod
          ? fetchDateRangeForGADataReducers.endDatePrevPeriod
          : null
      );
      setCurrPeriodYear1API(
        fetchDateRangeForGADataReducers.currPeriodYear1
          ? fetchDateRangeForGADataReducers.currPeriodYear1
          : null
      );
      setCurrPeriodYear2API(
        fetchDateRangeForGADataReducers.currPeriodYear2
          ? fetchDateRangeForGADataReducers.currPeriodYear2
          : null
      );
      setPrevPeriodYear1API(
        fetchDateRangeForGADataReducers.prevPeriodYear1
          ? fetchDateRangeForGADataReducers.prevPeriodYear1
          : null
      );
      setPrevPeriodYear2API(
        fetchDateRangeForGADataReducers.prevPeriodYear2
          ? fetchDateRangeForGADataReducers.prevPeriodYear2
          : null
      );
    }
  }, [fetchDateRangeForGADataReducers]);

  useEffect(() => {
    if (
      connectionsDataReducer["message"] === "FETCH_CONNECTION_DATA_RECEIVED"
    ) {
      fetchSynCheckId();
    }
  }, [connectionsDataReducer]);

  if (fetchSynCheckIDReducer.analyticsSyncCheckId) {
    synCheckId = fetchSynCheckIDReducer.analyticsSyncCheckId;
  }
  const [completedValue, setCompletedValue] = useState(
    fetchStatusForProgressBarReducer &&
      fetchStatusForProgressBarReducer.length > 0
      ? fetchStatusForProgressBarReducer.complete_value
      : 0
  );
  const [completedStatus, setCompletedStatus] = useState(
    fetchStatusForProgressBarReducer &&
      fetchStatusForProgressBarReducer.length > 0
      ? fetchStatusForProgressBarReducer.status
      : null
  );
  const countRef = useRef(null);
  countRef.current = completedValue;
  useEffect(() => {
    
    if (synCheckId) {
      const interval = setInterval(() => {
        if (countRef.current >= 100) {
          clearInterval(interval);
        } else {
          fetchStatusForProgressBar(synCheckId);
        }
      }, 10000);
    }
  }, [fetchSynCheckIDReducer.analyticsSyncCheckId]);

  useEffect(() => {
    if (fetchStatusForProgressBarReducer.complete_value) {
      setCompletedValue(fetchStatusForProgressBarReducer.complete_value);
      setCompletedStatus(fetchStatusForProgressBarReducer.status);
    }
  }, [fetchStatusForProgressBarReducer.complete_value]);

  useEffect(() => {
    if (
      connectionsDataReducer["message"] === "FETCH_CONNECTION_DATA_RECEIVED"
    ) {
      if (connectionsDataReducer["Universal-Analytics"].length > 0) {
        let name = connectionsDataReducer["Universal-Analytics"][0].accountName;
        setAccountName(name);

        setPropertyIdAPI(
          connectionsDataReducer["Universal-Analytics"][0].propertyId
        );
        setViewIdAPI(connectionsDataReducer["Universal-Analytics"][0].viewId);
        setAccountIdConnection(
          connectionsDataReducer["Universal-Analytics"][0].accountId
        );

        fetchGASessionsAndTransactionsData(
          connectionsDataReducer["Universal-Analytics"][0].accountId,
          connectionsDataReducer["Universal-Analytics"][0].propertyId,
          connectionsDataReducer["Universal-Analytics"][0].viewId,
          selectedPeriod
        );
        fetchStatesSessionAndBounceRateData(
          connectionsDataReducer["Universal-Analytics"][0].accountId,
          connectionsDataReducer["Universal-Analytics"][0].propertyId,
          connectionsDataReducer["Universal-Analytics"][0].viewId,
          selectedPeriod
        );
      } else if (connectionsDataReducer["Google-Analytics4"].length > 0) {
        let name = connectionsDataReducer["Google-Analytics4"][0].accountName;

        setAccountName(name);

        setPropertyIdAPI(
          connectionsDataReducer["Google-Analytics4"][0].propertyId
        );
        setViewIdAPI(connectionsDataReducer["Google-Analytics4"][0].viewId);
        setAccountIdConnection(
          connectionsDataReducer["Google-Analytics4"][0].accountId
        );
        fetchGASessionsAndTransactionsData(
          connectionsDataReducer["Google-Analytics4"][0].accountId,
          connectionsDataReducer["Google-Analytics4"][0].propertyId,
          connectionsDataReducer["Google-Analytics4"][0].viewId,
          selectedPeriod
        );
        fetchStatesSessionAndBounceRateData(
          connectionsDataReducer["Google-Analytics4"][0].accountId,
          connectionsDataReducer["Google-Analytics4"][0].propertyId,
          connectionsDataReducer["Google-Analytics4"][0].viewId,
          selectedPeriod
        );
      }
    }
  }, [completedValue]);

  const dailySessionsAndTransactionsObject =
    fetchGASessionsAndTransactionsDataReducer.sessionsTransactionsByDay
      ? fetchGASessionsAndTransactionsDataReducer.sessionsTransactionsByDay
      : null;

  const dailySessionsAndTransactionsObjectPrevYr =
    fetchGASessionsAndTransactionsDataReducer.prevPeriodSessionsTransactionsByDay
      ? fetchGASessionsAndTransactionsDataReducer.prevPeriodSessionsTransactionsByDay
      : null;

  const dailySessionsAndTransactions = dailySessionsAndTransactionsObject
    ? dailySessionsAndTransactionsObject.dailyData
    : null;
  const dailySessionsAndTransactionsPrevYr =
    dailySessionsAndTransactionsObjectPrevYr
      ? dailySessionsAndTransactionsObjectPrevYr.dailyData
      : null;

  if (dailySessionsAndTransactions) {
    dataForBothLineGraph = [];
    for (let i = 0; i < Object.keys(dailySessionsAndTransactions).length; i++) {
      dataForBothLineGraph.push({
        currSession: dailySessionsAndTransactions[i].sessions
          ? dailySessionsAndTransactions[i].sessions
          : 0,
        currTransaction: dailySessionsAndTransactions[i].transactions
          ? dailySessionsAndTransactions[i].transactions
          : 0,
        currDay: dailySessionsAndTransactions[i].day
          ? dailySessionsAndTransactions[i].day
          : 0,
        prevSession:
          dailySessionsAndTransactionsPrevYr[i] &&
          dailySessionsAndTransactionsPrevYr[i].sessions
            ? dailySessionsAndTransactionsPrevYr[i].sessions
            : 0,
        prevTransaction:
          dailySessionsAndTransactionsPrevYr[i] &&
          dailySessionsAndTransactionsPrevYr[i].transactions
            ? dailySessionsAndTransactionsPrevYr[i].transactions
            : 0,
        prevDay:
          dailySessionsAndTransactionsPrevYr[i] &&
          dailySessionsAndTransactionsPrevYr[i].day
            ? dailySessionsAndTransactionsPrevYr[i].day
            : 0,
      });
    }
  }

  if (dailySessionsAndTransactions) {
    dataForSesswithTranGraph = [];
    for (let i = 0; i < Object.keys(dailySessionsAndTransactions).length; i++) {
      dataForSesswithTranGraph.push({
        currSession: dailySessionsAndTransactions[i].sessionsWithTransactions
          ? dailySessionsAndTransactions[i].sessionsWithTransactions
          : 0,
        currDateInWords: dailySessionsAndTransactions[i].dateInWords
          ? dailySessionsAndTransactions[i].dateInWords
          : 0,
        currTransactionRevenue: dailySessionsAndTransactions[i]
          .transactionRevenue
          ? dailySessionsAndTransactions[i].transactionRevenue
          : 0,
        prevSession:
          dailySessionsAndTransactionsPrevYr[i] &&
          dailySessionsAndTransactionsPrevYr[i].sessionsWithTransactions
            ? dailySessionsAndTransactionsPrevYr[i].sessionsWithTransactions
            : 0,
        prevDateInWords:
          dailySessionsAndTransactionsPrevYr[i] &&
          dailySessionsAndTransactionsPrevYr[i].dateInWords
            ? dailySessionsAndTransactionsPrevYr[i].dateInWords
            : 0,
        prevTransactionRevenue:
          dailySessionsAndTransactionsPrevYr[i] &&
          dailySessionsAndTransactionsPrevYr[i].transactionRevenue
            ? dailySessionsAndTransactionsPrevYr[i].transactionRevenue
            : 0,
        maxOfCurrAndPrevTransactionRevenue:
          dailySessionsAndTransactionsPrevYr[i] &&
          dailySessionsAndTransactionsPrevYr[i].transactionRevenue >=
            dailySessionsAndTransactions[i].transactionRevenue
            ? dailySessionsAndTransactionsPrevYr[i].transactionRevenue
            : dailySessionsAndTransactions[i].transactionRevenue,

        maxOfCurrAndPrevSession:
          dailySessionsAndTransactionsPrevYr[i] &&
          dailySessionsAndTransactionsPrevYr[i].sessionsWithTransactions >=
            dailySessionsAndTransactions[i].sessionsWithTransactions
            ? dailySessionsAndTransactionsPrevYr[i].sessionsWithTransactions
            : dailySessionsAndTransactions[i].sessionsWithTransactions,
      });
    }
  }

  const totalSessionsAndTransactions = dailySessionsAndTransactionsObject
    ? dailySessionsAndTransactionsObject.totalData
    : null;
  const totalSessionsAndTransactionsPrevYr =
    dailySessionsAndTransactionsObjectPrevYr
      ? dailySessionsAndTransactionsObjectPrevYr.totalData
      : null;
  console.log(
    "dailySessionsAndTransactions---->",
    dailySessionsAndTransactions
  );

  const statesAndSessionsDataForProgressBar =
    fetchStatesSessionAndBounceRateDataReducer.topFourStates
      ? fetchStatesSessionAndBounceRateDataReducer.topFourStates
      : null;
  const demographicsData =
    fetchStatesSessionAndBounceRateDataReducer.demographicsData
      ? fetchStatesSessionAndBounceRateDataReducer.demographicsData
      : null;
  console.log("demographicsData", demographicsData);
  const [totalSessions, setTotalSessions] = useState(
    totalSessionsAndTransactions
      ? totalSessionsAndTransactions.TotalSessions
      : null
  );
  const [compactSessions, setCompactSessions] = useState(
    totalSessionsAndTransactions
      ? totalSessionsAndTransactions.CompactSessions
      : null
  );
  const [totalTransactions, setTotalTransaction] = useState(
    totalSessionsAndTransactions
      ? totalSessionsAndTransactions.TotalTransactions
      : null
  );
  const [compactTransactions, setCompactTransaction] = useState(
    totalSessionsAndTransactions
      ? totalSessionsAndTransactions.CompactTransactions
      : null
  );
  const [prevTotalSession, setPrevTotalSession] = useState(
    totalSessionsAndTransactionsPrevYr
      ? totalSessionsAndTransactionsPrevYr.TotalSessions
      : null
  );
  const [prevcCompactSessions, setPrevCompactSessions] = useState(
    totalSessionsAndTransactionsPrevYr
      ? totalSessionsAndTransactionsPrevYr.CompactSessions
      : null
  );
  const [prevTotalTransactions, setPrevTotalTransaction] = useState(
    totalSessionsAndTransactionsPrevYr
      ? totalSessionsAndTransactionsPrevYr.TotalTransactions
      : null
  );
  const [prevCompactTransactions, setPrevCompactTransaction] = useState(
    totalSessionsAndTransactionsPrevYr
      ? totalSessionsAndTransactionsPrevYr.CompactTransactions
      : null
  );
  useEffect(() => {
    if (totalSessionsAndTransactions) {
      setTotalSessions(
        totalSessionsAndTransactions.TotalSessions
          ? totalSessionsAndTransactions.TotalSessions
          : null
      );
      setTotalTransaction(
        totalSessionsAndTransactions.TotalTransactions
          ? totalSessionsAndTransactions.TotalTransactions
          : null
      );
      setCompactSessions(
        totalSessionsAndTransactions.CompactSessions
          ? totalSessionsAndTransactions.CompactSessions
          : null
      );
      setCompactTransaction(
        totalSessionsAndTransactions.CompactTransactions
          ? totalSessionsAndTransactions.CompactTransactions
          : null
      );
      setPrevTotalSession(
        totalSessionsAndTransactionsPrevYr.TotalSessions
          ? totalSessionsAndTransactionsPrevYr.TotalSessions
          : null
      );
      setPrevTotalTransaction(
        totalSessionsAndTransactionsPrevYr.TotalTransactions
          ? totalSessionsAndTransactionsPrevYr.TotalTransactions
          : null
      );
      setPrevCompactSessions(
        totalSessionsAndTransactionsPrevYr.CompactSessions
          ? totalSessionsAndTransactionsPrevYr.CompactSessions
          : null
      );
      setPrevCompactTransaction(
        totalSessionsAndTransactionsPrevYr.CompactTransactions
          ? totalSessionsAndTransactionsPrevYr.CompactTransactions
          : null
      );
    }
  }, [totalSessionsAndTransactions]);

  var num1 = totalSessions;
  var obj1 = new Intl.NumberFormat("en-US");
  var totalSessionsNumber = obj1.format(num1);

  var num3 = prevTotalSession;
  var obj3 = new Intl.NumberFormat("en-US");
  var prevTotalSessionNumber = obj3.format(num3);

  var num2 = totalTransactions;
  var obj2 = new Intl.NumberFormat("en-US");
  var totalTransactionsNumber = obj2.format(num2);

  var num4 = prevTotalTransactions;
  var obj4 = new Intl.NumberFormat("en-US");
  var prevTotalTransactionsNumber = obj4.format(num4);

  const [gender, setGender] = useState(
    demographicsData ? demographicsData.gender : null
  );

  const [region, setRegion] = useState(
    demographicsData ? demographicsData.region : null
  );
  const [ageRange, setAgeRange] = useState(
    demographicsData ? demographicsData.ageBracket : null
  );
  const [hostname, setHostName] = useState(
    demographicsData ? demographicsData.hostname : null
  );
  const [operatingSystem, setOperatingSystem] = useState(
    demographicsData ? demographicsData.operating_system : null
  );
  const [deviceCategory, setDeviceCategory] = useState(
    demographicsData ? demographicsData.deviceCategory : null
  );
  const [disableCalendarAndCompare, setDisableCalendarAndCompare] = useState(false);
  
  useEffect(() => {
    if (demographicsData) {
      setGender(demographicsData.gender ? demographicsData.gender : null);
      setAgeRange(
        demographicsData.ageBracket
          ? demographicsData.ageBracket.replace("-", " to ")
          : null
      );
      setAgeRange(
        demographicsData.ageBracket ? demographicsData.ageBracket : null
      );
      setRegion(demographicsData.region ? demographicsData.region : null);
      setHostName(demographicsData.hostname ? demographicsData.hostname : null);
      setOperatingSystem(
        demographicsData.operating_system
          ? demographicsData.operating_system
          : null
      );
      setDeviceCategory(
        demographicsData.deviceCategory ? demographicsData.deviceCategory : null
      );
      if (Object.keys(demographicsData).length === 0) {
        setDisableCalendarAndCompare(true);
      }
    }
  }, [demographicsData]);

  const bounceRateByChannelData = fetchGASessionsAndTransactionsDataReducer
    ? fetchGASessionsAndTransactionsDataReducer.bounceRateByChannel
    : null;
  const bounceRateByChannelDataPrevYr =
    fetchGASessionsAndTransactionsDataReducer
      ? fetchGASessionsAndTransactionsDataReducer.prevPeriodBounceRateByChannel
      : null;

  if (bounceRateByChannelData) {
    dataForBounceRateByChannel = [];
    for (let i = 0; i < Object.keys(bounceRateByChannelData).length; i++) {
      dataForBounceRateByChannel.push({
        currBounceRate: bounceRateByChannelData[i]
          ? bounceRateByChannelData[i].bounceRate
          : 0,
        prevBounceRate: bounceRateByChannelDataPrevYr[i]
          ? bounceRateByChannelDataPrevYr[i].bounceRate
          : 0,
        channel: bounceRateByChannelData[i]
          ? bounceRateByChannelData[i].channel
          : "NA",
      });
    }
  }

  const statesAndSessionsDataForMap = fetchStatesSessionAndBounceRateDataReducer
    ? fetchStatesSessionAndBounceRateDataReducer.sessionsByState
    : null;
  const [mapStatesAndSessionsDataForMap, setMapStatesAndSessionsDataForMap] =
    useState(
      fetchStatesSessionAndBounceRateDataReducer
        ? fetchStatesSessionAndBounceRateDataReducer.sessionsByState
        : null
    );
  const sessionsContentData =
    fetchGASessionsAndTransactionsDataReducer.sessionComparisonData
      ? fetchGASessionsAndTransactionsDataReducer.sessionComparisonData
      : null;

  const [startDateSes, setStartDateSes] = useState(
    sessionsContentData ? sessionsContentData.startDate : null
  );
  const [endDateSes, setEndDateSes] = useState(
    sessionsContentData ? sessionsContentData.endDate : null
  );
  const [prevYear, setPrevYear] = useState(
    sessionsContentData ? sessionsContentData.year1 : null
  );
  const [currYear, setCurrYear] = useState(
    sessionsContentData ? sessionsContentData.year2 : null
  );
  const [sessionPercentResult, setSessionPercentResult] = useState(
    sessionsContentData ? sessionsContentData.sessionPercentResult : null
  );
  const [sessionPercent, setSessionPercent] = useState(
    sessionsContentData ? sessionsContentData.sessionPercent : null
  );
  const [currYearTotalSession, setCurrTotalSession] = useState(
    sessionsContentData ? sessionsContentData.currTotalSession : null
  );
  const [prevYearTotalSession, setPrevYearTotalSession] = useState(
    sessionsContentData ? sessionsContentData.prevPeriodTotalSession : null
  );
  const [maxSessionChannel, setMaxSessionChannel] = useState(
    sessionsContentData ? sessionsContentData.maxSessionChannel : null
  );
  const [maxSessionPercentageByChannel, setMaxSessionPercentageByChannel] =
    useState(
      sessionsContentData
        ? sessionsContentData.maxSessionPercentageByChannel
        : null
    );
  const [
    sessionPercentageByChannelResult,
    setSessionPercentageByChannelResult,
  ] = useState(
    sessionsContentData
      ? sessionsContentData.sessionPercentageByChannelResult
      : null
  );
  const [sessionTrendPattern, setSessionTrendPattern] = useState(
    sessionsContentData ? sessionsContentData.sessionTrendPattern : null
  );
  useEffect(() => {
    if (statesAndSessionsDataForMap) {
      setMapStatesAndSessionsDataForMap(
        statesAndSessionsDataForMap ? statesAndSessionsDataForMap : null
      );
    }
  }, [statesAndSessionsDataForMap]);
  let allSessionsMap = 0;
  // const [allSessionsMap, setAllSessionsMap] = useState();
  console.log(
    "mapStatesAndSessionsDataForMap ---->",
    mapStatesAndSessionsDataForMap
  );
  if (mapStatesAndSessionsDataForMap != null) {
    const allValuesMapStatesAndSessionsDataForMapObj = Object.values(
      mapStatesAndSessionsDataForMap
    );
    allSessionsMap = allValuesMapStatesAndSessionsDataForMapObj.map((n) => {
      return n.sessions;
    });
  }
  console.log("list of sessions from map data-->", allSessionsMap);
  let maxOfSessions = 0;
  let minOfSessions = 0;
  if (allSessionsMap != 0) {
    maxOfSessions = Math.max(...allSessionsMap);
    minOfSessions = Math.min(...allSessionsMap);
  }

  console.log("Max--->", maxOfSessions);
  console.log("Min--->", minOfSessions);

  useEffect(() => {
    if (sessionsContentData) {
      setStartDateSes(
        sessionsContentData.startDate ? sessionsContentData.startDate : null
      );
      setEndDateSes(
        sessionsContentData.endDate ? sessionsContentData.endDate : null
      );
      setPrevYear(sessionsContentData.year1 ? sessionsContentData.year1 : null);
      setCurrYear(sessionsContentData.year2 ? sessionsContentData.year2 : null);
      setSessionPercentResult(
        sessionsContentData.sessionPercentResult
          ? sessionsContentData.sessionPercentResult
          : null
      );
      setSessionPercent(
        sessionsContentData.sessionPercent
          ? sessionsContentData.sessionPercent
          : null
      );
      setCurrTotalSession(
        sessionsContentData.currTotalSession >= 0
          ? sessionsContentData.currTotalSession.toLocaleString("en-US")
          : null
      );
      setPrevYearTotalSession(
        sessionsContentData.prevPeriodTotalSession >= 0
          ? sessionsContentData.prevPeriodTotalSession.toLocaleString("en-US")
          : null
      );
      setMaxSessionChannel(
        sessionsContentData.maxSessionChannel
          ? sessionsContentData.maxSessionChannel
          : null
      );
      setMaxSessionPercentageByChannel(
        sessionsContentData.maxSessionPercentageByChannel
          ? sessionsContentData.maxSessionPercentageByChannel
          : null
      );
      setSessionPercentageByChannelResult(
        sessionsContentData.sessionPercentageByChannelResult
          ? sessionsContentData.sessionPercentageByChannelResult
          : null
      );
      setSessionTrendPattern(
        sessionsContentData.sessionTrendPattern
          ? sessionsContentData.sessionTrendPattern
          : null
      );
    }
  }, [sessionsContentData]);

  const transactionsContentData =
    fetchGASessionsAndTransactionsDataReducer.transactionComparisonData
      ? fetchGASessionsAndTransactionsDataReducer.transactionComparisonData
      : null;

  const [startDateTr, setStartDateTr] = useState(
    transactionsContentData ? transactionsContentData.startDate : null
  );
  const [endDateTr, setEndDateTr] = useState(
    transactionsContentData ? transactionsContentData.endDate : null
  );
  const [transactionPercentResult, setTransactionPercentResult] = useState(
    transactionsContentData
      ? transactionsContentData.transactionPercentResult
      : null
  );
  const [transactionPercent, setTransactionPercent] = useState(
    transactionsContentData ? transactionsContentData.transactionPercent : null
  );
  // const [currYearTotalTransaction, setCurrTotalTransaction] = useState(
  //   transactionsContentData
  //     ? transactionsContentData.currTotalTransaction
  //     : null
  // );
  // const [prevYearTotalTransaction, setPrevYearTotalTransaction] = useState(
  //   transactionsContentData
  //     ? transactionsContentData.prevYearTotalTransaction
  //     : null
  // );
  const [currTotalTransactions, setCurrTotalTransactions] = useState(
    transactionsContentData
      ? transactionsContentData.currTotalTransactions
      : null
  );
  const [
    prevTransactionsContentDataTotalTransactions,
    setPrevTransactionsContentDataTotalTransactions,
  ] = useState(
    transactionsContentData
      ? transactionsContentData.prevPeriodTotalTransactions
      : null
  );
  const [maxTransactionChannel, setMaxTransactionChannel] = useState(
    transactionsContentData
      ? transactionsContentData.maxTransactionChannel
      : null
  );
  const [
    maxTransactionPercentageByChannel,
    setMaxTransactionPercentageByChannel,
  ] = useState(
    transactionsContentData
      ? transactionsContentData.maxTransactionPercentageByChannel
      : null
  );
  const [
    transactionPercentageByChannelResult,
    setTransactionPercentageByChannelResult,
  ] = useState(
    transactionsContentData
      ? transactionsContentData.transactionPercentageByChannelResult
      : null
  );
  const [transactionTrendPattern, setTransactionTrendPattern] = useState(
    transactionsContentData
      ? transactionsContentData.transactionTrendPattern
      : null
  );
  useEffect(() => {
    if (transactionsContentData) {
      setStartDateTr(
        transactionsContentData.startDate
          ? transactionsContentData.startDate
          : null
      );
      setEndDateTr(
        transactionsContentData.endDate ? transactionsContentData.endDate : null
      );
      setTransactionPercentResult(
        transactionsContentData.transactionPercentResult
          ? transactionsContentData.transactionPercentResult
          : null
      );
      setTransactionPercent(
        transactionsContentData.transactionPercent
          ? transactionsContentData.transactionPercent
          : null
      );
      // setCurrTotalTransaction(
      //   transactionsContentData.currTotalTransaction
      //     ? transactionsContentData.currTotalTransaction
      //     : null
      // );
      // setPrevYearTotalTransaction(
      //   transactionsContentData.prevYearTotalTransaction
      //     ? transactionsContentData.prevYearTotalTransaction
      //     : null
      // );
      setCurrTotalTransactions(
        transactionsContentData.currTotalTransactions >= 0
          ? transactionsContentData.currTotalTransactions
          : null
      );
      setPrevTransactionsContentDataTotalTransactions(
        transactionsContentData.prevPeriodTotalTransactions >= 0
          ? transactionsContentData.prevPeriodTotalTransactions
          : null
      );
      setMaxTransactionChannel(
        transactionsContentData.maxTransactionChannel
          ? transactionsContentData.maxTransactionChannel
          : null
      );
      setMaxTransactionPercentageByChannel(
        transactionsContentData.maxTransactionPercentageByChannel
          ? transactionsContentData.maxTransactionPercentageByChannel
          : null
      );
      setTransactionPercentageByChannelResult(
        transactionsContentData.transactionPercentageByChannelResult
          ? transactionsContentData.transactionPercentageByChannelResult
          : null
      );
      setTransactionTrendPattern(
        transactionsContentData.transactionTrendPattern
          ? transactionsContentData.transactionTrendPattern
          : null
      );
    }
  }, [transactionsContentData]);

  const sessionsWithTransactionContentData =
    fetchStatesSessionAndBounceRateDataReducer.sessionsWithTransactionData
      ? fetchStatesSessionAndBounceRateDataReducer.sessionsWithTransactionData
      : null;

  const [startDateSwT, setStartDateSwT] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.startDate
      : null
  );
  const [endDateSwT, setEndDateSwT] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.endDate
      : null
  );
  const [revenueCurrYearSwT, setRevenueCurrYearSwT] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.revenueCurrYear
      : null
  );
  const [revenuePrevYearSwT, setRevenuePrevYearSwT] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.revenuePrevYear
      : null
  );
  const [revenuePercentageSwT, setRevenuePercentageSwT] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.revenuePercentage
      : null
  );
  const [revenuePercentageResultSwT, setRevenuePercentageResultSwT] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.revenuePercentageResult
      : null
  );
  const [
    sessionsWithTransactionPercentageSwT,
    setSessionsWithTransactionPercentageSwT,
  ] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.sessionsWithTransactionPercentage
      : null
  );
  const [
    sessionsWithTransactionResultSwT,
    setSessionsWithTransactionResultSwT,
  ] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.sessionsWithTransactionResult
      : null
  );
  const [product1, setProduct1] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.product1
      : null
  );
  const [product2, setProduct2] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.product2
      : null
  );
  const [product3, setProduct3] = useState(
    sessionsWithTransactionContentData
      ? sessionsWithTransactionContentData.product3
      : null
  );
  useEffect(() => {
    if (sessionsWithTransactionContentData) {
      setStartDateSwT(
        sessionsWithTransactionContentData.startDate
          ? sessionsWithTransactionContentData.startDate
          : null
      );
      setEndDateSwT(
        sessionsWithTransactionContentData.endDate
          ? sessionsWithTransactionContentData.endDate
          : null
      );
      setRevenueCurrYearSwT(
        sessionsWithTransactionContentData.revenueCurrYear
          ? sessionsWithTransactionContentData.revenueCurrYear.toLocaleString(
              "en-US"
            )
          : null
      );
      setRevenuePrevYearSwT(
        sessionsWithTransactionContentData.revenuePrevYear
          ? sessionsWithTransactionContentData.revenuePrevYear.toLocaleString(
              "en-US"
            )
          : null
      );
      setRevenuePercentageSwT(
        sessionsWithTransactionContentData.revenuePercentage
          ? sessionsWithTransactionContentData.revenuePercentage
          : null
      );
      setRevenuePercentageResultSwT(
        sessionsWithTransactionContentData.revenuePercentageResult
          ? sessionsWithTransactionContentData.revenuePercentageResult
          : null
      );
      setSessionsWithTransactionPercentageSwT(
        sessionsWithTransactionContentData.sessionsWithTransactionPercentage
          ? sessionsWithTransactionContentData.sessionsWithTransactionPercentage
          : null
      );
      setSessionsWithTransactionResultSwT(
        sessionsWithTransactionContentData.sessionsWithTransactionResult
          ? sessionsWithTransactionContentData.sessionsWithTransactionResult
          : null
      );
      setProduct1(
        sessionsWithTransactionContentData.product1
          ? sessionsWithTransactionContentData.product1
          : null
      );
      setProduct2(
        sessionsWithTransactionContentData.product2
          ? sessionsWithTransactionContentData.product2
          : null
      );
      setProduct3(
        sessionsWithTransactionContentData.product3
          ? sessionsWithTransactionContentData.product3
          : null
      );
    }
  }, [sessionsWithTransactionContentData]);
  const K_Formatter = (num) => {
    if ((num / 100) % 10 == 0) return Math.floor(num / 1000) + "K";
    else return Math.floor(num / 100) / 10.0 + "K";
  };
  const sessionAndTrascationsToolTip = ({ active, payload, label }) => {
    if (active && label && payload && payload.length) {
      return (
        <div
          style={{ background: "white" }}
          className={classes.S_with_T_TooltipDiv}
        >
          <p className={classes.bounceRateTooltipDivPara1}>{`${label}`}</p>
          <p className={classes.S_with_T_TooltipDivPara1}>
            Curr. Sessions with Transactions:{" "}
            {payload[0].payload.currSession.toLocaleString("en-US")}
          </p>
          <p className={classes.S_with_T_TooltipDivPara2}>
            Curr. Revenue: $
            {payload[0].payload.currTransactionRevenue.toLocaleString("en-US")}
          </p>
          {compareSwitch ? (
            <div>
              <p className={classes.bounceRateTooltipDivPara1}>
                {payload[0].payload.prevDateInWords}
              </p>
              <p className={classes.S_with_T_TooltipDivPara1}>
                Prev. Sessions with Transactions:{" "}
                {payload[0].payload.prevSession.toLocaleString("en-US")}
              </p>
              <p className={classes.S_with_T_TooltipDivPara2}>
                Prev. Revenue: $
                {payload[0].payload.prevTransactionRevenue.toLocaleString(
                  "en-US"
                )}
              </p>{" "}
            </div>
          ) : null}
        </div>
      );
    }
    return null;
  };
  let PercentageFormatter = (num) => {
    return num + "%";
  };
  const bounceRateByChannelToolTip = ({ active, payload, label }) => {
    if (active && label && payload && payload.length) {
      return (
        <div className={classes.bounceRateTooltipDiv}>
          <p className={classes.bounceRateTooltipDivPara1}>
            Channel: {`${label}`}
          </p>
          <p className={classes.bounceRateTooltipDivPara2}>
            Curr. Bounce Rate: {`${payload[0].value}%`}
          </p>
          {compareSwitch ? (
            <p className={classes.bounceRateTooltipDivPara3}>
              Prev. Bounce Rate: {`${payload[1].value}%`}
            </p>
          ) : null}
        </div>
      );
    }
    return null;
  };

  const [compareSwitch, setCompareSwitch] = useState(false);
  const handleCompare = () => {
    if (compareSwitch) {
      setCompareSwitch(false);
    } else {
      setCompareSwitch(true);
    }
  };

  const [show, setShow] = useState(false);
  const handleTooltip = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [isClickedDropdown, setIsClickedDropdown] = useState(false);
  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (isClickedDropdown) {
      fetchGASessionsAndTransactionsData(
        accountIdConnection,
        propertyIdAPI,
        viewIdAPI,
        selectedDays
      );
      fetchStatesSessionAndBounceRateData(
        accountIdConnection,
        propertyIdAPI,
        viewIdAPI,
        selectedDays
      );
      setIsClickedDropdown(false);
    }
  }, [isClickedDropdown]);

  const handleMenuClose = (event) => {
    setAnchorEl(null);
    selectedDays = event.target.value;
    setIsClickedDropdown(true);
    fetchDateRangeForGAData(selectedDays);
  };

  const [accountIdConnection, setAccountIdConnection] = useState("");

  const [accountName, setAccountName] = useState("");
  const [propertyIdAPI, setPropertyIdAPI] = useState("");
  const [viewIdAPI, setViewIdAPI] = useState("");

  const [screenResolution, setScreenResolution] = useState({
    width: 0,
    height: 0,
  });
  const [widthForBounceRateGraph, setWidthForBounceRateGraph] = useState();
  useEffect(() => {
    // Function to update the screen resolution state
    const updateScreenResolution = () => {
      setScreenResolution({
        width: window.screen.width,
        height: window.screen.height,
      });
    };
    // Add event listener to update the screen resolution state on resize
    window.addEventListener("resize", updateScreenResolution);

    // Initial call to set the screen resolution state
    updateScreenResolution();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenResolution);
    };
  }, []);

  return (
    <div className={classes.mainDiv}>
      {completedValue == 100 ? (
        <div>
          {connectionsDataReducer["message"] ===
          "FETCH_CONNECTION_DATA_RECEIVED" ? (
            <div>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Grid container>
                    <Grid item>
                      <Typography
                        style={{
                          fontSize: "26px",
                          fontWeight: "bold",
                          wordBreak: "break-word",
                        }}
                      >
                        {accountName ? accountName : "-"}
                      </Typography>
                      <span>
                        Property ID: {propertyIdAPI ? propertyIdAPI : "-"}
                      </span>
                      {/* <span>&nbsp;| &nbsp;</span> */}
                      {/* <span>View ID: {viewIdAPI ? viewIdAPI : "-"}</span> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  style={{ textAlign: "left" }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      <div>
                        <Typography className={classes.duration}>
                          Duration {icons.RectangleIcon}
                        </Typography>
                        {disableCalendarAndCompare ? (
                          <Typography></Typography>
                        ) : (
                          <Typography>
                            {startDateAPI} to {endDateAPI}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{ textAlign: "center", marginTop: "inherit" }}
                    >
                      <tooltip
                        title="Please select the date Range"
                        placement="right"
                        onClick={handleTooltip}
                      >
                        <span
                          aria-owns={anchorEl ? "simple-menu" : undefined}
                          aria-haspopup="true"
                          onClick={handleIconClick}
                        >
                          {icons.CalenderIcon}
                        </span>
                      </tooltip>
                      {disableCalendarAndCompare ? (
                        <div></div>
                      ) : (
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem value={7} onClick={handleMenuClose}>
                            Last 7 Days
                          </MenuItem>
                          <MenuItem value={15} onClick={handleMenuClose}>
                            Last 15 Days
                          </MenuItem>
                          <MenuItem value={30} onClick={handleMenuClose}>
                            Last 30 Days
                          </MenuItem>
                        </Menu>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      style={{ textAlign: "left" }}
                    >
                      <div>
                        <Typography className={classes.compare}>
                          Compare {icons.RectangleIcon}
                        </Typography>
                        {compareSwitch ? (
                          <Typography>
                            {startDatePrevPeriodAPI} to {endDatePrevPeriodAPI}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      md={1}
                      lg={1}
                      style={{ marginTop: "10px" }}
                    >
                      <Switch
                        checked={compareSwitch}
                        onChange={handleCompare}
                        disabled={disableCalendarAndCompare}
                        // value={ok}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color={"secondary"} />
            </div>
          )}
          {demographicsData && Object.keys(demographicsData).length !== 0 ? (
            <div style={{ marginTop: "20px" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  {gender && gender === "Male" ? (
                    <Typography>{icons.GAMaleIcon}</Typography>
                  ) : (
                    <Typography>{icons.GAFemaleIcon}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Typography style={{ font: "lato", fontSize: "25px" }}>
                    The majority of your customers are{" "}
                    <b>{gender ? gender : "Female"}</b> between the age(s)
                    of&nbsp;
                    <b>{ageRange ? ageRange : "25 to 34"}</b>. They reside in{" "}
                    <b>{region}</b> and transact on&nbsp;
                    <b>{hostname}</b>
                    &nbsp;using {operatingSystem === "iOS" || operatingSystem === "Android" || operatingSystem === null ? "an" : "a"}&nbsp;
                    <b>{operatingSystem ? operatingSystem : "iOS"}</b>&nbsp;
                    <b>{deviceCategory ? deviceCategory : "Mobile"}</b> device.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div style={{ margin: "20px", textAlign: "center" }}>
              <div>{/* <CircularProgress color={"secondary"} /> */}</div>
              <Typography>No data to display</Typography>
            </div>
          )}
          <div style={{ marginTop: "20px" }}>
            <Paper className={classes.paper}>
              <Typography className={classes.heading}>
                Sessions by Location
              </Typography>
              <hr />
              <Grid container>
                <Grid item xs={12} sm={12} md={7} lg={7}>
                  <GAMap
                    selectedRegion={"usa"}
                    data={mapStatesAndSessionsDataForMap}
                  />
                  {!_.isEmpty(mapStatesAndSessionsDataForMap) ? (
                    // <div style={{ paddingLeft: "2rem" }}> {icons.minmax}</div>
                    <Grid container style={{ paddingLeft: "2rem" }}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      >
                        <div>
                          <Grid container>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              style={{ textAlign: "left" }}
                            >
                              {minOfSessions.toLocaleString("en-US")}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              style={{ textAlign: "right" }}
                            >
                              {maxOfSessions.toLocaleString("en-US")}
                            </Grid>
                          </Grid>
                        </div>
                        <div>{icons.minmax}</div>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                {!_.isEmpty(statesAndSessionsDataForProgressBar) ? (
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <ProgressBar
                      progressBarData={statesAndSessionsDataForProgressBar}
                      compareSwitch={compareSwitch}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div style={{ margin: "20px", textAlign: "center" }}>
                      <div>
                        {/* <CircularProgress color={"secondary"} /> */}
                      </div>
                      <Typography>No data to display</Typography>
                    </div>
                  </Grid>
                )}
              </Grid>
              <br />
              <br />
            </Paper>
          </div>
          <div style={{ marginTop: "20px" }}>
            {dailySessionsAndTransactions ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <LineGraph
                    Gdata={dataForBothLineGraph}
                    kpiNumber={compactSessions}
                    prevKpiNumber={prevcCompactSessions}
                    totalValue={totalSessionsNumber}
                    prevTotalValue={prevTotalSessionNumber}
                    compareSwitch={compareSwitch}
                    kpiName="Curr. Sessions"
                    prevKpiName="Prev. Sessions"
                    currValue="currSession"
                    prevValue="prevSession"
                  />
                </Grid>
                {!_.isEmpty(dailySessionsAndTransactions) ? (
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    {currYearTotalSession === prevYearTotalSession ? (
                      <Typography className={classes.content}>
                        The number of sessions between <b>{startDateSes}</b>,
                        and <b>{endDateSes}</b>, remain similar over the
                        previous period. This session trend was influenced by
                        the <b>{maxSessionChannel}</b> channel.
                      </Typography>
                    ) : (
                      <Typography className={classes.content}>
                        The number of sessions between <b>{startDateSes}</b>,
                        and <b>{endDateSes}</b>, <b>{sessionPercentResult}</b>{" "}
                        by <b> {sessionPercent}%</b> over the previous period
                        (from <b>{prevYearTotalSession}</b> to{" "}
                        <b>{currYearTotalSession}</b>). The session trend was
                        influenced by the <b>{maxSessionChannel}</b> channel,
                        which saw a <b>{maxSessionPercentageByChannel}%</b>{" "}
                        <b>{sessionPercentageByChannelResult}</b> in sessions.
                        The same pattern was <b>{sessionTrendPattern}</b> in the
                        previous year.
                      </Typography>
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <div style={{ margin: "20px", textAlign: "center" }}>
                      <div>
                        {/* <CircularProgress color={"secondary"} /> */}
                      </div>
                      <Typography>No data to display</Typography>
                    </div>
                  </Grid>
                )}
              </Grid>
            ) : (
              <div style={{ margin: "20px", textAlign: "center" }}>
                <div>{/* <CircularProgress color={"secondary"} /> */}</div>
                <Typography>No data to display</Typography>
              </div>
            )}
          </div>
          <div style={{ marginTop: "20px" }}>
            {dailySessionsAndTransactions ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <LineGraph
                    Gdata={dataForBothLineGraph}
                    kpiNumber={compactTransactions}
                    prevKpiNumber={prevCompactTransactions}
                    totalValue={totalTransactionsNumber}
                    prevTotalValue={prevTotalTransactionsNumber}
                    compareSwitch={compareSwitch}
                    kpiName="Curr. Transactions"
                    prevKpiName="Prev. Transactions"
                    currValue="currTransaction"
                    prevValue="prevTransaction"
                  />
                </Grid>
                {!_.isEmpty(dailySessionsAndTransactions) ? (
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    {currTotalTransactions ===
                    prevTransactionsContentDataTotalTransactions ? (
                      <Typography className={classes.content}>
                        The number of transactions between <b>{startDateTr}</b>,{" "}
                        and <b>{endDateTr}</b>, remain similar over the previous
                        period. This transaction trend was influenced by the{" "}
                        <b>{maxTransactionChannel}</b> channel.
                      </Typography>
                    ) : (
                      <Typography className={classes.content}>
                        The number of transactions between <b>{startDateTr}</b>,{" "}
                        and <b>{endDateTr}</b>,{" "}
                        <b>{transactionPercentResult}</b> by{" "}
                        <b> {transactionPercent}%</b> over the previous period
                        (from{" "}
                        <b>
                          {prevTransactionsContentDataTotalTransactions != null
                            ? prevTransactionsContentDataTotalTransactions.toLocaleString(
                                "en-US"
                              )
                            : "0"}
                        </b>{" "}
                        to{" "}
                        <b>{currTotalTransactions.toLocaleString("en-US")}</b>
                        ). The transaction trend was influenced by the{" "}
                        <b>{maxTransactionChannel}</b> channel, which saw a{" "}
                        <b>{maxTransactionPercentageByChannel}%</b>{" "}
                        <b>{transactionPercentageByChannelResult}</b> in
                        transactions. The same pattern was{" "}
                        <b>{transactionTrendPattern}</b> in the previous year.
                      </Typography>
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <div style={{ margin: "20px", textAlign: "center" }}>
                      <div>
                        {/* <CircularProgress color={"secondary"} /> */}
                      </div>
                      <Typography>No data to display</Typography>
                    </div>
                  </Grid>
                )}
              </Grid>
            ) : (
              <div style={{ margin: "20px", textAlign: "center" }}>
                <div>{/* <CircularProgress color={"secondary"} /> */}</div>
                <Typography>No data to display</Typography>
              </div>
            )}
          </div>
          <div style={{ marginTop: "20px" }}>
            <Paper className={classes.paper}>
              <Grid conatiner>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h4 className={classes.heading}>
                    Sessions with Transactions
                  </h4>
                  <hr />
                  {dataForSesswithTranGraph &&
                  dataForSesswithTranGraph.length > 0 ? (
                    <ResponsiveContainer width="100%" height={250}>
                      <ComposedChart data={dataForSesswithTranGraph}>
                        <CartesianGrid
                          strokeDasharray="3 3"
                          stroke="#E3E6F4"
                          vertical={false}
                          heading
                        />
                        <XAxis dataKey="currDateInWords" />
                        <YAxis
                          yAxisId="left"
                          // tickFormatter={K_Formatter}
                          tickCount={5}
                          orientation="left"
                          dataKey="maxOfCurrAndPrevSession"
                        />

                        <YAxis
                          yAxisId="right"
                          tickFormatter={K_Formatter}
                          tickCount={5}
                          orientation="right"
                          dataKey="maxOfCurrAndPrevTransactionRevenue"
                        />
                        <Tooltip
                          cursor={false}
                          content={(props) =>
                            sessionAndTrascationsToolTip(props)
                          }
                        />
                        <Legend />
                        <Bar
                          yAxisId="left"
                          barSize={10}
                          name="Curr. Sessions with Transactions"
                          dataKey="currSession"
                          fill="#74839C"
                        />
                        {compareSwitch ? (
                          <Bar
                            yAxisId="left"
                            barSize={10}
                            name="Prev. Sessions with Transactions"
                            dataKey="prevSession"
                            fill="#B6C2D1"
                          />
                        ) : null}

                        <Line
                          strokeWidth={2}
                          yAxisId="right"
                          dataKey="currTransactionRevenue"
                          name="Curr. Revenue"
                          stroke="#FF8735"
                          dot={false}
                        />
                        {compareSwitch ? (
                          <Line
                            strokeWidth={1}
                            yAxisId="right"
                            dataKey="prevTransactionRevenue"
                            name="Prev. Revenue"
                            stroke="#949EAB"
                            dot={false}
                          />
                        ) : null}
                      </ComposedChart>
                    </ResponsiveContainer>
                  ) : (
                    <div style={{ margin: "20px", textAlign: "center" }}>
                      <div>
                        {/* <CircularProgress color={"secondary"} /> */}
                      </div>
                      <Typography>No data to display</Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </div>
          {!_.isEmpty(sessionsWithTransactionContentData) ? (
            <div style={{ marginTop: "20px" }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {sessionsWithTransactionResultSwT != "remained similar" &&
                  revenuePercentageResultSwT != "remained similar" ? (
                    <Typography className={classes.content}>
                      Last year, between <b>{startDateSwT}</b>, and{" "}
                      <b>{endDateSwT}</b>, the number of sessions with
                      transactions <b>{sessionsWithTransactionResultSwT}</b> by{" "}
                      <b>{sessionsWithTransactionPercentageSwT}%</b>,
                      contributing <b>{revenuePercentageSwT}%</b>{" "}
                      <b>{revenuePercentageResultSwT}</b> revenue (from{" "}
                      <b>${revenuePrevYearSwT}</b> to{" "}
                      <b>${revenueCurrYearSwT}</b>) than the prior period. The
                      three most commonly purchased items during that time were
                      the <b>{product1}</b>, the <b>{product2}</b>, and the{" "}
                      <b>{product3}</b>.
                    </Typography>
                  ) : sessionsWithTransactionResultSwT === "remained similar" &&
                    revenuePercentageResultSwT != "remained similar" ? (
                    <Typography className={classes.content}>
                      Last year, between <b>{startDateSwT}</b>, and{" "}
                      <b>{endDateSwT}</b>, the number of sessions with
                      transactions remained similar, contributing{" "}
                      <b>{revenuePercentageSwT}%</b>{" "}
                      <b>{revenuePercentageResultSwT}</b> revenue (from{" "}
                      <b>${revenuePrevYearSwT}</b> to{" "}
                      <b>${revenueCurrYearSwT}</b>) compared to than the prior
                      period. The three most commonly purchased items during
                      that time were the <b>{product1}</b>, the{" "}
                      <b>{product2}</b>, and the <b>{product3}</b>.
                    </Typography>
                  ) : sessionsWithTransactionResultSwT === "remained similar" &&
                    revenuePercentageResultSwT === "remained similar" ? (
                    <Typography className={classes.content}>
                      Last year, between <b>{startDateSwT}</b>, and{" "}
                      <b>{endDateSwT}</b>, the number of sessions with
                      transactions remained similar, contributing the same
                      amount in revenue (<b>{revenueCurrYearSwT}</b>). The three
                      most commonly purchased items during that time were the{" "}
                      <b>{product1}</b>, the <b>{product2}</b>, and the{" "}
                      <b>{product3}</b>.
                    </Typography>
                  ) : sessionsWithTransactionResultSwT === "remained similar" &&
                    revenuePercentageResultSwT === "remained similar" ? (
                    <Typography className={classes.content}>
                      Last year, between <b>{startDateSwT}</b>, and{" "}
                      <b>{endDateSwT}</b>, the number of sessions with
                      transactions <b>{sessionsWithTransactionResultSwT}</b>
                      by <b>{sessionsWithTransactionPercentageSwT}%</b>,
                      contributing the same amount in revenue
                      (curreny_symbol_number-integer_RP). The three most
                      commonly purchased items during that time were the{" "}
                      <b>{product1}</b>, the <b>{product2}</b>, and the{" "}
                      <b>{product3}</b>.
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          ) : null}
          <div style={{ marginTop: "40px" }}>
            <Paper
              className={classes.paper}
              style={{
                overflowX:
                  dataForBounceRateByChannel.length > 8 &&
                  screenResolution.width < 1600
                    ? "scroll"
                    : "hidden",
                overflowY: "hidden",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <h4 className={classes.heading}>Bounce Rate by Channel</h4>
                  <div
                    style={{
                      width:
                        dataForBounceRateByChannel.length > 8 &&
                        screenResolution.width < 1600
                          ? 1700
                          : "100%",
                    }}
                  >
                    <hr style={{ width: "100%" }} />
                  </div>
                  {dataForBounceRateByChannel &&
                  dataForBounceRateByChannel.length > 0 ? (
                    <ResponsiveContainer
                      width={
                        dataForBounceRateByChannel.length > 8 &&
                        screenResolution.width < 1600
                          ? 1700
                          : "100%"
                      }
                      height={250}
                    >
                      <BarChart
                        data={dataForBounceRateByChannel}
                        barCategoryGap={3}
                      >
                        <CartesianGrid
                          strokeDasharray="3 3"
                          stroke="#E3E6F4"
                          vertical={false}
                        />
                        <XAxis
                          dataKey="channel"
                          interval={0}
                          // interval="preserveStartEnd"
                          textAnchor="middle"
                          tick={{
                            dy: 5,
                            width: 50,
                            wordBreak: "break-all",
                          }}
                        />
                        <YAxis tickFormatter={PercentageFormatter} />
                        <Tooltip
                          cursor={false}
                          content={(props) => bounceRateByChannelToolTip(props)}
                        />
                        <Legend
                          verticalAlign="bottom"
                          margin={{ top: 50 }}
                          wrapperStyle={{ paddingTop: "25px" }}
                        />
                        <Bar
                          barSize={20}
                          name="High Bounce Rate"
                          dataKey="currBounceRate"
                          fill="#EB3223"
                        >
                          {dataForBounceRateByChannel.map((item) => {
                            const color =
                              item.currBounceRate > 50.0
                                ? "#EB3223"
                                : "#74839C";
                            return <Cell fill={color} />;
                          })}
                        </Bar>
                        {compareSwitch ? (
                          <Bar
                            barSize={20}
                            name="Previous Period"
                            dataKey="prevBounceRate"
                            fill="#B6C2D1"
                          >
                            {dataForBounceRateByChannel.map((item) => {
                              const color =
                                item.prevBounceRate > 50.0
                                  ? "#B6C2D1"
                                  : "#B6C2D1";
                              return <Cell fill={color} />;
                            })}
                          </Bar>
                        ) : null}
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <div style={{ margin: "20px", textAlign: "center" }}>
                      <div>
                        {/* <CircularProgress color={"secondary"} /> */}
                      </div>
                      <Typography>No data to display</Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </div>
          <div style={{ marginTop: "20px" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.bounceRateContentGrid}
              >
                <Typography
                  style={{ margin: "10px" }}
                  className={classes.content}
                >
                  A website with a high bounce rate is likely not engaging.
                  Optimize your website to retain visitors exploring it and
                  interacting with your content.
                </Typography>
              </Grid>
            </Grid>
            {/* </Paper> */}
          </div>
          {/* <div style={{ marginTop: "15px" }}>
        <h4 className={classes.heading1}>Select Slot for free Consultation</h4>
        <br />
        <Grid container spacing={2}>
          {calender.map((items, index) => (
            <Grid item>
              <Card
                className={[
                  items.date == calender[today].date
                    ? classes.selectedDate
                    : classes.card,
                ]}
                key={index}
                style={{
                  border:
                    selected === index
                      ? "solid 2px #F7931E"
                      : "solid 2px #FFFFFF",
                }}
                onClick={() => handleOnClick(index, items)}
              >
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography className={classes.month}>
                      {items.month}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography className={classes.year}>
                      {items.year}{" "}
                    </Typography>
                  </Grid>
                </Grid>
                <CardContent style={{ backgroundColor: "#F5F6FA" }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={classes.date}>
                        {items.day}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography className={classes.day}>
                        {items.dayText}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography>
                        <IconText
                          classes={classes}
                          icon={
                            items.date == selectedDate ? (
                              <span className={classes.selectedCalender}>
                                {icons.calenderChecked}
                              </span>
                            ) : null
                          }
                          text={<b>9:30 AM ET </b>}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div> */}
          {/* <div style={{ marginTop: "20px" }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Typography
              style={{ font: "lato", fontSize: "14px", fontWeight: "400" }}
            >
              Netelixir is inviting you to a scheduled meeting on {selectedDate}
              &nbsp; 9:30 AM ET.
            </Typography>
          </Grid>
        </Grid>
        <ButtonBase className={classes.button}>
          <h4 className={classes.btnTypography}>Free Consultation</h4>
        </ButtonBase>
      </div> */}
        </div>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className={classes.progressBar}
        >
          <LinearProgressBar
            status={completedStatus}
            percentage={completedValue}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  fetchGASessionsAndTransactionsDataReducer:
    state.fetchGASessionsAndTransactionsDataReducer,
  fetchStatesSessionAndBounceRateDataReducer:
    state.fetchStatesSessionAndBounceRateDataReducer,
  connectionsDataReducer: state.connectionsDataReducer,
  fetchDateRangeForGADataReducers: state.fetchDateRangeForGADataReducers,

  saveAnalyAccDataReducer: state.saveAnalyAccDataReducer,
  fetchSynCheckIDReducer: state.fetchSynCheckIDReducer,
  fetchStatusForProgressBarReducer: state.fetchStatusForProgressBarReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchGASessionsAndTransactionsData: (
    accountIdConnection,
    propertyIdAPI,
    viewIdAPI,
    selectedPeriod
  ) =>
    dispatch(
      fetchGASessionsAndTransactionsData(
        accountIdConnection,
        propertyIdAPI,
        viewIdAPI,
        selectedPeriod
      )
    ),

  fetchStatesSessionAndBounceRateData: (
    accountIdConnection,
    propertyIdAPI,
    viewIdAPI,
    selectedPeriod
  ) =>
    dispatch(
      fetchStatesSessionAndBounceRateData(
        accountIdConnection,
        propertyIdAPI,
        viewIdAPI,
        selectedPeriod
      )
    ),
  fetchConnectionRelatedInfo: () => dispatch(fetchConnectionRelatedInfo()),

  fetchDateRangeForGAData: (selectedPeriod) =>
    dispatch(fetchDateRangeForGAData(selectedPeriod)),

  fetchSynCheckId: () => dispatch(fetchSynCheckId()),
  fetchStatusForProgressBar: (synCheckId) =>
    dispatch(fetchStatusForProgressBar(synCheckId)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(googleAnalyticsDashboardPage));
