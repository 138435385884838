
  
import * as types from "./actionTypes";

export const domainUrl = "http://localhost:8080";
import Cookies from "js-cookie";
export function fetchProductFeedIssue(clientId) {
    return async function (dispatch) {
      const url = `/gmc-product-status.json?clientId=${clientId}`;

      dispatch({
        type: types.FETCH_PRODUCT_FEED_ISSUE_REQUESTED,
        clientId: clientId,
      });
      
      try {
        let response = await fetch(url, {
          method: "POST",  // Use POST request as expected by the server
          headers: {
            Accept: "application/json",
            'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
          },
     
        });
     
        let data = await response.json();  // Expect a JSON response
      
        dispatch({
          type: types.FETCH_PRODUCT_FEED_ISSUE_RECEIVED,
          data,
        });
        
        return data;
      } catch (e) {
        dispatch({
          type: types.FETCH_PRODUCT_FEED_ISSUE_FAILED,
        });
        console.error("Error fetching WHS scores: ", e);
      }
    };
  }
  