import * as types from "./actionTypes";

// import {googleAnalyticsDomainUrl,APIgateway,localAPIgatewayUrl} from './jumpstartDomain'
import { testAPIGateWayUrl } from "./jumpstartDomain";
import Cookies from "js-cookie";

export function fetchAuthGoogleAnalytics_URL(clientId, applicationName) {
  return async function (dispatch) {
    const url =
      testAPIGateWayUrl +
      "/google-api/analytics/auth-url?clientId=" +
      clientId +
      "&applicationName=" +
      applicationName;
    dispatch({
      type: types.FETCH_GOOGLE_ANALYTICS_URL_DATA_REQUESTED_NEWDASHBOARD,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("jwt_token")}`,
        },
      });
      let data = await response.json();

      console.log("The GA URLs data is ", data);
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_URL_DATA_RECEIVED_NEWDASHBOARD,
        data,
      });

      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_URL_DATA_FAILED_NEWDASHBOARD,
      });
    }
  };
}

export function storeGoogleAnalyticsUserAccount(
  clientId,
  accountId,
  accountName,
  propertyId,
  propertyName,
  websiteUrl
) {
  return async function (dispatch) {
    let accountDTO = {
      clientId: clientId,
      accountId: accountId,
      accountName: accountName,
      propertyId: propertyId,
      propertyName: propertyName,
      websiteUrl: websiteUrl,
    };
    const url =
      testAPIGateWayUrl + "/google-api/analytics/store-user-acc-data.json";
    dispatch({
      type: types.FETCH_GOOGLE_ANALYTICS_STORE_USER_ACCOUNT_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("jwt_token")}`,
        },
        body: JSON.stringify(accountDTO),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_STORE_USER_ACCOUNT_DATA_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_STORE_USER_ACCOUNT_DATA_FAILED,
      });
    }
  };
}

export function fetchGoogleAnalyticsIntegrationNewDashboard(analyticsId) {
  return async function (dispatch) {
    console.log(
      "fetchGoogleAnalyticsViewsAndPropertiesNewDashboard=>" + analyticsId
    );

    const url =
      testAPIGateWayUrl +
      "/google-api/analytics/analytics-integration.json?analyticsId=" +
      analyticsId;

    dispatch({
      type: types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_REQUESTED_NEWDASHBOARD,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("jwt_token")}`,
        },
      });

      let data = await response.json();
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_RECEIVED_NEWDASHBOARD,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_FAILED_NEWDASHBOARD,
      });
    }
  };
}

export function fetchGoogleAnalyticsDownloadStatusNewDashboard(syncCheckId) {
  return async function (dispatch) {
    console.log(
      "fetchGoogleAnalyticsViewsAndPropertiesNewDashboard=>" + syncCheckId
    );

    const url =
      testAPIGateWayUrl +
      "/google-api/analytics/analytics-download-status.json?syncCheckId=" +
      syncCheckId;

    dispatch({
      type: types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_DOWNLOAD_STATUS_NEWDASHBOARD_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("jwt_token")}`,
        },
      });

      let data = await response.json();
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_DOWNLOAD_STATUS_NEWDASHBOARD_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_INTEGRATION_DOWNLOAD_STATUS_NEWDASHBOARD_FAILED,
      });
    }
  };
}

export function fetchGoogleAnalyticsViewsAndPropertiesNewDashboard(
  addAccountStatus,
  clientId,
  analyticsId,
  applicationName
) {
  return async function (dispatch) {
    console.log(
      "fetchGoogleAnalyticsViewsAndPropertiesNewDashboard=>",
      addAccountStatus,
      clientId,
      analyticsId,
      applicationName
    );
    // const url =
    //   domainUrl + "/google-api/analytics/fetch-gadata-views-properties?addAccountStatus="+addAccountStatus+"&clientId="+clientId+"&analyticsId="+analyticsId+"&applicationName="+applicationName;
    const url =
      testAPIGateWayUrl +
      "/google-api/analytics/fetch-gadata-views-properties?addAccountStatus=" +
      addAccountStatus +
      "&clientId=" +
      clientId +
      "&analyticsId=" +
      analyticsId +
      "&applicationName=" +
      applicationName;

    dispatch({
      type: types.FETCH_GOOGLE_ANALYTICS_VIEWS_PROPERTIES_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("jwt_token")}`,
        },
        // body: JSON.stringify(accountData),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_VIEWS_PROPERTIES_DATA_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_VIEWS_PROPERTIES_DATA_FAILED,
      });
    }
  };
}

export function fetchProductPerformanceReportData(
  accountId,
  propertyId,
  startDate,
  endDate,
  timeUnit,
  channels,
  devices,
  products,
  locations,
  gender,
  sortBy,
  direction,
) {
  return async function (dispatch) {
    let GoogleAnalyticsReportRequest = {
      accountId: accountId,
      propertyId: propertyId,
      startDate: startDate,
      endDate: endDate,
      timeUnit: timeUnit,
      filters: {
        channels: [],
        devices: [],
        products: [],
        locations: [],
        gender: [],
      },
      sorts: [
        {
          sortBy: sortBy,
          direction: direction,
        },
      ],
    };
    // const domainUrl="http://localhost:8765";
    const url =
      testAPIGateWayUrl + "/google-api/analytics/product-performance-report";
    dispatch({
      type: types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_REQUESTED,
    });

    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(GoogleAnalyticsReportRequest),
      });
      let data = await response.json();

      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_FAILED,
      });
    }
  };
}
// export function fetchProductPerformanceReportData(accountId, propertyId, startDate, endDate, timeUnit, channels, devices, products, locations, gender, sortBy, direction,token) {

//   return async function (dispatch) {

//     let GoogleAnalyticsReportRequest = {
//       accountId: accountId,
//       propertyId: propertyId,
//       startDate: startDate,
//       endDate: endDate,
//       timeUnit: timeUnit,
//       filters: {
//         channels: [],
//         devices: [
//         ],
//         products: [

//         ],
//         locations: [

//         ],
//         gender: [

//         ]
//       },
//       sorts: [
//         {
//           sortBy: sortBy,
//           direction: direction,
//         }
//       ]
//     }
//   // const domainUrl="http://localhost:8765";
//     const url = domainUrl + "/google-api/analytics/product-performance-report";
//     dispatch({
//       type: types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_REQUESTED,
//     });

//     try {
//       let response = await fetch(url, {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(GoogleAnalyticsReportRequest),
//       });
//       let data = await response.json();

//       console.log("Google Analytics Product Performance data====", data);
//       dispatch({
//         type: types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_RECEIVED,
//         data,
//       });
//       return data;
//     } catch (e) {
//       dispatch({
//         type: types.FETCH_GOOGLE_ANALYTICS_PRODUCT_PERFORMANCE_REPORT_DATA_FAILED,
//       });
//     }
//   };
// }
export function fetchOverallPerformanceReportData(
  accountId,
  propertyId,
  startDate,
  endDate,
  timeUnit,
  channels,
  devices,
  products,
  locations,
  gender,
  sortBy,
  direction
) {
  return async function (dispatch) {
    let GoogleAnalyticsReportRequest = {
      accountId: accountId,
      propertyId: propertyId,
      startDate: startDate,
      endDate: endDate,
      timeUnit: timeUnit,
      filters: {
        channels: [],
        devices: [],
        products: [],
        locations: [],
        gender: [],
      },
      sorts: [
        {
          sortBy: sortBy,
          direction: direction,
        },
      ],
    };
    const url =
      testAPIGateWayUrl + "/google-api/analytics/overall-performance-report";
    dispatch({
      type: types.FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get('jwt_token')}`,
        },
        body: JSON.stringify(GoogleAnalyticsReportRequest),
      });
      let data = await response.json();

      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_OVERALL_PERFORMANCE_REPORT_DATA_FAILED,
      });
    }
  };
}

export function fetchChannelPerformanceReportData(
  accountId,
  propertyId,
  startDate,
  endDate,
  timeUnit,
  channels,
  devices,
  products,
  locations,
  gender,
  sortBy,
  direction
) {
  return async function (dispatch) {
    let GoogleAnalyticsReportRequest = {
      accountId: accountId,
      propertyId: propertyId,
      startDate: startDate,
      endDate: endDate,
      timeUnit: timeUnit,
      filters: {
        channels: channels,
        devices: devices,
        products: products,
        locations: locations,
        gender: gender,
      },
      sorts: [
        {
          sortBy: sortBy,
          direction: direction,
        },
      ],
    };
    const url =
      testAPIGateWayUrl + "/google-api/analytics/channel-performance-report";
    dispatch({
      type: types.FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_REQUESTED,
    });
    try {
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get('jwt_token')}`,
        },
        body: JSON.stringify(GoogleAnalyticsReportRequest),
      });
      let data = await response.json();
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_RECEIVED,
        data,
      });
      return data;
    } catch (e) {
      dispatch({
        type: types.FETCH_GOOGLE_ANALYTICS_CHANNEL_PERFORMANCE_REPORT_DATA_FAILED,
      });
    }
  };
}
