import * as types from "./actionTypes";
// import { amazonDomainUrl } from "./jumpstartDomain";
import { testAPIGateWayUrl } from "./jumpstartDomain";
import Cookies from "js-cookie";
export function fetchAmazonAuthURL(clientId, appName) {
    return async function (dispatch) {
      const url = testAPIGateWayUrl + `/amazon-api/auth-url?clientId=${clientId}&appName=${appName}`;
      dispatch({
        type: types.AMAZON_AUTH_URL_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method:"GET",
          headers:{
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
        });
        let data = await response.text();   
        dispatch({
          type: types.AMAZON_AUTH_URL_RECEIVED,
          data,
        });
        return data;
      }
      catch(e){
        dispatch({
          type: types.AMAZON_AUTH_URL_FAILED,
        });
      }
    }
  }



  export function fetchAmazonProfiles(status, amazonId, clientId, appName) {
    return async function (dispatch) {
      const url = testAPIGateWayUrl + `/amazon-api/fetch-amazon-profiles?addAccountStatus=${status}&amazonId=${amazonId}&clientId=${clientId}&appName=${appName}`;
      dispatch({
        type: types.AMAZON_PROFILES_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method:"POST",
          credentials:"include",
          headers:{ 
            Accept: "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
        });
        let data = await response.json();   
        dispatch({
          type: types.AMAZON_PROFILES_RECEIVED,
          data,
        });
        return data;
      }
      catch(e){
        dispatch({
          type: types.AMAZON_PROFILES_FAILED,
        });
      }
    }
  }


  export function storeAmazonProfile(accountData,clientId,appName) {
    return async function (dispatch) {
      const url = testAPIGateWayUrl + `/amazon-api/store-amazon-account-data?clientId=${clientId}&appName=${appName}`;
      dispatch({
        type: types.SAVE_AMAZON_PROFILES_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
          body: JSON.stringify(accountData),
        });
        let data = await response.json();
        dispatch({
          type: types.SAVE_AMAZON_PROFILES_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.SAVE_AMAZON_PROFILES_FAILED,
        });
      }
    };
  }
  

  export function startAmazonAccIntegration(amazonId) {
    return async function (dispatch) {
      const url = `${testAPIGateWayUrl}/amazon-api/start-amazon-data-integration/${amazonId}`;
      dispatch({
        type: types.START_AMAZON_ACC_INTEGRATION_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
        });
        let data = await response.json();
        dispatch({
          type: types.START_AMAZON_ACC_INTEGRATION_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.START_AMAZON_ACC_INTEGRATION_FAILED,
        });
      }
    };
  }
 


  export function fetchAmazonAccDetails(clientId) {
    return async function (dispatch) {
      const url = `${testAPIGateWayUrl}/amazon-api/fetch-account?clientId=${clientId}`;
      dispatch({
        type: types.FETCH_AMAZON_ACC_DATA_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
        });
        
        let data = await response.json();
        dispatch({
          type: types.FETCH_AMAZON_ACC_DATA_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.FETCH_AMAZON_ACC_DATA_FAILED,
        });
      }
    };
  }


  export function deleteAmazonAcc(accountData) {
    return async function (dispatch) {
      const url = `${testAPIGateWayUrl}/amazon-api/delete-account`;
      dispatch({
        type: types.DELETE_AMAZON_ACC_DATA_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "DELETE",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
          body: JSON.stringify(accountData),
        });
        let data = await response.json();
        dispatch({
          type: types.DELETE_AMAZON_ACC_DATA_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.DELETE_AMAZON_ACC_DATA_FAILED,
        });
      }
    };
  }

  export function updateAmazonAccessToken(amazonId) {
    return async function (dispatch) {
      const url = `${testAPIGateWayUrl}/amazon-api/update-tokens/${amazonId}`;
      dispatch({
        type: types.AMAZON_ACCESS_TOKEN_UPDATE_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "PUT",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
        });
        let data = await response.json();
        dispatch({
          type: types.AMAZON_ACCESS_TOKEN_UPDATE_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.AMAZON_ACCESS_TOKEN_UPDATE_FAILED,
        });
      }
    };
  }

  

  export function fetchAmazonAccRevenuePerformanceData(profileId,startDate,endDate,campareFlag,timeUnit,adProducts,reportType) {
    return async function (dispatch) {
      let accountData = {
        profileId: profileId,
        selectedFromDate: startDate,
        selectedToDate: endDate,
        campareFlag: campareFlag,
        timeUnit: timeUnit,
        adProducts:adProducts,
        reportType: reportType,
      };
    
      const url = `${testAPIGateWayUrl}/amazon-api/fetch-campaign-data`;
      dispatch({
        type: types.FETCH_AMAZON_ACC_DATA_REV_PERF_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
          body: JSON.stringify(accountData),

        });
        let data = await response.json();
        dispatch({
          type: types.FETCH_AMAZON_ACC_DATA_REV_PERF_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.FETCH_AMAZON_ACC_DATA_REV_PERF_FAILED,
        });
      }
    };
  }
 


  export function fetchAmazonDownloadStatus(syncCheckId) {
    return async function (dispatch) {
      const url = `${testAPIGateWayUrl}/amazon-api/amazon-download-status.json`+`?syncCheckId=${syncCheckId}`;
      dispatch({
        type: types.AMAZON_ADS_DOWNLOAD_STATUS_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
        });
        let data = await response.json();
        dispatch({
          type: types.AMAZON_ADS_DOWNLOAD_STATUS_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.AMAZON_ADS_DOWNLOAD_STATUS_FAILED,
        });
      }
    };
  }
 
  export function fetchAmazonSyncCheckId(amazonId) {
    return async function (dispatch) {
      const url = `${testAPIGateWayUrl}/amazon-api/fetch-amazon-sync-check-id/${amazonId}`;
      dispatch({
        type: types.FETCH_AMAZON_SYNC_CHECK_ID_REQUESTED,
      });
      try {
        let response = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get('jwt_token')}`,
          },
        });
        let data = await response.json();
        dispatch({
          type: types.FETCH_AMAZON_SYNC_CHECK_ID_RECEIVED,
          data,
        });
        return data;
      } catch (e) {
        dispatch({
          type: types.FETCH_AMAZON_SYNC_CHECK_ID_FAILED,
        });
      }
    };
  }
 
