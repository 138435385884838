import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import icons from "../../components/icons";
import JsHeader from "./jsHeader";
import { Tab, Tabs } from "@mui/material";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import { Authority } from "./commonComponents/jumpstartEnum";
import { fetchUserInfo } from "../../actions/jumpstartRevamped";
import { fetchUserInfoReducer } from "../../reducers/jumpstartRevampedReducer";
import { connect } from "react-redux";
import Footer from "../footer/footer";
import Cookies from "js-cookie";
const drawerWidth = 100;

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    color: "#FFF",
    fontSize: "0.875rem",
    "&.Mui-selected": {
      backgroundColor: "#293D57",
      color: "#FFF",
    },
  })
);

function JsSideMenuBar(props) {
  const { fetchUserInfo, fetchUserInfoReducer } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const [open, setOpen] = React.useState(!isSmallScreen);

  const handleDrawerToggle = (event) => {
    setOpen(!open);
  };

  const [value, setValue] = useState(0);
  const history = useHistory();

  const [authority, setAuthority] = useState();

  const [userName, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [jwtToken, setJwtToken] = React.useState(
    Cookies.get("js_jwtToken")
  );

  useEffect(async () => {
    const data = await fetchUserInfo(jwtToken);
    if (data == undefined || (data && data.status == 401)) {
      sessionStorage.removeItem("jwtToken");
      sessionStorage.removeItem("loggedOut");
      history.push("/jumpstart-login");
    }
  }, []);

  useEffect(() => {
    if (fetchUserInfoReducer.message == "JS_USER_INFO_RECEIVED") {
      setUsername(fetchUserInfoReducer.login);
      setEmail(fetchUserInfoReducer.email);
      setAuthority(fetchUserInfoReducer.authority);
    }
  }, [fetchUserInfoReducer]);

  useEffect(() => {
    // Update the tab value based on the current URL when the component mounts
    const currentPath = history.location.pathname;
    if (currentPath === "/jumpstart-dashboard") {
      setValue(0);
    } else if (currentPath === "/jumpstart-user-management") {
      setValue(1);
    }
  }, [history.location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Use the newValue to determine which tab is selected and redirect accordingly
    switch (newValue) {
      case 0:
        history.push("/jumpstart-dashboard");
        break;
      case 1:
        history.push("/jumpstart-user-management");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <JsHeader
          isSmallScreen={isSmallScreen}
          handleDrawerToggle={handleDrawerToggle}
          open={open}
          userName={userName}
          email={email}
        />
        <Drawer
          variant={isSmallScreen ? "temporary" : "permanent"}
          open={open}
          onClose={handleDrawerToggle}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#1E334E",
              color: "#fff",
            },
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Toolbar />
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            sx={{
              ["& .MuiTabs-indicator"]: {
                right: "auto",
                width: "4px",
              },
            }}
          >
            <AntTab icon={icons.AccountsMenu} label="ACCOUNTS" id="accounts" />
            {authority == Authority.ADMIN || authority == Authority.MANAGER ? (
              <AntTab icon={icons.UsersMenu} label="USERS" id="users" />
            ) : (
              ""
            )}
          </Tabs>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          {props.children}
        </Box>
      </Box>
    </>
  );
}
const mapStateToProps = (state) => ({
  fetchUserInfoReducer: state.fetchUserInfoReducer,
});
const mapDispatchToProps = (dispatch) => ({
  fetchUserInfo: (jwtToken) => dispatch(fetchUserInfo(jwtToken)),
});
export default connect(mapStateToProps, mapDispatchToProps)(JsSideMenuBar);
