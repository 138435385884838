import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import "./jumpstart.css";
import JumpstartMain from "./jumpstartMain.jsx";
import JsBusinessDetails from "./jsBusinessDetails.jsx";
import PageHeader from "./commonComponents/pageHeaderDetails.jsx";
import CustomTabs from "./customTabs.jsx";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import icons from "../../components/icons.js";
import JsCampaignDetails from "./jsCampaignDetails.jsx";
import {
  fetchCampaignDetailsByJsAccountId,
  storeUpdatedCampaignData,
  campaignFrameworkDownload,
  fetchJsAccountById,
} from "../../actions/jumpstartRevamped.js";
import { JsTextAsButton } from "../../components/buttons.jsx";
import Cookies from "js-cookie";
function JsCampaignFramework(props) {
  const {
    fetchUserInfoReducer,
    campaignFrameworkDownload,
    fetchJsAccountByIdReducer,
    fetchJsAccountById,
  } = props;


  const token = Cookies.get("js_jwtToken");

  const { id } = useParams(); //jumpstart account id
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabs = [{ label: "Business Details" }, { label: "Campaign Details" }];

  const [authority, setAuthority] = useState();
  const [disableCampaignTab, setDisableCampaignTab] = useState(null);

  useEffect(() => {
    if (fetchUserInfoReducer.message == "JS_USER_INFO_RECEIVED") {
      setAuthority(fetchUserInfoReducer.authority);
    }
  }, [fetchUserInfoReducer]);



  const handleTabChange = (event, tabIndex) => {
    setCurrentTabIndex(tabIndex);
    setValue1(0);
  };



  useEffect(async () => {
    await fetchJsAccountById(token, id);
  },[])

  const [value1, setValue1] = useState(0);

  const handleChange = (event, newValue) => {
    setValue1(newValue);
  };

  const handleDownload = () => {
    if(fetchJsAccountByIdReducer.message == "JS_ACCOUNT_BY_ID_RECEIVED" && fetchJsAccountByIdReducer.id == id ){
      const data = campaignFrameworkDownload(token,id,fetchJsAccountByIdReducer.companyName);
    }
  }
  return (
    <JumpstartMain>
      <div className="pageContent">
        <PageHeader sectionHeading={"Campaign Framework"} token={token} />
      </div>
      <div className="pageContainer">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ borderBottom: "1px solid #E6E6E6" }}
        >
          <Grid item xs={8}>
            <CustomTabs
              variant="standard"
              tabs={tabs}
              currentTabIndex={currentTabIndex}
              handleTabChange={handleTabChange}
              tabcolor="#4E4E4E"
              disabled={disableCampaignTab}
            />
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Grid item>
              <JsTextAsButton color="inherit" onClick={() => handleDownload()}>
                <span>{icons.ExcelDownload}</span>&nbsp;Download
              </JsTextAsButton>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <div>
                  <JsSubSectionTypography className="centered-container">
                    <span>
                      <RemoveRedEyeOutlinedIcon sx={{ color: "#F58120" }} />
                    </span>
                    &nbsp;Preview
                  </JsSubSectionTypography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <div>
                  <JsSubSectionTypography className="centered-container">
                    <span>{icons.ExcelDownload}</span>&nbsp;Download
                  </JsSubSectionTypography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <div>
                  <JsSubSectionTypography className="centered-container">
                    <span>{icons.ExcelUpload}</span>&nbsp;Upload
                  </JsSubSectionTypography>
                </div>
              </Grid> */}
          </Grid>
        </Grid>

        {currentTabIndex === 0 && (
          <Box sx={{ p: 3 }}>
            <JsBusinessDetails
              handleTabChange={handleTabChange}
              authority={authority}
              handleCampaignDetailsDisable={setDisableCampaignTab}
              id={id}
              jwtToken={token}
            />
          </Box>
        )}
        {currentTabIndex === 1 && (
          <JsCampaignDetails
          id={id}
          jwtToken = {token}
          authority={authority}
          value1={value1}
          handleChange={handleChange}
          /> 
        )
        }
      </div>
    </JumpstartMain>
  );
}

const mapStateToProps = (state) => ({
  storeUpdatedCampaignDataReducer: state.storeUpdatedCampaignDataReducer,
  storeAdgroupDataReducer: state.storeAdgroupDataReducer,
  fetchUserInfoReducer: state.fetchUserInfoReducer,
  campaignFrameworkDownloadReducer: state.campaignFrameworkDownloadReducer,
  fetchJsAccountByIdReducer: state.fetchJsAccountByIdReducer,
});
const mapDispatchToProps = (dispatch) => ({
  storeUpdatedCampaignData: (jwtToken, updatedCampaignData) =>
    dispatch(storeUpdatedCampaignData(jwtToken, updatedCampaignData)),
  campaignFrameworkDownload: (jwtToken, id, companyName) =>
    dispatch(campaignFrameworkDownload(jwtToken, id,companyName)),
    fetchJsAccountById: (jwtToken, id) =>
    dispatch(fetchJsAccountById(jwtToken, id)),  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JsCampaignFramework);
