import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  Typography,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import icons from "../../components/icons";
import { authenticateUser } from "../../actions/jumpstartRevamped";
import "./jumpstart.css";
import JsUserLoginDetails from "./userLoginDetails";
import { emailValidationMtd } from "../commonFunctions/commonFunctions";
import JumpstartExternalFooter from "./commonComponents/jumpstartExternalFooter";
import Cookies from "js-cookie";
import { storeTokensInCookies } from "../../actions/jumpstartDomain";
function JsLogin(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    var footerElement = document.getElementById("external-footer");
    if (footerElement) {
      footerElement.style.display = "none";
    }
    var headerElement = document.getElementById("homepage-header");
    if (headerElement) {
      headerElement.style.display = "none";
    }
  }, []);

  const { authenticateUser, authenticateUserReducer } = props;

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState("");
  const [allCapsError, setAllCapsError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailFormatError("");
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleRememberChange = (e) => {
    //we are not taking e.target.value because we are not getting true or false there we are only getting "on", which is causing issue while validating
    setRememberMe(e.currentTarget.checked);
  };

  const handleSignIn = async (e) => {
    let data = "";
    setLoading(true);

    if (emailValidationMtd(email)) {
      data = await authenticateUser(email, password, rememberMe);
      if (data && data.status == 200) {
        storeTokensInCookies("js_jwtToken", data.jwt_token,'strict');
        // sessionStorage.setItem("jwtToken", data.jwt_token);
        window.location.href = "/jumpstart-dashboard";
      } else {
        Cookies.remove("js_jwtToken");
        // sessionStorage.removeItem("jwtToken");
        setError(true);
      }
    } else {
      setEmailFormatError("Please enter valid email format");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mainDiv">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={8} sm={8} md={5} lg={5} xl={5}>
            <JsUserLoginDetails
              handleEmailChange={handleEmailChange}
              handlePasswordChange={handlePasswordChange}
              handleRememberChange={handleRememberChange}
              handleSignIn={handleSignIn}
              error={error}
              loading={loading}
              emailFormatError={emailFormatError}
            />
          </Grid>
        </Grid>
      </div>
      <JumpstartExternalFooter />
    </>
  );
}

const mapStateToProps = (state) => ({
  authenticateUserReducer: state.authenticateUserReducer,
});
const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (email, password, rememberMe) =>
    dispatch(authenticateUser(email, password, rememberMe)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JsLogin);
