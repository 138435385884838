import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { chunkText } from "../../NewInterfacePages/commonFunctions/commonFunctions";
import axios from "axios";
import "./chatbottest.css";
import ChatWelcomePage from "./ChatbotWelcome";
import ChatConversionalAI from "./ChatConversionalAI";
import { ThemeProvider } from "styled-components";
import { ChatBotTheme } from "./ChatbotTheme";
import { testAPIGateWayUrlChatbot } from "../../actions/jumpstartDomain";
import Cookies from "js-cookie";
const ChatWindow = (props) => {
  const { isOpen, onClose, userDetails } = props;
  const [messages, setMessages] = useState([]);
  const [isWelcomePage, setIsWelcomePage] = useState(true); // Default to true to show welcome page initially
  const chatWindowRef = useRef(null);

  const sendMessage = async (input) => {
    setIsWelcomePage(false);
    if (input.trim()) {
      const newMessages = [...messages, { text: input, isUser: true }];
      setMessages(newMessages);
      // Add typing indicator
      const typingIndicator = {
        text: "Typing...",
        isUser: false,
        isTyping: true,
      };
      setMessages((prevMessages) => [...prevMessages, typingIndicator]);

      const url = testAPIGateWayUrlChatbot;
      const msg = input;

      const customData = {
        sender: userDetails.userInfo.userId,
        message: msg,
        metadata: {
          user_id: userDetails.userInfo.userId,
          ppc_id: userDetails.selectedAccountInfo.ppcId,
          client_id: userDetails.selectedAccountInfo.clientId,
        },
      };

      try {
        // Make API call
        const response = await axios.post(url, customData, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${Cookies.get('jwt_token')}`,
          },
        });

        // Handle bot response
        const botResponses = response.data.map((item) => ({
          text: item.text,
          isUser: false,
        }));

        // Remove typing indicator and add bot responses in chunks
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => !msg.isTyping)
        );

        // Display chunks sequentially
        for (const botResponse of botResponses) {
          const chunks = chunkText(botResponse.text, 200); // Adjust chunk size as needed

          for (const chunk of chunks) {
           
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay as needed
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: chunk, isUser: false },
            ]);
          }
        }
      } catch (error) {
        console.error("Error sending message:", error);

        // Remove typing indicator and add error message
        setMessages((prevMessages) => [
          ...prevMessages.filter((msg) => !msg.isTyping),
          { text: "Error: Could not send message", isUser: false },
        ]);
      }
    }
  };

  const handleHomePageClick = (flag) => {
    setIsWelcomePage(flag);
  };

  const handleClickOutside = (event) => {
    if (
      chatWindowRef.current &&
      !chatWindowRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return isOpen ? (
    isWelcomePage ? (
      <ThemeProvider theme={ChatBotTheme}>
        <ChatWelcomePage
          onSend={sendMessage}
          userName={userDetails.userInfo.username}
          chatWindowRef={chatWindowRef}
        />
      </ThemeProvider>
    ) : (
      <ChatConversionalAI
        onClose={onClose}
        onSend={sendMessage}
        messages={messages}
        onHomeIconClick={handleHomePageClick}
        chatWindowRef={chatWindowRef}
      />
    )
  ) : null;
};

const mapStateToProps = (state) => ({
  userDetails: state.userInformation,
});

export default connect(mapStateToProps)(ChatWindow);
