// import * as types from "../actions/actionTypes";
// export default function googleAnalyticsDataReducers(state = { message: "" }, action) {
//   console.log("action.type----->", action.type);
//   switch (action.type) {

//     case types.FETCH_GOOGLE_ANALY_URL_DATA_REQUESTED:
//       return Object.assign({}, state, {
//         message: "FETCH_GOOGLE_ANALY_URL_DATA_REQUESTED",
//       });
//     case types.FETCH_GOOGLE_ANALY_URL_DATA_RECEIVED:
//       let { data } = action;
//       console.log("action Type==>",action)
//       return Object.assign({}, state, {
//         message: "FETCH_GOOGLE_ANALY_URL_DATA_RECEIVED",
//         ...action.data,

//       });
//     case types.FETCH_GOOGLE_ANALY_URL_DATA_FAILED:
//       return Object.assign({}, state, {
//         message: "FETCH_GOOGLE_ANALY_URL_DATA_FAILED",
//       });
//     default:
//       console.log("The state is default ", state);
//       return state;
//   }
// }
import * as types from "../actions/actionTypes";
export function googleAnalyticsURLDataReducers(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALY_URL_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALY_URL_DATA_REQUESTED",
      });
    case types.FETCH_GOOGLE_ANALY_URL_DATA_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_GOOGLE_ANALY_URL_DATA_RECEIVED",
      });
    case types.FETCH_GOOGLE_ANALY_URL_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALY_URL_DATA_FAILED",
      });
    default:
      // console.log("The state is Google Analytics Data ", state);
      return state;
  }
}
// reducer for the Connection Data
export function connectionsDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_CONNECTION_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_CONNECTION_DATA_REQUESTED",
      });
    case types.FETCH_CONNECTION_DATA_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_CONNECTION_DATA_RECEIVED",
      });
    case types.FETCH_CONNECTION_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_CONNECTION_DATA_FAILED",
      });
    default:
      // console.log("The state is Connections Data= ", state);
      return state;
  }
}
// reducer for the Delete Connection Data
export function deleteConnectionsDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.DELETE_ANALY_CONNECTION_REQUESTED:
      return Object.assign({}, state, {
        message: "DELETE_ANALY_CONNECTION_REQUESTED",
      });
    case types.DELETE_ANALY_CONNECTION_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "DELETE_ANALY_CONNECTION_RECEIVED",
      });
    case types.DELETE_ANALY_CONNECTION_FAILED:
      return Object.assign({}, state, {
        message: "DELETE_ANALY_CONNECTION_FAILED",
      });
    default:
      // console.log("The state of Delete Connections Data:", state);
      return state;
  }
}

export function fetchDataAndStoreEngIdDataReducers(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_REQUESTED",
      });
    case types.FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_RECEIVED",
      });
    case types.FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALY_STOREENGID_FETCHDATA_FAILED",
      });
    default:
      // console.log("The state is ", state);
      return state;
  }
}

//reducer for date range API
export function fetchDateRangeForGADataReducers(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_DATE_RANGE_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_DATE_RANGE_REQUESTED",
      });
    case types.FETCH_DATE_RANGE_RECEIVED:
      let { data } = action;
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_DATE_RANGE_RECEIVED",
      });
    case types.FETCH_DATE_RANGE_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_DATE_RANGE_FAILED",
      });
    default:
      // console.log("The state is date range", state);
      return state;
  }
}
//end

export function fetchPropertiesAndViewsDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_REQUESTED",
      });
    case types.FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_RECEIVED:
      let { data } = action;
    
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_RECEIVED",
      });
    case types.FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_GOOGLE_ANALY_FETCH_VIEWS_PROPERTIES_FAILED",
      });
    default:
      // console.log("The state is ", state);
      return state;
  }
}

export function saveAnalyAccDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.STORE_ANALY_USER_ACC_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "STORE_ANALY_USER_ACC_DATA_REQUESTED",
      });
    case types.STORE_ANALY_USER_ACC_DATA_RECEIVED:
      let { data } = action;
      console.log("inside saveAnalyAccDataReducer", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "STORE_ANALY_USER_ACC_DATA_RECEIVED",
      });
    case types.STORE_ANALY_USER_ACC_DATA_FAILED:
      return Object.assign({}, state, {
        message: "STORE_ANALY_USER_ACC_DATA_FAILED",
      });
    default:
      // console.log("The fetchSaveAnalyAccDataReducer state is ", state);
      return state;
  }
}

export function fetchGAModelDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GA_MODEL_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_GA_MODEL_DATA_REQUESTED",
      });

    case types.FETCH_GA_MODEL_DATA_RECEIVED:
      let { data } = action;
      console.log("FETCH_GA_MODEL_DATA_RECEIVED", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_GA_MODEL_DATA_RECEIVED",
      });

    case types.FETCH_GA_MODEL_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_GA_MODEL_DATA_FAILED",
      });
    default:
      return state;
  }
}

export function fetchGASessionsAndTransactionsDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_REQUESTED",
      });
    case types.FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_RECEIVED:
      let { data } = action;
      console.log("FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_RECEIVED", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_RECEIVED",
      });
    case types.FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_GA_SESSIONS_AND_TRANSACTIONS_DATA_FAILED",
      });
    default:
      return state;
  }
}

export function fetchStatesSessionAndBounceRateDataReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_REQUESTED",
      });
    case types.FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_RECEIVED:
      let { data } = action;
      console.log("FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_RECEIVED", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_RECEIVED",
      });
    case types.FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_STATES_SESSIONS_AND_BOUNCE_RATE_DATA_FAILED",
      });
    default:
      return state;
  }
}

export function fetchSynCheckIDReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_SYNCHECKID_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_SYNCHECKID_REQUESTED",
      });
    case types.FETCH_SYNCHECKID_RECEIVED:
      let { data } = action;
      console.log("The data in Reducers", data);
      console.log("We are in Reducers");
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_SYNCHECKID_RECEIVED",
      });
    case types.FETCH_SYNCHECKID_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_SYNCHECKID_FAILED",
      });
    default:
      return state;
  }
}

export function fetchStatusForProgressBarReducer(
  state = {
    message: "",
  },
  action
) {
  switch (action.type) {
    case types.FETCH_STATUS_FOR_PROGRESS_BAR_REQUESTED:
      return Object.assign({}, state, {
        message: "FETCH_STATUS_FOR_PROGRESS_BAR_REQUESTED",
      });
    case types.FETCH_STATUS_FOR_PROGRESS_BAR_RECEIVED:
      let { data } = action;
      console.log("FETCH_STATUS_FOR_PROGRESS_BAR_RECEIVED", data);
      return Object.assign({}, state, {
        ...action.data,
        message: "FETCH_STATUS_FOR_PROGRESS_BAR_RECEIVED",
      });
    case types.FETCH_STATUS_FOR_PROGRESS_BAR_FAILED:
      return Object.assign({}, state, {
        message: "FETCH_STATUS_FOR_PROGRESS_BAR_FAILED",
      });
    default:
      return state;
  }
}

export function googleAnalyticsBannerServiceReducer(
  state = {
    message: "",
    isGoogleAnalyticsBanner: true,
  },
  action
) {
  switch (action.type) {
    case types.OPEN_ANALYTICS_BANNER:
      return Object.assign({}, state, {
        isGoogleAnalyticsBanner: true,
        // below is for the holiday banner
        // isShowed: true,
      });
    case types.CLOSE_ANALYTICS_BANNER:
      return Object.assign({}, state, {
        isGoogleAnalyticsBanner: false,
      });
    default:
      return state;
  }
}
