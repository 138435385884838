import * as types from "./actionTypes";

export const domainUrl = "http://localhost:8080";
import Cookies from "js-cookie";

export function fetchWHSScoresByDateRanges(clientId, whsScoresRequest) {
    return async function (dispatch) {
     
      const url = `/whs-scores-by-date-range.json?clientId=${clientId}`;
      dispatch({
        type: types.FETCH_WHS_SCORES_BY_DATE_RANGE_REQUESTED,
        clientId: clientId,
        whsScoresRequest:JSON.stringify(whsScoresRequest)
      });
      
      try {
        let response = await fetch(url, {
          method: "POST",  // Use POST request as expected by the server
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",  // Ensure JSON content type
            'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
          },
          // body: new URLSearchParams({
          //               clientId: clientId,
          //             }),
          body: JSON.stringify(whsScoresRequest),  // Send the request body
        });
        
        let data = await response.json();  // Expect a JSON response
        console.log("WHS Date Range data====", data);
        
        dispatch({
          type: types.FETCH_WHS_SCORES_BY_DATE_RANGE_RECEIVED,
          data,
        });
        
        return data;
      } catch (e) {
        dispatch({
          type: types.FETCH_WHS_SCORES_BY_DATE_RANGE_FAILED,
        });
        console.error("Error fetching WHS scores: ", e);
      }
    };
  }
  